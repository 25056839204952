import ApiService from '../../services/ApiService';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormControl } from '@mui/material';
import '../../styles/AddNewApp.css';
import { toast } from 'react-toastify';
import LoadingSpinner from '../loader/LoadingSpinner';
import '../../styles/LoadingSpinner.css';
import '../../styles/FiltersLayout.scss';
import { MultiSelect } from 'primereact/multiselect';
import { useDispatch } from 'react-redux';
import { setLinkList } from '../../Redux/slices/variantListSlice';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';

const LinkFilter = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedLink, setSelectedLink] = useState({
    linkIds: [],
    filterType: 'link',
    name: [],
    isExcluded: false
  });
  const [linksFilterList, setLinksFilterList] = useState();
  useEffect(() => {
    fetchLinks();
    if (props.filterObj && props.filterObj.linkIds && props.filterObj.linkIds.length > 0) {
      setSelectedLink(props.filterObj);

      props.setIsValid(true);
    } else {
      props.setIsValid(false);
    }
  }, []);

  useEffect(() => {
    props.handleFilterChange(selectedLink);
  }, [selectedLink]);

  const fetchLinks = async () => {
    const requestBody = {
      search: null,
      filterPageNo: '1',
      filterPageSize: '100',
      filterSortOrder: 'ASC'
    };
    try {
      setLoading(true);
      const response = await ApiService.getLinkFilter(
        requestBody,
        props.workgroupId,
        props.projectId
      );
      dispatch(setLinkList(response));
      setLinksFilterList(
        response.map((link) => ({
          label: `${link.fromTask.name} → ${link.toTask.name}`,
          value: link.id
        }))
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t('FILTER.FAILED_TO_FETCH_LINKS_ERROR'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    }
  };

  const handleFilterChange = (value) => {
    setSelectedLink((prevSelectedLink) => ({
      ...prevSelectedLink,
      linkIds: value
    }));
    if (value && value.length > 0) {
      props.setIsValid(true);
    } else {
      props.setIsValid(false);
    }
  };

  const onShow = () => {
    const selectAllCheckbox = document.querySelector('.p-multiselect-select-all-label');
    selectAllCheckbox?.after(`${t('FILTER.SELECT_ALL')}`);
  };

  return (
    <FormControl fullWidth size='small'>
      <label id='filter-task-label'>
        <DashboardTypography id='filter-type-label' typographyClass={'title-14-500'}>
          {t('LINK')}
        </DashboardTypography>
      </label>
      <MultiSelect
        value={selectedLink.linkIds}
        onChange={(e) => handleFilterChange(e.value)}
        options={linksFilterList}
        optionLabel='label'
        placeholder={t('SELECT')}
        maxSelectedLabels={3}
        onShow={onShow}
        id='select-filter-type'
        className='select-filter-type'
      />

      {loading === true && <LoadingSpinner></LoadingSpinner>}
    </FormControl>
  );
};
LinkFilter.propTypes = {
  handleFilterChange: PropTypes.func,
  workgroupId: PropTypes.string,
  projectId: PropTypes.string,
  filterObj: PropTypes.object,
  setIsValid: PropTypes.func
};
export default LinkFilter;
