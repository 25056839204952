export const ENUM_LOCAL_STORAGE = {
  USER_ID: 'userId',
  ACCESS_TOKEN: 'accessToken'
};

export const I18N_LANGUAGES = [
  { label: 'English', value: 'en' },
  { label: 'German', value: 'de' },
  { label: 'French', value: 'fr' },
  { label: 'Spanish', value: 'es' },
  { label: 'Japanese', value: 'ja' }
];
export const RECENT = 'recent';
export const OLDEST = 'oldest';
export const MODIFIED_DATE = 'modifiedDate';
export const DESCENDING = 'desc';
export const A_Z = 'az';
export const Z_A = 'za';
export const ASCENDING = 'asc';
export const NAME = 'name';
export const CONTAINED = 'contained';
export const OUTLINED = 'outlined';
export const GRID = 'Grid';
export const LIST = 'List';
export const ASC_DESC = 'asc-dsc';
export const DURATION = 'duration';
export const DATA_SETS = [
  {
    id: '5bb5eef8-a03d-4f46-ac85-367e132b09ea',
    name: 'Ressource',
    data: [
      {
        label: 'Francis Dussard',
        data: 16
      },
      {
        label: 'Marie Coval',
        data: 22
      },
      {
        label: 'Thomas Durand',
        data: 26
      },
      {
        label: 'Valerie Predut',
        data: 40
      },
      {
        label: 'Katie Grimaldo',
        data: 45
      },
      {
        label: 'Francois Cormier',
        data: 46
      },
      {
        label: 'Julie Sauvage',
        data: 197
      },
      {
        label: 'Paul Flot',
        data: 216
      }
    ]
  },
  {
    id: '5bb5eef8-a03d-4f46-ac85-367e132b09ea',
    name: 'Variants',
    data: [
      {
        label: 'v1',
        data: 0.14473684210526316
      },
      {
        label: 'v2',
        data: 0.12664473684210525
      },
      {
        label: 'v3',
        data: 0.10361842105263158
      },
      {
        label: 'v4',
        data: 0.09703947368421052
      },
      {
        label: 'v5',
        data: 0.07894736842105263
      },
      {
        label: 'v6',
        data: 0.07401315789473684
      },
      {
        label: 'v7',
        data: 0.05263157894736842
      },
      {
        label: 'v8',
        data: 0.04276315789473684
      },
      {
        label: 'v9',
        data: 0.03289473684210526
      },
      {
        label: 'v10',
        data: 0.03289473684210526
      },
      {
        label: 'v11',
        data: 0.029605263157894735
      },
      {
        label: 'v12',
        data: 0.027960526315789474
      },
      {
        label: 'v13',
        data: 0.02138157894736842
      },
      {
        label: 'v14',
        data: 0.019736842105263157
      },
      {
        label: 'v15',
        data: 0.01644736842105263
      },
      {
        label: 'v16',
        data: 0.011513157894736841
      },
      {
        label: 'v17',
        data: 0.008223684210526315
      },
      {
        label: 'v18',
        data: 0.00822368421052631
      },
      {
        label: 'v19',
        data: 0.006578947368421052
      },
      {
        label: 'v20',
        data: 0.004934210526315789
      },
      {
        label: 'v21',
        data: 0.004934210526315799
      },
      {
        label: 'v22',
        data: 0.004934210526315779
      },
      {
        label: 'v23',
        data: 0.004934210526315769
      },
      {
        label: 'v24',
        data: 0.003289473684210526
      },
      {
        label: 'v25',
        data: 0.003289473684210506
      },
      {
        label: 'v26',
        data: 0.003289473684210516
      },
      {
        label: 'v27',
        data: 0.003289473684210536
      },
      {
        label: 'v28',
        data: 0.003289473684210546
      },
      {
        label: 'v29',
        data: 0.003289473684210556
      },
      {
        label: 'v30',
        data: 0.003289473684210566
      },
      {
        label: 'v31',
        data: 0.003289473684210576
      },
      {
        label: 'v32',
        data: 0.001644736842105213
      },
      {
        label: 'v33',
        data: 0.001644736842105223
      },
      {
        label: 'v34',
        data: 0.001644736842105233
      },
      {
        label: 'v35',
        data: 0.001644736842105243
      },
      {
        label: 'v36',
        data: 0.001644736842105253
      },
      {
        label: 'v37',
        data: 0.001644736842105263
      },
      {
        label: 'v38',
        data: 0.001644736842105273
      },
      {
        label: 'v39',
        data: 0.001644736842105283
      },
      {
        label: 'v40',
        data: 0.001644736842105293
      },
      {
        label: 'v41',
        data: 0.001644736842105203
      },
      {
        label: 'v42',
        data: 0.001644736842105113
      }
    ]
  }
];

export const WIDGET_TYPES = {};
export const LAYOUT_TYPES = {
  table: {
    type: 'table',
    label: 'Table'
  },
  tabs: {
    type: 'tabs',
    label: 'Tabs'
  },
  text: {
    type: 'text',
    label: 'Text'
  }
};
export const SUPPORTED_CHART_TYPES = ['bar', 'line', 'pie', 'numeric', 'text', 'bubble'];

export const PAGES_TYPES = [
  { name: 'Workgroups', navigation: 'Workgroups' },
  { name: 'Apps', navigation: 'Apps' }
];
export const SETTINGS_MENU = ['Profile', 'Account', 'Dashboard', 'Logout'];
export const PERIOD_TYPES = [
  {
    periodTypes: [
      { periodType: 'CURRENT_YEAR' },
      { periodType: 'PREVIOUS_YEAR' },
      { periodType: 'CURRENT_MONTH' },
      { periodType: 'PREVIOUS_MONTH' },
      { periodType: 'LAST_30_DAYS' },
      { periodType: 'CUSTOM_PERIOD' }
    ]
  }
];
export const PERIOD_TYPES_LABELS = {
  CURRENT_YEAR: 'Current Year',
  PREVIOUS_YEAR: 'Previous Year',
  CURRENT_MONTH: 'Current Month',
  PREVIOUS_MONTH: 'Previous Month',
  LAST_30_DAYS: 'Last 30 days of the project',
  CUSTOM_PERIOD: 'Custom period of time'
};
export const PROCESS_SCOPE = [
  {
    ProcessScopes: [{ ProcessScope: 'START' }, { ProcessScope: 'END' }, { ProcessScope: 'ALL' }]
  }
];
export const PROCESS_SCOPE_LABELS = {
  START: {
    task: 'start of the task',
    cases: 'start of the case',
    links: 'start of the link'
  },
  END: {
    task: 'end of the task',
    cases: 'end of the case',
    links: 'end of the link'
  },
  ALL: {
    task: 'all of the task duration',
    cases: 'all of the case duration',
    links: 'all of the link duration'
  }
};

export const OPERATORS = [
  { key: '>=', value: '≥' },
  { key: '>', value: '>' },
  { key: '<=', value: '≤' },
  { key: '<', value: '<' },
  { key: '=', value: '=' },
  { key: '!=', value: '≠' }
];

export const CASE_STATUSES = [
  { key: 'CASE_FINISHED', value: true },
  { key: 'CASE_IN_PROGRESS', value: false }
];

export const TIME_SCALE = [
  { key: 'YEAR', value: 'Year' },
  { key: 'MONTH', value: 'Month' },
  { key: 'WEEK', value: 'Week' },
  { key: 'DAY', value: 'Day' },
  { key: 'HOUR', value: 'Hour' },
  { key: 'SECOND', value: 'Second' }
];

export const TASK_TYPES_IN_LINKS = ['source', 'destination'];

export const PAGE_SIZES = [12, 25, 50, 100];

export const METRICS = ['frequency', 'duration', 'compliance', 'conformance'];

export const SHOW_MODEL = [
  { key: true, value: 'Yes' },
  { key: false, value: 'No' }
];

export const ORIENTATION = [
  { key: 'landscape', value: 'Landscape' },
  { key: 'portrait', value: 'Portrait' }
];

export const sessionKeys = {
  GLOBAL_FILTERS: 'globalFilters'
};

export const themeModes = {
  DARK_THEME: 'dark',
  LIGHT_THEME: 'light'
};

export const REPORT_TYPES = [
  { key: 'USER_LIST', value: 'User list' },
  { key: 'LICENSE_EFFECTIVE', value: 'License to user counts' },
  { key: 'LICENSE_UTILIZATION', value: 'License utilization' },
  { key: 'USER_LOGINS_COUNT_BY_USERNAME', value: 'Logins by username' },
  { key: 'APP_INTERACTIONS_COUNT_BY_USERNAME', value: 'User interaction by username' },
  { key: 'OBJECT_ACCESS_COUNT', value: 'Object access count by object name' },
  { key: 'TABS_VISIT_COUNT', value: 'Number of visits by tab type' }
];

export const CHART_TYPES_IN_REPORT = {
  USER_LIST: [{ key: 'table', value: 'Table' }],
  LICENSE_EFFECTIVE: [{ key: 'bar', value: 'Bar Chart' }],
  LICENSE_UTILIZATION: [{ key: 'stackbar', value: 'Stack Bar Chart' }],
  USER_LOGINS_COUNT_BY_USERNAME: [{ key: 'bar', value: 'Bar Chart' }],
  APP_INTERACTIONS_COUNT_BY_USERNAME: [{ key: 'bar', value: 'Bar Chart' }],
  OBJECT_ACCESS_COUNT: [{ key: 'bar', value: 'Bar Chart' }],
  TABS_VISIT_COUNT: [{ key: 'pie', value: 'Pie Chart' }]
};

export const REPORT_PERIODS = [
  { lable: 'LAST_ONE_WEEK', value: '604800' },
  { lable: 'LAST_ONE_MONTH', value: '2629800' },
  { lable: 'LAST_THREE_MONTH', value: '7889400' },
  { lable: 'LAST_SIX_MONTH', value: '15778800' },
  { lable: 'LAST_NINE_MONTH', value: '23668200' },
  { lable: 'LAST_ONE_YEAR', value: '31557600' }
];

export const LICENSE_TYPES = {
  noLicense: 'No License',
  viewerLicense: 'Viewer',
  designerLicense: 'Designer',
  collaboratorUserLicense: 'Collaborator',
  architectLicense: 'Architect'
};

export const USER_LIST_REPORT_TABLE_HEADERS = [
  'id',
  'loginName',
  'firstName',
  'lastName',
  'eMailAddress',
  'enabled'
];
export const linkTypesList = [
  { key: 'source', value: 'Sources' },
  { key: 'destination', value: 'Destinations' }
];

export const bubbleChartScopeList = [
  { key: 'links', value: 'Links' },
  { key: 'tasks', value: 'Tasks' },
  { key: 'cases', value: 'Cases' }
];

export const bubbleStatistics = [
  { key: 'total', value: 'Total' },
  { key: 'min', value: 'Minimum' },
  { key: 'max', value: 'Maximum' },
  { key: 'avg', value: 'Average' },
  { key: 'median', value: 'Median' }
];

export const bubbleChartThemeColors = [
  '#040DBF',
  '#D982C2',
  '#5BA67B',
  '#D9AC59',
  '#F28066',
  '#6B80BF',
  '#80ADBF',
  '#5BA67B',
  '#020873',
  '#84878C'
];

export const bubbleChartShape = 'bubble';
export const bubbleChartFrequency = 'frequency';
export const bubbleChartduration = 'duration';
export const svgChartShape = 'svg';
export const textChartShape = 'text';
export const imageChartShape = 'image';
export const reportChartType = 'designReport';

export const LICENSE_COUNT_REPORT_TABLE_HEADERS = ['loginName', 'userLicense'];

export const licenseTypes = [
  { key: 'all', value: 'All' },
  { key: 'No License', value: 'No License' },
  { key: 'Viewer', value: 'Viewer' },
  { key: 'Designer', value: 'Designer' },
  { key: 'Collaborator', value: 'Collaborator' },
  { key: 'Architect', value: 'Architect' }
];
