import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import configVariables from '../../../../../utils/ConfigurationVariables.js';
import './ImageWidgetConfigPanel.scss';
import Dropzone from 'react-dropzone';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { ToasterError } from '../../../../../utils/toaster.js';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DashboardTypography from '../../../../shared/DashboardTypography/DashboardTypography.js';
import ThemeMode from '../../../../themeMode/themeMode.js';

const ImageWidgetConfigPanel = (props) => {
  const { t } = useTranslation();
  const { chartOptions, setChartOptions } = props;
  const maxFileSize = 2097152;

  const updateChartOptions = (key, value) => {
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      [key]: value
    }));
  };

  const validateImageSize = (file) => {
    if (file.size > maxFileSize) {
      ToasterError(t('IMAGE_WIDGET.IMAGE_WIDGET_MAX_FILE_SIZE_INFO'));
      return false;
    } else {
      return true;
    }
  };

  const validateImageType = (file) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg', 'image/svg+xml'];
    if (validTypes.indexOf(file.type) === -1) {
      ToasterError(t('IMAGE_WIDGET.IMAGE_WIDGET_FILE_TYPE_INFO'));
      return false;
    }
    return true;
  };

  const uploadFiles = (files) => {
    if (files?.length > 0) {
      if (validateImageSize(files[0]) && validateImageType(files[0])) {
        props.setImageUploadedError(false);
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          const base64String = reader.result;
          updateChartOptions('imageData', base64String);
          updateChartOptions('imageName', files[0].name);
        };
      }
    }
  };

  const deleteUploadedImage = (event) => {
    event.stopPropagation();
    updateChartOptions('imageData', '');
    updateChartOptions('imageName', '');
  };

  return (
    <div>
      <div data-testid='image-config-title' className='image-config-heading'>
        <DashboardTypography typographyClass='title-16-700' id='image-config-title'>
        {t('chartConfiguration')}
        </DashboardTypography>
      </div>
      <TextField
        required
        error={props?.chartLabelError}
        id='chart-label'
        label={t('CHART_NAME')}
        placeholder={t('ENTER')}
        data-testid='image-chart-name'
        size='small'
        fullWidth
        inputProps={{
          maxLength: configVariables?.image?.maxtitleAllowedChar
        }}
        value={chartOptions.title}
        onChange={(event) => {
          const regex = /^$|^\S+.*/;
          const labelCheck = regex.test(event.target.value);
          if (labelCheck) {
            updateChartOptions('title', event.target.value);
            event ? props?.setChartLabelError(false) : props?.setChartLabelError(true);
          }
        }}
      />
        <div className='character-limit-msg'>
            <DashboardTypography typographyClass='title-12-500' id='character-limit-msg'>
           { props?.chartLabelError
            ? t('CHART_NAME_ERROR_MESSAGE')
            : t('MAX_CHARACTERS_MESSAGE', {
                characterLength: chartOptions?.title?.length,
                maxAllowdedCharLength: configVariables?.image?.maxtitleAllowedChar
              })}
            </DashboardTypography>
          </div>
      <div data-testid='image-drop-zone' className='file-upload-container' id='file-upload-container'>
        <Dropzone onDrop={uploadFiles} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div data-testid='image-upload-zone' {...getRootProps({ className: 'drop-zone' })}>
                <input {...getInputProps()} />
                <FileUploadIcon className='upload-icon image-widget-icon'></FileUploadIcon>
                <div>
                  <DashboardTypography typographyClass='title-14-500' id='image-upload-message'>
                  {t('IMAGE_WIDGET.IMAGE_UPLOAD_MESSEAGE')}
                        </DashboardTypography>
                </div>
                <div className='image-widget-size-info'>
                  <DashboardTypography typographyClass='title-12-500' id='image-widget-size-info'>
                  {t('IMAGE_WIDGET.IMAGE_WIDGET_TYPE_SIZE_INFO', { maxSize: '2mb' })}
                        </DashboardTypography>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      {props?.imageUploadedError && (
        <div >
         <DashboardTypography typographyClass='title-12-500' id='no-image-uploaded'>
         {t('IMAGE_WIDGET.IMAGE_NOT_UPLOADED_INFO')}
            </DashboardTypography>
        </div>
      )}
      {chartOptions?.imageData !== '' && (
        <div className='image-info-conatiner' id='image-info-container'>
          <img className='uploaded-image' src={chartOptions?.imageData}></img>
          <Tooltip title={chartOptions?.imageName} arrow>
            <div className='selected-file' >
            <DashboardTypography typographyClass='title-14-500' id='selected-image-file'>
            {chartOptions?.imageName}
            </DashboardTypography>
            </div>
          </Tooltip>
          <ThemeMode />
          <DeleteOutlinedIcon
            onClick={deleteUploadedImage}
            className='image-widget-icon'
            data-testid='delete-img-btn'
            id='delete-img-btn'
          ></DeleteOutlinedIcon>
        </div>
      )}
    </div>
  );
};

ImageWidgetConfigPanel.propTypes = {
  chartOptions: PropTypes.object,
  setChartOptions: PropTypes.func,
  chartLabelError: PropTypes.bool,
  setChartLabelError: PropTypes.func,
  imageUploadedError: PropTypes.bool,
  setImageUploadedError: PropTypes.func
};

export default ImageWidgetConfigPanel;
