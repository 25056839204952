const addEditChartStyles = {
  containerGrid: {
    paddingLeft: '25x'
  },
  chartPropertiesGrid: {
    paddingLeft: '20px',
    borderLeft: '1px solid #E0E2E7',
    backgroundColor: '#FFFFFF'
  },
  paperContainerForChart: {
    width: '100%',
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    top: '80px',
    left: '-15px'
  },
  dummyChartThumbnail: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '7rem',
    justifyContent: 'center'
  },
  dummyChartImage: {
    width: '800px',
    height: '275px'
  },
  data: {
    color: '#808B99',
    textTransform: 'unset',
    fontSize: '14px',
    borderRadius: '6px'
  },
  Customised: {
    cursor: 'pointer',
    color: '#808B99',
    textTransform: 'unset',
    fontSize: '14px',
    borderRadius: '6px'
  },
  selectedTab: {
    color: '#4543AE',
    backgroundColor: '#DEDEFA',
    margin: '4px',
    borderRadius: '6px',
    textTransform: 'unset'
  },
  customTabs: {
    '& .MuiTab-root': {
      width: '100px'
    }
  },
  dialogTitleContainer: {
    paddingLeft: '20px',
    paddingTop: '1%',
    paddingRight: '20px',
    height: '60px'
  },
  profileDialogTitleContainer: {
    paddingLeft: '20px',
    paddingTop: '1%',
    paddingRight: '20px',
    height: '7%',
    borderBottom: '1px solid #F9F9FC',
    backgroundColor: '#E0E2E7'
  },
  headerContainer: {
    display: 'flex'
  },

  backBreadcrumb: {
    display: 'flex',
    position: 'relative',
    top: '-5px'
  },
  updateButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px'
  },
};

export default addEditChartStyles;
