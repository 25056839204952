import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import LoadingSpinner from '../loader/LoadingSpinner';
import '../../styles/LoadingSpinner.scss';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../../styles/DashboardLayout.css';
import { useDispatch } from 'react-redux';
import {
  setReportConfigValidationInfo,
  resetReportConfigValidationInfo
} from '../../Redux/slices/reportConfigSlice';
import WidgetConfigHeaderComponent from '../shared/WidgetConfigHeaderComponent/WidgetConfigHeaderComponent';
import ReportConfigPanel from './ReportConfigPanel/ReportConfigPanel';
import ReportPreviewPanel from './ReportPreviewPanel/ReportPreviewPanel';

const ReportConfigMainPanel = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open] = React.useState(props.openReportConfigDialog);
  const [loading, setLoading] = useState(false);
  const [chartOptions, setChartOptions] = React.useState({
    chartId: '',
    title: '',
    description: '',
    shape: '',
    type: 'designReport',
    dataToDisplay: 'USER_LIST',
    chartData: [],
    filters: [],
    customFieldId: '',
    aggregation: {},
    projectIds: '',
    viewAsTable: false
  });

  useEffect(() => {
    const newChartOptions = {
      ...props.chartOptions,
      shape: props.chartOptions?.shape === 'designReport' ? '' : props.chartOptions?.shape,
      dataToDisplay: props.chartOptions?.dataToDisplay || 'USER_LIST'
    };

    // Only update if there are differences
    if (JSON.stringify(newChartOptions) !== JSON.stringify(chartOptions)) {
      setChartOptions(newChartOptions);
    }
  }, [props.chartOptions]);

  const saveConfiguration = () => {
    if (validateReportConfigFields()) {
      props.saveReportConfig(chartOptions, chartOptions.chartData);
      dispatch(resetReportConfigValidationInfo());
      props.onClose();
    } else {
      const validationObj = {
        reportNameError: chartOptions.title ? false : true,
        reportTypeError: chartOptions.dataToDisplay ? false : true,
        chartTypeError: chartOptions.shape ? false : true,
        repositoryError: chartOptions.repositoryName ? false : true
      };
      dispatch(setReportConfigValidationInfo(validationObj));
    }
  };

  const validateReportConfigFields = () => {
    return chartOptions.title && chartOptions.dataToDisplay && chartOptions.shape;
  };

  const closeConfigPopup = () => {
    dispatch(resetReportConfigValidationInfo());
    props.onClose();
  };

  const isLoading = (isloading) => {
    setLoading(isloading);
  };

  return (
    <div>
      <Dialog
        onClose={closeConfigPopup}
        aria-labelledby='report-widget-dialog-title'
        open={open}
        fullScreen
        role='dialog'
      >
        <Grid
          container
          spacing={2}
          style={{ paddingTop: '26px' }}
          data-testid={'report-preview-header'}
          id='report-preview-header'
        >
          <WidgetConfigHeaderComponent
            headerTitle={
              chartOptions && chartOptions.chartId !== ''
                ? t('REPORT.EDIT_REPORT')
                : t('REPORT.NEW_REPORT')
            }
            onClose={closeConfigPopup}
            saveChartConfig={saveConfiguration}
          ></WidgetConfigHeaderComponent>
          <Grid item xs={8} id='report-preview-panel'>
            <ReportPreviewPanel
              chartOptions={chartOptions}
              workgroupId={props.workgroupId}
              themeColors={props.themeColors}
              setChartOptions={setChartOptions}
            ></ReportPreviewPanel>
          </Grid>
          <Grid item xs={4} sx={{ borderLeft: '1px solid #e7e7e7' }}>
            <ReportConfigPanel
              chartOptions={chartOptions}
              setChartOptions={setChartOptions}
              isLoading={isLoading}
            ></ReportConfigPanel>
          </Grid>
        </Grid>
        {loading === true && <LoadingSpinner></LoadingSpinner>}
      </Dialog>
    </div>
  );
};

ReportConfigMainPanel.propTypes = {
  openReportConfigDialog: PropTypes.bool,
  onClose: PropTypes.func,
  chartOptions: PropTypes.object,
  workgroupId: PropTypes.string,
  saveReportConfig: PropTypes.func,
  themeColors: PropTypes.array
};

export default ReportConfigMainPanel;
