import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Chip, Divider, Popover, Stack, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import './FilterListPopover.scss';
import DashboardTypography from '../../shared/DashboardTypography/DashboardTypography';
import { useTranslation } from 'react-i18next';

const FilterListPopover = (props) => {
  const { t } = useTranslation();
  const anchorEl = useSelector((state) => state.filterLisrPopover.filterLisrPopoverRef);
  const appliedGlobalFilterList = useSelector((state) => state.filterLisrPopover.appliedGlobalFilterList);
  const appliedWigetFilterList = useSelector((state) => state.filterLisrPopover.appliedWigetFilterList);
  const dataSources = useSelector((state) => state.dataSources.dataSources);
  const [currentDataSource, setCurrentDataSource] = useState(null);
  const [widgetFilers, setWidgetFilers] = useState(null);
  const [globalFilters, setGlobalFilters] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (dataSources && dataSources.length > 0) {
      setCurrentDataSource(dataSources.find(dataSource => dataSource.projectId === props?.chartOption?.projectIds[0]));
    }
  });

  useEffect(() => {
    setWidgetFilers(getFilterList(appliedWigetFilterList));
  }, [appliedWigetFilterList]);

  useEffect(() => {
    setGlobalFilters(getFilterList(appliedGlobalFilterList));
  }, [appliedGlobalFilterList]);

  const getFilterList = (filters) => {
    const filterLIst = [];
    if (filters.length > 0) {
      filters.forEach(filter => {
        filterLIst.push(`${filter.name}`);
      });
    }
    return filterLIst;
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Card sx={{ minWidth: 300, maxWidth: 600 }}>
        <CardContent>
          <div className='mb-10'>
            <DashboardTypography typographyClass={'title-14-600 cool-gray-color'} >{t('DATA_SOURCE')}: </DashboardTypography>
            <DashboardTypography typographyClass={'title-14-400 charcoal-gray-color'} >{currentDataSource?.name}</DashboardTypography>
          </div>

          {widgetFilers && widgetFilers.length > 0 &&
            <div className='mb-10'>
              <DashboardTypography typographyClass={'title-14-700 cool-gray-color disply-block'} >{t('FILTER.WIDGET_FILTER')}:</DashboardTypography>
              <Box sx={{ p: 1 }}>
                <Stack direction="row" spacing={1}>
                  {widgetFilers && widgetFilers.map((name, index) => (
                    <Tooltip key={index} title={name} arrow>
                      <Chip label={name} size="medium" />
                    </Tooltip>
                  ))}
                </Stack>
              </Box>
            </div>}

          {widgetFilers && widgetFilers.length > 0 && globalFilters && globalFilters.length > 0 &&
            <div className='mb-10'><Divider /></div>}

          {globalFilters && globalFilters.length > 0 &&
            <div>
              <DashboardTypography typographyClass={'title-14-700 cool-gray-color disply-block'} >{t('FILTER.GLOBAL_FILTER')}:</DashboardTypography>
              <Box sx={{ p: 1 }}>
                <Stack direction="row" spacing={1}>
                  {globalFilters && globalFilters.map((name, index) => (
                    <Tooltip key={index} title={name} arrow>
                      <Chip label={name} size="medium" />
                    </Tooltip>
                  ))}
                </Stack>
              </Box>
            </div>}
        </CardContent>
      </Card>
    </Popover>
  );
};

FilterListPopover.propTypes = {
  handleClose: PropTypes.func,
  chartOption: PropTypes.object
};

export default FilterListPopover;