import { createSlice } from '@reduxjs/toolkit';

const initialDashboardState = {
  isDashboardEditMode: false,
  leftSectionWidth: window.innerWidth,
  leftSectionContainerWidthForGrid: 0,
  isWidgetConfigOn: false,
  isSaveScreenShot: false
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialDashboardState,
  reducers: {
    setDashboardEditMode: (state, action) => {
      state.isDashboardEditMode = action.payload;
    },

    setLeftSectionWidth: (state, action) => {
      state.leftSectionWidth = action.payload;
    },

    setLeftSectionContainerWidthForGrid: (state, action) => {
      state.leftSectionContainerWidthForGrid = action.payload;
    },

    setIsWidgetConfigOn: (state, action) => {
      state.isWidgetConfigOn = action.payload;
    },

    setIsSaveScreenShot: (state, action) => {
      state.isSaveScreenShot = action.payload;
    }
  }
});

export const {
  setDashboardEditMode,
  setLeftSectionWidth,
  setLeftSectionContainerWidthForGrid,
  setIsWidgetConfigOn,
  setIsSaveScreenShot
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
