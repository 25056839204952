import React, { useState, useEffect } from 'react';
import ApiService from '../../services/ApiService';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Select, FormControl, FormGroup, MenuItem, Grid, FormControlLabel } from '@mui/material';
import '../../styles/AddNewApp.css';
import { toast } from 'react-toastify';
import LoadingSpinner from '../loader/LoadingSpinner';
import '../../styles/LoadingSpinner.css';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import '../../styles/FiltersLayout.scss';
import ThemeMode from '../themeMode/themeMode';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';

const BusinessRuleFilter = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedBusinessRule, setSelectedBusinessRule] = useState({
    businessRuleId: '',
    filterType: 'businessRule',
    name: [],
    isCompliant: false
  });
  const [businessRule, setBusinessRule] = useState([]);
  useEffect(() => {
    fetchBusinessRule();
    if (props.filterObj && props.filterObj.businessRuleId) {
      setSelectedBusinessRule(props.filterObj);
    }
    if (
      props.filterObj &&
      props.filterObj.businessRuleId &&
      props.filterObj.businessRuleId !== ''
    ) {
      props.setIsValid(true);
    } else {
      props.setIsValid(false);
    }
  }, []);
  useEffect(() => {
    props.handleFilterChange(selectedBusinessRule);
  }, [selectedBusinessRule]);
  const fetchBusinessRule = async () => {
    const requestBody = {
      search: null,
      filterPageNo: '1',
      filterPageSize: '100',
      filterSortOrder: 'ASC'
    };
    try {
      setLoading(true);
      const response = await ApiService.getBusinessRuleFilter(
        requestBody,
        props.workgroupId,
        props.projectId
      );
      setBusinessRule(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t('FILTER.FAILED_TO_FETCH_BUSINESS'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    }
  };

  const handleFilterChange = (value) => {
    setSelectedBusinessRule((prevselectedBusinessRule) => ({
      ...prevselectedBusinessRule,
      businessRuleId: value
    }));
    if (value && value !== null) {
      props.setIsValid(true);
    } else {
      props.setIsValid(false);
    }
  };

  const handleCompliantChange = (event) => {
    setSelectedBusinessRule((prevselectedBusinessRule) => ({
      ...prevselectedBusinessRule,
      isCompliant: event
    }));
  };

  return (
    <Grid container>
      <Grid item lg={10.2} xl={10.2} className='genericfilter-grid'>
        <div style={{ width: '100%' }}>
          <FormControl fullWidth size='small'>
            <label id='filter-task-label'>
              <DashboardTypography id='filter-type-label' typographyClass={'title-14-500'}>
                {t('FILTER.BUSINESSRULE')}
              </DashboardTypography>
            </label>
            <Select
              labelId='filter_businessRule_label'
              id='businessRule-select'
              style={{ backgroundColor: '#F9F9Fc', width: '100%', border: '1px solid #D8D8D8' }}
              placeholder={t('SELECT')}
              label={t('BUSINESSRULE')}
              data-testid='business-filter'
              value={selectedBusinessRule.businessRuleId}
              onChange={(event) => {
                handleFilterChange(event.target.value);
              }}
              sx={{
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  border: 0
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 0
                }
              }}
              input={<OutlinedInput label='Tag' />}
              className='select-filter-type'
            >
              <ThemeMode></ThemeMode>
              {businessRule &&
                businessRule.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid item lg={1.8} xl={1.8} className='genericfilter-grid'>
        <FormGroup>
          <FormControlLabel
            labelPlacement='top'
            control={
              <Checkbox
                checked={selectedBusinessRule.isCompliant}
                onChange={(e) => handleCompliantChange(e.target.checked)}
                data-testid='exclude-switch'
              />
            }
            label='Compliant'
          />
        </FormGroup>
      </Grid>
      {loading === true && <LoadingSpinner></LoadingSpinner>}
    </Grid>
  );
};
BusinessRuleFilter.propTypes = {
  handleFilterChange: PropTypes.func,
  workgroupId: PropTypes.string,
  projectId: PropTypes.string,
  filterObj: PropTypes.object,
  setIsValid: PropTypes.func
};

export default BusinessRuleFilter;
