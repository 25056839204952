import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import './DashboardButton.scss';

const DashboardButton = (props) => {
  const { color, startIcon, eventHandler, dataTestid, children, btnClassName, disabled, size } = props;

  const getClass = () => {
    if (btnClassName) {
      return btnClassName;
    } else {
      return color === 'secondary' ? 'btn secondary-btn' : 'btn primary-btn';
    }
  };

  return (
    <Button
      variant={color === 'secondary' ? 'outlined' : 'contained'}
      className={getClass()}
      startIcon={startIcon}
      onClick={eventHandler}
      data-testid={dataTestid}
      disabled={disabled}
      size={size}
    >
      {children}
    </Button>
  );
};

DashboardButton.propTypes = {
  color: PropTypes.string,
  startIcon: PropTypes.any,
  eventHandler: PropTypes.func,
  dataTestid: PropTypes.string,
  children: PropTypes.string,
  btnClassName: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string
};
export default DashboardButton;
