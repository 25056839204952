import colors from '../../../src/styles/constants.scss';

export const darkThemeModeIconColors = {
  'add-chart-button-icon': colors.lightGrayishBlue,
  'close-icon': colors.coolGray,
  'plus-icon': colors.lightGrayishBlue,
  'global-filter-close-icon': colors.coolGray,
  'filter-icon': colors.lightPurple,
  'edit-filter-button': colors.lightGrayishBlue,
  'remove-filter-icon': colors.lightGrayishBlue,
  'global-filter-icon-edit-icon': colors.coolGray,
  'global-filter-icon-delete-icon': colors.coolGray,
  'arrow-back-icon': colors.lightGray,
  'profile-icon': colors.bluishSilver,
  'logout-icon': colors.bluishSilver,
  'delete-theme-button-icon': colors.lightGrey,
  'edit-theme-button-icon': colors.lightGrey,
  'view-theme-button-icon': colors.lightGrey,
  'add-dashboard-button-icon': colors.lightGrayishBlue,
  'back-arrow-icon': colors.lightGrayishBlue,
  'delete-img-btn': colors.lightGrayishBlue,
  'search-icon': colors.coolGray,
  'clear-search': colors.coolGray,
  'view-as-table-icon': colors.lightGrey,
  'reset-fullscreen-icon': colors.lightGrey,
  'full-screen-pmv-icon': colors.lightGrey,
  'open-filter-list-popover-icon': colors.lightGrey,
  'edit-widget-icon': colors.lightGrey,
  'widget-menu': colors.lightGrey,
};

export const lightThemeModeIconColors = {
  'add-chart-button-icon': colors.royalBlue,
  'close-icon': colors.coolGray,
  'plus-icon': colors.royalBlue,
  'global-filter-close-icon': colors.black,
  'filter-icon': colors.royalBlue,
  'edit-filter-button': colors.igrafxBlue,
  'remove-filter-icon': colors.igrafxBlue,
  'global-filter-icon-edit-icon': colors.coolGray,
  'global-filter-icon-delete-icon': colors.coolGray,
  'arrow-back-icon': colors.softBlack,
  'profile-icon': colors.coolGray,
  'logout-icon': colors.coolGray,
  'delete-theme-button-icon': colors.lightGrey,
  'edit-theme-button-icon': colors.lightGrey,
  'view-theme-button-icon': colors.lightGrey,
  'add-dashboard-button-icon': colors.igrafxBlue,
  'back-arrow-icon': colors.charcoalGray,
  'delete-img-btn': colors.black,
  'search-icon': colors.charcoalGray,
  'clear-search': colors.charcoalGray,
  'view-as-table-icon': colors.coolGray,
  'reset-fullscreen-icon': colors.coolGray,
  'full-screen-pmv-icon': colors.coolGray,
  'open-filter-list-popover-icon': colors.coolGray,
  'edit-widget-icon': colors.coolGray,
  'widget-menu': colors.coolGray,
};