import colors from '../../../styles/constants.scss';

export const darkThemeModeTextColors = {
    'all-workgroup-text': colors.lightGrayishBlue,
    'work-group-name-title': colors.lightGrayishBlue,
    'input': colors.lightGrey,
    'label': colors.lightGrey,
    'apps-header': colors.lightGrey,
    'header-workgroup-name': colors.lightGrey,
    'header-all-apps-title': colors.lightGrey,
    'app-name': colors.lightGrayishBlue,
    'app-description': colors.lightGrayishBlue,
    'app-date-modified': colors.lightGrey,
    'app-date-edited': colors.lightGrey,
    'widget-card-title': colors.lightGrey,
    'metric-chart-description': colors.lightGrey,
    'metric-chart-value': colors.royalBlue,
    'chart-config-header-title': colors.lightGrayishBlue,
    'chart-config-chart-title': colors.lightGrey,
    'chart-configuration-title': colors.lightGrey,
    'field-label': colors.lightGrey,
    'header-app-name': colors.lightGrey,
    'empty-screen-msg': colors.lightGrayishBlue,
    'add-components-to-screen': colors.coolGray,
    'profile-menu': colors.bluishSilver,
    'logout-menu': colors.bluishSilver,
    'version-menu': colors.lightGrey,
    'component-existing-chart-title': colors.lightGrayishBlue,
    'add-chart-button-title': colors.darkNavy,
    'no-dashboard': colors.lightGrey,
    'new-app-title': colors.lightGrayishBlue,
    'apps-label': colors.lightGrey,
    'apps-description': colors.lightGrey,
    'data-source-label' : colors.lightGrayishBlue,
    'description-helper-text': colors.coolGray,
    'chip': colors.royalBlue,
    'delete-app-title': colors.lightGrayishBlue,
    'delete-confirmation-message': colors.lightGrayishBlue,
    'delete-confirmation-note' : colors.charcoalGray,
    'app-name-helper-text': colors.coolGray,
    'data-source-label-heading' : colors.lightGrey,
    'selected-data-source-label' : colors.lightGrey,
    'selected-data-source-label-heading' : colors.lightGrey,
    'selected-datasource-chip':colors.royalBlue,
    'process-model-config-header-title': colors.lightGrayishBlue,
    'global-filter-heading': colors.lightGrayishBlue,
    'new-filter-heading': colors.lightGrayishBlue,
    'no-filter-created': colors.lightGrayishBlue,
    'global-filter-name': colors.lightGrayishBlue,
    'data-to-display-select-label': colors.lightGrayishBlue,
    'duplicate-filter-name': colors.red,
    'filter-helper-text': colors.coolGray,
    'data-source-helper-text': colors.coolGray,
    'filters': colors.lightGrey,
    'load-filters-lists': colors.lightPurple,
    'add-filter': colors.lightGrayishBlue,
    'no-filter-found': colors.lightGrayishBlue,
    'filter-name': colors.lightGrayishBlue,
    'global-filter-app-name': colors.coolGray,
    'global-filter-name-list': colors.coolGray,
    'global-filter-datasource': colors.coolGray,
    'global-filter-datasource-name': colors.coolGray,
    'filter-list-error-msg': colors.red,
    'theme-name': colors.lightGrayishBlue,
    'add-dashboard-button-title': colors.lightGrayishBlue,
    'dashboard-card-name': colors.lightGrayishBlue,
    'character-limit-msg': colors.lightGrey,
    'image-name': colors.lightGrayishBlue,
    'image-config-title': colors.lightGrayishBlue,
    'image-upload-message': colors.lightGrayishBlue,
    'image-widget-size-info': colors.lightGrey,
    'selected-image-file': colors.paleBlue,
    'no-image-uploaded': colors.red,
    'profile-header': colors.lightGrayishBlue,
    'user-first-name': colors.lightGrey,
    'user-last-name': colors.lightGrey,
    'user-email': colors.lightGrey,
    'user-language': colors.lightGrey,
    'input-field-outline': colors.darkGrayishBlue,
    'duplicate-widget-name-label': colors.lightGrayishBlue,
    'duplicate-widget-destination-label': colors.lightGrayishBlue,
    'configure-chart-warning': colors.lightGrayishBlue,
    'new-theme-title': colors.lightGrayishBlue,
    'modal-theme-name': colors.lightGrayishBlue,
    'new-theme-description': colors.lightGrayishBlue,
    'variants-selection-panel-title': colors.lightGrayishBlue,
    'variants-selection-panel-selected-cases': colors.lightGrayishBlue,
    'dashboard-dialog-title': colors.lightGrayishBlue,
    'dashboard-name': colors.lightGrey,
    'view-as-table-title': colors.lightGrayishBlue,
    'table-header-x-axis-title': colors.lightGrey,
    'table-header-y-axis-title': colors.lightGrey,
    'table-data-format-date': colors.lightGrayishBlue,
    'table-data-to-display': colors.lightGrayishBlue,
    'filter-dialog-title': colors.lightGrayishBlue,
    'filter-type-label' : colors.lightGrayishBlue,
    'select-filter-type': colors.lightGrayishBlue,
};

export const lightThemeModeTextColors = {
    'all-workgroup-text': colors.darkGrey,
    'work-group-name-title': colors.charcoalGray,
    'input': 'unset',
    'label': 'unset',
    'apps-header': colors.darkGrey,
    'header-workgroup-name': colors.darkGrey,
    'header-all-apps-title': colors.darkGrey,
    'app-name': colors.charcoalGray,
    'app-description': colors.charcoalGray,
    'app-date-modified': colors.coolGray,
    'app-date-edited': colors.coolGray,
    'widget-card-title': colors.charcoalGray,
    'metric-chart-description': colors.charcoalGray,
    'metric-chart-value': colors.charcoalGray,
    'chart-config-header-title': colors.charcoalGray,
    'chart-config-chart-title': colors.charcoalGray,
    'chart-configuration-title': colors.charcoalGray,
    'field-label': colors.charcoalGray,
    'header-app-name': colors.charcoalGray,
    'empty-screen-msg': colors.charcoalGray,
    'add-components-to-screen': colors.coolGray,
    'profile-menu': colors.coolGray,
    'logout-menu': colors.coolGray,
    'version-menu': colors.lightGrey,
    'component-existing-chart-title': colors.charcoalGray,
    'add-chart-button-title': colors.black,
    'no-dashboard': colors.darkGrey,
    'new-app-title': colors.darkGrey,
    'apps-label': colors.darkGrey,
    'apps-description': colors.darkGrey,
    'data-source-label' : colors.darkGrey,
    'description-helper-text': colors.darkGrey,
    'chip': colors.royalBlue,
    'delete-app-title': colors.darkGrey,
    'delete-confirmation-message': colors.charcoalGray,
    'delete-confirmation-note' : colors.charcoalGray, 
    'app-name-helper-text': colors.darkGrey,
    'data-source-label-heading' : colors.darkGrey,
    'selected-data-source-label' : colors.darkGrey,
    'selected-data-source-label-heading' : colors.darkGrey,
    'selected-datasource-chip':colors.royalBlue,
    'process-model-config-header-title': colors.charcoalGray,
    'global-filter-heading': colors.charcoalGray,
    'new-filter-heading': colors.royalBlue,
    'no-filter-created': colors.coolGray,
    'global-filter-name': colors.darkGrey,
    'data-to-display-select-label': colors.darkGrey,
    'duplicate-filter-name': colors.red,
    'filter-helper-text': colors.darkGrey,
    'data-source-helper-text': colors.darkGrey,
    'filters': colors.darkGrey,
    'load-filters-lists': colors.royalBlue,
    'add-filter': colors.royalBlue,
    'no-filter-found': colors.darkGrey,
    'filter-name': colors.igrafxBlue,
    'global-filter-app-name': colors.coolGray,
    'global-filter-name-list': colors.black,
    'global-filter-datasource': colors.coolGray,
    'global-filter-datasource-name': colors.black,
    'filter-list-error-msg': colors.red,
    'theme-name': colors.charcoalGray,
    'add-dashboard-button-title': colors.igrafxBlue,
    'dashboard-card-name': colors.coolGray,
    'character-limit-msg': colors.charcoalGray,
    'input-field-outline': 'unset',
    'image-name': colors.charcoalGray,
    'image-config-title': colors.coolGray,
    'image-upload-message': colors.black,
    'image-widget-size-info': colors.lightGrey,
    'selected-image-file': colors.paleBlue,
    'no-image-uploaded': colors.red,
    'profile-header': colors.charcoalGray,
    'user-first-name': colors.darkGrey,
    'user-last-name': colors.darkGrey,
    'user-email': colors.darkGrey,
    'user-language': colors.darkGrey,
    'duplicate-widget-name-label': colors.darkGrey,
    'duplicate-widget-destination-label': colors.darkGrey,
    'configure-chart-warning': colors.charcoalGray,
    'new-theme-title': colors.darkGrey,
    'modal-theme-name': colors.darkGrey,
    'new-theme-description': colors.darkGrey,
    'variants-selection-panel-title': colors.charcoalGray,
    'variants-selection-panel-selected-cases': colors.charcoalGray,
    'dashboard-dialog-title': colors.darkGrey,
    'dashboard-name': colors.darkGrey,
    'view-as-table-title': colors.darkGrey,
    'table-header-x-axis-title': colors.black,
    'table-header-y-axis-title': colors.black,
    'table-data-format-date': colors.black,
    'table-data-to-display': colors.black,
    'filter-dialog-title': colors.darkGrey,
    'filter-type-label' : colors.darkGrey,
    'select-filter-type': colors.black,
};