import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import addEditChartStyles from '../../styles/addEditChartStyles';
import LoadingSpinner from '../loader/LoadingSpinner';
import '../../styles/LoadingSpinner.scss';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText
} from '@mui/material';
import ApiService from '../../services/ApiService';
import { useTranslation } from 'react-i18next';
import BarChart from '../charts/Bar/BarChart';
import LineChart from '../charts/lineChart/LineChart';
import PieChart from '../charts/pieChart/PieChart';
import {
  SUPPORTED_CHART_TYPES,
  bubbleChartScopeList,
  bubbleChartShape
} from '../../utils/Constants';
import chartImage from '../../assets/thumbnails/chartPlaceholder.svg';
import '../../styles/DashboardLayout.css';
import MetricChart from '../charts/metricChart/MetricChart';
import TextChart from '../charts/textChart/TextChart';
import FiltersList from '../../components/filters/FiltersList';
import { SketchPicker } from 'react-color';
import { useParams } from 'react-router';
import configVariables from '../../utils/ConfigurationVariables';
import { useDispatch, useSelector } from 'react-redux';
import { setIsWidgetConfigOn } from '../../Redux/slices/dashboardSlice';
import WidgetConfigHeaderComponent from '../shared/WidgetConfigHeaderComponent/WidgetConfigHeaderComponent';
import getChartOptionObject from '../../utils/getChartOptionObject';
import { ToasterError } from '../../utils/toaster';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import './addEditWidget.scss';
import { getFilterName } from '../../utils/FilterNameUtils';
import ThemeMode from '../themeMode/themeMode';
import BubbleChart from '../charts/bubbleChart/BubbleChart';
import { constructBubbleChartData } from '../../utils/BubbleChartUtils';
import BubbleChartService from '../../services/BubbleChartService';
import ConfigureBubbleChart from '../charts/bubbleChart/ConfigureBubbleChart';

const AddEditWidget = (props) => {
  const { t } = useTranslation();
  const [open] = React.useState(props.open);
  const dispach = useDispatch();

  const [chartLabelError, setChartLabelError] = React.useState(false);
  const [chartDescriptionError, setChartDescriptionError] = React.useState(false);
  const [chartDataSetError, setChartDataSetError] = React.useState(false);
  const [chartTypeError, setChartTypeError] = React.useState(false);
  const [chartDistributionError, setChartDistributionError] = React.useState(false);
  const [chartFrequencyError, setChartFrequencyError] = React.useState(false);
  const [chartStatisticError, setChartStatisticError] = React.useState(false);
  const [chartDataSourceError, setChartDataSourceError] = React.useState(false);
  const [chartScopError, setChartScopError] = React.useState(false);
  const [allDataSets, setallDataSets] = React.useState([]);
  const [metrics, setMetrics] = React.useState([]);
  const [dimensions, setDimensions] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [availableOptions, setAvailableOptions] = React.useState([]);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [nameColor, setNameColor] = useState('black');
  const [descriptionColor, setDescriptionColor] = useState('black');
  const [titleLength, setTitleLength] = useState(0);
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [titleDescriptionSelected, settitleDescriptionSelected] = useState('');
  const [renderChartData, setRenderChartData] = useState(false);
  const [isChartTypeChanged, setIsChartTypeChanged] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);
  const [chartDimensions] = React.useState({
    width: 600,
    height: 450
  });
  const [chartData, setChartData] = React.useState([]);
  const [chartOptions, setChartOptions] = React.useState({
    ...getChartOptionObject('', '', '', 'category', 'value')
  });
  const [selectedDataSourceId, setSelectedDataSourceId] = useState('');
  const routerParams = useParams();
  const maxAllowdedCharLength = 24;
  const variantList = useSelector((state) => state.variantList.variants);
  const taskList = useSelector((state) => state.variantList.tasks);
  const links = useSelector((state) => state.variantList.links);
  const [bubbleChartData, setBubbleChartData] = useState([]);
  const [bubbleMetricList, setBubbleMetricList] = useState([]);
  const [yAxisMetricList, setYAxisMetricList] = useState([]);

  const getAllDataToDisplay = async (projectId) => {
    try {
      setLoading(true);

      const response = await ApiService.getFieldList(routerParams.workgroupId, projectId);

      setallDataSets(response.dashboardFields);
      if (response.metrics) {
        setMetrics(response.metrics);
      }
      if (response.dimensions) {
        setDimensions(response.dimensions);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ToasterError(t('FAILED_DATA_SET_API_ERROR'));
    }
  };

  const fetchSavedFilters = async (projectId) => {
    try {
      setLoading(true);
      const response = await ApiService.getSavedFilter(projectId);
      setSavedFilters(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ToasterError(t('FAILED_SAVED_FILTERS'));
    }
  };

  useEffect(() => {
    const dialog = document.getElementById('dialog');
    dialog?.addEventListener('dragstart', (event) => {
      event.preventDefault();
    });
  });

  useEffect(() => {
    dispach(setIsWidgetConfigOn(true));
    setChartOptions({ ...props.chartOptions });
    if (props.selectedDataSourceList?.length === 1) {
      changeDataSource('projectIds', [props.selectedDataSourceList[0]?.projectId]);
    }
    const projectId = props?.chartOptions?.projectIds ? props?.chartOptions?.projectIds[0] : [];
    const filtersArray = props?.chartOptions?.filters ? props?.chartOptions?.filters : [];
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      filters: filtersArray
    }));
    if (props?.chartOptions?.type && props?.chartOptions?.type !== 'dimensionBubblechart') {
      setChartData(props.chartOptions.chartData);
      getAvailableOptions(
        props.chartOptions?.type,
        props.chartOptions.customFieldId ? props?.chartOptions?.customFieldId : undefined,
        projectId
      );
    }
    if (props?.chartOptions?.type === 'dimensionBubblechart') {
      getBubblechartMetricsAndDimensions(props?.chartOptions?.projectIds[0]);
      setChartData(props.chartOptions.chartData);
    }
    setNameColor(props.chartOptions?.nameColor ? props.chartOptions?.nameColor : '#000000');
    setDescriptionColor(
      props.chartOptions?.descriptionColor ? props.chartOptions?.descriptionColor : '#000000'
    );
    updateDescriptionLength(props.chartOptions?.description?.length);
    updateTitleLength(props.chartOptions?.title?.length);
    if (props?.chartOptions && props.chartOptions?.projectIds?.length !== 0) {
      getAllDataToDisplay(projectId);
      fetchSavedFilters(projectId);
      setSelectedDataSourceId(projectId);
    }
  }, []);

  const setAvailableData = () => {
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      frequency: availableOptions?.frequency ? availableOptions.frequency[0] : '',
      distribution: availableOptions?.distribution ? availableOptions.distribution[0] : '',
      statistic: availableOptions?.statistic ? availableOptions.statistic[0] : '',
      scope: availableOptions?.scope ? availableOptions.scope[0] : '',
      metricFormat: availableOptions?.metricFormat ? availableOptions.metricFormat[0] : ''
    }));
    setRenderChartData(true);
  };
  useEffect(() => {
    if (
      availableOptions &&
      isChartTypeChanged &&
      (availableOptions.frequency ||
        availableOptions.distribution ||
        availableOptions.statistic ||
        availableOptions.scope ||
        availableOptions.metricFormat)
    ) {
      setAvailableData();
      setIsChartTypeChanged(false);
    }
  }, [availableOptions]);
  useEffect(() => {
    if (renderChartData) {
      getChartData(
        chartOptions.distribution,
        chartOptions.frequency,
        chartOptions.statistic,
        chartOptions.filters,
        chartOptions.scope,
        chartOptions.limit
      );
    }
  }, [
    chartOptions.frequency,
    chartOptions.distribution,
    chartOptions.statistic,
    chartOptions.scope,
    chartOptions.limit,
    chartOptions.metricFormat
  ]);

  //It will call on change of data to display to select chart type if only one
  useEffect(() => {
    if (chartOptions.dataToDisplay) {
      const chartTypes = [];
      allDataSets &&
        allDataSets.forEach((option) => {
          if (option.fieldName === chartOptions.dataToDisplay) {
            chartTypes.push(option.shapes);
          }
        });

      metrics &&
        metrics.forEach((option) => {
          if (option.name === chartOptions.dataToDisplay) {
            chartTypes.push(option.shapes);
          }
        });

      dimensions &&
        dimensions.forEach((option) => {
          if (option.name === chartOptions.dataToDisplay) {
            chartTypes.push(option.shapes);
          }
        });

      if (chartTypes.length > 0 && chartTypes[0].length === 1) {
        handleChartTypeChange(null, null, chartTypes[0][0].pmKey);
      }
    }
  }, [chartOptions.dataToDisplay]);

  const updateChartOptions = (key, value) => {
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      [key]: value
    }));
  };

  const handleDataToDisplayChange = (event) => {
    const option = event.target.value;
    option ? setChartDataSetError(false) : setChartDataSetError(true);
    setRenderChartData(false);
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      distribution: '',
      frequency: '',
      statistic: '',
      scope: '',
      limit: '',
      metricFormat: ''
    }));
    setAvailableOptions((prevAvailableOptions) => ({
      ...prevAvailableOptions,
      distribution: '',
      frequency: '',
      statistic: '',
      scope: '',
      limit: '',
      metricFormat: ''
    }));
    updateChartOptions('type', '');
    setChartData([]);
    if (option['fieldName']) {
      updateChartOptions('dataToDisplay', option.fieldName);
      updateChartOptions('customFieldId', '');
    } else {
      updateChartOptions('dataToDisplay', option.name);
      updateChartOptions('customFieldId', option.id);
    }
  };

  const handleChartTypeChange = async (event, type, chartValue) => {
    const value = chartValue ? chartValue : event.target.value;
    setRenderChartData(false);
    setIsChartTypeChanged(true);
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      distribution: '',
      frequency: '',
      statistic: '',
      scope: '',
      limit: '',
      metricFormat: '',
      type: value ? value : '',
      bound: '',
      bubbleType: '',
      bubbleMetricId: '',
      yAxisMetricId: '',
      bubbleStatistic: '',
      yAxisStatistic: '',
      bubbleMetricsUnit: '',
      yAxisMetricsUnit: '',
      dimensionId: '',
      xAxisTitle: '',
      yAxisTitle: '',
      xAxisType: 'category',
      yAxisType: 'value',
      shape: ''
    }));
    setChartData([]);
    value ? setChartTypeError(false) : setChartTypeError(true);
    for (const chartType of SUPPORTED_CHART_TYPES) {
      if (value.toLowerCase().includes(chartType)) {
        updateChartOptions('shape', chartType);
      }
    }
    if (value !== 'text' && value !== 'dimensionBubblechart') {
      const chartOptionsProjectId = chartOptions?.projectIds[0];
      await getAvailableOptions(
        value,
        chartOptions.customFieldId ? chartOptions.customFieldId : undefined,
        chartOptionsProjectId
      );
    }
    if (value === 'dimensionBubblechart') {
      const dimensionId = dimensions?.filter(
        (dimensionData) => dimensionData?.name === chartOptions?.dataToDisplay
      );
      const chartObj = {
        ...chartOptions,
        dimensionId: dimensionId[0]?.id,
        shape: 'bubble',
        type: 'dimensionBubblechart',
        scope: 'tasks',
        limit: '100',
        bubbleMetricsUnit: 'frequency',
        yAxisMetricsUnit: 'duration',
        bubbleStatistic: 'avg',
        yAxisStatistic: 'avg',
        bubbleType: 'frequency',
        yAxisType: 'duration',
        xAxisTitle: 'frequency',
        yAxisTitle: 'duration'
      };
      setChartOptions(chartObj);
      await getBubblechartMetricsAndDimensions(chartOptions?.projectIds[0]);
      await fetchBubbleChartData(chartObj);
    }
  };

  const getAvailableOptions = async (type, dimensionId, projectId) => {
    try {
      setLoading(true);
      const response = await ApiService.getAvailableOptions(
        type,
        routerParams.workgroupId,
        projectId,
        dimensionId
      );
      if (
        !response?.distribution &&
        !response?.frequency &&
        !response?.statistic &&
        !response?.filters &&
        !response?.scope &&
        !response?.limit &&
        !response?.metricFormat
      ) {
        setChartOptions((prevChartOptions) => ({
          ...prevChartOptions,
          distribution: '',
          frequency: '',
          statistic: '',
          scope: '',
          limit: '',
          metricFormat: '',
          filters: []
        }));
      }
      setAvailableOptions(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ToasterError(t('FAILED_AVAILABLE_OPTIONS_API_ERROR'));
    }
  };

  const checkValidationDistribution = () => {
    if (availableOptions?.distribution && availableOptions.distribution?.length > 0) {
      setChartDistributionError(!chartOptions?.distribution);
      return chartOptions?.distribution;
    }
    return true;
  };

  const checkValidationFrequency = () => {
    if (availableOptions?.frequency && availableOptions.frequency?.length > 0) {
      setChartFrequencyError(!chartOptions?.frequency);
      return chartOptions?.frequency;
    }
    return true;
  };

  const checkValidationStatistic = () => {
    if (availableOptions?.statistic && availableOptions?.statistic?.length > 0) {
      setChartStatisticError(!chartOptions?.statistic);
      return chartOptions?.statistic;
    }
    return true;
  };

  const validateDiscription = () => {
    return !isDescriptionApplicable() || chartOptions.description;
  };

  const handleSave = () => {
    if (
      chartOptions.title &&
      validateDiscription() &&
      chartOptions.dataToDisplay &&
      chartOptions?.type &&
      checkValidationDistribution() &&
      checkValidationFrequency() &&
      checkValidationStatistic()
    ) {
      props.onSaveChanges(chartOptions, chartData, chartDimensions);

      handleClose();
    } else {
      chartOptions.description ? setChartDescriptionError(false) : setChartDescriptionError(true);
      chartOptions.title ? setChartLabelError(false) : setChartLabelError(true);
      chartOptions.dataToDisplay ? setChartDataSetError(false) : setChartDataSetError(true);
      chartOptions.type ? setChartTypeError(false) : setChartTypeError(true);
      selectedDataSourceId ? setChartDataSourceError(false) : setChartDataSourceError(true);
    }
  };

  const handleDistributionChange = (event) => {
    const distribution = event.target.value;
    distribution ? setChartDistributionError(false) : setChartDistributionError(true);
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      distribution: distribution ? distribution : ''
    }));
    setRenderChartData(true);
  };

  const handleFrequencyChange = (event) => {
    const frequency = event.target.value;
    frequency ? setChartFrequencyError(false) : setChartFrequencyError(true);
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      frequency: frequency ? frequency : ''
    }));
    setRenderChartData(true);
  };

  const handleStatisticChange = (event) => {
    const statistic = event.target.value;
    statistic ? setChartStatisticError(false) : setChartStatisticError(true);
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      statistic: statistic ? statistic : ''
    }));
    setRenderChartData(true);
  };

  const handleScopeChange = (event) => {
    const scope = event.target.value;
    scope ? setChartScopError(false) : setChartScopError(true);
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      scope: scope
    }));
    if (chartOptions?.type !== 'dimensionBubblechart') {
      setRenderChartData(true);
    }
    if (chartOptions?.type === 'dimensionBubblechart') {
      const chartObj = {
        ...chartOptions,
        scope: scope,
        bound: scope === 'links' ? 'source' : ''
      };
      setChartOptions(chartObj);
      fetchBubbleChartData(chartObj);
    }
  };
  const handleLimitChange = (event) => {
    const limit = event.target.value;
    if (chartOptions.shape === bubbleChartShape) {
      const chartObj = {
        ...chartOptions,
        limit: limit
      };
      setChartOptions(chartObj);
      fetchBubbleChartData(chartObj);
    } else {
      setChartOptions((prevChartOptions) => ({
        ...prevChartOptions,
        limit: limit
      }));
      setRenderChartData(true);
    }
  };

  const handleFilterChange = (value) => {
    updateChartOptions('filters', value);
    getChartData(
      chartOptions.distribution,
      chartOptions.frequency,
      chartOptions.statistic,
      value,
      chartOptions.scope,
      chartOptions.limit
    );
  };

  const getChartData = async (distribution, frequency, statistic, filters, scope, limit) => {
    try {
      setLoading(true);
      const requestBody = {
        type: chartOptions?.type
      };
      if (chartOptions.customFieldId && distribution) {
        requestBody['aggregation'] = { type: 'DISTRIBUTION', value: distribution };
      }
      if (distribution) {
        requestBody['distribution'] = distribution;
      }
      if (frequency) {
        requestBody['frequency'] = frequency;
      }
      if (statistic) {
        requestBody['statistic'] = statistic;
      }
      if (filters) {
        if (filters.length > 0) {
          filters.forEach((filter) => {
            filter.name = getFilterName(filter, variantList, taskList, links);
          });
        }
        requestBody['filters'] = filters;
      }
      if (scope) {
        requestBody['scope'] = scope;
      }
      if (limit) {
        requestBody['limit'] = limit;
      }
      if (chartOptions.customFieldId) {
        requestBody['customFieldId'] = chartOptions.customFieldId;
      }
      let response = {};
      if (chartOptions?.projectIds.length > 0) {
        response = await ApiService.getChartData(
          requestBody,
          routerParams.workgroupId,
          chartOptions?.projectIds[0]
        );
      }
      setLoading(false);

      if (chartOptions.shape !== 'numeric') {
        if (response.widgetData.chartData.length === 0) {
          ToasterError(t('CHART_DATA_NOT_AVAILABLE'));
        }
        setChartData(response.widgetData.chartData);
        if (response?.widget?.unit || response?.widget?.numberFormat) {
          setChartOptions((prevChartOptions) => ({
            ...prevChartOptions,
            unit: response.widget.unit,
            numberFormat: response.widget.numberFormat
          }));
        }
      } else {
        const filteredChartResponseData = [];
        filteredChartResponseData.push(response.widgetData.data);
        if (filteredChartResponseData.length === 0) {
          ToasterError(t('CHART_DATA_NOT_AVAILABLE'));
        }

        setChartData([response.widgetData.data]);
      }
      setChartOptions((prevChartOptions) => ({
        ...prevChartOptions,
        chartData: chartData
      }));
    } catch (error) {
      setLoading(false);
      ToasterError(t('CHART_DATA_NOT_AVAILABLE'));
    }
  };
  const handleClose = () => {
    dispach(setIsWidgetConfigOn(false));
    props.onClose();
  };

  const handleOpenColorPicker = (color) => {
    if (color === 'color1') {
      setColorPickerOpen(true);
      settitleDescriptionSelected('title');
    } else if (color === 'color2') {
      setColorPickerOpen(true);
      settitleDescriptionSelected('description');
    }
  };
  const handleCloseColorPicker = () => {
    setColorPickerOpen(false);
    settitleDescriptionSelected('');
  };

  const handleColorChange = (color) => {
    if (titleDescriptionSelected === 'title') {
      setNameColor(color.hex);
      chartOptions.nameColor = color.hex;
    } else if (titleDescriptionSelected === 'description') {
      setDescriptionColor(color.hex);
      chartOptions.descriptionColor = color.hex;
    }
  };

  const updateTitleLength = (characterLength) => {
    setTitleLength(characterLength);
  };

  const updateDescriptionLength = (characterLength) => {
    setDescriptionLength(characterLength);
  };

  const isDescriptionApplicable = () => {
    return chartOptions.shape === 'numeric' || chartOptions.shape === 'text';
  };
  const changeDataSource = (key, projectIds) => {
    projectIds ? setChartDataSourceError(false) : setChartDataSourceError(true);

    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      [key]: projectIds
    }));
    const selectedProjectId = projectIds[0];
    getAllDataToDisplay(selectedProjectId);
    fetchSavedFilters(selectedProjectId);
  };

  const getBubblechartMetricsAndDimensions = async (projectId) => {
    try {
      setLoading(true);
      const response = await BubbleChartService.fetchBubbleChartMetricsAndDimensions(projectId);
      setBubbleChartData(response?.metrics);
      setBubbleMetricList(response?.metrics);
      setYAxisMetricList(response?.metrics);
    } catch (error) {
      ToasterError(t('BUBBLE_CHART.BUBBLE_CHART_METRIC_FETCH_FAILED_INFO'));
    } finally {
      setLoading(false);
    }
  };

  const fetchBubbleChartData = async (chartObj) => {
    setChartData([]);
    setChartOptions(chartObj);
    const dimensionId = dimensions?.filter(
      (dimensionData) => dimensionData?.name === chartOptions?.dataToDisplay
    );
    try {
      setLoading(true);
      const response = await BubbleChartService.getBubbleChartWidgetData(
        chartOptions.projectIds[0],
        dimensionId[0]?.id,
        chartObj
      );
      const updatedBubbleChartData = constructBubbleChartData(response?.dimensions);
      setChartData(updatedBubbleChartData);
      setChartOptions((prevChartOptions) => ({
        ...prevChartOptions,
        chartData: updatedBubbleChartData
      }));
    } catch (error) {
      ToasterError(t('BUBBLE_CHART.BUBBLE_CHART_FETCH_FAILED_INFO'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Dialog
        onClose={handleClose}
        aria-labelledby='add-widget-dialog-title'
        open={open}
        fullScreen
        role='dialog'
        id='dialog'
      >
        <Grid container spacing={2} style={{ paddingTop: '26px' }}>
          <WidgetConfigHeaderComponent
            headerTitle={t('NEW_CHART')}
            onClose={handleClose}
            saveChartConfig={handleSave}
          ></WidgetConfigHeaderComponent>
          <Grid
            item
            xs={8}
            sx={{
              backgroundColor: '#F9F9FC',
              boxShadow: 'unset'
            }}
            id='widget-config-preview'
          >
            <div>
              <DashboardTypography
                id='chart-config-chart-title'
                typographyClass={'title-24-700 chart-title chart-title-color'}
              >
                {chartOptions?.title}
              </DashboardTypography>
            </div>
            {(chartData && chartData.length > 0) ||
              (chartOptions && chartOptions.shape === 'text') ? (
              <div style={addEditChartStyles.chartContainer}>
                {chartOptions && chartOptions.shape === 'bar' ? (
                  <BarChart
                    width='100%'
                    height='100%'
                    options={chartOptions}
                    chartData={chartData}
                    themeColors={props.themeColors}
                    isWidgetConfigureMode={true}
                  />
                ) : null}
                {chartOptions && chartOptions.shape === 'line' ? (
                  <LineChart
                    width='100%'
                    height='100%'
                    options={chartOptions}
                    chartData={chartData}
                    themeColors={props.themeColors}
                    isWidgetConfigureMode={true}
                  />
                ) : null}
                {chartOptions && chartOptions.shape === 'pie' ? (
                  <PieChart
                    width='100%'
                    height='100%'
                    options={chartOptions}
                    chartData={chartData}
                    themeColors={props.themeColors}
                    containerSection='AddEditWidgetSection'
                  />
                ) : null}
                {chartOptions && chartOptions.shape === 'numeric' ? (
                  <MetricChart
                    options={chartOptions}
                    chartData={chartData}
                    themeColors={props.themeColors}
                    layoutType='AddWidget'
                  />
                ) : null}
                {chartOptions && chartOptions.shape === 'text' ? (
                  <TextChart
                    options={chartOptions}
                    chartData={chartData}
                    themeColors={props.themeColors}
                    layoutType='AddWidget'
                  />
                ) : null}
                {chartOptions && chartOptions.shape === bubbleChartShape && (
                  <BubbleChart
                    width='100%'
                    height='100%'
                    options={chartOptions}
                    chartData={chartData}
                    themeColors={props.themeColors}
                    isWidgetConfigureMode={true}
                    layoutType='editSection'
                  />
                )}
              </div>
            ) : (
              <div style={addEditChartStyles.dummyChartThumbnail}>
                <img
                  src={chartImage}
                  alt='chart thumbnail'
                  data-testid='chart-thumbnail'
                  style={addEditChartStyles.dummyChartImage}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={4} id='widget-config-fields'>
            <div className='chartProperties-grid'>
              <DashboardTypography
                id='chart-configuration-title'
                typographyClass='title-16-700 chart-configuration-title-color'
              >
                {t('chartConfiguration')}
              </DashboardTypography>
              <Box
                component='form'
                sx={{
                  '& .MuiFormControl-root': { marginTop: 1.5, marginBottom: 1.5, width: '100%' }
                }}
                noValidate
                autoComplete='off'
              >
                <div className='addEditWdiget-container'>
                  <FormControl fullWidth size='small' error={chartDataSourceError}>
                    <InputLabel required id='data-to-display-select-label'>
                      <DashboardTypography id='field-label' typographyClass={'title-14-400'}>
                        {t('DATA_SOURCE')}
                      </DashboardTypography>
                    </InputLabel>
                    <Select
                      label='Data Source'
                      labelId='data-source-label'
                      id='data-source-label'
                      key='data-source'
                      data-testid='data-source-select'
                      value={chartOptions.projectIds}
                      onChange={(event) => {
                        setSelectedDataSourceId(event.target.value);
                        setChartOptions((prevOptions) => ({
                          ...prevOptions,
                          dataToDisplay: '',
                          type: '',
                          title: '',
                          distribution: '',
                          frequency: '',
                          yAxisTitle: '',
                          xAxisTitle: '',
                          scope: '',
                          limit: '',
                          description: ''
                        }));
                        setChartData([]);
                        changeDataSource('projectIds', [event.target.value]);
                      }}
                    >
                      <ThemeMode></ThemeMode>
                      {props.selectedDataSourceList && props.selectedDataSourceList.length > 0 ? (
                        props.selectedDataSourceList.map((project) => (
                          <MenuItem key={project.projectId} value={project.projectId}>
                            {project.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value='no_data' disabled>
                          {t('APP.EMPTY_DATA_SOURCE_MESSAGE')}
                        </MenuItem>
                      )}
                    </Select>
                    {chartDataSourceError && (
                      <FormHelperText>
                        <DashboardTypography typographyClass='title-12-400'>
                          {t('PLEASE_SELECT_DATA_SOURCE')}
                        </DashboardTypography>
                      </FormHelperText>
                    )}
                  </FormControl>
                  {chartOptions && chartOptions?.projectIds?.length !== 0 ? (
                    <>
                      <FormControl fullWidth size='small' error={chartDataSetError}>
                        <InputLabel required id='data-to-display-select-label'>
                          <DashboardTypography id='field-label' typographyClass='title-14-400'>
                            {t('DATA_TO_DISPLAY')}
                          </DashboardTypography>
                        </InputLabel>
                        <Select
                          labelId='data-to-display-select-label'
                          id='data-to-display-select'
                          key='data-to-display'
                          data-testid='data-to-display-select'
                          value={chartOptions.dataToDisplay}
                          label='Data to display'
                          onChange={handleDataToDisplayChange}
                          renderValue={(value) => {
                            let item = null;
                            if (allDataSets.length > 0) {
                              if (chartOptions.customFieldId) {
                                item = metrics.find((v) => v.name === value);
                                if (!item) {
                                  item = dimensions.find((v) => v.name === value);
                                }
                                return item ? item.name : '';
                              } else {
                                item = allDataSets.find((v) => v.fieldName === value);
                                return item ? item.label : '';
                              }
                            }
                          }}
                        >
                          <ThemeMode></ThemeMode>
                          {allDataSets &&
                            allDataSets.map((option, index) => {
                              if (option.fieldName !== 'customText') {
                                return (
                                  <MenuItem key={index} value={option}>
                                    {option.label}
                                  </MenuItem>
                                );
                              }
                            })}
                          {metrics &&
                            metrics.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option.name}
                              </MenuItem>
                            ))}
                          {dimensions &&
                            dimensions.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {chartDataSetError && (
                          <FormHelperText>
                            <DashboardTypography typographyClass='title-12-400'>
                              {t('PLEASE_ENTER_DATA_TO_DISPLAY')}
                            </DashboardTypography>
                          </FormHelperText>
                        )}
                      </FormControl>
                    </>
                  ) : null}
                  {chartOptions?.dataToDisplay ? (
                    <div>
                      <FormControl fullWidth size='small' error={chartTypeError}>
                        <InputLabel required id='chart-type-select-label'>
                          <DashboardTypography id='field-label' typographyClass='title-14-400'>
                            {t('CHART_TYPE')}
                          </DashboardTypography>
                        </InputLabel>
                        <Select
                          labelId='chart-type-select-label'
                          key='chart-type'
                          id='chart-type-select'
                          value={chartOptions?.type}
                          label='Chart Type'
                          onChange={handleChartTypeChange}
                        >
                          <ThemeMode></ThemeMode>
                          {allDataSets &&
                            allDataSets.map((option) => {
                              if (option.fieldName === chartOptions?.dataToDisplay) {
                                return option.shapes.map((shape, index1) => (
                                  <MenuItem key={index1} value={shape.pmKey}>
                                    {shape.label}
                                  </MenuItem>
                                ));
                              }
                            })}
                          {metrics &&
                            metrics.map((option) => {
                              if (option.name === chartOptions?.dataToDisplay) {
                                return option.shapes.map((shape, index1) => (
                                  <MenuItem key={index1} value={shape.pmKey}>
                                    {shape.label}
                                  </MenuItem>
                                ));
                              }
                            })}
                          {dimensions &&
                            dimensions.map((option) => {
                              if (option.name === chartOptions?.dataToDisplay) {
                                return option.shapes.map((shape, index1) => (
                                  <MenuItem key={index1} value={shape.pmKey}>
                                    {shape.label}
                                  </MenuItem>
                                ));
                              }
                            })}
                        </Select>
                        {chartTypeError && (
                          <FormHelperText>
                            <DashboardTypography typographyClass='title-12-400'>
                              {t('PLEASE_ENTER_CHART_TYPE')}
                            </DashboardTypography>
                          </FormHelperText>
                        )}
                      </FormControl>
                      {availableOptions?.distribution && (
                        <FormControl fullWidth size='small' error={chartDistributionError}>
                          <InputLabel required id='distribution-select-label'>
                            <DashboardTypography id='field-label' typographyClass={'title-14-400'}>
                              {t('DISTRIBUTION')}
                            </DashboardTypography>
                          </InputLabel>
                          <Select
                            labelId='distribution-select-label'
                            id='distribution-select'
                            value={chartOptions.distribution}
                            label='Distribution'
                            onChange={handleDistributionChange}
                          >
                            <ThemeMode></ThemeMode>
                            {availableOptions.distribution &&
                              availableOptions.distribution.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                          </Select>
                          {chartDistributionError && (
                            <FormHelperText>
                              <DashboardTypography typographyClass='title-12-400'>
                                {t('PLEASE_ENTER_DISTRIBUTION')}
                              </DashboardTypography>
                            </FormHelperText>
                          )}
                          <ThemeMode></ThemeMode>
                        </FormControl>
                      )}
                      {availableOptions?.frequency && (
                        <FormControl fullWidth size='small' error={chartFrequencyError}>
                          <InputLabel required id='frequency-select-label'>
                            <DashboardTypography id='field-label' typographyClass='title-14-400'>
                              {' '}
                              {t('FREQUENCY')}
                            </DashboardTypography>
                          </InputLabel>
                          <Select
                            labelId='frequency-select-label'
                            id='frequency-select'
                            value={chartOptions.frequency}
                            label='Frequency'
                            onChange={handleFrequencyChange}
                          >
                            <ThemeMode></ThemeMode>
                            {availableOptions.frequency &&
                              availableOptions.frequency.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                          </Select>
                          {chartFrequencyError && (
                            <FormHelperText>
                              <DashboardTypography typographyClass='title-12-400'>
                                {t('PLEASE_ENTER_CHART_FREQUENCY')}
                              </DashboardTypography>
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                      {availableOptions?.statistic && (
                        <FormControl fullWidth size='small' error={chartStatisticError}>
                          <InputLabel required id='statistic-select-label'>
                            <DashboardTypography id='field-label' typographyClass='title-14-400'>
                              {' '}
                              {t('STATISTICS')}
                            </DashboardTypography>
                          </InputLabel>
                          <Select
                            labelId='statistic-select-label'
                            id='statistic-select'
                            value={chartOptions.statistic}
                            label='Statistic'
                            onChange={handleStatisticChange}
                          >
                            <ThemeMode></ThemeMode>
                            {availableOptions.statistic &&
                              availableOptions.statistic.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                          </Select>
                          {chartStatisticError && (
                            <FormHelperText>
                              <DashboardTypography typographyClass='title-12-400'>
                                {t('PLEASE_ENTER_CHART_STATISTICS')}
                              </DashboardTypography>
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                      {(availableOptions?.scope ||
                        chartOptions?.type === 'dimensionBubblechart') && (
                          <FormControl fullWidth size='small' error={chartScopError}>
                            <InputLabel id='scope-select-label'>
                              <DashboardTypography id='field-label' typographyClass='title-14-400'>
                                {t('SCOPE')}
                              </DashboardTypography>
                            </InputLabel>
                            <Select
                              labelId='scope-select-label'
                              id='statiscopestic-select'
                              value={chartOptions.scope}
                              label='Scope'
                              onChange={handleScopeChange}
                            >
                              <ThemeMode></ThemeMode>
                              {availableOptions.scope &&
                                availableOptions.scope.map((option, index) => (
                                  <MenuItem key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              {bubbleChartScopeList?.length > 0 &&
                                chartOptions?.type === 'dimensionBubblechart' &&
                                bubbleChartScopeList?.map((option, index) => (
                                  <MenuItem key={index} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                            </Select>
                            {chartScopError && (
                              <FormHelperText>
                                <DashboardTypography typographyClass='title-12-400'>
                                  {t('PLEASE_ENTER_CHART_SCOPE')}
                                </DashboardTypography>
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      {(availableOptions?.limit ||
                        chartOptions?.type === 'dimensionBubblechart') && (
                          <TextField
                            id='limit-label'
                            label='Limit of values'
                            placeholder='Limit'
                            size='small'
                            type='number'
                            value={chartOptions.limit}
                            onChange={handleLimitChange}
                          />
                        )}
                      <TextField
                        required
                        key='title'
                        error={chartLabelError}
                        helperText={chartLabelError ? t('CHART_NAME_ERROR_MESSAGE') : null}
                        id='chart-label'
                        label={t('CHART_NAME')}
                        placeholder={t('ENTER')}
                        size='small'
                        sx={{ input: { color: chartOptions?.type === 'text' ? nameColor : null } }}
                        style={{
                          width: chartOptions.type === 'text' ? '90%' : '100%'
                        }}
                        inputProps={{
                          maxLength: chartOptions?.shape
                            ? configVariables[chartOptions.shape]?.maxtitleAllowedChar
                            : configVariables?.default?.maxtitleAllowedChar
                        }}
                        className={chartOptions?.type === 'text' ? 'add-textTitle' : ''}
                        value={chartOptions.title}
                        onChange={(event) => {
                          var regex = /^$|^\S+.*/;
                          const labelCheck = regex.test(event.target.value);
                          if (labelCheck) {
                            updateChartOptions('title', event.target.value);
                            updateTitleLength(event.target.value.length);
                            event ? setChartLabelError(false) : setChartLabelError(true);
                          }
                        }}
                      />
                      <div className='character-section MuiFormHelperText-root'>
                        {t('MAX_CHARACTERS_MESSAGE', {
                          characterLength: titleLength,
                          maxAllowdedCharLength:
                            configVariables[chartOptions.shape]?.maxtitleAllowedChar
                        })}
                      </div>
                      {isDescriptionApplicable() && (
                        <TextField
                          required
                          error={chartDescriptionError}
                          helperText={
                            chartDescriptionError ? t('CHART_DESCRIPTION_ERROR_MESSAGE') : null
                          }
                          id='chart-description'
                          label='Chart Description'
                          placeholder='Chart Description'
                          multiline
                          maxRows={2}
                          inputProps={{
                            maxLength: chartOptions?.shape
                              ? configVariables[chartOptions.shape]?.maxDescriptionAllowedChar
                              : configVariables?.default?.maxDescriptionAllowedChar,
                            style: { color: chartOptions.type === 'text' ? descriptionColor : null }
                          }}
                          size={chartOptions?.type === 'text' ? 'large' : 'small'}
                          style={{
                            width: chartOptions?.type === 'text' ? '90%' : '100%'
                          }}
                          className={chartOptions?.type === 'text' ? 'add-description' : ''}
                          value={chartOptions?.description}
                          onChange={(event) => {
                            var regex = /^$|^\S+.*/;
                            const descriptionCheck = regex.test(event.target.value);
                            if (descriptionCheck) {
                              updateChartOptions('description', event.target.value);
                              updateDescriptionLength(event.target.value.length);
                              event
                                ? setChartDescriptionError(false)
                                : setChartDescriptionError(true);
                            }
                          }}
                        />
                      )}

                      {isDescriptionApplicable() ? (
                        <div className='character-section MuiFormHelperText-root'>
                          {t('MAX_CHARACTERS_MESSAGE', {
                            characterLength: descriptionLength,
                            maxAllowdedCharLength:
                              configVariables[chartOptions.shape]?.maxDescriptionAllowedChar
                          })}
                        </div>
                      ) : null}

                      {chartOptions?.shape === 'line' || chartOptions?.shape === 'bar' ? (
                        <div>
                          <TextField
                            id='xaxis-label'
                            label='X Axis Label'
                            placeholder='X Axis Label'
                            size='small'
                            value={chartOptions.xAxisTitle}
                            onChange={(event) => {
                              updateChartOptions('xAxisTitle', event.target.value.slice(0, 24));
                              event ? setChartLabelError(false) : setChartLabelError(true);
                            }}
                            helperText={t('MAX_CHARACTERS_MESSAGE', {
                              characterLength: chartOptions?.xAxisTitle?.length,
                              maxAllowdedCharLength: maxAllowdedCharLength
                            })}
                          />

                          <TextField
                            id='yaxis-label'
                            label='Y Axis Label'
                            placeholder='Y Axis Label'
                            size='small'
                            value={chartOptions.yAxisTitle}
                            onChange={(event) => {
                              updateChartOptions('yAxisTitle', event.target.value.slice(0, 24));
                              event ? setChartLabelError(false) : setChartLabelError(true);
                            }}
                            helperText={t('MAX_CHARACTERS_MESSAGE', {
                              characterLength: chartOptions?.yAxisTitle?.length,
                              maxAllowdedCharLength: maxAllowdedCharLength
                            })}
                          />
                        </div>
                      ) : null}

                      {/* Bubble chart code starts here */}

                      {chartOptions?.type === 'dimensionBubblechart' && (
                        <ConfigureBubbleChart
                          chartOptions={chartOptions}
                          setChartOptions={setChartOptions}
                          fetchBubbleChartData={fetchBubbleChartData}
                          bubbleChartData={bubbleChartData}
                          bubbleMetricList={bubbleMetricList}
                          setBubbleMetricList={setBubbleMetricList}
                          yAxisMetricList={yAxisMetricList}
                          setYAxisMetricList={setYAxisMetricList}
                        />
                      )}

                      {chartOptions?.shape !== 'text' ? (
                        <FiltersList
                          appId={props.appId}
                          workgroupId={routerParams.workgroupId}
                          projectId={chartOptions.projectIds[0]}
                          filters={chartOptions.filters}
                          updateFilters={handleFilterChange}
                          savedFilters={savedFilters}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <div style={addEditChartStyles.updateButton}>
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{
                      height: '40px',
                      width: '80px',
                      marginTop: '4%',
                      boxShadow: 'unset',
                      color: '#3648C7',
                      borderColor: '#3648C7',
                      borderRadius: '8px',
                      textTransform: 'unset',
                      padding: '10px 14px 10px 14px !important',
                      minWidth: 'unset',
                      display: 'none',
                      ':hover': {
                        boxShadow: 'unset'
                      }
                    }}
                    onClick={handleSave}
                  >
                    {t('UPDATE')}
                  </Button>
                </div>
              </Box>
            </div>
          </Grid>
        </Grid>
        {loading === true && <LoadingSpinner data-testid='spinner'></LoadingSpinner>}
      </Dialog>
    </div>
  );
};

AddEditWidget.propTypes = {
  open: PropTypes.bool,
  onSaveChanges: PropTypes.func,
  onClose: PropTypes.func,
  chartOptions: PropTypes.object,
  themeColors: PropTypes.array,
  appId: PropTypes.string,
  projectId: PropTypes.string,
  selectedProjectIds: PropTypes.array,
  selectedDataSourceList: PropTypes.array
};

export default AddEditWidget;
