import axios from 'axios';
import { ENDPOINTURL } from '../utils/ApiConstants';
import { mockBubbleChartMetricsAndDimensionsData, mockBubbleData } from '../utils/MockData';

const BubbleChartService = {
  fetchBubbleChartMetricsAndDimensions: async (projectId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return mockBubbleChartMetricsAndDimensionsData;
    } else {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_PROJECTS +
            '/' +
            projectId +
            ENDPOINTURL.BUBBLE_CHART +
            ENDPOINTURL.METRICS_DIMENSIONS
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  getBubbleChartWidgetData: async (projectId, dimensionId, requestBody) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return mockBubbleData;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_PROJECTS +
            '/' +
            projectId +
            ENDPOINTURL.API_DIMENSIONS +
            '/' +
            dimensionId +
            ENDPOINTURL.API_BUBBLE_CHART,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};

export default BubbleChartService;
