import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  gridContainer: {
    width: 'auto',
    height: '220px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    border: '1px solid #e0e2e7',
    padding: '15px'
  },
  gridImageContainer: {
    backgroundColor: '#6876D5',
    height: '100px',
    width: 'auto',
    borderRadius: '6px'
  },
  placeholderImage: {
    width: '97%',
    height: '93%',
    marginLeft: 'auto',
    display: 'block',
    marginRight: 'auto',
    marginTop: 2,
    paddingTop: '5px',
    paddingBottom: '3px'
  },
  appDetailsSection: {
    width: '302px',
    height: '86px'
  },
  appHeaderSection: {
    display: 'flex',
    marginTop: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '30px'
  },

  DescriptionSection: {
    display: 'flex',
    width: 275,
    flexDirection: 'column',
    height: '40px',
    justifyContent: 'end'
  },

  appDescriptionSection: {
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '20px',
    fontFamily: 'Inter',
    maxHeight: '2.8em',
    marginTop: '15px',
    fontSize: '14px',
    fontWeight: '400',
    fontStyle: 'normal',
    color: '#333843'
  },

  appDescription: {
    cursor: 'pointer'
  },

  divider: {
    height: '1px',
    backgroundColor: '#E0E2E7',
    border: 'none',
    marginBottom: '5px'
  },
  Editedate: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  imageContainer: {
    marginBottom: '33px'
  },
  noAppsImage: {
    width: '246px',
    height: '170px'
  },
  link: {
    textDecoration: 'none'
  },
  dashboardAppContainer: {
    backgroundColor: '#F9F9FC',
    justifyContent: 'center'
  },
  listContainer: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 26,
    paddingBottom: 26,
    justifyContent: 'space-between'
  },
  favouriteButton: {
    flexDirection: 'row',
    display: 'flex'
  },
  paginationView: {
    backgroundColor: '#F9F9FC',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 30,
    paddingTop: 30,
    display: 'flex',
    '@media (max-width: 425px)': {
      display: 'unset',
      marginLeft: '110px'
    }
  }
}));
