import React, { useState, useEffect, useContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Theme from '../Theme';
import Typography from '@mui/material/Typography';
import Header from '../header/Header';
import AppsHeader from '../appsHeader/AppsHeader';
import ApiService from '../../services/ApiService';
import AppsGridView from '../apps/AppsGridView';
import AppsListView from '../apps/AppsListView';
import { useStyles } from '../../styles/appsViewStyles';
import noAppsIllustration from '../../assets/thumbnails/noAppsIllustration.svg';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useParams, useNavigate, generatePath, useLocation } from 'react-router-dom';
import '../../styles/AppsLayout.scss';
import { AuthContext } from '../../utils/KeycloakWrapper';
import AuthInterceptor from '../../utils/AuthInterceptor';
import LoadingSpinner from '../loader/LoadingSpinner';
import '../../styles/LoadingSpinner.css';
import { useSelector } from 'react-redux';
import { ToasterError, ToasterSuccess } from '../../utils/toaster';
import PaginationComponent from '../shared/Pagination/PaginationComponent';

const AppsContainer = () => {
  const { t } = useTranslation();
  const routerParams = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [allApps, setAllApps] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedAppViewType, setSelectedAppViewType] = useState('Grid');
  const [projects, setProjects] = useState([]);
  const [totalApps, setTotalApps] = useState([]);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const [workgroupName, setWorkgroupName] = useState('');
  const [pageSize, setPageSize] = useState(12);
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const sortOptions = useSelector((state) => state.sorting);

  useEffect(() => {
    fetchAllApps(0, searchQuery, pageSize);
  }, [sortOptions]);

  useEffect(() => {
    AuthInterceptor(authContext);
    setWorkgroupName(decodeURIComponent(urlParams.get('workgroupName')));
    const fetchData = async () => {
      setCurrentPageNumber(0);
      await fetchAllApps(0, '', pageSize);

      if (routerParams.workgroupId) {
        await fetchProjectsByWorkgroup(routerParams.workgroupId);
      }
    };
    if (authContext && Object.keys(authContext).length !== 0) {
      fetchData();
    }
  }, [authContext]);

  const searchApps = async (searchText) => {
    setSearchQuery(searchText);
    setCurrentPageNumber(0);
    await fetchAllApps(0, searchText, pageSize);
  };

  const fetchAllApps = async (pageNumber, searchText, newPageSize) => {
    try {
      if (routerParams.workgroupId) {
        const requestBody = {
          pageNo: pageNumber,
          pageSize: newPageSize ? newPageSize : pageSize,
          search: searchText,
          sortBy: sortOptions.sortBy,
          sortOrder: sortOptions.sortOrder
        };

        const response = await ApiService.getAppsByWorkgroup(requestBody, routerParams.workgroupId);
        setAllApps(response);
        const totalAppsResponse = await ApiService.getTotalApps(routerParams.workgroupId);
        setTotalApps(totalAppsResponse?.appNames);
      }
    } catch (error) {
      if (!error.toString().includes('401')) {
        ToasterError(t('APP.FAILED_TO_FETCH_APPS'));
      }
    }
  };
  const navigateToDashboard = (selectedAppId) => {
    const routePattern = '/Workgroups/:workgroupId/Apps/:appId';

    const params = {
      workgroupId: routerParams.workgroupId,
      appId: selectedAppId
    };

    const generatedUrl = generatePath(routePattern, params);
    const urlWithQueryParams = `${generatedUrl}?${new URLSearchParams({
      workgroupName: encodeURIComponent(workgroupName)
    })}`;
    navigate(urlWithQueryParams);
  };

  const addEditApplication = async (app, navigateOnUpdate = false) => {
    try {
      setLoading(true);
      app['connection']['workgroupId'] = routerParams.workgroupId;
      app['workgroupId'] = routerParams.workgroupId;
      app['workgroupName'] = workgroupName;

      let response;
      if (!app.id) {
        response = await ApiService.createNewApp(app);
        navigateToDashboard(response.id);
      } else {
        app['id'] = routerParams.id ? routerParams.id : app.id;
        response = await ApiService.updateApplicationDetails(app, app?.id);
        if (navigateOnUpdate) {
          navigateToDashboard(response.id);
        }
      }
      setLoading(false);
      countTotalApp(!app.id ? 'create' : 'update');
      ToasterSuccess(`${!app.id ? t('APP.CREATED_SUCCESS') : t('APP.UPDATED_SUCCESS')}!!`);
    } catch (error) {
      setLoading(false);
      if (error.response?.status === 409) {
        ToasterError(t('APP.DUPLICATE_APP_NAME_ERROR'));
      } else {
        ToasterError(t('APP.FAILED_TO_CREATE_APP_ERROR'));
      }
    }
  };

  const createApp = async (app) => {
    await addEditApplication(app, true);
  };

  const updateApp = async (app) => {
    await addEditApplication(app);
  };

  const fetchProjectsByWorkgroup = async (workgroupId) => {
    try {
      const response = await ApiService.getProjectsByWorkgroup(workgroupId);
      setProjects(response);
    } catch (error) {
      ToasterError(t('APP.FAILED_TO_FETCH_PROJECTS'));
    }
  };

  const handlePageChange = async (event, pageNumber) => {
    const selectedPageNumber = pageNumber - 1;
    setCurrentPageNumber(selectedPageNumber);
    await fetchAllApps(selectedPageNumber, searchQuery, pageSize);
  };

  const selectAppViewType = (type) => {
    setSelectedAppViewType(type);
  };

  const getStartingDataNumber = () => {
    return currentPageNumber * pageSize + 1;
  };

  const getEndingDataNumber = () => {
    if (currentPageNumber + 1 === allApps?.totalPages) {
      return allApps?.totalElements;
    }
    return (currentPageNumber + 1) * pageSize;
  };

  const countTotalApp = (appType) => {
    let appLength = 0;
    if (appType === 'delete') {
      appLength = totalApps.length - 1;
    } else {
      appLength = totalApps.length;
    }
    if (totalApps.length >= pageSize) {
      const totalappsPage = ~~(totalApps.length / pageSize);
      const appQuotienValue = ~~(appLength / pageSize);
      const appRemainderValue = appLength % pageSize;
      if (appQuotienValue === 0 && appRemainderValue > 0) {
        fetchAllApps(0, '', pageSize);
        setCurrentPageNumber(0);
      }
      if (appQuotienValue > 0 && appRemainderValue > 0) {
        fetchAllApps(currentPageNumber, '', pageSize);
      }
      if (appQuotienValue > 0 && appRemainderValue === 0) {
        if (totalappsPage !== currentPageNumber) {
          fetchAllApps(currentPageNumber, '', pageSize);
        } else {
          fetchAllApps(currentPageNumber - 1, '', pageSize);
          setCurrentPageNumber(currentPageNumber - 1);
        }
      }
    } else {
      fetchAllApps(0, '', pageSize);
    }
  };

  const deleteApp = async (appId) => {
    try {
      await ApiService.deleteApp(appId);
      countTotalApp('delete');
      ToasterSuccess(t('APP.APP_DELETED'));
    } catch (error) {
      ToasterError(t('APP.FAILED_TO_DELETE_APP_ERROR'));
    }
  };

  const closeAddPopup = (isOpenAddPopup) => {
    setOpenAddPopup(isOpenAddPopup);
  };

  const handlePageSizeChange = async (selectedPageSize) => {
    setPageSize(selectedPageSize);
    setCurrentPageNumber(0);
    await fetchAllApps(0, searchQuery, selectedPageSize);
  };

  return (
    <ThemeProvider theme={Theme}>
      <Header />
      <div>
        <AppsHeader
          searchApps={searchApps}
          totalNumberOfApps={allApps?.totalElements}
          selectAppViewType={selectAppViewType}
          selectedAppViewType={selectedAppViewType}
          projects={projects}
          handleSave={createApp}
          openAddPopup={openAddPopup}
          totalApps={totalApps}
          workgroupName={workgroupName}
          closeAddPopup={closeAddPopup}
        />
        <div className={allApps?.content?.length === 0 ? 'no-app-list' : 'apps-ContentWrapper'}>
          {allApps?.content?.length === 0 && (
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'flex', sm: 'flex' },
                justifyContent: 'center',
                alignSelf: 'center',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <div className='create-new-img-container'>
                <img
                  src={noAppsIllustration}
                  alt={noAppsIllustration}
                  className={classes.noAppsImage}
                />
              </div>
              <Typography
                textAlign='center'
                sx={{
                  color: '#333843',
                  fontSize: 20,
                  fontWeight: 400,
                  fontFamily: 'Inter',
                  lineHeight: '30px',
                  letterSpacing: '0.1px'
                }}
              >
                {t('YOU_DO_NOT_HAVE_ANY_APPS')}
              </Typography>
              <a onClick={() => setOpenAddPopup(true)} className={classes.link}>
                <Typography
                  data-testid='no-apps-in-list'
                  textAlign='center'
                  sx={{
                    color: '#041AB9',
                    fontSize: 16,
                    fontWeight: 600,
                    fontFamily: 'Inter',
                    cursor: 'pointer'
                  }}
                >
                  {t('CREATE_NEW')}
                </Typography>
              </a>
            </Box>
          )}
          {allApps?.content?.length > 0 && selectedAppViewType === 'Grid' ? (
            <AppsGridView
              allApps={allApps?.content}
              data-testid='AppsGridViewContainer'
              deleteApp={deleteApp}
              workgroupName={workgroupName}
              workgroupId={routerParams.workgroupId}
              projects={projects}
              totalApps={totalApps}
              updateApp={updateApp}
              handleSave={createApp}
            />
          ) : (
            <AppsListView
              allApps={allApps?.content}
              data-testid='AppsListViewContainer'
              deleteApp={deleteApp}
              workgroupName={workgroupName}
              workgroupId={routerParams.workgroupId}
              projects={projects}
              totalApps={totalApps}
              updateApp={updateApp}
              handleSave={createApp}
            />
          )}
        </div>
      </div>
      <div data-testid='paginationView' className='apps-pagination' id='apps-pagination'>
        {allApps?.content?.length > 0 && (
          <PaginationComponent
            data={allApps}
            pageSize={pageSize}
            handlePageSizeChange={handlePageSizeChange}
            getStartingDataNumber={getStartingDataNumber}
            getEndingDataNumber={getEndingDataNumber}
            currentPageNumber={currentPageNumber}
            handlePageChange={handlePageChange}
          ></PaginationComponent>
        )}
      </div>
      {loading === true && <LoadingSpinner></LoadingSpinner>}
    </ThemeProvider>
  );
};

export default AppsContainer;
