import { createSlice } from '@reduxjs/toolkit';

const initialGlobalFilterState = {
  isglobalFiltersDrawerOpen: false
};

const globalFilterSlice = createSlice({
  name: 'globalFilter',
  initialState: initialGlobalFilterState,
  reducers: {
    setGlobalFilterDrawerState: (state, action) => {
      state.isglobalFiltersDrawerOpen = action.payload;
    }
  }
});

export const { setGlobalFilterDrawerState } = globalFilterSlice.actions;
export default globalFilterSlice.reducer;
