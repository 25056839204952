import React, { useState } from 'react';
import {
  Button,
  Stack,
  Typography,
  IconButton,
  Box,
  Divider,
  Grid,
  Menu,
  MenuItem
} from '@mui/material';

import PropTypes from 'prop-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { useTranslation } from 'react-i18next';
import AddEditFilter from './AddEditFilters';
import { capitalize } from '../../utils/utils.js';
import '../../styles/FilterList.scss';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography.js';
import AddIcon from '@mui/icons-material/Add';
import ThemeMode from '../themeMode/themeMode.js';

function FiltersList(props) {
  const [filterSelectionDialogOpen, setFilterSelectionDialogOpen] = useState(false);
  const [filterObj, setFilterObj] = useState();
  const [filterMenuOpen, setFilterMenuOpen] = useState(null);
  const [isAddEdit, setIsAddEdit] = useState(true);
  const { t } = useTranslation();
  const [indexToEdit, setIndexToEdit] = useState(-1);

  const openFilterSelectionDialog = () => {
    setFilterObj(null);
    setFilterSelectionDialogOpen(true);
    setIsAddEdit(true);
  };

  const handleFilterChange = (value) => {
    let selectedFilters = props.filters;

    if (indexToEdit !== -1) {
      const filtersCopy = [...selectedFilters]; // important to create a copy, otherwise you'll modify state outside of setState call
      filtersCopy[indexToEdit] = value;
      selectedFilters = filtersCopy;
    } else {
      selectedFilters = selectedFilters.concat(value);
    }
    selectedFilters.map((filter) => {
      if (filter.filterType === 'occurrencesInCase') {
        filter['itemIds'] = filter.scope === 'TASKS' ? filter['taskIds'] : filter['linkIds'];
      }
    });
    props.updateFilters(selectedFilters);
  };

  const handleFilterClose = () => {
    setFilterSelectionDialogOpen(false);
    setIndexToEdit(-1);
  };

  const removeFilter = (index) => {
    const filtersArray = [...props.filters];
    filtersArray.splice(index, 1);
    props.updateFilters(filtersArray);
  };

  const editFilter = (filter, index) => {
    if (filter.filterType === 'occurrencesInCase') {
      if (filter.scope === 'TASKS') {
        filter['taskIds'] = filter['itemIds'];
      } else {
        filter['linkIds'] = filter['itemIds'];
      }
    }
    setIndexToEdit(index);
    setFilterObj(filter);
    setFilterSelectionDialogOpen(true);
    setIsAddEdit(false);
  };

  const getFilterName = (filter) => {
    if (filter.filterType !== 'dimension' && filter.filterType !== 'metric') {
      return t('FILTER.' + filter.filterType.toUpperCase());
    }
    if (filter.filterType === 'dimension') {
      return capitalize(filter.dimensionName);
    }
    if (filter.filterType === 'metric') {
      return capitalize(filter.metricName);
    }
    return '';
  };

  const openFilterMenu = (event) => {
    setFilterMenuOpen(event.currentTarget);
  };

  const open = Boolean(filterMenuOpen);

  const handleFilterMenuClose = () => {
    setFilterMenuOpen(null);
  };

  const savedFiltersChange = (filter) => {
    handleFilterChange(filter.filters);
    setFilterMenuOpen(null);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={2} xl={2}>
          <DashboardTypography id='filters' typographyClass={'title-14-500 filters'}>
            {t('FILTERS')}
          </DashboardTypography>
        </Grid>
        <Grid item lg={10} xl={10} className='load-filters-grid'>
          <Button id='load-filter' data-testid='load-filter-btn' onClick={openFilterMenu}>
            <BookmarksIcon className='filter-icon' id='filter-icon' />
            <DashboardTypography
              id='load-filters-lists'
              typographyClass={'title-14-500 load-filters-lists'}
            >
              {t('LOAD_FILTERS')}
            </DashboardTypography>
          </Button>
          <Menu
            id='load-filter-menu'
            data-testid='load-filter-menu'
            aria-labelledby='load-filter-menu'
            className='load-filter-menu'
            anchorEl={filterMenuOpen}
            open={open}
            onClose={handleFilterMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            {props.savedFilters && props.savedFilters.length !== 0 ? (
              props.savedFilters.map((option, index) => (
                <MenuItem onClick={() => savedFiltersChange(option)} key={index} value={option.id}>
                  {option.name}
                </MenuItem>
              ))
            ) : (
              <div className='no-filter-found'>
                <DashboardTypography
                  id='no-filter-found'
                  typographyClass={'title-16-500 no-filter-found'}
                >
                  {t('NO_FILTER')}
                </DashboardTypography>
              </div>
            )}
          </Menu>
        </Grid>
      </Grid>
      <div className='add-filter-container' id='add-filter-container'>
        <div className='filter-inner-section' onClick={openFilterSelectionDialog}>
          <DashboardTypography id='add-filter' typographyClass={'title-14-500 add-filter'}>
            {t('ADD_FILTER')}
          </DashboardTypography>
          <ThemeMode />
          <AddIcon className='add-filter-icon' id='plus-icon' data-testid='+'></AddIcon>
        </div>
        {props.filters && props.filters.length > 0 && (
          <Divider sx={{ marginTop: '1%', marginBottom: '4%' }} />
        )}
        {filterSelectionDialogOpen ? (
          <AddEditFilter
            open={filterSelectionDialogOpen}
            handleClose={handleFilterClose}
            handleSave={handleFilterChange}
            workgroupId={props.workgroupId}
            projectId={props.projectId}
            filterObj={filterObj}
            isAddEdit={isAddEdit}
          />
        ) : null}
        <Box>
          {props.filters &&
            props.filters.map((filter, index) => (
              <>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  key={filter.filterType}
                  className='added-filters'
                >
                  <DashboardTypography id='filter-name' typographyClass={'title-14-500'}>
                    {getFilterName(filter)}
                  </DashboardTypography>
                  <Stack direction='row' spacing={1}>
                    <ThemeMode />
                    <IconButton
                      onClick={() => editFilter(filter, index)}
                      data-testid='edit-filter-button'
                    >
                      <ModeEditOutlineOutlinedIcon
                        data-testid='edit-filter-icon'
                        id='edit-filter-button'
                      />
                    </IconButton>
                    <IconButton onClick={() => removeFilter(index)} data-testid='close-button'>
                      <CloseIcon id='remove-filter-icon' />
                    </IconButton>
                  </Stack>
                </Stack>
              </>
            ))}
        </Box>
      </div>
    </>
  );
}
FiltersList.propTypes = {
  workgroupId: PropTypes.string,
  projectId: PropTypes.string,
  filters: PropTypes.array,
  updateFilters: PropTypes.func,
  savedFilters: PropTypes.array
};
export default FiltersList;
