import React from 'react';
import PropTypes from 'prop-types';
import ImageChart from '../../../imageChart/ImageChart';
import './ImageWidgetPreviewPanel.scss';
import ThemeMode from '../../../../themeMode/themeMode';
import DashboardTypography from '../../../../shared/DashboardTypography/DashboardTypography';

const ImageWidgetPreviewPanel = (props) => {
  return (
    <>
      <ThemeMode/>
    <div id='image-container' className='image-main-container'>
      <div data-testid='preview-image-title' className='image-container' id='image-name-container'>
        <DashboardTypography typographyClass='title-24-700' id='image-name'>
        {props?.chartOptions?.title ? props?.chartOptions?.title : null}
        </DashboardTypography>
      </div>
      {props?.chartOptions?.imageData && (
        <div className='image-grid' id='display-image-container'>
          <ImageChart
            chartOptions={props?.chartOptions}
            layoutType='editWisgetSection'
          ></ImageChart>
        </div>
      )}
    </div>
    </>
  );
};

ImageWidgetPreviewPanel.propTypes = {
  chartOptions: PropTypes.object
};

export default ImageWidgetPreviewPanel;
