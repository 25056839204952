import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from './slices/dashboardSlice';
import sortingReducer from './slices/sortSlice';
import metricAndStatisticsReducer from './slices/metricAndStatisticsSlice';
import processModelConfigReducer from './slices/processModelCofigSlice';
import variantSelectionReducer from './slices/variantSelectionSlice';
import allDashboardReducer from './slices/allDashboardSlice';
import globalFilterReducer from './slices/globalFilterSlice';
import selectedWidgetSlice from './slices/selectedWidgetDataSlice';
import existingWidgetReducer from './slices/existingWidgetSlice';
import viewAsTableDialog from './slices/viewAsTableDialog';
import variantListReducer from './slices/variantListSlice';
import dataSourcesReducer from './slices/dataSourcesSlice';
import filterLisrPopoverSlice from './slices/filterLisrPopoverSlice';
import setThemeModeReducer from './slices/setThemeMode';
import reportConfigReducer from './slices/reportConfigSlice';
import languageSelectedReducer from './slices/languageSelectedSlice';
import workgroupRepositoriesReducer from './slices/repositoriesSlice';

const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    sorting: sortingReducer,
    metricAndStatistics: metricAndStatisticsReducer,
    processModelConfig: processModelConfigReducer,
    variantSelection: variantSelectionReducer,
    allDashboard: allDashboardReducer,
    globalFilter: globalFilterReducer,
    selectedWidget: selectedWidgetSlice,
    existingWidget: existingWidgetReducer,
    dialog: viewAsTableDialog,
    variantList: variantListReducer,
    filterLisrPopover: filterLisrPopoverSlice,
    dataSources: dataSourcesReducer,
    themeMode: setThemeModeReducer,
    reportConfig: reportConfigReducer,
    selectedLanguage: languageSelectedReducer,
    workgroupRepositories: workgroupRepositoriesReducer
  }
});

export default store;
