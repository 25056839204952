import React from 'react';
import PropTypes from 'prop-types';
import ChartRenderer from '../ChartRenderer.js';
import ChartService from '../../../services/chartService.js';

const PieChart = (props) => {
  const option = ChartService.getChartOption(
    props.options,
    props.chartData,
    props.themeColors,
  );
  return <ChartRenderer width={props.width} height={props.height} option={option} />;
};

PieChart.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  options: PropTypes.object,
  chartData: PropTypes.array,
  themeColors: PropTypes.array,
};

export default PieChart;
