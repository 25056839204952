import React, { useContext, useEffect, useRef, useState } from 'react';
import ApiService from '../../services/ApiService';
import DashboardHeader from '../header/DashboardHeader';
import DashboardContainer from '../screens/DashboardContainer';
import { ThemeProvider } from '@mui/material/styles';
import Theme from '../Theme';
import Header from '../header/Header';
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingSpinner from '../loader/LoadingSpinner';
import '../../styles/LoadingSpinner.scss';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useTranslation } from 'react-i18next';
import DashboardDataWarning from './DashboardDataWarning';
import { AuthContext } from '../../utils/KeycloakWrapper';
import AuthInterceptor from '../../utils/AuthInterceptor';
import { useDispatch, useSelector } from 'react-redux';
import { setDashboardEditMode, setIsSaveScreenShot } from '../../Redux/slices/dashboardSlice';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import {
  resetDashboardState,
  setIsDashboardRefreshed,
  setSelectedDataSources
} from '../../Redux/slices/allDashboardSlice';
import { ToasterError, ToasterSuccess } from '../../utils/toaster';
import store from '../../Redux/store.js';
import { getGlobalFiltersFromSessionStorage } from '../../utils/GlobalFilterUtils.js';
import { setDataSources } from '../../Redux/slices/dataSourcesSlice.js';
import ReportService from '../../services/ReportService.js';
import BubbleChartService from '../../services/BubbleChartService';
import { bubbleChartShape, LICENSE_TYPES } from '../../utils/Constants.js';
import { licenseUtilizationReportDataFormatting } from '../../utils/licenseUtilizationReportDataFormatting.js';
import { getReportData, getReportTableViewData } from '../../utils/reportUtils.js';
import { setRepositories } from '../../Redux/slices/repositoriesSlice.js';

const Dashboard = () => {
  const dashboardObj = {
    name: '',
    charts: [],
    layout: {
      rows: 1,
      columns: 12,
      layoutType: 'GridLayout',
      layouts: []
    },
    legend: {},
    isPublished: false,
    isPublic: false,
    themeId: 'themeID',
    globalThemeId: 'globalThemeId'
  };
  const { t } = useTranslation();
  const routeParams = useParams();
  const [appData, setAppData] = useState({});
  const [dashboardData, setDashboardData] = useState({ ...dashboardObj });
  const [loading, setLoading] = useState(false);
  const [allDashboards, setAllDashboards] = useState();
  const [renderChartData, setRenderChartData] = useState(false);
  const [showDataWarning, setShowDataWarning] = useState(false);
  const [selectedDataSourceList, setSelectedDataSourceList] = useState([]);
  const authContext = useContext(AuthContext);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const workgroupName = decodeURIComponent(urlParams.get('workgroupName'));
  const dispatch = useDispatch();
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const selectedDashboardData = useSelector((state) => state.allDashboard.selectedDashboardData);
  const isFavouriteDashboardBtnClicked = useSelector(
    (state) => state.allDashboard.isFavouriteDashboardBtnClicked
  );
  const globalFilterKey = 'globalFilters';

  const handleSaveDashboard = (editMode) => {
    return submitDashboardData(editMode);
  };

  const updateDashboardName = (selectedDashboard) => {
    submitDashboardData(true, selectedDashboard);
  };

  const addNewDashboard = (dashboardName) => {
    dashboardObj.name = dashboardName;
    setDashboardData({ ...dashboardObj });
    updateDashboardData('name', dashboardName);
  };

  const createDuplicateDashboard = async (dashboardName) => {
    const selectedDashboard =
      Object.keys(selectedDashboardData).length === 0 ? dashboardData : selectedDashboardData;
    try {
      setLoading(true);
      const requestBody = {
        dashboardName: dashboardName,
        appId: appData.id
      };
      const response = await ApiService.createDuplicateDashboard(requestBody, selectedDashboard.id);
      await fetchDashboardData(response.id);
      setLoading(false);
      dispatch(setDashboardEditMode(true));
      ToasterSuccess(t('DASHBOARD.DUPLICATE_DASHBOARD_API_SUCCESS'));
      dispatch(resetDashboardState());
    } catch (error) {
      setLoading(false);
      ToasterError(t('DASHBOARD.DUPLICATE_DASHBOARD_API_FAILED'));
      dispatch(resetDashboardState());
    }
  };

  const handleDiscard = async (selectedDashboard) => {
    const newDashboardData =
      selectedDashboard && Object.keys(selectedDashboard).length !== 0
        ? selectedDashboard
        : dashboardData;
    await fetchDashboardData(newDashboardData?.id);
    await fetchAppDetails();
    dispatch(setDashboardEditMode(false));
  };

  const isDataLoading = (data) => {
    setLoading(data);
  };

  const updateAppTheme = (newSelectedTheme) => {
    setAppData((prevAppData) => ({
      ...prevAppData,
      theme: newSelectedTheme ? newSelectedTheme : {}
    }));
  };

  const updateNewSelectedDashboard = (newDashboardObj) => {
    setDashboardData({ ...newDashboardObj });
    renderDashboard(newDashboardObj?.id);
  };

  const handleConfirmationClose = () => {
    setShowDataWarning(false);
  };

  useEffect(() => {
    AuthInterceptor(authContext);
    const fetchData = async () => {
      if (routeParams && routeParams.appId) {
        await fetchDashboardData();
        await fetchAppDetails();
      }
    };
    if (authContext && Object.keys(authContext).length !== 0) {
      fetchData();
    }
  }, [authContext]);

  useEffect(() => {
    if (dashboardData && dashboardData.charts.length > 0 && renderChartData) {
      fetchDataByConfig(dashboardData);
      setRenderChartData(false);
    }
  }, [dashboardData?.charts]);

  useEffect(() => {
    if (appData?.connection?.projectIds) {
      getDataSources();
      getWorkgroupRepositories();
    }
  }, [appData?.connection?.projectIds]);

  const getDataSources = async () => {
    try {
      const response = await ApiService.getProjectsByWorkgroup(routeParams.workgroupId);
      dispatch(setDataSources(response));
      const selectedProjects = response?.filter((project) => {
        return appData?.connection?.projectIds.includes(project.projectId);
      });
      setSelectedDataSourceList(selectedProjects);
      dispatch(setSelectedDataSources(selectedProjects));
    } catch (error) {
      ToasterError(t('APP.FAILED_TO_FETCH_PROJECTS'));
    }
  };

  const getWorkgroupRepositories = async () => {
    try {
      const response = await ApiService.getRepositoriesByWorkgroup(routeParams.workgroupId);
      dispatch(setRepositories(response));
    } catch (error) {
      ToasterError(t('APP.FAILED_TO_FETCH_REPOSITORIES'));
    }
  };

  const fetchDashboardData = async (currentDashboardId, isRefreshDashboard = false) => {
    try {
      setLoading(true);
      const response = await ApiService.getDashboardByApplicationId(routeParams.appId, 0, 10, '');
      setRenderChartData(true);
      if (response.content.length > 0) {
        setAllDashboards(response.content);
        if (currentDashboardId) {
          const matchedIndex = response.content.findIndex((obj) => obj.id === currentDashboardId);
          if (!isFavouriteDashboardBtnClicked) {
            setDashboardData(response.content[matchedIndex]);
            if (isRefreshDashboard) {
              dispatch(setIsDashboardRefreshed(true));
            }
          }
        } else {
          setDashboardData(response.content[0]);
          dispatch(setDashboardEditMode(false));
        }
      } else {
        setAllDashboards(response.content);
        dispatch(setDashboardEditMode(true));
        addNewDashboard('Default Dashboard');
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchAppDetails = async () => {
    try {
      setLoading(true);
      const response = await ApiService.getApplicationDetails(routeParams.appId);
      setAppData(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const renderDashboard = (id) => {
    fetchDashboardById(id);
  };

  const fetchDashboardById = async (dashboardId) => {
    try {
      setLoading(true);
      setRenderChartData(true);
      const response = await ApiService.getDashboardById(dashboardId);
      setDashboardData(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchDataByConfig = (dashboard) => {
    for (const chart of dashboard.charts) {
      getChartDataByConfig(chart);
    }
  };

  const getChartConfigByGlobalFilters = (chartConfig, appliedGlobalFilters) => {
    const id = chartConfig?.projectIds[0];
    if (chartConfig?.filters?.length > 0) {
      const chartFilters = chartConfig?.filters;
      chartConfig.filters = [];
      chartConfig.filters = chartFilters?.filter(
        (item) => item.globalFilterUniqueId === undefined && item.isGlobalFilter === undefined
      );
    }
    const dashboardDataArr = appliedGlobalFilters?.filter(
      (filter) => filter.projectIds.includes(id) && dashboardData.id === filter.dashboardId
    );
    if (dashboardDataArr?.length > 0) {
      dashboardDataArr?.forEach((globalFilter) => {
        globalFilter?.filters?.forEach((filters) => {
          chartConfig.filters.push(filters);
        });
      });
    }
    return chartConfig;
  };

  const getChartDataByConfig = async (chartConfig) => {
    const newState = store.getState();
    try {
      setLoading(true);
      const requestBody = {
        type: chartConfig.type
      };

      const appliedGlobalFilters = getGlobalFiltersFromSessionStorage(globalFilterKey);
      if (appliedGlobalFilters?.length > 0 && newState?.dashboard?.isDashboardEditMode === false) {
        chartConfig = getChartConfigByGlobalFilters(chartConfig, appliedGlobalFilters);
      } else {
        const chartFilters = chartConfig?.filters;
        chartConfig.filters = [];
        chartConfig.filters = chartFilters?.filter(
          (item) => item.globalFilterUniqueId === undefined && item.isGlobalFilter === undefined
        );
      }
      if (chartConfig.customFieldId && chartConfig.distribution) {
        requestBody['aggregation'] = { type: 'DISTRIBUTION', value: chartConfig.distribution };
      }
      if (chartConfig.distribution) {
        requestBody['distribution'] = chartConfig.distribution;
      }
      if (chartConfig.frequency) {
        requestBody['frequency'] = chartConfig.frequency;
      }
      if (chartConfig.statistic) {
        requestBody['statistic'] = chartConfig.statistic;
      }
      if (chartConfig.filters) {
        requestBody['filters'] = chartConfig.filters;
      }
      if (chartConfig.scope) {
        requestBody['scope'] = chartConfig.scope;
      }
      if (chartConfig.limit) {
        requestBody['limit'] = chartConfig.limit;
      }
      if (chartConfig.customFieldId) {
        requestBody['customFieldId'] = chartConfig.customFieldId;
      }
      if (chartConfig.shape === 'svg' && chartConfig.projectIds.length > 0) {
        if (chartConfig.metric) {
          requestBody['metric'] = chartConfig.metric;
          requestBody['otherMetricId'] = chartConfig.otherMetricId;
          requestBody['modelId'] = chartConfig?.modelId;
          requestBody['isOkConformance'] = chartConfig?.isOkConformance;
          requestBody['showModel'] = chartConfig?.showModel;
          requestBody['ruleId'] = chartConfig?.ruleId;
        }
        if (chartConfig.orientation) {
          requestBody['orientation'] = chartConfig.orientation;
        }
        const response = await ApiService.generateProcessModelView(
          requestBody,
          chartConfig.projectIds[0]
        );
        chartConfig.chartData = response.svg;
        chartConfig.legend = response.legend;
      } else if (chartConfig?.shape === bubbleChartShape) {
        const bubbleChartRequestBody = { ...chartConfig };
        const bubbleChartResponse = await BubbleChartService.getBubbleChartWidgetData(
          chartConfig?.projectIds[0],
          chartConfig?.dimensionId,
          bubbleChartRequestBody
        );
        const updatedBubblechartData = [];
        bubbleChartResponse?.dimensions?.map((chartData) => {
          updatedBubblechartData.push([chartData?.name, chartData?.y, chartData?.r]);
        });
        chartConfig.chartData = updatedBubblechartData;
      } else if (
        chartConfig.shape !== 'text' &&
        chartConfig.shape !== 'image' &&
        chartConfig.type !== 'designReport'
      ) {
        let response = {};
        if (chartConfig?.projectIds.length > 0) {
          response = await ApiService.getChartData(
            requestBody,
            routeParams.workgroupId,
            chartConfig.projectIds[0]
          );

          if (chartConfig.shape !== 'numeric') {
            chartConfig.chartData = response.widgetData.chartData;
            chartConfig.unit = response.widget.unit;
            chartConfig.numberFormat = response.widget.numberFormat;
          } else {
            chartConfig.chartData = [response.widgetData.data];
          }
        }
      } else if (chartConfig.type === 'designReport') {
        const reportRequestBody = {
          reportType: chartConfig.dataToDisplay,
          designReportDto: {
            approvedOnly: true,
            filters: [],
            properties: []
          },
          workgroupId: routeParams.workgroupId,
          repositoryName: chartConfig?.repositoryName ? chartConfig.repositoryName : '',
          secondThreshold: chartConfig?.secondThreshold ? chartConfig.secondThreshold : 604800
        };
        if (chartConfig?.dataToDisplay === 'LICENSE_UTILIZATION') {
          const licenseCountRequest = { ...reportRequestBody, reportType: 'LICENSE_COUNT' };
          const licenseEffectiveRequest = { ...reportRequestBody, reportType: 'LICENSE_EFFECTIVE' };
          Promise.all([
            ReportService.generateReport(licenseCountRequest),
            ReportService.generateReport(licenseEffectiveRequest)
          ]).then((combinedReportData) => {
            const licenseUtilizations = licenseUtilizationReportDataFormatting(combinedReportData);
            chartConfig.chartData = licenseUtilizations;
          });
        } else if (reportRequestBody?.reportType) {
          const response = await ReportService.generateReport(reportRequestBody);
          chartConfig.chartData = getReportData(chartConfig?.dataToDisplay, response);
          chartConfig['tableViewData'] = getReportTableViewData(
            chartConfig?.dataToDisplay,
            response
          );
        }
      }
      updateDashboardData('charts', chartConfig);
    } catch (error) {
      ToasterError(t('WIDGET.FETCH_WIDGET_DATA_FAILED'));
    } finally {
      setLoading(false);
    }
  };

  const validateData = (data) => {
    if (data.charts && data.charts.length > 0) {
      for (const chart of data.charts) {
        if (chart.shape !== 'svg' && chart.shape !== 'image' && chart.type !== 'designReport') {
          if (!chart.dataToDisplay || !chart.type) {
            return false;
          }
        } else if (chart.shape === 'svg') {
          if (!chart.metric || !chart.statistic) {
            return false;
          }
        } else if (chart.shape === 'image') {
          if (chart.imageData === '') {
            return false;
          }
        } else if (chart.type === 'designReport') {
          if (chart.chartData?.length <= 0) {
            return false;
          }
        }
      }
    } else {
      return true;
    }
    return true;
  };

  const submitDashboardData = async (editMode, selectedDashboard) => {
    dispatch(setIsSaveScreenShot(true));
    try {
      const newDashboardData =
        selectedDashboard && Object.keys(selectedDashboard).length !== 0
          ? selectedDashboard
          : dashboardData;
      setLoading(true);
      if (validateData(newDashboardData)) {
        updateApp(appData);
        let response = {};
        if (newDashboardData.id) {
          response = await ApiService.updateDashboard(newDashboardData);
        } else {
          response = await ApiService.createDashboard(newDashboardData, routeParams.appId);
        }
        fetchDashboardData(
          newDashboardData.id !== dashboardData.id ? dashboardData.id : response.id
        );
        dispatch(setDashboardEditMode(editMode));
        return response;
      } else {
        setShowDataWarning(true);
        dispatch(setDashboardEditMode(true));
        return false;
      }
    } catch (error) {
      ToasterError(t('APP.FAILED_TO_SAVE_APP_DATA'));
      return false;
    } finally {
      setLoading(false);
      dispatch(setIsSaveScreenShot(false));
    }
  };

  const updateApp = async (requestBody) => {
    try {
      setLoading(true);
      const response = await ApiService.updateApplicationDetails(requestBody, routeParams.appId);
      setAppData(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('APP.FAILED_TO_UPDATE_APP_DATA', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    }
  };

  const updateDashboardData = (key, value) => {
    if (key === 'charts') {
      const matchedIndex = dashboardData[key].findIndex((obj) => obj.chartId === value.chartId);
      let updatedArray = [];

      if (matchedIndex !== -1) {
        updatedArray = dashboardData[key].map((item, index) => {
          if (matchedIndex === index) {
            dashboardData[key][matchedIndex] = value;
            return dashboardData[key][matchedIndex];
          } else {
            return item;
          }
        });
      } else {
        updatedArray = dashboardData[key].concat(value);
      }

      setDashboardData((prevDashboardData) => ({
        ...prevDashboardData,
        [key]: updatedArray
      }));
    } else {
      setDashboardData((prevDashboardData) => ({
        ...prevDashboardData,
        [key]: value
      }));
    }
  };

  const updateDashboardLayout = (key, value) => {
    //If single object then concat else update array as is
    if (!Array.isArray(value)) {
      value = dashboardData.layout[key] ? dashboardData.layout[key].concat(value) : [value];
    }
    setDashboardData((prevDashboardData) => ({
      ...prevDashboardData,
      layout: { ...prevDashboardData.layout, [key]: value }
    }));
  };

  const deleteDashboardChartData = async (Id) => {
    const dashBoardTempData = dashboardData;
    const filteredCharts = dashboardData.charts.filter((chart) => chart.chartId !== Id);
    dashBoardTempData.charts = filteredCharts;
    const filteredLayout = dashboardData.layout.layouts.filter((layout) => layout.chartId !== Id);
    dashBoardTempData.layout.layouts = filteredLayout;
    setDashboardData({ ...dashBoardTempData });
  };

  const refreshDashboard = async (currentDashboardId) => {
    await fetchDashboardData(currentDashboardId ? currentDashboardId : dashboardData.id, true);
  };

  const echartsRef = useRef(null);

  const generateFile = (format) => {
    const input = echartsRef.current;

    html2canvas(input, {
      scrollY: -window.scrollY,
      useCORS: true,
      allowTaint: true,
      backgroundColor: '#ffffff',
      logging: true,
      height: input.scrollHeight
    }).then((canvas) => {
      const imgData = canvas.toDataURL(`image/${format}`);
      const appName = appData.name;
      const dashboardName = dashboardData.name;
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const day = currentDate.getDate().toString().padStart(2, '0');
      const hours = currentDate.getHours().toString().padStart(2, '0');
      const minutes = currentDate.getMinutes().toString().padStart(2, '0');
      const seconds = currentDate.getSeconds().toString().padStart(2, '0');

      const currentDateTime = `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
      const extension = format === 'png' ? 'png' : 'pdf';

      const fileName = `${appName}-${dashboardName}-${currentDateTime}.${extension}`;

      if (format === 'pdf') {
        const pdf = new jsPDF();
        const width = pdf.internal.pageSize.getWidth();
        const height = (canvas.height * width) / canvas.width;
        pdf.addImage(imgData, 'PNG', 0, 0, width, height);
        pdf.save(fileName);
      } else {
        const link = document.createElement('a');
        link.href = imgData;
        link.download = fileName;
        link.click();
      }
    });
  };

  const generatePdf = () => {
    generateFile('pdf');
  };

  const generatePng = () => {
    generateFile('png');
  };

  const deleteDashboard = () => {
    setShowDeleteWarning(true);
  };

  const toggleDashboardFavouriteStatus = async () => {
    const selectedDashboard =
      Object.keys(selectedDashboardData).length === 0 ? dashboardData : selectedDashboardData;
    try {
      setLoading(true);
      const response = await ApiService.toggleDashboardFavouriteStatus(
        selectedDashboard.id,
        !selectedDashboard.isFavourite
      );
      await fetchDashboardData(
        selectedDashboard.id !== dashboardData.id ? dashboardData.id : response.id
      );
      setLoading(false);
      dispatch(setDashboardEditMode(true));
      ToasterSuccess(
        t(
          selectedDashboard.isFavourite
            ? 'DASHBOARD.DASHBOARD_REMOVE_FAVOURITE_API_SUCCESS'
            : 'DASHBOARD.DASHBOARD_FAVOURITE_API_SUCCESS',
          {
            dashboardName: selectedDashboard.name
          }
        )
      );
      dispatch(resetDashboardState());
    } catch (error) {
      setLoading(false);
      ToasterError(
        t(
          selectedDashboard.isFavourite
            ? 'DASHBOARD.DASHBOARD_REMOVE_FAVOURITE_API_FAILED'
            : 'DASHBOARD.DASHBOARD_FAVOURITE_API_FAILED',
          {
            dashboardName: selectedDashboard.name
          }
        )
      );
      dispatch(resetDashboardState());
    }
  };

  const confirmDelete = async () => {
    setShowDeleteWarning(false);
    try {
      setLoading(true);
      await ApiService.deleteDashboard(
        Object.keys(selectedDashboardData).length === 0
          ? dashboardData.id
          : selectedDashboardData.id
      );
      fetchDashboardData();
      setLoading(false);
      toast.success(t('DASHBOARD.DASHBOARD_DELETED'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
      dispatch(resetDashboardState());
    } catch (error) {
      setLoading(false);
      toast.error(t('DASHBOARD.FAILED_TO_DELETE_DASHBOARD'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
      dispatch(resetDashboardState());
    }
  };

  const confirmationClose = () => {
    setShowDeleteWarning(false);
  };

  const copyDashboardURL = (callback) => {
    const url = window.location.href;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          callback();
          ToasterSuccess(t('DASHBOARD.COPIED_LINK_TO_CLIPBOARD'));
        })
        .catch(() => {
          ToasterError(t('DASHBOARD.FAILED_TO_COPY_LINK_TO_CLIPBOARD'));
        });
    } else {
      ToasterError(t('DASHBOARD.FAILED_TO_COPY_LINK_TO_CLIPBOARD'));
    }
  };

  return (
    <>
      <ThemeProvider theme={Theme}>
        <Header />
        <div
          data-testid='dashboard-container'
          className={loading === true ? 'mainSection-blur' : ''}
          style={{ width: '100%' }}
        >
          <div style={{ width: '100%' }}>
            <DashboardHeader
              dashboards={allDashboards}
              updateDashboardData={updateDashboardData}
              updateDashboardName={updateDashboardName}
              onSaveDashboard={handleSaveDashboard}
              onDiscard={handleDiscard}
              appData={appData}
              generatePdf={generatePdf}
              addNewDashboard={addNewDashboard}
              createDuplicateDashboard={createDuplicateDashboard}
              renderDashboard={renderDashboard}
              dashboardData={dashboardData}
              generatePng={generatePng}
              workgroupName={workgroupName}
              isValidDashboard={validateData}
              deleteDashboard={deleteDashboard}
              selectedDataSourceList={selectedDataSourceList}
              toggleDashboardFavouriteStatus={toggleDashboardFavouriteStatus}
              getChartDataByConfig={getChartDataByConfig}
              onCopyDashboardURL={copyDashboardURL}
            />
          </div>
          <div style={{ width: '100%' }} ref={echartsRef}>
            <DashboardContainer
              dashboards={allDashboards}
              dashboardData={dashboardData}
              selectedThemeData={appData?.theme}
              updateDashboardData={updateDashboardData}
              updateDashboardLayout={updateDashboardLayout}
              isLoading={isDataLoading}
              updateAppTheme={updateAppTheme}
              updateNewSelectedDashboard={updateNewSelectedDashboard}
              appId={routeParams.appId}
              projectId={appData && appData.connection ? appData.connection.projectId : ''}
              selectedProjectIds={appData?.connection?.projectIds}
              deleteChart={deleteDashboardChartData}
              refreshDashboard={refreshDashboard}
              selectedDataSourceList={selectedDataSourceList}
            />
          </div>
        </div>
        {showDeleteWarning && (
          <ConfirmationDialog
            onClose={confirmationClose}
            onConfirm={confirmDelete}
            title={'DASHBOARD.DELETE_DASHBOARD'}
            confirmationMsg={'DASHBOARD.DELETE_DASHBOARD_CONFIRMATION'}
            note={'DASHBOARD.DELETE_DASHBOARD_NOTE'}
          />
        )}

        {showDataWarning && <DashboardDataWarning onClose={handleConfirmationClose} />}
        {loading === true && <LoadingSpinner></LoadingSpinner>}
      </ThemeProvider>
    </>
  );
};

export default Dashboard;
