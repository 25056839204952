export const getFilterName = (filter, variantList = [], taskList = [], links = []) => {
  let filterName = '';
  if (filter.filterType === 'variants') {
    filterName = getVariantsFilterName(filter, variantList);
  } else if (filter.filterType === 'task') {
    filterName = getTaskFilterName(filter, taskList);
  } else if (filter.filterType === 'case') {
    filterName = getCaseFilterName(filter);
  } else if (filter.filterType === 'period') {
    filterName = getPeriodFilterName(filter);
  } else if (filter.filterType === 'duration') {
    filterName = getDurationFilterName(filter);
  } else if (filter.filterType === 'link') {
    filterName = getLinkFilterName(filter, links);
  } else if (filter.filterType === 'occurrencesInCase') {
    filterName = getOccuranceFilterName(filter);
  } else if (filter.filterType === 'rework') {
    filterName = getReworkFilterName(filter);
  } else if (filter.filterType === 'completedCase') {
    filterName = getFinishedCasesFilterName(filter);
  }

  return filterName;
};


export const getVariantsFilterName = (filter, variantList) => {
  const filterName = [];
  const variantName = [];
  filter.variantIds.forEach((variantId) => {
    if (variantList && variantList.length > 0) {
      const ind = variantList.findIndex((variant) => variant.variantId === variantId);
      variantName.push('Variant ' + variantList[ind].variantName);
    }
  });
  filterName.push(`Variant ${filter.isExcluded ? 'Excluded' : 'included'} ${variantName.join(', ')}`);
  return filterName;
};

export const getTaskFilterName = (filter, taskList) => {
  const filterName = [];
  const tasksName = [];
  filter.taskIds.forEach((taskId) => {
    if (taskList && taskList.length > 0) {
      const ind = taskList.findIndex((task) => task.id === taskId);
      tasksName.push(`${taskList[ind].name}`);
    }
  });
  filterName.push(`Tasks ${filter.isExcluded ? 'Excluded' : 'included'} ${tasksName.join(', ')}`);
  return filterName;
};

export const getCaseFilterName = (filter) => {
  const filterName = [];
  filterName.push(`Case ${filter.isExcluded ? 'Excluded' : 'included'} ${filter.caseIds.join(', ')}`);
  return filterName;
};

export const getPeriodFilterName = (filter) => {
  const filterName = [];
  filterName.push(`Period ${filter.isExcluded ? 'Excluded' : 'included'} ${formatDate(filter.startPeriod) + '-' + formatDate(filter.endPeriod)}`);
  return filterName;
};

export const getDurationFilterName = (filter) => {
  const filterName = [];
  filterName.push(`Duration ${filter.isExcluded ? 'Excluded' : 'included'} ${filter.scope} ${filter.operator} ${filter.value} ${filter.scale}`);
  return filterName;
};

export const getLinkFilterName = (filter, links) => {
  const filterName = [];
  const linksWithName = [];
  filter.linkIds.forEach((linkId) => {
    if (links && links.length > 0) {
      const link = links.find(linkObj => linkObj.id === linkId);
      linksWithName.push(`${link.fromTask.name} → ${link.toTask.name}`);
    }
  });
  filterName.push(`Link ${filter.isExcluded ? 'Excluded' : 'included'} ${linksWithName.join(', ')}`);
  return filterName;
};

export const getOccuranceFilterName = (filter) => {
  const filterName = [];
  filterName.push(`Occurrences in ${filter.scope} ${filter.operator} ${filter.value}`);
  return filterName;
};

export const getReworkFilterName = (filter) => {
  const filterName = [];
  filterName.push(`Rework ${filter.scope} ${filter.operator} ${filter.value}`);
  return filterName;
};

export const getFinishedCasesFilterName = (filter) => {
  const filterName = [];
  filterName.push(`Finished cases - include ${filter.isCaseCompleted ? 'completed cases' : 'cases in progress'}`);
  return filterName;
};

const formatDate = (dateString) => {
  const dateStr = new Date(dateString.split('z')[0]);
  const date = dateStr.toDateString();
  const time = dateStr.toLocaleTimeString('en-US');
  return `${date} ${time}`;
};