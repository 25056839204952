import colors from '../../../src/styles/constants.scss';

export const darkThemeModeBackgroundColors = {
  'header': colors.black,
  'workgroup-container': colors.darkNavy,
  'workgroup-header': colors.midnightBlue,
  'workgroup-header-border': 'unset',
  'workgroup-cards': colors.midnightBlue,
  'workgroup-pagination': colors.darkNavy,
  'apps-pagination': colors.darkNavy,
  'spinner': colors.darkNavy,
  'apps-header-section': colors.midnightBlue,
  'apps-card': colors.midnightBlue,
  'app-list-view': colors.black,
  'app-grid-view': colors.black,
  'dialog-title': colors.darkNavy,
  'dialog-content': colors.darkNavy,
  'dialog-action': colors.darkNavy,
  'left-section-container': colors.black,
  'widget-grid-layout': colors.black,
  'widget-card': colors.darkGrayishBlue,
  'widget-config-header': colors.midnightBlue,
  'widget-config-header-border': colors.darkGrayishBlue,
  'widget-config-preview': colors.black,
  'widget-config-fields': colors.darkCharcoal,
  'widget-config-fields-border': colors.darkGrayishBlue,
  'dashboard-header-container': colors.midnightBlue,
  'dashboard-header-container-border': colors.darkGrayishBlue,
  'empty-screen-image-container': colors.blackishBlue,
  'middle-section-actions': colors.midnightBlue,
  'middle-section-menu': colors.midnightBlue,
  'right-dashboard-config-section': colors.darkCharcoal,
  'right-dashboard-config-section-border': colors.darkGrayishBlue,
  'right-section-actions-container': colors.darkCharcoal,
  'right-dashboard-sectionr': colors.darkCharcoal,
  'add-charts-button': colors.coolGray,
  'process-model-view-widget-add-button': colors.coolGray,
  'custom-text-add-button': colors.coolGray,
  'image-chart-add-button': colors.coolGray,
  'right-dashboard-section': colors.darkCharcoal,
  'add-new-app': colors.darkNavy,
  'chart-label': colors.darkCharcoal,
  'app-description': colors.darkCharcoal,
  'select-data-source-label': colors.darkCharcoal,
  'data-source-chip': colors.darkGrayishBlue,
  'chip': colors.yellowishOrange,
  'delete-confirmation': colors.darkNavy,
  'chart-label-border' : colors.white,
  'chart-label-border-onhover' : colors.white,
  'delete-confirmation-title': colors.darkNavy,
  'delete-confirmation-content': colors.darkNavy,
  'delete-note' : colors.lightPink,
  'process-model-full-screen': colors.darkNavy,
  'process-model-preview-panel': colors.darkNavy,
  'process-model-preview-header': colors.darkCharcoal,
  'process-model-config-panel': colors.white,
  'global-filter-container': colors.darkCharcoal,
  'add-filter': colors.darkCharcoal,
  'add-filter-border': colors.lightGrayishBlue,
  'add-filter-container': colors.darkCharcoal,
  'add-global-filter-container': colors.midnightBlue,
  'add-global-filter-container-selected': colors.midnightBlue,
  'add-global-filter-container-border': colors.softGray,
  'add-global-filter-container-selected-border': colors.softGray,
  'global-filter-container-border': colors.blakishCharcoal,
  'theme-card': colors.darkGrayishBlue,
  'existing-chart-card': colors.darkGrayishBlue,
  'existing-chart-card-border': colors.coolGray,
  'add-new-dashboard-button': 'transparent',
  'add-new-dashboard-button-border': colors.lightGrayishBlue,
  'dashboard-card': colors.darkGrayishBlue,
  'dashboard-card-border': colors.darkGrayishBlue,
  'text-widget-container': colors.darkNavy,
  'custom-text-chart-label': colors.midnightBlue,
  'text-editor': colors.midnightBlue,
  'image-container-grid': colors.blackishBlue,
  'image-widegt-setting-panel': colors.darkCharcoal,
  'image-widegt-setting-panel-border': colors.blakishCharcoal,
  'file-upload-container': colors.darkGrayishBlue,
  'file-upload-container-border': colors.coolGray,
  'image-container': colors.midnightBlue,
  'display-image-container': colors.midnightBlue,
  'image-name-container': colors.midnightBlue,
  'image-info-container': colors.midnightBlue,
  'image-info-container-border': colors.darkGrayishBlue,
  'profile-page-container': colors.darkNavy,
  'profile-page-header': colors.coolGrayishBlue,
  'profile-page-header-border': colors.midnightBlue,
  'profile-content-container': colors.midnightBlue,
  'user-first-name-field': colors.darkCharcoal,
  'user-first-name-field-border': colors.darkGrayishBlue,
  'user-last-name-field': colors.darkCharcoal,
  'user-last-name-field-border': colors.darkGrayishBlue,
  'user-email-field': colors.darkCharcoal,
  'user-email-field-border': colors.darkGrayishBlue,
  'input-field-background': colors.darkCharcoal,
  'duplicate-widget': colors.darkNavy,
  'duplicate-widget-title': colors.darkNavy,
  'duplicate-widget-dialog-content-container': colors.darkNavy,
  'duplicate-widget-dialog-actions-container': colors.darkNavy,
  'data-warning': colors.darkNavy,
  'data-warning-dialog-title': colors.darkNavy,
  'data-warning-dialog-content-container': colors.darkNavy,
  'data-warning-dialog-action-container': colors.darkNavy,
  'create-theme-dialog-actions': colors.darkNavy,
  'create-theme-dialog-title': colors.darkNavy,
  'create-theme-dialog': colors.darkNavy,
  'create-theme-dialog-content': colors.darkNavy,
  'variants-selection-panel-container': colors.darkCharcoal,
  'variants-selection-panel-header': colors.darkCharcoal,
  'process-model-settings-panel-dialog-container': colors.darkNavy,
  'process-model-settings-panel-container': colors.darkNavy,
  'add-dashboard': colors.darkNavy,
  'add-edit-dashboard-dialog-title': colors.darkNavy,
  'add-edit-dashboard-dialog-content': colors.darkNavy,
  'add-edit-dashboard-dialog-body': colors.darkNavy,
  'add-edit-dashboard-dialog-inner-container': colors.darkNavy,
  'add-edit-dashboard-dialog-action-container': colors.darkNavy,
  'delete-dashboard': colors.darkNavy,
  'customized-dialog-title': colors.darkNavy,
  'delete-dashboard-content-container': colors.darkNavy,
  'delete-dashboard-action-container': colors.darkNavy,
  'widget-actions-menu': colors.darkNavy,
  'pagination-component': colors.darkNavy,
  'view-as-table-dialog-title-container': colors.darkNavy,
  'view-as-table-dialog-content-container': colors.darkNavy,
  'view-as-table-container': colors.midnightBlue,
  'view-as-table-header-container': colors.darkGrayishBlue,
  'filter-modal': colors.darkNavy,
  'filter-modal-title': colors.darkNavy,
  'filter-modal-content': colors.darkNavy,
  'filter-modal-actions-container': colors.darkNavy,
  'select-filter-type': colors.darkCharcoal,
  'select-filter-type-border': colors.darkGrayishBlue,
  'select-filter-type-color': colors.lightGrayishBlue,
  'exclude-switch-border': colors.lightGrayishBlue,
  'filters-container': colors.darkNavy,
  'filters-container-tabs' : colors.darkGrayishBlue,
  'filters-container-tabs-border' : colors.grayishSilver,
  'filters-container-tab': colors.coolGray,
  'filters-container-tab-color': colors.lightGrayishBlue,
  'filters-main-container': colors.darkCharcoal,
  'filters-main-container-border': colors.darkGrayishBlue,
};

export const lightThemeModeBackgroundColors = {
  'header': colors.white,
  'workgroup-container': colors.lightLavender,
  'workgroup-header': colors.lightLavender,
  'workgroup-header-border': colors.lightLavender,
  'workgroup-cards': colors.white,
  'workgroup-pagination': colors.lightLavender,
  'apps-pagination': colors.lightLavender,
  'apps-header-section': colors.grayishBlue,
  'apps-card': colors.white,
  'app-grid-view': colors.grayishBlue,
  'app-list-view': colors.grayishBlue,
  'dialog-title': colors.white,
  'dialog-content': colors.white,
  'dialog-action': colors.white,
  'left-section-container': colors.grayishBlue,
  'widget-grid-layout': colors.grayishBlue,
  'widget-card': colors.white,
  'widget-config-header': colors.white,
  'widget-config-header-border': colors.ligthSilver,
  'widget-config-preview': colors.grayishBlue,
  'widget-config-fields': colors.white,
  'widget-config-fields-boder': colors.white,
  'dashboard-header-container': colors.white,
  'dashboard-header-container-border': colors.mildWhite,
  'empty-screen-image-container': colors.grayishBlue,
  'middle-section-actions': colors.white,
  'middle-section-menu': colors.white,
  'right-dashboard-config-section': colors.white,
  'right-dashboard-config-section-border': colors.mildWhite,
  'right-section-actions-container': colors.white,
  'add-charts-button': colors.lightLavender,
  'process-model-view-widget-add-button': colors.lightLavender,
  'custom-text-add-button': colors.lightLavender,
  'image-chart-add-button': colors.lightLavender,
  'right-dashboard-section': colors.white,
  'add-new-app': colors.white,
  'chart-label': colors.grayishBlue,
  'app-description': colors.grayishBlue,
  'select-data-source-label': colors.grayishBlue,
  'data-source-chip': colors.softGray,
  'chip': colors.yellowishOrange,
  'delete-confirmation': colors.white,
  'delete-confirmation-title': colors.white,
  'delete-confirmation-content': colors.white,
  'delete-note' : colors.lightPink,
  'process-model-full-screen': colors.white,
  'process-model-preview-panel': colors.white,
  'process-model-config-panel': colors.white,
  'global-filter-container': colors.white,
  'add-filter': colors.lightLavender,
  'add-filter-border': colors.lightLavender,
  'add-filter-container': colors.grayishBlue,
  'add-global-filter-container': colors.canvasColor,
  'add-global-filter-container-selected': colors.canvasColor,
  'add-global-filter-container-border': colors.softGray,
  'add-global-filter-container-selected-border': colors.igrafxBlue,
  'global-filter-container-border': colors.softGray,
  'theme-card': colors.white,
  'existing-chart-card': colors.white,
  'existing-chart-card-border': colors.lavenderMist,
  'add-new-dashboard-button': colors.lightLavender,
  'add-new-dashboard-button-border': 'unset',
  'dashboard-card': colors.white,
  'dashboard-card-border': colors.softGray,
  'text-widget-container': colors.white,
  'custom-text-chart-label': colors.white,
  'text-editor': colors.white,
  'input-field-background': colors.white,
  'image-container-grid': colors.white,
  'image-widegt-setting-panel': colors.white,
  'image-widegt-setting-panel-border': colors.ligthSilver,
  'file-upload-container': colors.lightPearl,
  'file-upload-container-border': colors.paleGray,
  'image-container': colors.white,
  'display-image-container': colors.white,
  'image-name-container': colors.white,
  'image-info-container': colors.white,
  'image-info-container-border': colors.slateGray,
  'profile-page-container': colors.grayishBlue,
  'profile-page-header': colors.grayishBlue,
  'profile-page-header-border': colors.softGray,
  'profile-content-container': colors.white,
  'user-first-name-label-border': colors.darkGrayishBlue,
  'user-first-name-field': colors.grayishBlue,
  'user-first-name-field-border': colors.softGray,
  'user-last-name-field': colors.grayishBlue,
  'user-last-name-field-border': colors.softGray,
  'user-email-field': colors.grayishBlue,
  'user-email-field-border': colors.softGray,
  'duplicate-widget': colors.white,
  'duplicate-widget-title': colors.white,
  'duplicate-widget-dialog-content-container': colors.white,
  'duplicate-widget-dialog-actions-container': colors.white,
  'data-warning': colors.white,
  'data-warning-dialog-title': colors.white,
  'data-warning-dialog-content-container': colors.white,
  'data-warning-dialog-action-container': colors.white,
  'create-theme-dialog-actions': colors.white,
  'create-theme-dialog-title': colors.white,
  'create-theme-dialog': colors.white,
  'create-theme-dialog-content': colors.white,
  'variants-selection-panel-container': colors.white,
  'variants-selection-panel-header': colors.white,
  'process-model-settings-panel-dialog-container': colors.white,
  'process-model-settings-panel-container': colors.white,
  'add-dashboard': colors.white,
  'add-edit-dashboard-dialog-title': colors.white,
  'add-edit-dashboard-dialog-content': colors.white,
  'add-edit-dashboard-dialog-body': colors.white,
  'add-edit-dashboard-dialog-inner-container': colors.white,
  'add-edit-dashboard-dialog-action-container': colors.white,
  'delete-dashboard': colors.white,
  'customized-dialog-title': colors.white,
  'delete-dashboard-content-container': colors.white,
  'delete-dashboard-action-container': colors.white,
  'widget-actions-menu': colors.white,
  'pagination-component': colors.white,
  'view-as-table-dialog-title-container': colors.white,
  'view-as-table-dialog-content-container': colors.white,
  'view-as-table-container': colors.white,
  'view-as-table-header-container': colors.mildGray,
  'filter-modal': colors.white,
  'filter-modal-title': colors.white,
  'filter-modal-content': colors.white,
  'filter-modal-actions-container': colors.white,
  'select-filter-type': colors.white,
  'select-filter-type-border': colors.softGray,
  'select-filter-type-color': colors.black,
  'exclude-switch-border': colors.black,
  'filters-container': colors.white,
  'filters-container-tabs' : colors.white,
  'filters-container-tabs-border' : colors.softGray,
  'filters-container-tab': colors.white,
  'filters-container-tab-color': colors.coolGray,
  'filters-main-container': colors.grayishBlue,
  'filters-main-container-border': colors.softGray,
};
