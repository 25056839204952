import { createSlice } from '@reduxjs/toolkit';

const variantSelectionState = {
  sliderCombination: {
    firstVariantIndex: 0,
    lastVariantIndex: 0
  },

  totalSelectedVariants: 0,
};

const variantSelectionSlice = createSlice({
  name: 'variantSelection',
  initialState: variantSelectionState,
  reducers: {
    setSelectedVariantIndex: (state, action) => {
      state.sliderCombination.firstVariantIndex = action.payload.firstSelectedVariantIndex;
      state.sliderCombination.lastVariantIndex = action.payload.lastSelectedVariantIndex;
      if (action.payload.firstSelectedVariantIndex === 0 && action.payload.lastSelectedVariantIndex === 0) {
        state.totalSelectedVariants = 0;
      } else {
        state.totalSelectedVariants = (action.payload.lastSelectedVariantIndex - action.payload.firstSelectedVariantIndex) + 1;
      }
    },
  }
});

export const { setSelectedVariantIndex } = variantSelectionSlice.actions;
export default variantSelectionSlice.reducer;