import React from 'react';
import PropTypes from 'prop-types';
import WidgetComponents from '../../../screens/Screen_Sections/WidgetComponents';
import ThemeSection from '../../../screens/Screen_Sections/ThemeSection';
import Navigation from '../../../screens/Screen_Sections/Navigation';
import DashboardSection from '../../../screens/Screen_Sections/DashboardSection';
import { useTranslation } from 'react-i18next';

const WidgetConfigurationMode = (props) => {
  const { t } = useTranslation();
  const {
    dashboards,
    dashboardData,
    appId,
    selectedThemeData,
    configurationMode,
    handleAddChart,
    getSelectedTheme,
    addProcessModelView,
    getSelectedDashboard,
    addImage,
    addReportWidget
  } = props;

  switch (configurationMode) {
    case t('DASHBOARDS'):
      return (
        <DashboardSection
          dashboards={dashboards ? dashboards : []}
          selectedDashboardObject={dashboardData ? dashboardData : null}
          onChangeDashboard={getSelectedDashboard}
          data-testid='pages-section'
        />
      );
    case t('WIDGETCOMPONENTS'):
      return (
        <WidgetComponents
          appId={appId ? appId : null}
          data-testid='components-section'
          handleAddChart={handleAddChart}
          addProcessModelView={addProcessModelView}
          addReportWidget={addReportWidget}
          addImage={addImage}
        />
      );
    case t('NAVIGATION'):
      return <Navigation data-testid='navigation-section' />;
    case t('THEMESECTION'):
      return (
        <ThemeSection
          appId={appId ? appId : null}
          selectedThemeId={selectedThemeData ? selectedThemeData.id : null}
          onChangeTheme={getSelectedTheme}
          data-testid='theme-section'
        />
      );
    default:
      return null;
  }
};
WidgetConfigurationMode.propTypes = {
  dashboards: PropTypes.array,
  dashboardData: PropTypes.object,
  appId: PropTypes.string,
  selectedThemeData: PropTypes.object,
  handleAddChart: PropTypes.func,
  getSelectedTheme: PropTypes.func,
  configurationMode: PropTypes.string,
  addProcessModelView: PropTypes.func,
  getSelectedDashboard: PropTypes.func,
  addImage: PropTypes.func,
  addReportWidget: PropTypes.func
};

export default WidgetConfigurationMode;
