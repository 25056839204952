import { createSlice } from '@reduxjs/toolkit';
import { themeModes } from '../../utils/Constants';

const initialThemeModeState = {
  themeMode: themeModes?.LIGHT_THEME,

};

const setThemeModeSlice = createSlice({
  name: 'setThemeMode',
  initialState: initialThemeModeState,
  reducers: {
    setThemeModeValue: (state, action) => {
      state.themeMode = action.payload;
    },
  }
});

export const {
  setThemeModeValue,
} = setThemeModeSlice.actions;

export default setThemeModeSlice.reducer;
