const StackBarChartConfiguration = {
  getStackBatChartOptions: (data, themeColors) => {
    return {
      grid: {
        left: 100,
        right: 100,
        top: 50,
        bottom: 80
      },
      xAxis: {
        type: 'category',
        show: true,
        data: data?.licenseList?.map((license, ind) => `${license} (${data.totalData[ind]})`),
      },
      yAxis: {
        type: 'value',
        show: false,
      },
      legend: {
        selectedMode: true,
      },
      series: [
        'Used',
        'Remaining',
      ].map((name, sid) => {
        return {
          name,
          type: 'bar',
          stack: 'total',
          barWidth: '30%',
          label: {
            show: true,
            rotate: 90,
            verticalAlign: 'middle',
            formatter: (params) => {
              return parseInt(params.value * data.totalData[params.dataIndex]);
            }
          },
          data: data.usedRemainingData[sid]?.map((d, did) => {
            return {
              value: data.totalData[did] <= 0 ? 0 : d / data.totalData[did]
            };
          }),
        };
      })
    };
  }
};

export default StackBarChartConfiguration;
