import { createSlice } from '@reduxjs/toolkit';

const initialSelectedLanguage = {
  languageSelected: 'en'
};

const languageSelectedSlice = createSlice({
  name: 'languageSelected',
  initialState: initialSelectedLanguage,
  reducers: {
    setLanguageSelected: (state, action) => {
      state.languageSelected = action.payload;
    }
  }
});

export const { setLanguageSelected } = languageSelectedSlice.actions;
export default languageSelectedSlice.reducer;
