import React from 'react';
import loaderImage from '../../assets/thumbnails/loader.gif';
import '../../styles/LoadingSpinner.scss';
import ThemeMode from '../themeMode/themeMode';

const LoadingSpinner = () => {
  return (
    <div className='spinner-container' id='spinner'>
      <ThemeMode />
      <img src={loaderImage} className='spinner'></img>
    </div>
  );
};

export default LoadingSpinner;
