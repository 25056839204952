import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import '../../styles/DashboardLayout.scss';
import ThemeMode from '../themeMode/themeMode';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';

const DashboardDataWarning = (props) => {
  const { t } = useTranslation();

  return (
    <div id='data-warning'>
    <Dialog
      className='delete-confirmation'
      onClose={props.onClose}
      aria-labelledby='customized-dialog-title'
      open={true}
    >
      <ThemeMode></ThemeMode>
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id='data-warning-dialog-title'
        data-testid='data-warning-dialog-title'
      >
        <DashboardTypography typographyClass='title-18-700' id='dashboard-dialog-title'>
        {t('DATA_WARNING')}
         </DashboardTypography>  
        <IconButton
          aria-label='close'
          onClick={props.onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          id='close-icon'
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent id='data-warning-dialog-content-container'>
        <DialogContentText
          data-testid='data-warning-dialog-content'
        >
          <DashboardTypography  id='configure-chart-warning' typographyClass={'title-16-400'}> {t('CONFIGURE_CHART_WARNING')}</DashboardTypography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          paddingBottom:'15px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
        id='data-warning-dialog-action-container'
      >
        <Button
          variant='outlined'
          autoFocus
          onClick={props.onClose}
          sx={{
            textTransform: 'unset',
            color: '#3648C7',
            borderColor: '#3648C7',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 600,
            ':hover': {
              boxShadow: 'unset'
            }
          }}
          className='generic-btn'
          role='btn'
          data-testid='data-warning-close-btn'
        >
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
};

DashboardDataWarning.propTypes = {
  onClose: PropTypes.func
};

export default DashboardDataWarning;
