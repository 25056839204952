export const licenseUtilizationReportDataFormatting = (licenseUtilization) => {
  const usedLicense = [];
  const remainingLicense = [];

  const totalData = [];
  let licenseList = [];
  if (licenseUtilization.length > 0) {
    Object.keys(licenseUtilization[0].userExtensions).forEach(element => {
      licenseList = Object.keys(licenseUtilization[0]?.userExtensions);
      totalData.push(licenseUtilization[0]?.userExtensions[element]);

      const licenseTypes = Object.keys(licenseUtilization[1]?.effectiveLicenseCounts);
      const licenseType = licenseTypes.filter(type => {
        const matchedIndex = type.toLowerCase().indexOf(element.toLowerCase());
        if (matchedIndex > -1) {
          return type;
        }
      });
      if (licenseType.length > 0) {
        usedLicense.push(licenseUtilization[1]?.effectiveLicenseCounts[licenseType[0]]);
        remainingLicense.push(licenseUtilization[0]?.userExtensions[element] - licenseUtilization[1]?.effectiveLicenseCounts[licenseType[0]]);
      }
    });

    return {
      usedRemainingData: [usedLicense, remainingLicense],
      totalData,
      licenseList
    };
  }

  return null;
};