import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import '../../styles/AddNewApp.css';
import FilterTypes from './FilterTypes';
import TaskFilter from './TaskFilter';
import VariantFilter from './VariantFilter';
import PeriodFilter from './PeriodFilter';
import '../../styles/DashboardLayout.css';
import CaseFilter from './CaseFilter';
import LinkFilter from './LinkFilter';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DurationFilter from './DurationFilter';
import OccurrencesFilter from './OccurrencesFilter';
import ReworkFilter from './ReworkFilter';
import MetricFilter from './MetricFilter';
import DimensionFilter from './DimensionFilter';
import ConformanceFilter from './ConformanceFilter';
import BusinessRuleFilter from './BusinessRuleFilter';
import '../../styles/FiltersLayout.scss';
import { toast } from 'react-toastify';
import ApiService from '../../services/ApiService';
import LoadingSpinner from '../loader/LoadingSpinner';
import '../../styles/LoadingSpinner.scss';
import CompletedCasesFilter from './CompletedCasesFilter';
import ThemeMode from '../themeMode/themeMode';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';

const AddEditFilter = (props) => {
  const { t } = useTranslation();
  const object = { isExcluded: false };
  const [selectedFilter, setSelectedFilter] = useState(object);
  const [isValid, setIsValid] = useState(false);
  const [filterTypes, setFilterTypes] = useState();
  const [loading, setLoading] = useState(false);
  const [keyForRerender, setKeyForRerender] = useState(0);

  useEffect(() => {
    if (props.filterObj) {
      setSelectedFilter(props.filterObj);
    }
    if (!filterTypes) {
      fetchFilterTypes();
    }
  }, [props.filterObj]);

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
  };

  const handleFilterTypeChange = (value) => {
    setSelectedFilter(value);
    setKeyForRerender((prevKey) => prevKey + 1);
  };
  const handleSave = () => {
    props.handleSave(selectedFilter);
    props.handleClose();
  };
  const handleExcludChange = (val) => {
    setSelectedFilter((prevSelectedFilter) => ({
      ...prevSelectedFilter,
      isExcluded: val
    }));
  };
  const fetchFilterTypes = async () => {
    const requestBody = {
      search: null,
      filterPageNo: '1',
      filterPageSize: '100',
      filterSortOrder: 'ASC'
    };
    try {
      setLoading(true);
      const response = await ApiService.getFilterTypes(
        requestBody,
        props.workgroupId,
        props.projectId
      );
      setFilterTypes(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t('FILTER.FAILED_TO_FETCH_FILTER_TYPES_ERROR'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    }
  };
  return (
    <div id='filter-modal'>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby='add-widget-dialog-title'
        open={props.open}
        fullWidth='true'
        maxWidth='md'
        role='dialog'
        PaperProps={{
          sx: {
            height: 'auto',
            maxHeight: '500px',
            width: '600px'
          }
        }}
      >
        <ThemeMode></ThemeMode>
        <DialogTitle sx={{ paddingLeft: '0px 0px 20px 25px' }} id='filter-modal-title'>
          <div data-testid='new-filter'>
            <DashboardTypography id='filter-dialog-title' typographyClass={'title-18-700'}>
              {props.isAddEdit === true ? t('NEW_FILTER') : t('EDIT_FILTER')}
            </DashboardTypography>
          </div>
        </DialogTitle>
        <DialogContent id='filter-modal-content' sx={{ paddingLeft: '40px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} className='genericfilter-grid'>
              <FilterTypes
                handleFilterTypeChange={handleFilterTypeChange}
                projectId={props.projectId}
                selectedFilter={selectedFilter ? selectedFilter : props.filterObj}
                filterTypes={filterTypes}
              ></FilterTypes>
            </Grid>
            <Grid item xs={12} className='grid-section genericfilter-grid' sx={{ display: 'flex' }}>
              <Grid
                item
                xs={
                  selectedFilter &&
                  (selectedFilter.filterType === 'task' ||
                    selectedFilter.filterType === 'variants' ||
                    selectedFilter.filterType === 'link' ||
                    selectedFilter.filterType === 'case')
                    ? 10.2
                    : 12
                }
                className='filter-grid-section'
                sx={{ display: 'inline-flex' }}
                key={keyForRerender}
              >
                {selectedFilter && selectedFilter.filterType === 'task' ? (
                  <TaskFilter
                    workgroupId={props.workgroupId}
                    projectId={props.projectId}
                    handleFilterChange={handleFilterChange}
                    filterObj={selectedFilter}
                    setIsValid={setIsValid}
                  />
                ) : null}
                {selectedFilter && selectedFilter.filterType === 'variants' ? (
                  <VariantFilter
                    workgroupId={props.workgroupId}
                    projectId={props.projectId}
                    handleFilterChange={handleFilterChange}
                    filterObj={selectedFilter}
                    setIsValid={setIsValid}
                  />
                ) : null}
                {selectedFilter && selectedFilter.filterType === 'period' ? (
                  <PeriodFilter
                    workgroupId={props.workgroupId}
                    projectId={props.projectId}
                    filterObj={selectedFilter}
                    handleFilterChange={handleFilterChange}
                    setIsValid={setIsValid}
                  />
                ) : null}
                {selectedFilter && selectedFilter.filterType === 'case' ? (
                  <CaseFilter
                    workgroupId={props.workgroupId}
                    projectId={props.projectId}
                    handleFilterChange={handleFilterChange}
                    filterObj={selectedFilter}
                    setIsValid={setIsValid}
                  />
                ) : null}
                {selectedFilter && selectedFilter.filterType === 'link' ? (
                  <LinkFilter
                    workgroupId={props.workgroupId}
                    projectId={props.projectId}
                    handleFilterChange={handleFilterChange}
                    filterObj={selectedFilter}
                    setIsValid={setIsValid}
                  />
                ) : null}
                {selectedFilter && selectedFilter.filterType === 'duration' ? (
                  <DurationFilter
                    workgroupId={props.workgroupId}
                    projectId={props.projectId}
                    filterObj={selectedFilter}
                    handleFilterChange={handleFilterChange}
                    setIsValid={setIsValid}
                  />
                ) : null}
                {selectedFilter && selectedFilter.filterType === 'occurrencesInCase' ? (
                  <OccurrencesFilter
                    workgroupId={props.workgroupId}
                    projectId={props.projectId}
                    filterObj={selectedFilter}
                    handleFilterChange={handleFilterChange}
                    setIsValid={setIsValid}
                  />
                ) : null}
                {selectedFilter && selectedFilter.filterType === 'completedCase' ? (
                  <CompletedCasesFilter
                    workgroupId={props.workgroupId}
                    projectId={props.projectId}
                    filterObj={selectedFilter}
                    handleFilterChange={handleFilterChange}
                    setIsValid={setIsValid}
                  />
                ) : null}
                {selectedFilter && selectedFilter.filterType === 'rework' ? (
                  <ReworkFilter
                    workgroupId={props.workgroupId}
                    projectId={props.projectId}
                    filterObj={selectedFilter}
                    handleFilterChange={handleFilterChange}
                    setIsValid={setIsValid}
                  />
                ) : null}
                {selectedFilter && selectedFilter.filterType === 'metric' ? (
                  <MetricFilter
                    workgroupId={props.workgroupId}
                    projectId={props.projectId}
                    filterObj={selectedFilter}
                    handleFilterChange={handleFilterChange}
                    setIsValid={setIsValid}
                  />
                ) : null}
                {selectedFilter && selectedFilter.filterType === 'dimension' ? (
                  <DimensionFilter
                    workgroupId={props.workgroupId}
                    projectId={props.projectId}
                    filterObj={selectedFilter}
                    handleFilterChange={handleFilterChange}
                    setIsValid={setIsValid}
                  />
                ) : null}
                {selectedFilter && selectedFilter.filterType === 'businessRule' ? (
                  <BusinessRuleFilter
                    workgroupId={props.workgroupId}
                    projectId={props.projectId}
                    filterObj={selectedFilter}
                    handleFilterChange={handleFilterChange}
                    setIsValid={setIsValid}
                  />
                ) : null}
                {selectedFilter && selectedFilter.filterType === 'conformance' ? (
                  <ConformanceFilter
                    workgroupId={props.workgroupId}
                    projectId={props.projectId}
                    filterObj={selectedFilter}
                    handleFilterChange={handleFilterChange}
                    setIsValid={setIsValid}
                  />
                ) : null}
              </Grid>
              {selectedFilter &&
              (selectedFilter.filterType === 'task' ||
                selectedFilter.filterType === 'variants' ||
                selectedFilter.filterType === 'link' ||
                selectedFilter.filterType === 'case') ? (
                <Grid item xs={1.8} sx={{ display: 'inline-flex', textAlign: 'right' }}>
                  <FormGroup>
                    <FormControlLabel
                      labelPlacement='top'
                      control={
                        <Checkbox
                          checked={selectedFilter.isExcluded}
                          onChange={(e) => handleExcludChange(e.target.checked)}
                        />
                      }
                      label='Exclude'
                      data-testid='exclude-switch'
                    />
                  </FormGroup>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions id='filter-modal-actions-container' sx={{ padding: '0px 0px 20px 28px' }}>
          <Grid container item xs={12} className='grid-btn'>
            <Grid item xs={6} className='cancel-btn-section'>
              <Button
                data-testid='cancel-filter'
                onClick={props.handleClose}
                className='filterCancel-btn genericFilter-btn'
              >
                {t('CANCEL')}
              </Button>
            </Grid>
            <Grid item xs={6} className='save-btn-section'>
              <Button
                autoFocus
                onClick={handleSave}
                disabled={!isValid}
                data-testid='validate-filter-button'
                inputprops={{ 'data-testid': 'validate-filter-button' }}
                className={
                  isValid
                    ? 'validate-btn genericFilter-btn'
                    : 'disabledSaveValidate-btn genericFilter-btn'
                }
              >
                {t('VALIDATE')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {loading === true && <LoadingSpinner></LoadingSpinner>}
    </div>
  );
};
AddEditFilter.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  workgroupId: PropTypes.string,
  projectId: PropTypes.string,
  filterObj: PropTypes.object,
  isAddEdit: PropTypes.bool
};
export default AddEditFilter;
