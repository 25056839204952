import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import '../../../styles/WidgetComponents.scss';
import PropTypes from 'prop-types';
import ApiService from '../../../services/ApiService';
import { ToasterError } from '../../../utils/toaster';
import LoadingSpinner from '../../loader/LoadingSpinner';
import barPlaceholder from '../../../assets/thumbnails/barPlaceholder.svg';
import imagePlaceholder from '../../../assets/thumbnails/imagePlaceholder.svg';
import linePlaceholder from '../../../assets/thumbnails/linePlaceholder.svg';
import metricPlaceholder from '../../../assets/thumbnails/metricPlaceholder.svg';
import piePlaceholder from '../../../assets/thumbnails/piePlaceholder.svg';
import proceessPlaceholder from '../../../assets/thumbnails/proceessPlaceholder.svg';
import textPlaceholder from '../../../assets/thumbnails/textPlaceholder.svg';
import databasePlaceholder from '../../../assets/thumbnails/database.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedExistingWidget } from '../../../Redux/slices/existingWidgetSlice';
import ThemeMode from '../../themeMode/themeMode';
import DashboardButton from '../../shared/Buttons/DashboardButton';

const ExistingCharts = (props) => {
  const { t } = useTranslation();
  const [allExistingCharts, setAllExistingCharts] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const selectedDataSources = useSelector((state) => state.allDashboard.selectedDataSources);
  const dispatch = useDispatch();
  const isCurrentDashboardUpdated = useSelector(
    (state) => state.allDashboard.isCurrentDashboardUpdated
  );

  useEffect(() => {
    getExistingCharts();
  }, []);

  const getExistingCharts = async () => {
    try {
      setLoading(true);
      const response = await ApiService.getAllExistingWidgets(props.appId);
      setAllExistingCharts(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ToasterError(t('APP.FAILED_TO_FETCH_EXISTING_CHARTS'));
    }
  };

  const getProjectName = (projectId) => {
    const selectedProject = selectedDataSources.find((project) => project.projectId === projectId);
    return selectedProject ? selectedProject.name : 'Unknown';
  };

  const addExistingWidget = (selectedWidget) => {
    if (isCurrentDashboardUpdated) return;
    dispatch(
      setSelectedExistingWidget({
        dashboardId: selectedWidget.dashboardId,
        chartId: selectedWidget.chartId
      })
    );
  };

  return (
    <div className='widgetContainer'>

      {allExistingCharts && allExistingCharts?.length !== 0 ? (
        allExistingCharts.map((existingChart, index) => (
          <div key={index} className='existingChartsContainerBox'>
            <ThemeMode></ThemeMode>
            <div
              id='existing-chart-card'
              data-testid='existing-chart-card'
              className={`existing-chart-row existing-chart-row-not-selected`}
            >
              <div className='widget-image-placeholder-section'>
                {existingChart.shape === 'bar' && <img src={barPlaceholder} alt={barPlaceholder} />}
                {existingChart.shape === 'line' && (
                  <img src={linePlaceholder} alt='Line Placeholder' />
                )}
                {existingChart.shape === 'svg' && (
                  <img src={proceessPlaceholder} alt='Process Model View Placeholder' />
                )}
                {existingChart.shape === 'text' && (
                  <img src={textPlaceholder} alt='Text Placeholder' />
                )}
                {existingChart.shape === 'numeric' && (
                  <img src={metricPlaceholder} alt='Metric Placeholder' />
                )}
                {existingChart.shape === 'pie' && (
                  <img src={piePlaceholder} alt='Pie Placeholder' />
                )}
                {existingChart.shape === 'image' && (
                  <img src={imagePlaceholder} alt='Image Placeholder' />
                )}
              </div>
              <div className='right-section'>
                <div className='row'>
                  <Tooltip title={existingChart.title}>
                    <div className='widget-name-truncate'>{existingChart.title}</div>
                  </Tooltip>
                </div>
                <div className='row'>
                  <div data-testid='data-source-name' className='data-source-details'>
                    <span className='data-source-placeholder'>
                      <img src={databasePlaceholder} alt='Data Source Placeholder' />
                    </span>
                    <Tooltip title={getProjectName(existingChart.projectIds[0])}>
                      <div className='data-source-name-truncate'>
                        {getProjectName(existingChart.projectIds[0])}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className='row'>
                  <Tooltip
                    title={isCurrentDashboardUpdated ? t('WIDGET.ADD_WIDGET_BUTTON_DISABLED') : ''}
                    arrow
                  >
                    <DashboardButton size='small' color='secondary' dataTestid='add-existing-chart-btn' startIcon={<AddIcon
                      sx={{
                        color: '#041AB9',
                        cursor: 'pointer',
                      }}
                    />} eventHandler={() => addExistingWidget(existingChart)}>
                      {t('ADD')}
                    </DashboardButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div data-testid='empty-dashboard-label' className='no-dashboard-found'>
          {t('APP.NO_EXISTING_CHARTS')}
        </div>
      )}
      {loading === true && <LoadingSpinner data-testid='spinner'></LoadingSpinner>}
    </div>
  );
};

ExistingCharts.propTypes = {
  appId: PropTypes.string
};

export default ExistingCharts;
