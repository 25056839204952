import { MenuItem, Select, Tab, Tabs, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../styles/DashboardLayout.css';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useStyles } from '../../styles/periodFilterStyles';
import TaskFilter from './TaskFilter';
import LinkFilter from './LinkFilter';
import ApiService from '../../services/ApiService';
import { toast } from 'react-toastify';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import SelectAllOption from './SelectAllOption';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingSpinner from '../loader/LoadingSpinner';
import '../../styles/LoadingSpinner.scss';
import '../../styles/DimensionFilter.scss';
import { checkIsValid, getScope } from '../../utils/filter';
import ThemeMode from '../themeMode/themeMode';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';

const DimensionFilter = (props) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState();
  const classes = useStyles();
  const [dimensions, setDimensions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTask, setSelectedTask] = useState({
    taskIds: [],
    filterType: 'task',
    name: []
  });

  const [selectedLinks, setSelectedLinks] = useState({
    linkIds: [],
    filterType: 'link',
    name: []
  });

  const [dimensionFilter, setDimensionFilter] = useState({
    filterType: 'dimension',
    dimensionName: props.filterObj.customFieldName,
    dimensionId: props.filterObj.customFieldId
      ? props.filterObj.customFieldId
      : props.filterObj.dimensionId,
    customFieldName: props.filterObj.customFieldName,
    dimensionValues: [],
    scope: 'CASE',
    isExcluded: false
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };
  useEffect(() => {
    fetchDimensions();
  }, []);

  const fetchDimensions = async () => {
    try {
      setLoading(true);
      const response = await ApiService.getDimensions(
        props.projectId,
        props.filterObj.customFieldId ? props.filterObj.customFieldId : props.filterObj.dimensionId
      );
      if (response && response.values) {
        setDimensions(response.values);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t('FILTER.FAILED_TO_FETCH_DIMENSIONS_ERROR'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    }
  };
  useEffect(() => {
    setSelectedTab(1);
    if (props?.filterObj && props?.filterObj?.scope) {
      setDimensionFilter(props.filterObj);
      if (props?.filterObj?.scope === 'TASKS') {
        setSelectedTab(0);
        setSelectedTask((prevSelectedTask) => ({
          ...prevSelectedTask,
          taskIds: props.filterObj.taskIds
        }));
      }
      if (props?.filterObj?.scope === 'LINKS') {
        setSelectedTab(2);
        setSelectedLinks((prevSelectedLinks) => ({
          ...prevSelectedLinks,
          linkIds: props.filterObj.linkIds
        }));
      }
    }
    const isDimensionSelected = props.filterObj?.dimensionValues
      ? props.filterObj?.dimensionValues.length > 0
      : false;
    checkIsValid(
      props.filterObj?.scope === 'TASKS' ? dimensionFilter.taskIds : dimensionFilter.linkIds,
      isDimensionSelected,
      props.filterObj?.scope === 'CASE' ? true : false
    );
  }, []);

  useEffect(() => {
    const filteredDimensionFilter = {
      ...dimensionFilter,
      ...(dimensionFilter.scope === 'TASKS' && {
        linkIds: undefined
      }),
      ...(dimensionFilter.scope === 'LINKS' && {
        taskIds: undefined
      }),
      ...(dimensionFilter.scope === 'CASE' && {
        taskIds: undefined,
        linkIds: undefined
      })
    };
    props.handleFilterChange(filteredDimensionFilter);
  }, [dimensionFilter]);

  const handleTabChange = (event, newValue) => {
    const selectedScope = getScope(newValue);
    setDimensionFilter((prevDimensionFilterData) => ({
      ...prevDimensionFilterData,
      scope: selectedScope
    }));
    setSelectedTab(newValue);
    props.setIsValid(
      checkIsValid(
        selectedScope === 'TASKS' ? dimensionFilter.taskIds : dimensionFilter.linkIds,
        dimensionFilter.dimensionValues.length > 0,
        selectedScope === 'CASE' ? true : false
      )
    );
  };

  const handleTaskFilterChange = (value) => {
    setDimensionFilter((prevDimensionFilterData) => ({
      ...prevDimensionFilterData,
      taskIds: value ? value.taskIds : []
    }));
    props.handleFilterChange(dimensionFilter);
    props.setIsValid(
      checkIsValid(value.taskIds, dimensionFilter.dimensionValues.length > 0, false)
    );
  };

  const handleLinkFilterChange = (value) => {
    setDimensionFilter((prevDimensionFilterData) => ({
      ...prevDimensionFilterData,
      linkIds: value ? value.linkIds : []
    }));
    props.handleFilterChange(dimensionFilter);
    props.setIsValid(
      checkIsValid(value.linkIds, dimensionFilter.dimensionValues.length > 0, false)
    );
  };

  const handleDimensionChange = (selectedDimensions) => {
    setDimensionFilter((prevDimensionFilterData) => ({
      ...prevDimensionFilterData,
      dimensionValues: selectedDimensions
    }));
    props.setIsValid(
      checkIsValid(
        dimensionFilter.scope === 'TASKS' ? dimensionFilter.taskIds : dimensionFilter.linkIds,
        selectedDimensions.length > 0,
        props.filterObj?.scope === 'CASE' ? true : false
      )
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allDimensions = dimensions.map((option) => option);
      handleDimensionChange(allDimensions);
    } else {
      handleDimensionChange([]);
    }
  };
  const checkAllSelected = () => {
    return !!(
      dimensionFilter.dimensionValues &&
      dimensionFilter.dimensionValues.length === dimensions.length
    );
  };
  const handleExcludChange = (selectedIsExcluded) => {
    setDimensionFilter((prevDimensionFilterData) => ({
      ...prevDimensionFilterData,
      isExcluded: selectedIsExcluded
    }));
  };
  const handleDelete = (val) => {
    const index = dimensionFilter.dimensionValues.findIndex((item) => item === val);
    dimensionFilter.dimensionValues.splice(index, 1);
    handleDimensionChange(dimensionFilter.dimensionValues);
  };
  return (
    <>
      <div className={classes.tabsContainer} id='filters-main-container'>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          sx={{
            borderRadius: '6px',
            padding: '4px',
            margin: '2%'
          }}
          id='filters-container-tabs'
        >
          <Tab
            label='Tasks'
            sx={{
              borderRadius: '6px',
              paddingLeft: '12px',
              paddingRight: '12px',
              paddingTop: '6px',
              marginRight: '3px',
              textTransform: 'unset',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 500,
              margin: '5px'
            }}
            className={selectedTab === 0 ? classes.selectedTab : ''}
            id='filters-container-tab'
          />
          <Tab
            label='Cases'
            sx={{
              borderRadius: '6px',
              paddingLeft: '12px',
              paddingRight: '12px',
              paddingTop: '6px',
              marginRight: '3px',
              textTransform: 'unset',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 500,
              margin: '5px'
            }}
            className={selectedTab === 1 ? classes.selectedTab : ''}
            id='filters-container-tab'
          />
          <Tab
            label='Links'
            sx={{
              borderRadius: '6px',
              paddingLeft: '12px',
              paddingRight: '12px',
              paddingTop: '6px',
              marginRight: '3px',
              textTransform: 'unset',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 500,
              margin: '5px'
            }}
            className={selectedTab === 2 ? classes.selectedTab : ''}
            id='filters-container-tab'
          />
        </Tabs>
        <div className='dimensional-filter-grid'>
          {selectedTab === 0 && (
            <div>
              <TaskFilter
                workgroupId={props.workgroupId}
                projectId={props.projectId}
                filterObj={selectedTask}
                handleFilterChange={handleTaskFilterChange}
                setIsValid={props.setIsValid}
              />
            </div>
          )}
          {selectedTab === 1 && <div></div>}
          {selectedTab === 2 && (
            <div>
              <LinkFilter
                workgroupId={props.workgroupId}
                projectId={props.projectId}
                handleFilterChange={handleLinkFilterChange}
                filterObj={selectedLinks}
                setIsValid={props.setIsValid}
              />
            </div>
          )}

          <Grid className='grid-container' container spacing={2}>
            <Grid item lg={10.2} xl={10.2}>
              <DashboardTypography id='filter-type-label' typographyClass={'title-14-500'}>
                {t('FILTER.DIMENSIONS')}
              </DashboardTypography>
              <Select
                value={dimensionFilter.dimensionValues}
                onChange={(e) => {
                  handleDimensionChange(e.target.value);
                }}
                fullWidth
                data-testid='dimension-select'
                labelId='dimension-label'
                id='dimension-label'
                key='dimension'
                role='listbox'
                size='small'
                style={{ backgroundColor: '#F9F9Fc', border: '1px solid #D8D8D8' }}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    border: 0
                  },
                  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0
                  },
                  marginTop: '10px'
                }}
                multiple={true}
                input={<OutlinedInput label='Tag' />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          dimensionFilter.dimensionValues
                            ? dimensionFilter.dimensionValues.find((item) => item === value)
                            : ''
                        }
                        data-testid='dimension-chip'
                        variant='light'
                        color='primary'
                        size='small'
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        onDelete={(event) => {
                          handleDelete(value);
                        }}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                <ThemeMode></ThemeMode>
                {dimensions && dimensions.length && (
                  <SelectAllOption
                    checkAllSelected={checkAllSelected}
                    handleSelectAll={handleSelectAll}
                  />
                )}
                {dimensions &&
                  dimensions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      <Checkbox checked={dimensionFilter.dimensionValues.indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item lg={1.8} xl={1.8}>
              <FormControlLabel
                labelPlacement='top'
                control={
                  <Checkbox
                    checked={dimensionFilter.isExcluded}
                    onChange={(e) => handleExcludChange(e.target.checked)}
                  />
                }
                label='Exclude'
                data-testid='exclude-switch'
              />
            </Grid>
          </Grid>
        </div>
        {loading === true && <LoadingSpinner></LoadingSpinner>}
      </div>
    </>
  );
};

DimensionFilter.propTypes = {
  workgroupId: PropTypes.string,
  projectId: PropTypes.string,
  filterObj: PropTypes.object,
  handleFilterChange: PropTypes.func,
  setIsValid: PropTypes.func
};
export default DimensionFilter;
