import { v4 as uuidv4 } from 'uuid';
import humanizeDuration from 'humanize-duration';

export const getUuid = () => {
  return uuidv4();
};
export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatDuration = (val) => {
  return shortEnglishHumanizer(val, { largest: 2, conjunction: ' ' });
};

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  maxDecimalPoints: 2,
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mth',
      w: () => 'wk',
      d: () => 'd',
      h: () => 'h',
      m: () => 'mn',
      s: () => 's',
      ms: () => 'ms'
    }
  }
});

export function formatDate(dateString) {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay}-${formattedMonth}-${year}`;
}
