import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../../../styles/LoadingSpinner.scss';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CHART_TYPES_IN_REPORT, REPORT_PERIODS, REPORT_TYPES } from '../../../utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetReportConfigValidationInfo,
  setReportConfigValidationInfo
} from '../../../Redux/slices/reportConfigSlice.js';
import configVariables from '../../../utils/ConfigurationVariables.js';

const ReportConfigPanel = (props) => {
  const { chartOptions, setChartOptions } = props;
  const { t } = useTranslation();
  const [titleLength, setTitleLength] = useState(0);
  const validationInfo = useSelector((state) => state.reportConfig.reportConfigValidationInfo);
  const repositories = useSelector((state) => state.workgroupRepositories.repositories);
  const dispatch = useDispatch();

  const updateChartOptions = (key, val) => {
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      [key]: val
    }));
  };

  const updateSecondThreshold = (value) => {
    updateChartOptions('secondThreshold', value);
  };

  const updateRepositoryName = (value) => {
    updateChartOptions('repositoryName', value);
  };

  const updateReportType = (value) => {
    updateChartOptions('dataToDisplay', value);
    updateChartOptions('shape', '');
    if (
      value === 'APP_INTERACTIONS_COUNT_BY_USERNAME' ||
      value === 'OBJECT_ACCESS_COUNT' ||
      value === 'TABS_VISIT_COUNT' ||
      value === 'USER_LOGINS_COUNT_BY_USERNAME'
    ) {
      updateSecondThreshold(2629800);
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {
    updateTitleLength(chartOptions?.title?.length);
  }, [chartOptions.title]);

  const updateTitleLength = (characterLength) => {
    setTitleLength(characterLength);
  };

  return (
    <div className='chartProperties-grid'>
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 700,
          color: '#667085',
          position: 'relative',
          top: '-5px'
        }}
      >
        {t('REPORT.REPORT_CONFIGURATION')}
      </Typography>
      <Box
        component='form'
        sx={{
          '& .MuiFormControl-root': { marginTop: 1.5, marginBottom: 1.5, width: '100%' }
        }}
        noValidate
        autoComplete='off'
      >
        <div className='addEditWdiget-container'>
          <TextField
            required
            key='title'
            error={validationInfo?.reportNameError}
            helperText={
              validationInfo?.reportNameError ? t('REPORT.REPORT_NAME_ERROR_MESSAGE') : null
            }
            id='report-label'
            label={t('REPORT.NAME')}
            placeholder={t('REPORT.ENTER_REPORT_NAME')}
            size='small'
            style={{
              width: '100%'
            }}
            data-testid='report-name'
            className='add-textTitle'
            value={chartOptions.title}
            inputProps={{
              maxLength: configVariables?.default?.maxtitleAllowedChar
            }}
            onChange={(event) => {
              updateChartOptions(
                'title',
                event.target.value.slice(0, configVariables.default.maxtitleAllowedChar)
              );
              updateTitleLength(event.target.value.length);
              dispatch(
                setReportConfigValidationInfo({
                  ...validationInfo,
                  reportNameError: event ? false : true
                })
              );
            }}
          />
          <div className='character-section'>
            {t('MAX_CHARACTERS_MESSAGE', {
              characterLength: titleLength,
              maxAllowdedCharLength: configVariables?.default.maxtitleAllowedChar
            })}
          </div>
          <FormControl fullWidth size='small' error={validationInfo?.reportTypeError}>
            <InputLabel required id='report-type-select-label'>
              {t('REPORT.REPORT_TYPE')}
            </InputLabel>
            <Select
              label={t('REPORT.REPORT_TYPE')}
              labelId='report-type-select-label'
              id='report-type-label'
              data-testid='report-type-select'
              value={chartOptions.dataToDisplay}
              onChange={(event) => {
                updateReportType(event.target.value);
                dispatch(resetReportConfigValidationInfo());
              }}
            >
              {REPORT_TYPES && REPORT_TYPES.length > 0 ? (
                REPORT_TYPES.map((report) => (
                  <MenuItem key={report.key} value={report.key}>
                    {report.value}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value='no_data' disabled>
                  {t('REPORT.EMPTY_REPORT_TYPE_MESSAGE')}
                </MenuItem>
              )}
            </Select>
            {validationInfo?.reportTypeError && (
              <FormHelperText>{t('REPORT.REPORT_TYPE_ERROR_MESSAGE')}</FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth size='small' error={validationInfo?.chartTypeError}>
            <InputLabel required id='chart-type-select-label'>
              {t('CHART_TYPE')}
            </InputLabel>
            <Select
              labelId='chart-type-select-label'
              id='chart-type-select'
              value={chartOptions.shape}
              label={t('CHART_TYPE')}
              data-testid='chart-type-select'
              onChange={(event) => {
                updateChartOptions('shape', event.target.value);
              }}
            >
              {CHART_TYPES_IN_REPORT[chartOptions.dataToDisplay]?.length > 0 ? (
                CHART_TYPES_IN_REPORT[chartOptions.dataToDisplay].map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value='no_data' disabled>
                  {t('REPORT.EMPTY_CHART_TYPE_MESSAGE')}
                </MenuItem>
              )}
            </Select>
            {validationInfo?.chartTypeError && (
              <FormHelperText>{t('REPORT.CHART_TYPE_ERROR_MESSAGE')}</FormHelperText>
            )}
          </FormControl>

          {(chartOptions?.dataToDisplay === 'APP_INTERACTIONS_COUNT_BY_USERNAME' ||
            chartOptions?.dataToDisplay === 'USER_LOGINS_COUNT_BY_USERNAME' ||
            chartOptions?.dataToDisplay === 'OBJECT_ACCESS_COUNT' ||
            chartOptions?.dataToDisplay === 'TABS_VISIT_COUNT') && (
            <>
              <FormControl fullWidth size='small' error={validationInfo?.repositoryError}>
                <InputLabel required id='repository-select'>
                  {t('REPORT.REPOSITORY')}
                </InputLabel>
                <Select
                  labelId='repository-select'
                  id='repository-select'
                  value={chartOptions.repositoryName}
                  label={t('REPORT.REPOSITORY')}
                  data-testid='repository-select'
                  onChange={(event) => {
                    updateRepositoryName(event.target.value);
                  }}
                >
                  {repositories?.length > 0 ? (
                    repositories.map((repository) => (
                      <MenuItem key={repository.shortname} value={repository.shortname}>
                        {repository.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value='no_data' disabled>
                      {t('REPORT.EMPTY_REPOSITORY_MESSAGE')}
                    </MenuItem>
                  )}
                </Select>
                {validationInfo?.repositoryError && (
                  <FormHelperText>{t('REPORT.REPOSITORY_ERROR_MESSAGE')}</FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth size='small'>
                <InputLabel id='report-period'>{t('PERIOD')}</InputLabel>
                <Select
                  labelId='report-period'
                  id='report-period'
                  value={chartOptions.secondThreshold}
                  label={t('PERIOD')}
                  data-testid='report-period'
                  onChange={(event) => {
                    updateSecondThreshold(event.target.value);
                  }}
                >
                  {REPORT_PERIODS.map((option) => (
                    <MenuItem key={option.lable} value={option.value}>
                      {t('REPORT.' + option.lable)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </div>
      </Box>
    </div>
  );
};

ReportConfigPanel.propTypes = {
  chartOptions: PropTypes.object,
  setChartOptions: PropTypes.func,
  isLoading: PropTypes.func
};

export default ReportConfigPanel;
