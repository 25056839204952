import { createTheme } from '@mui/material/styles';

const initializeThemeBreakpoints = () => {
  const windowSize = window.innerWidth;
  const breakpoints = {
    values: {
      xs: 0,
      sm: 425,
      md: 768,
      lg: windowSize,
      xl: windowSize
    }
  };

  if ((windowSize >= 426 && windowSize <= 1440) || windowSize > 1440) {
    breakpoints.values = {
      xs: 0,
      sm: 425,
      md: windowSize,
      lg: windowSize,
      xl: windowSize
    };
  }

  return breakpoints;
};

const initialBreakpoints = initializeThemeBreakpoints();

const Theme = createTheme({
  colors: {},
  breakpoints: initialBreakpoints
});

window.addEventListener('resize', () => {
  Theme.breakpoints.values = initializeThemeBreakpoints();
});

export default Theme;
