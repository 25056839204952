import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/system';
import { useTranslation } from 'react-i18next';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { Tooltip, MenuItem, Select, Grid, Menu, Badge } from '@mui/material';
import Theme from '../Theme';
import PropTypes from 'prop-types';
import '../../styles/DashboardLayout.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import '../../styles/DashboardLayout.scss';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { setDashboardEditMode } from '../../Redux/slices/dashboardSlice';
import { setGlobalFilterDrawerState } from '../../Redux/slices/globalFilterSlice';
import Box from '@mui/material/Box';
import AddEditDashboard from '../dashboard/AddEditDashboard';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import DashboardButton from '../shared/Buttons/DashboardButton';
import AddIcon from '@mui/icons-material/Add';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import GlobalFilters from '../globalFilters/GlobalFilters';
import {
  resetDashboardState,
  setIsAddNewDashboardBtnClicked,
  setIsCurrentDashboardUpdated,
  setIsDashboardRefreshed
} from '../../Redux/slices/allDashboardSlice';
import { getGlobalFiltersFromSessionStorage } from '../../utils/GlobalFilterUtils.js';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { sessionKeys } from '../../utils/Constants.js';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography.js';
import ThemeMode from '../themeMode/themeMode.js';

export default function DashboardHeader({
  dashboardName,
  onSaveDashboard,
  onDiscard,
  updateDashboardData,
  updateDashboardName,
  appData,
  generatePdf,
  addNewDashboard,
  dashboards,
  renderDashboard,
  dashboardData,
  generatePng,
  workgroupName,
  isValidDashboard,
  deleteDashboard,
  selectedDataSourceList,
  toggleDashboardFavouriteStatus,
  getChartDataByConfig,
  createDuplicateDashboard,
  onCopyDashboardURL
}) {
  const isDashboardEditMode = useSelector((state) => state.dashboard.isDashboardEditMode);
  const isAddNewDashboardBtnClicked = useSelector(
    (state) => state.allDashboard.isAddNewDashboardBtnClicked
  );
  const selectedDashboardData = useSelector((state) => state.allDashboard.selectedDashboardData);
  const isEditDashboardNameBtnClicked = useSelector(
    (state) => state.allDashboard.isEditDashboardNameBtnClicked
  );
  const isDeleteDashboardBtnClicked = useSelector(
    (state) => state.allDashboard.isDeleteDashboardBtnClicked
  );
  const isFavouriteDashboardBtnClicked = useSelector(
    (state) => state.allDashboard.isFavouriteDashboardBtnClicked
  );
  const isDuplicateDashboardBtnClicked = useSelector(
    (state) => state.allDashboard.isDuplicateDashboardBtnClicked
  );
  const isDashboardRefreshed = useSelector((state) => state.allDashboard.isDashboardRefreshed);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openPopup, setOpenPopup] = React.useState(false);
  const appName = appData.name;
  const [isEditDashboard, setIsEditDashboard] = useState(false);
  const [isCreateDuplicateDashboard, setIsCreateDuplicateDashboard] = useState(false);
  const [updateDashboard, setUpdateDashboard] = useState(false);
  const [originalDashboardObj, setOriginalDashboardObj] = useState(null);
  const [showDataWarning, setShowDataWarning] = useState(false);
  const isGlobaFilterDrawerOpen = useSelector(
    (state) => state.globalFilter.isglobalFiltersDrawerOpen
  );
  const [globalFilterAppliedCount, setGlobalFilterAppliedCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (updateDashboard && dashboardData.name) {
      onSave(true);
      setUpdateDashboard(false);
    }
  }, [dashboardData?.name]);

  useEffect(() => {
    setOriginalDashboardObj({ ...dashboardData });
  }, [dashboardData?.id]);

  useEffect(() => {
    if (isDashboardUpdated(originalDashboardObj, dashboardData)) {
      dispatch(setIsCurrentDashboardUpdated(true));
      if (isDashboardRefreshed) {
        setOriginalDashboardObj({ ...dashboardData });
        dispatch(setIsDashboardRefreshed(false));
      }
    } else {
      dispatch(setIsCurrentDashboardUpdated(false));
    }
  }, [dashboardData, isDashboardRefreshed]);

  useEffect(() => {
    if (isAddNewDashboardBtnClicked) {
      handleAddNewDashboard();
    }
  }, [isAddNewDashboardBtnClicked]);

  useEffect(() => {
    let appliedGlobalFilters = getGlobalFiltersFromSessionStorage(sessionKeys.GLOBAL_FILTERS);
    if (appliedGlobalFilters?.length > 0) {
      appliedGlobalFilters = appliedGlobalFilters?.filter(
        (item) => item.dashboardId === dashboardData?.id
      );
      setGlobalFilterAppliedCount(appliedGlobalFilters?.length);
    } else {
      setGlobalFilterAppliedCount(0);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (isEditDashboardNameBtnClicked) {
      editDashboardName();
    }
  }, [isEditDashboardNameBtnClicked]);

  useEffect(() => {
    if (isDeleteDashboardBtnClicked) {
      deleteCurrentDashboard();
    }
  }, [isDeleteDashboardBtnClicked]);

  useEffect(() => {
    if (isFavouriteDashboardBtnClicked) {
      handleIsFavouriteDashboardChange();
    }
  }, [isFavouriteDashboardBtnClicked]);

  useEffect(() => {
    if (isDuplicateDashboardBtnClicked) {
      duplicateDashboard();
    }
  }, [isDuplicateDashboardBtnClicked]);

  const handleExportOption = (option) => {
    if (option === 'pdf') {
      generatePdf();
    } else if (option === 'png') {
      generatePng();
    }
    handleClose();
  };

  const onSave = function (editMode) {
    onSaveDashboard(editMode).then((dashboard) => {
      if (dashboard) {
        setOriginalDashboardObj({ ...dashboard });
      }
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const editDashboardName = () => {
    setIsEditDashboard(true);
    setIsCreateDuplicateDashboard(false);
    setOpenPopup(true);
  };

  const duplicateDashboard = () => {
    setIsEditDashboard(false);
    setIsCreateDuplicateDashboard(true);
    setOpenPopup(true);
  };

  const handleIsFavouriteDashboardChange = () => {
    toggleDashboardFavouriteStatus();
  };

  const handleAddNewDashboard = () => {
    if (isDashboardUpdated(originalDashboardObj, dashboardData)) {
      setShowDataWarning(true);
    } else {
      setIsEditDashboard(false);
      setIsCreateDuplicateDashboard(false);
      setOpenPopup(true);
    }
  };

  const skipPropertiesFromCompare = (dashboardObj) => {
    delete dashboardObj?.isFavourite;
    if (dashboardObj?.charts?.length > 0) {
      dashboardObj?.charts.forEach(function (chartObj) {
        if (chartObj.chartData !== undefined) {
          delete chartObj.chartData;
        }
        if (chartObj?.legend !== undefined) {
          delete chartObj?.legend;
        }
      });
    }
    return dashboardObj;
  };

  const isDashboardUpdated = (originalObj, objToCompare) => {
    if (isDashboardEditMode) {
      const originalDashboardObjDeepCopy = originalObj
        ? JSON.parse(JSON.stringify(originalObj))
        : null;
      const dashboardCompareObjDeepCopy = objToCompare
        ? JSON.parse(JSON.stringify(objToCompare))
        : null;
      const originalDashboardCleanObj = skipPropertiesFromCompare(originalDashboardObjDeepCopy);
      const dashboardCompareCleanObj = skipPropertiesFromCompare(dashboardCompareObjDeepCopy);
      return JSON.stringify(originalDashboardCleanObj) !== JSON.stringify(dashboardCompareCleanObj);
    } else return false;
  };

  const addUpdateDashboard = (name) => {
    if (!isEditDashboard && !isCreateDuplicateDashboard) {
      addNewDashboard(name);
    } else {
      if (isDuplicateDashboardBtnClicked) {
        createDuplicateDashboard(name);
      } else {
        if (isEditDashboardNameBtnClicked) {
          const updatedDashboardData = { ...selectedDashboardData, name: name };
          updateDashboardName(updatedDashboardData);
        } else {
          updateDashboardData('name', name);
        }
      }
    }
    isDuplicateDashboardBtnClicked || isEditDashboardNameBtnClicked
      ? setUpdateDashboard(false)
      : setUpdateDashboard(true);
    dispatch(resetDashboardState());
    setOpenPopup(false);
  };

  const handleClosePopup = () => {
    dispatch(resetDashboardState());
    setOpenPopup(false);
  };

  const handleConfirmationClose = () => {
    dispatch(setIsAddNewDashboardBtnClicked(false));
    setShowDataWarning(false);
  };

  const handleConfirmation = () => {
    onSave(true);
    dispatch(setIsAddNewDashboardBtnClicked(false));
    setShowDataWarning(false);
  };

  const handleDashboardChange = (dashboardId) => {
    if (isDashboardUpdated(originalDashboardObj, dashboardData)) {
      setShowDataWarning(true);
    } else {
      renderDashboard(dashboardId);
    }
  };

  const deleteCurrentDashboard = () => {
    deleteDashboard();
  };

  const openGlobalFiltersDrawer = () => {
    dispatch(setGlobalFilterDrawerState(true));
  };

  const setDashboardEdit = () => {
    dispatch(setDashboardEditMode(true));
    for (const chart of dashboardData.charts) {
      getChartDataByConfig(chart);
    }
  };

  return (
    <ThemeProvider theme={Theme}>
      <div className='dashboardHeaderContainer' id='dashboard-header-container'>
        <Grid
          container
          spacing={2}
          className='dashboardHeader-mainGrid'
          style={{ marginTop: '0px' }}
        >
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8} className='displayFlex'>
            <Tooltip title={t('BACK')} arrow style={{ cursor: 'pointer', paddingTop: '5px' }}>
              <ArrowBackIosIcon onClick={() => navigate(-1)} className='back-arrow' />
            </Tooltip>
            <Grid item mt={0.4} xs={6} sm={6} md={6} lg={6} xl={6} className='displayFlex'>
              <Tooltip title={appName ? appName : ''} arrow>
                <DashboardTypography
                  id='header-app-name'
                  testId={'header-app-name'}
                  typographyClass={'title-24-500 text-overflow-60'}
                  title={appName}
                >
                  {appName ? appName : ''}
                </DashboardTypography>
              </Tooltip>
              <DashboardTypography
                typographyClass={'title-16-500 text-overflow-34 margin-top'}
                testId='header-workgroup-name'
                id='header-workgroup-name'
              >
                <span title={workgroupName}>{workgroupName ? '(' + workgroupName : ''}</span>
              </DashboardTypography>
              <DashboardTypography id='header-app-name' typographyClass={'title-16-500 margin-top'}>
                {')'}
              </DashboardTypography>
            </Grid>
            <div className='dashoboard-select-container'>
              <Select
                className='leftSectionDropDown-select'
                displayEmpty
                value={dashboardData && dashboardData.id ? dashboardData.id : dashboardData.name}
                onChange={(event) => {
                  handleDashboardChange(event.target.value);
                }}
                defaultValue=''
                data-testid='dashboard-select'
                labelId='dashboard-select'
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, overflow: 'hidden' }}>
                    <div className='displayFlex' key={selected}>
                      <label data-testid='dashboard-name'>
                        {dashboards?.length > 0
                          ? dashboards.find((item) => item.id === selected)?.name
                          : dashboardData.name}
                      </label>
                    </div>
                  </Box>
                )}
              >
                <ThemeMode></ThemeMode>
                {dashboards?.length === 0 && (
                  <MenuItem key={dashboardData.name} value={dashboardData.name} disabled>
                    {dashboardData.name}
                  </MenuItem>
                )}
                {dashboards &&
                  dashboards.map((dashboard) => (
                    <MenuItem key={dashboard.id} value={dashboard.id}>
                      {dashboard.name}
                    </MenuItem>
                  ))}
              </Select>
              {isDashboardEditMode && (
                <div className='displayFlex'>
                  <DashboardButton
                    eventHandler={handleAddNewDashboard}
                    color='secondary'
                    btnClassName='more-vert-icon'
                  >
                    <Tooltip title={t('ADD_DASHBOARD')} arrow>
                      <AddIcon
                        className='edit-delete-menu-icon'
                        fontSize='small'
                        data-testid='add-dashboard-icon'
                      />
                    </Tooltip>
                  </DashboardButton>
                </div>
              )}
            </div>
          </Grid>
          {isDashboardEditMode ? (
            <>
              <Grid style={{ textAlign: 'right' }} item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Button
                  variant='outlined'
                  className='dashboard-DiscardBtn generic-btn'
                  onClick={() => onDiscard(selectedDashboardData)}
                  data-testid='discard-btn'
                >
                  {t('DISCARD')}
                </Button>
                <Button
                  variant='contained'
                  className='dashboard-SaveBtn generic-btn'
                  startIcon={<SaveOutlinedIcon />}
                  onClick={() => onSave(false)}
                  data-testid='save-app'
                  inputprops={{ 'data-testid': 'save-app' }}
                >
                  {t('SAVE')}
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid style={{ textAlign: 'right' }} item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Button
                  variant='contained'
                  className='global-filter-btn generic-btn'
                  startIcon={<FilterAltOutlinedIcon className='global-filter-dashboard-icon' />}
                  onClick={openGlobalFiltersDrawer}
                  data-testid='edit-app'
                >
                  <Badge badgeContent={globalFilterAppliedCount} className='filter-list-badge'>
                    {t('FILTERS')}
                  </Badge>
                </Button>
                <Button
                  id='export-button'
                  variant='outlined'
                  className='dashboard-ShareBtn generic-btn'
                  onClick={handleExport}
                  data-testid='export-btn'
                >
                  {isDashboardEditMode ? t('CANCEL') : t('EXPORT')}
                </Button>
                <Menu
                  id='export-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      borderRadius: '6px ',
                      color: ' #667085',
                      fontWeight: 'bold',
                      border: '1px solid #E0E2E7',
                      marginTop: '0.5rem'
                    }
                  }}
                >
                  <MenuItem
                    onClick={() => handleExportOption('pdf')}
                    data-testid='pdf-btn'
                    sx={{ borderBottom: '1px solid #E0E2E7' }}
                  >
                    {' '}
                    <DownloadOutlinedIcon className='export-menu-icons' />
                    {t('PDF')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleExportOption('png')}
                    data-testid='png-btn'
                    sx={{ borderBottom: '1px solid #E0E2E7' }}
                  >
                    {' '}
                    <DownloadOutlinedIcon className='export-menu-icons' />
                    {t('PNG')}
                  </MenuItem>
                  <MenuItem onClick={() => onCopyDashboardURL(handleClose)} data-testid='copy-url'>
                    {' '}
                    <ContentCopyIcon className='export-menu-icons' />
                    {t('COPY_APP_LINK')}
                  </MenuItem>
                </Menu>
                <Button
                  variant='contained'
                  className='dashboard-Btn generic-btn'
                  onClick={setDashboardEdit}
                  startIcon={<EditOutlinedIcon />}
                  data-testid='edit-app'
                >
                  {isDashboardEditMode ? t('CANCEL') : t('EDIT')}
                </Button>
              </Grid>
              {isGlobaFilterDrawerOpen && (
                <GlobalFilters
                  selectedDataSourceList={selectedDataSourceList}
                  dashboardId={dashboardData.id}
                  dashboardData={dashboardData}
                  getChartDataByConfig={getChartDataByConfig}
                />
              )}
            </>
          )}
        </Grid>
      </div>
      <div className='responsive-dashboardHeaderContainer'>
        <Typography className='dashboard-title'>
          {dashboardName ? dashboardName : appName}
        </Typography>
        <Button
          variant='outlined'
          className='dashboard-ShareBtn'
          startIcon={<ShareOutlinedIcon />}
        ></Button>
      </div>
      {openPopup && (
        <AddEditDashboard
          handleClose={handleClosePopup}
          handleSave={addUpdateDashboard}
          dashboardData={
            Object.keys(selectedDashboardData).length === 0 ? dashboardData : selectedDashboardData
          }
          isEditDashboard={isEditDashboard}
          isCreateDuplicateDashboard={isCreateDuplicateDashboard}
          dashboards={dashboards}
          openPopup={openPopup}
          isValidDashboard={isValidDashboard}
        ></AddEditDashboard>
      )}
      {showDataWarning && (
        <ConfirmationDialog
          onClose={handleConfirmationClose}
          onConfirm={handleConfirmation}
          title={'UNSAVED_CHANGES'}
          confirmationMsg={'DASHBOARD_SAVE_CONFIRMATION'}
          note={''}
        />
      )}
    </ThemeProvider>
  );
}

DashboardHeader.propTypes = {
  onSaveDashboard: PropTypes.func,
  onDiscard: PropTypes.func,
  updateDashboardData: PropTypes.func,
  updateDashboardName: PropTypes.func,
  dashboardName: PropTypes.string,
  appData: PropTypes.object,
  generatePdf: PropTypes.func,
  addNewDashboard: PropTypes.func,
  dashboards: PropTypes.array,
  renderDashboard: PropTypes.func,
  dashboardData: PropTypes.object,
  generatePng: PropTypes.func,
  workgroupName: PropTypes.string,
  isValidDashboard: PropTypes.func,
  deleteDashboard: PropTypes.func,
  selectedDataSourceList: PropTypes.array,
  toggleDashboardFavouriteStatus: PropTypes.func,
  getChartDataByConfig: PropTypes.func,
  createDuplicateDashboard: PropTypes.func,
  onCopyDashboardURL: PropTypes.func,
  isCopied: PropTypes.bool
};
