import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { TextField, Tooltip } from '@mui/material';
import { SketchPicker } from 'react-color';
import dashboardStyles from '../../../styles/dashboardStyles';
import '../../../styles/DashboardLayout.scss';
import { useTranslation } from 'react-i18next';
import ThemeMode from '../../themeMode/themeMode';
import DashboardTypography from '../../shared/DashboardTypography/DashboardTypography';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} id='create-theme-dialog-title'>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#667085'
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

const CreateThemeDialog = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(props.open);

  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [colorList, setColorList] = useState(['']);
  const [selectedColorIndex, setSelectedColorIndex] = useState(null);
  const [selectedColor, setSelectedColor] = useState('');
  const [themeNameError, setThemeNameError] = useState(false);
  const [colorNotSelectedError, setColorNotSelectedError] = useState(false);
  const [themeName, setThemeName] = useState('');
  const [themeDialogTitle, setThemeDialogTitle] = useState('');

  useEffect(() => {
    if (props.isEdit) {
      setThemeName(props.themeData.name);
      setColorList(props.themeData.colourList);
      setThemeDialogTitle(props.isDefault ? t('VIEW_THEME') : t('EDIT_THEME'));
    } else {
      setThemeDialogTitle(t('CREATE_NEW_THEME'));
    }
  }, []);

  const handleRemoveColor = (colorIndex) => {
    const updatedColorList = [...colorList];
    updatedColorList.splice(colorIndex, 1);
    setColorList(updatedColorList);
  };

  const handleAddColor = () => {
    const updatedColorList = [...colorList];
    updatedColorList.push('');
    setColorList(updatedColorList);
  };

  const handleClose = () => {
    setOpen(false);
    setColorPickerOpen(false);
    props.onClose();
  };

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    const updatedColorList = [...colorList];
    updatedColorList[selectedColorIndex] = color.hex;
    setColorList(updatedColorList);
  };

  const handleCreateOrUpdate = () => {
    if (themeName && colorList && !colorList.includes('')) {
      if (props.isEdit) {
        const updatedThemeData = { ...props.themeData, name: themeName, colourList: colorList };
        props.onUpdate(updatedThemeData);
      } else {
        const newThemeOption = {
          name: themeName,
          isDefault: false,
          description: themeName,
          colourList: colorList
        };
        props.onCreate(newThemeOption);
      }
      handleClose();
    } else {
      themeName ? setThemeNameError(false) : setThemeNameError(true);
      colorList && !colorList.includes('')
        ? setColorNotSelectedError(false)
        : setColorNotSelectedError(true);
    }
  };

  const handleOpenColorPicker = (color, colorIndex) => {
    setSelectedColor(color);
    setSelectedColorIndex(colorIndex);
    setColorPickerOpen(true);
  };

  const handleCloseColorPicker = () => {
    setColorPickerOpen(false);
  };

  const styles = {
    borderRadius: '4px',
    backgroundColor: '#F9F9FC',
    height: '45px',
    color: '#858D9D',
    fontSize: '14px',
    fontFamily: 'Inter',
    marginTop:'15px',
    marginBottom:'15px'
  };

  return (
    <div >
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} id='create-theme-dialog'>
       <ThemeMode></ThemeMode>
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleClose}
        > 
          <DashboardTypography  id='new-theme-title' typographyClass={'title-18-700'}>{themeDialogTitle}</DashboardTypography>
        </BootstrapDialogTitle>
        <DialogContent className='createTheme-dialog' id='create-theme-dialog-content'>
          <DashboardTypography  id='modal-theme-name' typographyClass={'title-14-500 modalThemeName'}>{t('THEME_NAME')}</DashboardTypography>
          <TextField
            id='name'
            error={themeNameError}
            helperText={themeNameError ? t('THEME_NAME_ERROR_MESSAGE') : null}
            placeholder={t('ENTER_THEME_NAME')}
            variant='outlined'
            disabled={props.isDefault}
            size='small'
            value={themeName}
            onChange={(event) => {
              var regex = /^$|^\S+.*/;
              const themeNameCheck = regex.test(event.target.value);
              if (themeNameCheck) {
                setThemeName(event.target.value);
                event ? setThemeNameError(false) : setThemeNameError(true);
              }
            }}
            fullWidth
            InputProps={{
              style: styles
            }}
            data-testid='theme-name-input'
          />
          <DashboardTypography id='new-theme-description' typographyClass={'title-14-700 modalThemeDescription'}>{t('COLORS')}</DashboardTypography>
          {colorNotSelectedError && (
            <p className='colorNotSelectedError'>{t('THEME_COLOR_ERROR_MESSAGE')}</p>
          )}

          <div>
            {colorList.map((color, index) => (
              <div key={index} style={dashboardStyles.shadeNameContainer}>
                <p style={dashboardStyles.modalThemeColorPallete} data-testid='shade-name'>
                  {t('SHADE')} {' ' + (index + 1)}
                </p>
                <div style={dashboardStyles.selectColor}>
                  <Tooltip title={t('SELECT_COLOR')} arrow>
                    <Button
                      className='color-btn'
                      disabled={props.isDefault}
                      sx={{
                        minWidth: '15px !important',
                        borderRadius: '50px',
                        height: '15px',
                        marginTop: '1%',
                        backgroundColor: color,
                        border: '1px solid gray',
                        padding: 'unset',
                        ':hover': {
                          boxShadow: 'unset'
                        }
                      }}
                      onClick={() => handleOpenColorPicker(color, index)}
                      role='color-btn'
                    ></Button>
                  </Tooltip>
                </div>
                <div className='shadeColorHexCode'>
                  <p className='modalShadeColor'>{color ? color : '#'}</p>
                </div>
                <span className='actionButtons' data-testid='-'>
                  {index === 0 || props.isDefault ? null : (
                    <Tooltip title={t('REMOVE_SHADE')} arrow>
                      <Button
                        sx={{
                          border: '1px solid #E0E2E7',
                          backgroundColor: '#F9F9FC',
                          color: '#667085',
                          height: '5px',
                          padding: '0.4rem',
                          minWidth: 'unset',
                          ':hover': {
                            boxShadow: 'unset'
                          }
                        }}
                        onClick={() => handleRemoveColor(index)}
                        role='-'
                        data-testid='minus-button-0'
                      >
                        -
                      </Button>
                    </Tooltip>
                  )}
                  {index === colorList.length - 1 && !props.isDefault && (
                    <Tooltip title={t('ADD_SHADE')} arrow>
                      <Button
                        sx={{
                          border: '1px solid #E0E2E7',
                          backgroundColor: '#F9F9FC',
                          color: '#667085',
                          height: '5px',
                          minWidth: 'unset',
                          marginLeft: '10px',
                          padding: '0.4rem',
                          ':hover': {
                            boxShadow: 'unset'
                          }
                        }}
                        onClick={handleAddColor}
                        role='+'
                      >
                        +
                      </Button>
                    </Tooltip>
                  )}
                </span>
              </div>
            ))}
          </div>
        
        </DialogContent>
        {!props.isDefault && (
          <DialogActions className='createTheme-bottomSection' id='create-theme-dialog-actions'>
            <Button
              variant='outlined'
              autoFocus
              onClick={handleClose}
              className='createTheme-cancelBtn generic-btn'
              role='btn'
            >
              {t('CANCEL')}
            </Button>
            <Button
              variant='contained'
              autoFocus
              onClick={handleCreateOrUpdate}
              className='createTheme-saveBtn generic-btn'
              role='create-btn'
              data-testid='create-btn'
            >
              {props.isEdit ? t('UPDATE') : t('CREATE')}
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
      <Dialog open={colorPickerOpen} onClose={handleCloseColorPicker} role='dialog'>
        <SketchPicker
          color={selectedColor}
          onChange={handleColorChange}
          role='color-picker'
          data-testid='color-picker'
        />
      </Dialog>
    </div>
  );
};

CreateThemeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  isEdit: PropTypes.bool,
  isDefault: PropTypes.bool,
  themeData: PropTypes.object,
  onUpdate: PropTypes.func
};

export default CreateThemeDialog;
