import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LeftSection from './Screen_Sections/LeftSection';
import MiddleSection from './Screen_Sections/MiddleSection';
import Theme from '../Theme';
import '../../styles/DashboardLayout.scss';
import WidgetConfigurationMode from '../dashboard/DashboardContainer/WidgetConfigurationContainer/WidgetConfigurationMode';

const DashboardContainer = (props) => {
  const isDashboardEditMode = useSelector((state) => state.dashboard.isDashboardEditMode);
  const { t } = useTranslation();
  const [selectedRightSectionComponent, setSelectedRightSectionComponent] = useState();
  const [addChart, setAddChart] = useState(false);
  const [addProcessModel, setAddProcessModel] = useState(false);
  const [addReportWidget, setAddReportWidget] = useState(false);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [appThemeColors, setAppThemeColors] = useState([
    '#041AB9',
    '#3648C7',
    '#6876D5',
    '#9BA3E3',
    '#CDD1F1',
    '#EFEFFD'
  ]);

  useEffect(() => {
    if (props.selectedThemeData) {
      setAppThemeColors([...props.selectedThemeData.colourList]);
    }
  }, [props.selectedThemeData]);

  const handleAddChart = () => {
    setAddChart(true);
  };
  const addProcessModelView = () => {
    setAddProcessModel(true);
  };

  const addReportAsWidget = () => {
    setAddReportWidget(true);
  };

  useEffect(() => {}, [props.dashboardData]);

  useEffect(() => {
    setSelectedRightSectionComponent(t('WIDGETCOMPONENTS'));
  }, [isDashboardEditMode]);

  const handleSelectComponent = (componentName) => {
    setSelectedRightSectionComponent(componentName);
  };

  const getSelectedTheme = (newSelectedTheme) => {
    setAppThemeColors(newSelectedTheme.colourList);
    props.updateAppTheme(newSelectedTheme);
  };

  const getSelectedDashboard = (newSelectedDashboard) => {
    props.updateNewSelectedDashboard(newSelectedDashboard);
  };

  const addImage = () => {
    setIsImageDialogOpen(true);
  };

  return (
    <ThemeProvider theme={Theme}>
      <div
        style={{
          width: '100%',
          backgroundColor: isDashboardEditMode ? '#FFF' : '#F9F9FC'
        }}
        className='dashboard-MainContainer'
      >
        <div
          className='display-components'
          style={{
            width: isDashboardEditMode ? '340px' : '0px',
            float: 'right',
            display: 'inline-block',
            backgroundColor: '#ffffff',
            borderLeft: '1px solid #DBDBDB'
          }}
          id='middle-section-menu'
        >
          <div
            style={{
              width: '78%',
              float: 'right',
              display: 'inline-block'
            }}
            className='themeMain-container'
            id='right-dashboard-config-section'
          >
            {isDashboardEditMode ? (
              <WidgetConfigurationMode
                {...props}
                configurationMode={selectedRightSectionComponent}
                handleAddChart={handleAddChart}
                getSelectedTheme={getSelectedTheme}
                getSelectedDashboard={getSelectedDashboard}
                addProcessModelView={addProcessModelView}
                addReportWidget={addReportAsWidget}
                addImage={addImage}
              ></WidgetConfigurationMode>
            ) : null}
          </div>
          <div
            style={{
              width: '20%',
              display: 'inline-block'
            }}
          >
            {isDashboardEditMode ? (
              <MiddleSection onSelectComponent={handleSelectComponent} />
            ) : null}
          </div>
        </div>
        <div
          className={isDashboardEditMode ? 'charts-outerContainer-1' : 'charts-outerContainer-2'}
        >
          <LeftSection
            dashboards={props.dashboards}
            dashboardData={props.dashboardData}
            updateDashboardData={props.updateDashboardData}
            updateDashboardLayout={props.updateDashboardLayout}
            selectedThemeColors={appThemeColors}
            isLoading={props.isLoading}
            appId={props.appId}
            projectId={props.projectId}
            deleteChart={props.deleteChart}
            refreshDashboard={props.refreshDashboard}
            addChart={addChart}
            setAddChart={setAddChart}
            addProcessModel={addProcessModel}
            setAddProcessModel={setAddProcessModel}
            addReportWidget={addReportWidget}
            setAddReportWidget={setAddReportWidget}
            selectedProjectIds={props.selectedProjectIds}
            selectedDataSourceList={props.selectedDataSourceList}
            isImageDialogOpen={isImageDialogOpen}
            setIsImageDialogOpen={setIsImageDialogOpen}
          />
        </div>
      </div>
    </ThemeProvider>
  );
};

DashboardContainer.propTypes = {
  dashboards: PropTypes.array,
  dashboardData: PropTypes.object,
  updateDashboardData: PropTypes.func,
  updateDashboardLayout: PropTypes.func,
  isLoading: PropTypes.func,
  appId: PropTypes.string,
  projectId: PropTypes.string,
  updateAppTheme: PropTypes.func,
  selectedThemeData: PropTypes.object,
  deleteChart: PropTypes.func,
  refreshDashboard: PropTypes.func,
  selectedProjectIds: PropTypes.array,
  selectedDataSourceList: PropTypes.array,
  updateNewSelectedDashboard: PropTypes.func
};
export default DashboardContainer;
