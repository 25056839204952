export const configVariables = {
  default: {
    maxtitleAllowedChar: 50,
    maxDescriptionAllowedChar: 500,
    minWidth: 4,
    minHeight: 5
  },
  bar: {
    maxtitleAllowedChar: 50,
    maxDescriptionAllowedChar: 500,
    minWidth: 2,
    minHeight: 5
  },
  line: {
    maxtitleAllowedChar: 50,
    maxDescriptionAllowedChar: 500,
    minWidth: 2,
    minHeight: 5
  },
  pie: {
    maxtitleAllowedChar: 50,
    maxDescriptionAllowedChar: 500,
    minWidth: 2,
    minHeight: 5
  },
  numeric: {
    maxtitleAllowedChar: 50,
    maxDescriptionAllowedChar: 500,
    minWidth: 2,
    minHeight: 5
  },
  text: {
    maxtitleAllowedChar: 50,
    maxDescriptionAllowedChar: 500,
    minWidth: 2,
    minHeight: 5
  },
  svg: {
    minWidth: 4,
    minHeight: 4
  },
  app: {
    maxAllowdedDataSources: 10,
    maxDescriptionAllowedChar: 255,
    maxAppNameAllowedChar: 255
  },
  globalFilter: {
    maxGlobalFilterAllowedChar: 255
  },
  image: {
    minWidth: 2,
    minHeight: 5,
    maxtitleAllowedChar: 50
  },
  bubble: {
    maxtitleAllowedChar: 50,
    maxDescriptionAllowedChar: 500,
    minWidth: 2,
    minHeight: 5
  }
};

export default configVariables;
