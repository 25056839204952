import { ENUM_LOCAL_STORAGE } from '../utils/Constants';

const UserService = {
  /**
   * Store the user ID of the newly connected user inside the localStorage
   *
   * @param userId The user ID to register
   */
  setUserId: (userId) => {
    localStorage.setItem(ENUM_LOCAL_STORAGE.USER_ID, userId);
  },

  /**
   * Get the user ID of the connected user
   */
  getUserId: () => {
    return localStorage.getItem(ENUM_LOCAL_STORAGE.USER_ID);
  }
};

export default UserService;
