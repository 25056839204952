import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, TextField, InputLabel, Select, MenuItem } from '@mui/material';
import DashboardButton from '../../../../shared/Buttons/DashboardButton';
import '../../../../../styles/DuplicateWidget.scss';
import ThemeMode from '../../../../themeMode/themeMode';
import DashboardTypography from '../../../../shared/DashboardTypography/DashboardTypography';

const DuplicateWidget = (props) => {
  const { t } = useTranslation();
  const [widgetNameError, setWidgetNameError] = useState(false);
  const [widgetName, setWidgetName] = useState();
  const {
    handleClose,
    duplicateWidget,
    dashboardData,
    dashboards,
    openPopup,
    selectedWidgetTitle
  } = props;
  const [dashboardId, setDashboardId] = useState(null);

  useEffect(() => {
    setWidgetName('Copy of ' + selectedWidgetTitle);
    setDashboardId(dashboardData?.id);
  }, [openPopup]);

  const handleDuplicateWidget = () => {
    if (widgetName && !widgetNameError) {
      duplicateWidget(dashboardId, widgetName);
    } else {
      widgetName ? setWidgetNameError(false) : setWidgetNameError(true);
    }
  };

  const handleWidgetNameChange = function (value) {
    setWidgetName(value);
  };

  const handleDashboardChange = function (value) {
    setDashboardId(value);
  };

  return (
    <div id='duplicate-widget'>
      <Dialog
        className='add-edit-dialog'
        onClose={handleClose}
        aria-labelledby='add-widget-dialog-title'
        open={true}
        role='dialog'
        PaperProps={{
          sx: {
            height: 'auto',
            width: '300px',
          }
        }}
      >
        <ThemeMode></ThemeMode>
        <DialogTitle id='duplicate-widget-title'>
          <div>
            <DashboardTypography typographyClass='title-18-700' id='dashboard-dialog-title'>
            {t('WIDGET.DUPLICATE_WIDGET')}
            </DashboardTypography>  
           </div>
        </DialogTitle>
        <DialogContent className='dialog-mainSection' id='duplicate-widget-dialog-content-container'>
          <Grid container spacing={2} className='dialogBody-grid'>
            <Grid item xs={12}>
              <InputLabel required className='label-name' id='duplicate-widget-destination-label'>
                <DashboardTypography id='field-label' typographyClass={'title-14-400'}>
                {t('WIDGET.DESTINATION_DASHBOARD')}
                </DashboardTypography>
              </InputLabel>
              <Select
                className='duplicate-widget-dashboard-select'
                value={dashboardId}
                id='dashboard-select'
                onChange={(event) => {
                  handleDashboardChange(event.target.value);
                }}
                data-testid='dashboard-select'
              >
                <ThemeMode></ThemeMode>
                {dashboards &&
                  dashboards.map((dashboard, index) => (
                    <MenuItem key={index} value={dashboard.id}>
                      {dashboard.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <InputLabel required className='label-name' id='duplicate-widget-name-label'>
                <DashboardTypography id='field-label' typographyClass={'title-14-400'}>
                {t('WIDGET.WIDGET_NAME')}
              </DashboardTypography>
              </InputLabel>
              <TextField
                variant='outlined'
                required
                fullWidth
                key='widgetName'
                error={widgetNameError}
                helperText={widgetNameError ? t('WIDGET_NAME_ERROR_MESSAGE') : null}
                id='chart-label'
                placeholder={t('WIDGET.WIDGET_NAME')}
                size='small'
                className='app-input'
                style={{ backgroundColor: '#F9F9Fc' }}
                value={widgetName}
                data-testid='widget-name-input'
                onChange={(event) => {
                  var regex = /^$|^\S+.*/;
                  const labelCheck = regex.test(event.target.value);
                  if (labelCheck) {
                    handleWidgetNameChange(event.target.value);
                    event ? setWidgetNameError(false) : setWidgetNameError(true);
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='padding-top-20 padding-left-20' id='duplicate-widget-dialog-actions-container'>
          <Grid container item xs={12} className='grid-btn'>
            <Grid item xs={6} className='cancel-btn-section'>
              <DashboardButton
                dataTestid='cancel-duplicate-widget'
                eventHandler={handleClose}
                color='secondary'
              >
                {t('CANCEL')}
              </DashboardButton>
            </Grid>
            <Grid item xs={6} className='save-btn-section'>
              <DashboardButton
                autoFocus
                eventHandler={handleDuplicateWidget}
                dataTestid='duplicate-widget-button'
                color='primary'
              >
                {t('DASHBOARD.DUPLICATE')}
              </DashboardButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DuplicateWidget.propTypes = {
  handleClose: PropTypes.func,
  duplicateWidget: PropTypes.func,
  dashboardData: PropTypes.object,
  dashboards: PropTypes.array,
  selectedWidgetTitle: PropTypes.string,
  openPopup: PropTypes.bool
};

export default DuplicateWidget;
