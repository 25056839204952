import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import lightModeLoGo from '../../assets/thumbnails/lightModeLogo.svg';
import darkModeLoGo from '../../assets/thumbnails/darkModeLogo.svg';
import { Avatar, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { PAGES_TYPES, themeModes } from '../../utils/Constants';
import { Link, generatePath, useLocation, useNavigate } from 'react-router-dom';
import '../../styles/HeaderLayout.scss';
import LogoutIcon from '@mui/icons-material/Logout';
import { useKeycloak } from '../../utils/KeycloakWrapper';
import ApiService from '../../services/ApiService';
import packageJSON from '../../../package.json';
import { ToasterError } from '../../utils/toaster';
import { removeGlobalFiltersFromSessionStorage } from '../../utils/GlobalFilterUtils.js';
import ThemeToggleButton from '../shared/ThemeToggleButton/themeToggleButton.js';
import ThemeMode from '../themeMode/themeMode.js';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import { useSelector } from 'react-redux';

function Header() {
  const [anchorNavigationMenu, setAnchorNavigationMenu] = useState(null);
  const [selectedPage, setSelectedPage] = useState('Workgroups');
  const navigationMenuOpen = Boolean(anchorNavigationMenu);
  const [anchorEl, setAnchorEl] = useState(false);
  const { keycloak, initialized } = useKeycloak();
  const [profilePhoto, setProfilePhoto] = useState();
  const [formData] = useState({
    firstName: keycloak ? keycloak.idTokenParsed?.given_name : '',
    lastName: keycloak ? keycloak.idTokenParsed?.family_name : ''
  });
  const [selectedWorkgroupId, setSelectedWorkgroupId] = useState(null);
  const open = Boolean(anchorEl);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const [workgroupName, setWorkgroupName] = useState('');
  const globalFilterKey = 'globalFilters';
  const disabledThemeModeButton = false;
  const themeMode = useSelector((state) => state.themeMode?.themeMode);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (keycloak) {
          await keycloak.updateToken();

          const userData = await ApiService.getUserProfileDetails();

          if (userData.logo) {
            setProfilePhoto(userData.logo);
          }
        }
      } catch (error) {
        ToasterError(t('FAILED_USER_PROFILE_FETCH_API'));
      }
    };

    if (keycloak) {
      fetchUserData();
    }
  }, [initialized, keycloak]);

  const handleLogout = () => {
    if (keycloak) {
      removeGlobalFiltersFromSessionStorage(globalFilterKey);
      keycloak.logout();
    }
  };

  const openProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeProfileMenu = () => {
    setAnchorEl(false);
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isAppsPage = location.pathname.includes('/Apps');
    setSelectedPage(isAppsPage ? 'Apps' : 'Workgroups');
    setSelectedWorkgroupId(isAppsPage ? location.pathname.split('/')[2] : null);
    setWorkgroupName(isAppsPage ? decodeURIComponent(urlParams.get('workgroupName')) : '');
  }, [location.pathname]);

  const handleOpenNavigationMenu = (event) => {
    setAnchorNavigationMenu(event.currentTarget);
  };

  const handleCloseNavigationMenu = () => {
    setAnchorNavigationMenu(null);
  };

  const navigateToApps = (selectedMenu) => {
    setSelectedPage(selectedMenu.name);
    if (selectedMenu.name === 'Apps') {
      const routePattern = '/Workgroups/:id/Apps';

      const params = {
        id: selectedWorkgroupId
      };

      const generatedUrl = generatePath(routePattern, params);
      const urlWithQueryParams = `${generatedUrl}?${new URLSearchParams({
        workgroupName: encodeURIComponent(workgroupName)
      })}`;
      navigate(urlWithQueryParams);
    } else {
      navigate('/' + selectedMenu.navigation);
    }
  };

  return (
    <AppBar position='sticky' top={0} className='header-container' id='header'>
      <ThemeMode />
      <Container
        maxWidth='1440px'
        sx={{ paddingLeft: 'unset !important', paddingRight: 'unset !important' }}
      >
        <Toolbar disableGutters>
          <Link className='logo-image' to='/'>
            <img
              className='header-logo'
              src={themeMode === themeModes?.LIGHT_THEME ? lightModeLoGo : darkModeLoGo}
              alt={themeMode === themeModes?.LIGHT_THEME ? lightModeLoGo : darkModeLoGo}
            />
          </Link>
          {
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' }
              }}
              className='workgroups-section1'
            >
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavigationMenu}
                color='inherit'
                data-testid='navigation-icon'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                role='menu'
                data-testid='menu-appbar'
                id='menu-appbar'
                anchorEl={anchorNavigationMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={navigationMenuOpen}
                onClose={handleCloseNavigationMenu}
                sx={{
                  display: { xs: 'block', sm: 'block', md: 'none' }
                }}
              >
                {PAGES_TYPES.map(
                  (page) =>
                    (page.name === 'Apps' && !location.pathname.includes('/Apps')) || (
                      <MenuItem
                        key={page.name}
                        name={page.name}
                        onClick={handleCloseNavigationMenu}
                      >
                        <Typography
                          textAlign='center'
                          sx={{
                            fontFamily: 'Inter'
                          }}
                        >
                          {page.name}
                        </Typography>
                      </MenuItem>
                    )
                )}
              </Menu>
            </Box>
          }

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }
            }}
            className='workgroups-section2'
          >
            {PAGES_TYPES.map(
              (page, index) =>
                (page.name === 'Apps' && !location.pathname.includes('/Apps')) || (
                  <Button
                    key={page.name}
                    name={page.name}
                    onClick={() => navigateToApps(page)}
                    sx={{
                      borderBottom: page.name === selectedPage ? '4px solid #6876D5' : 'none'
                    }}
                    className='workgroups-btn'
                  >
                    {page.name}
                  </Button>
                )
            )}
          </Box>
          {disabledThemeModeButton && <ThemeToggleButton></ThemeToggleButton>}
          <div>
            <Button id='profile-button' onClick={openProfileMenu}>
              <Tooltip
                title={`${formData.firstName} ${formData.lastName}`}
                placement='bottom-start'
              >
                <div data-testid='icon-avatar' onClick={openProfileMenu}>
                  <Avatar
                    src={profilePhoto}
                    alt={`${formData.firstName} ${formData.lastName}`}
                    className='icon-avatar'
                  />
                </div>
              </Tooltip>
            </Button>
            <Menu
              id='profile-menu-items'
              anchorEl={anchorEl}
              open={open}
              onClose={closeProfileMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              data-testid='profile-menu'
            >
              <MenuItem
                onClick={() => {
                  navigate('/userinfo');
                }}
                data-testid='Profile'
              >
                <ThemeMode></ThemeMode>{' '}
                <PermIdentityIcon className='profile-icon' id='profile-icon' />
                <DashboardTypography id='profile-menu' typographyClass={'title-14-500'}>
                  {t('PROFILE')}
                </DashboardTypography>
              </MenuItem>

              <MenuItem onClick={() => handleLogout()} data-testid='logout-btn'>
                {' '}
                <LogoutIcon className='profile-icon' id='logout-icon' />
                <DashboardTypography id='logout-menu' typographyClass={'title-14-500'}>
                  {t('LOG_OUT')}
                </DashboardTypography>
              </MenuItem>

              <MenuItem className='version-menu' data-testid='version-lbl'>
                <DashboardTypography id='version-menu' typographyClass={'title-12-500'}>
                  {t('VERSION')} {packageJSON.version}
                </DashboardTypography>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
