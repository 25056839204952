import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Keycloak from 'keycloak-js';
import keycloakInitializer from './keycloak-initializer';
import UserService from '../services/UserService';
import LoadingSpinner from '../components/loader/LoadingSpinner';

export const AuthContext = createContext();

export function KeycloakWrapper({ children }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [keycloak, setKeycloak] = useState(null);

  useEffect(() => {
    const keycloakConfig = new Keycloak(keycloakInitializer);

    keycloakConfig
      .init({
        onLoad: 'login-required',
        checkLoginIframe: false,
        enableBearerInterceptor: true,
        bearerPrefix: 'Bearer',
        bearerExcludedUrls: ['/assets'],
        promiseType: 'native'
      })
      .then((auth) => {
        setKeycloak(keycloakConfig);
        setAuthenticated(auth);
        UserService.setUserId(keycloakConfig.idTokenParsed['user_id']);
      })
      .catch((error) => {
        console.error('Keycloak initialization error:', error);
      });
  }, []);

  if (!keycloak) {
    return (
      <div
        data-testid='keycloak'
        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(0,-50%)' }}
      >
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }
  const handleLogout = () => {
    if (keycloak) {
      keycloak.logout();
    }
  };
  return (
    <AuthContext.Provider value={{ keycloak, authenticated, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
}

KeycloakWrapper.propTypes = {
  children: PropTypes.any
};

// Custom hook to access the Keycloak instance
export function useKeycloak() {
  return useContext(AuthContext);
}

export const refreshKeycloakToken = async (keycloak) => {
  try {
    const refreshed = await keycloak.updateToken();
    return refreshed ? true : false;
  } catch (error) {
    return false;
  }
};
