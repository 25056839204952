import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  Button,
  FormControlLabel,
  Switch,
  InputLabel
} from '@mui/material';
import '../../styles/ProcessModelSettingsPanel.scss';
import { useSelector } from 'react-redux';
import { capitalize } from '../../utils/utils';
import ThemeMode from '../themeMode/themeMode';

const ProcessModelSettingsPanel = (props) => {
  const { t } = useTranslation();
  const [selectedMetrics, setSelectedMetrics] = useState({});
  const [selectedStatistics, setSelectedStatistics] = useState({});
  const [selectedConformanceModel, setSelectedConformanceModel] = useState({});
  const [selectedBusinessRule, setSelectedBusinessRule] = useState({});
  const metricAndStatistics = useSelector((state) => state.metricAndStatistics.metricAndStatistics);
  const [businessRules, setBusinessRules] = useState([]);
  const [conformanceModels, setConformanceModels] = useState([]);
  const [showConformanceModel, setShowConformanceModel] = useState(false);
  const [statistics, setStatistics] = useState([]);

  useEffect(() => {
    let filteredMetrics = null;
    if (props.selectedDisplayMetrics !== 'metric') {
      filteredMetrics = metricAndStatistics?.metrics?.find(
        (metric) => metric.id === props.selectedDisplayMetrics
      );
    } else {
      filteredMetrics = metricAndStatistics?.metrics?.find(
        (metric) => metric.id === props.selectedDisplayMetricsId
      );
    }

    setSelectedMetrics(filteredMetrics || {});
    metricAndStatistics?.businessRule
      ? setBusinessRules(metricAndStatistics.businessRule)
      : setBusinessRules([]);
    metricAndStatistics?.validModels
      ? setConformanceModels(metricAndStatistics.validModels)
      : setConformanceModels([]);
    if (metricAndStatistics?.statistics?.length) {
      setStatisticsBasedOnMetrics(filteredMetrics);
    }
  }, [props.open]);

  const applyDisplayMetricsAndStatistics = () => {
    props.applyDisplayMetricsAndStatisticsConfig({
      selectedMetrics: selectedMetrics,
      selectedStatistics: selectedStatistics,
      selectedBusinessRule: selectedBusinessRule,
      selectedConformanceModel: selectedConformanceModel,
      showConformanceModel: showConformanceModel
    });
    props.panelClose();
  };

  const setStatisticsBasedOnMetrics = (selectedMetricObj) => {
    if (selectedMetricObj?.id === 'compliance') {
      setStatistics(metricAndStatistics?.ruleComplianceStatistics);
      const selectedRule = props.selectedDisplayBusinessRule
        ? metricAndStatistics?.businessRule?.find(
          (rule) => rule.id === props.selectedDisplayBusinessRule?.id
        )
        : metricAndStatistics?.businessRule[0];
      setSelectedBusinessRule(selectedRule);
      const filteredStatistics =
        props.selectedDisplayStatistics === ''
          ? metricAndStatistics?.ruleComplianceStatistics[0]
          : getCompleteStatisticsObject(
            selectedMetricObj?.id,
            metricAndStatistics?.ruleComplianceStatistics
          );
      setSelectedStatistics(filteredStatistics);
    } else if (selectedMetricObj?.id === 'conformance') {
      setStatistics(metricAndStatistics?.conformanceStatistics);
      const selectedModel = props.selectedDisplayConformanceModel
        ? metricAndStatistics?.validModels?.find(
          (model) => model.id === props.selectedDisplayConformanceModel?.id
        )
        : metricAndStatistics?.validModels[0];
      setSelectedConformanceModel(selectedModel);
      setShowConformanceModel(props.selectedDisplayShowModel);
      const filteredStatistics =
        props.selectedDisplayStatistics === ''
          ? metricAndStatistics?.conformanceStatistics[0]
          : getCompleteStatisticsObject(
            selectedMetricObj?.id,
            metricAndStatistics?.conformanceStatistics
          );
      setSelectedStatistics(filteredStatistics);
    } else {
      setStatistics(metricAndStatistics?.statistics);
      const filteredStatistics =
        props.selectedDisplayStatistics === ''
          ? metricAndStatistics?.statistics[0]
          : getCompleteStatisticsObject(selectedMetricObj?.id, metricAndStatistics?.statistics);
      setSelectedStatistics(filteredStatistics);
    }
  };

  const getCompleteStatisticsObject = (selectedMetric, allStatistics) => {
    let item = null;
    const selectedStatisticName =
      typeof props.selectedDisplayStatistics === 'object'
        ? props.selectedDisplayStatistics.name
        : props.selectedDisplayStatistics;

    if (selectedMetric === 'compliance' || selectedMetric === 'conformance') {
      const allMatchingStatistics = allStatistics.filter((v) => v.name === selectedStatisticName);
      item = allMatchingStatistics.find((statistic) =>
        props.selectedIsOkConformance
          ? !statistic?.id?.includes('non-')
          : statistic?.id?.includes('non-')
      );
    } else {
      item = allStatistics.find((statistic) => statistic.name === selectedStatisticName);
    }
    return item;
  };

  const handleMetricChange = (event) => {
    const metric = event.target.value;
    setSelectedMetrics(metric);
    if (metric?.id === 'compliance') {
      setStatistics(metricAndStatistics?.ruleComplianceStatistics);
      setSelectedStatistics(metricAndStatistics?.ruleComplianceStatistics[0]);
      setSelectedBusinessRule(businessRules[0]);
    } else if (metric?.id === 'conformance') {
      setStatistics(metricAndStatistics?.conformanceStatistics);
      setSelectedStatistics(metricAndStatistics?.conformanceStatistics[0]);
      setSelectedConformanceModel(conformanceModels[0]);
    } else {
      setStatistics(metricAndStatistics?.statistics);
      setSelectedStatistics(metricAndStatistics?.statistics[0]);
    }
  };

  return (
    <div id='process-model-settings-panel-container'>
      <Dialog
        aria-labelledby='process-model-dialog'
        role='dialog'
        onClose={props.panelClose}
        open={props.open}
        className='settings-dialog-container'
      >
        <ThemeMode></ThemeMode>
        <DialogContent className='settings-dialog-inner-container' id='process-model-settings-panel-dialog-container'>
        <ThemeMode></ThemeMode>
          <Grid container spacing={2} className='dialog-grid-container'>
            <Grid xs={6} lg={6} xl={6} item className='dialog-grid-item'>
              <FormControl fullWidth size='small'>
                <InputLabel id='metric-select-label'>{t('PROCESS_MODEL.METRIC')}</InputLabel>
                <Select
                  labelId='metric-select-label'
                  id='metric-select'
                  value={selectedMetrics}
                  label='Metric'
                  onChange={handleMetricChange}
                >
                  <ThemeMode></ThemeMode>
                  {metricAndStatistics?.metrics && metricAndStatistics?.metrics.length > 0 ? (
                    metricAndStatistics.metrics.map((option) => (
                      <MenuItem key={option.id} value={option}>
                        {capitalize(option.name)}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value='no_data' disabled>
                      {t('APP.EMPTY_METRICS_MESSAGE')}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={6} lg={6} xl={6} item className='dialog-grid-item'>
              <FormControl fullWidth size='small'>
                <InputLabel id='statistic-select-label'>{t('PROCESS_MODEL.STATISTIC')}</InputLabel>
                <Select
                  labelId='statistic-select-label'
                  id='statistic-select'
                  value={selectedStatistics}
                  label='Statistic'
                  onChange={(event) => {
                    setSelectedStatistics(event.target.value);
                  }}
                >
                  <ThemeMode></ThemeMode>
                  {statistics && statistics.length > 0 ? (
                    statistics.map((option) => (
                      <MenuItem key={option.name} value={option}>
                        {option.id}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value='no_data' disabled>
                      {t('APP.EMPTY_STATISTIC_MESSAGE')}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            {selectedMetrics?.id === 'conformance' && (
              <>
                <Grid xs={6} lg={6} xl={6} item className='dialog-grid-item'>
                  <FormControl fullWidth size='small'>
                    <InputLabel id='conformance-model-select-label'>
                      {t('PROCESS_MODEL.MODEL_FOR_CONFORMANCE')}
                    </InputLabel>
                    <Select
                      labelId='model-for-conformance-select-label'
                      id='model-for-conformance-select'
                      data-testid='model-for-conformance-select'
                      label='Model for conformance'
                      value={selectedConformanceModel}
                      onChange={(event) => {
                        setSelectedConformanceModel(event.target.value);
                      }}
                    >
                      <ThemeMode></ThemeMode>
                      {conformanceModels && conformanceModels.length > 0 ? (
                        conformanceModels.map((option) => (
                          <MenuItem key={option.id} value={option}>
                            {option.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value='no_data' disabled>
                          {t('PROCESS_MODEL.EMPTY_CONFORMANCE_MESSAGE')}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={6} lg={6} xl={6} item className='dialog-grid-item'>
                  <FormControlLabel
                    className='show-model'
                    control={
                      <Switch
                        checked={showConformanceModel}
                        onChange={(event) => {
                          setShowConformanceModel(event.target.checked);
                        }}
                      />
                    }
                    label='Show Model'
                  />
                </Grid>
              </>
            )}

            {selectedMetrics?.id === 'compliance' && (
              <>
                <Grid xs={6} lg={6} xl={6} item className='dialog-grid-item'>
                  <FormControl fullWidth size='small'>
                    <InputLabel id='business-rule-select-label'>
                      {t('FILTER.BUSINESSRULE')}
                    </InputLabel>
                    <Select
                      labelId='business-rule-select-label'
                      id='business-rule-select'
                      value={selectedBusinessRule}
                      label='Business rule'
                      onChange={(event) => {
                        setSelectedBusinessRule(event.target.value);
                      }}
                    >
                      <ThemeMode></ThemeMode>
                      {businessRules && businessRules.length > 0 ? (
                        businessRules.map((option) => (
                          <MenuItem key={option.id} value={option}>
                            {option.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value='no_data' disabled>
                          {t('PROCESS_MODEL.EMPTY_BUSINESS_RULE_MESSAGE')}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={6} lg={6} xl={6} item className='dialog-grid-item'></Grid>
              </>
            )}
            <Grid item xs={6} lg={6} xl={6} className='cancel-btn-container'>
              <Button
                data-testid='cancel-app'
                onClick={props.panelClose}
                className='cancel-btn generic-settings-btn'
              >
                {t('CANCEL')}
              </Button>
            </Grid>
            <Grid item xs={6} lg={6} xl={6} className='apply-btn-container'>
              <Button
                autoFocus
                data-testid='apply-settings-button'
                className='apply-btn generic-settings-btn'
                onClick={applyDisplayMetricsAndStatistics}
              >
                {t('PROCESS_MODEL.APPLY')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ProcessModelSettingsPanel.propTypes = {
  open: PropTypes.bool,
  panelClose: PropTypes.func,
  applyDisplayMetricsAndStatisticsConfig: PropTypes.func,
  selectedDisplayMetrics: PropTypes.string,
  selectedDisplayStatistics: PropTypes.string,
  selectedDisplayMetricsId: PropTypes.string,
  selectedDisplayBusinessRule: PropTypes.object,
  selectedDisplayConformanceModel: PropTypes.object,
  selectedDisplayShowModel: PropTypes.bool,
  selectedIsOkConformance: PropTypes.bool
};

export default ProcessModelSettingsPanel;
