export const ENVIRONMENT = {
  LOCAL: 'http://localhost:8080',
  DEV: ''
};

export const ID = {
  DASHBOARD_ID: 'c705c356-ec2f-484a-8d86-c6b71cb1c489',
  PROJECT_ID: 'a5050933-958b-4357-a335-fe5018f35e54'
};
export const ENDPOINTURL = {
  API_HUBCAP: '/api/v1/hubcap',
  API_DASHBOARD: '/dashboards',
  API_WORKGROUP: '/workgroups',
  API_APPLICATIONS: '/applications',
  FIELD_LIST: '/fields',
  CHART_CONFIG: '/chart_config',
  AVAILABLE_OPTIONS: '/widgets/availableOptions?widgetType=',
  DIMENSION_ID: '&dimensionId=',
  CHART_DATA: '/widgets',
  EDIT_URL:
    'https://dev-360-api.igrafxcloud.com/dashboards/%dashboardId%/widgets/preview?projectId=%projectId%',
  DATASOURCES: '/datasources',
  DATASOURCE_TYPE: 'LOGPICKER',
  APP_THEMES: '/themes',
  API_PROJECT: '/project',
  API_PROJECTS: '/projects',
  API_FILTERS: '/filters',
  API_PERIOD: '/period',
  API_TASKS: '/tasks',
  API_VARIANTS: '/variants',
  API_BUSINESS: '/ready',
  API_LINK: '/links',
  API_CASE: '/case',
  API_AUTHENTICATION: '/users',
  API_DIMENSIONS: '/dimensions',
  API_VALID_MODELS: '/validModels',
  API_SAVED_FILTERS: '/saved-filters',
  API_VIEW: '/view',
  API_STATISTIC: '/metrics-statistics',
  API_STATISTIC_SVG: '/statistics',
  API_GLOBAL_FILTERS: '/globalFilters',
  API_FAVOURITE: '/favourite',
  API_COPY: '/copy',
  COPY_WIDGET: '/copyWidget',
  API_THUMBNAIL: '/thumbnail',
  API_DESIGN_REPORT_DATA: '/designReportData',
  BUBBLE_CHART: '/bubblechart',
  METRICS_DIMENSIONS: '/full-metrics-dimensions',
  API_BUBBLE_CHART: '/bubble-chart',
  API_DASHBOARD_TEMPLATES: '/dashboardTemplates',
  REPOSITORIES: '/repositories'
};
