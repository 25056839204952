import { createSlice } from '@reduxjs/toolkit';
import getChartOptionObject from '../../utils/getChartOptionObject';
const chartData = {
  ...getChartOptionObject('', '', '')
};

const initiaSelectedWidgetState = {
  chartData,
  isAddTextWidget: false
};

const selectedWidgetSlice = createSlice({
  name: 'dashboard',
  initialState: initiaSelectedWidgetState,
  reducers: {
    setAddCustomTextWiget: (state, action) => {
      state.isAddTextWidget = action.payload;
    },

    setChartData: (state, action) => {
      state.chartData = action.payload;
    },
  }
});

export const {
  setAddCustomTextWiget,
  setChartData
} = selectedWidgetSlice.actions;
export default selectedWidgetSlice.reducer;
