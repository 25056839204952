import React from 'react';
import LineChart from '../components/charts/lineChart/LineChart';
import MetricChart from '../components/charts/metricChart/MetricChart';
import TextChart from '../components/charts/textChart/TextChart';
import BarChart from '../components/charts/Bar/BarChart';
import PieChart from '../components/charts/pieChart/PieChart';
import ImageChart from '../components/charts/imageChart/ImageChart';
import ProcessModelView from '../components/charts/ProcessModelView/ProcessModelView';
import { USER_LIST_REPORT_TABLE_HEADERS } from './Constants';
import BubbleChart from '../components/charts/bubbleChart/BubbleChart';
import TableWidget from '../components/charts/tableWidget/TableWidget';
import StackBarChart from '../components/charts/StackBarChart/StackBarChart';

export function SelectChart(props, chartData, chartOption) {
  let chart;
  if (chartOption.shape === 'bar') {
    chart = (
      <BarChart
        options={chartOption}
        chartData={chartData}
        themeColors={props.selectedThemeColors}
      />
    );
  } else if (chartOption.shape === 'line') {
    chart = (
      <LineChart
        options={chartOption}
        chartData={chartData}
        themeColors={props.selectedThemeColors}
      />
    );
  } else if (chartOption.shape === 'pie') {
    chart = (
      <PieChart
        options={chartOption}
        chartData={chartData}
        themeColors={props.selectedThemeColors}
        containerSection='leftSection'
      />
    );
  } else if (chartOption.shape === 'numeric') {
    chart = (
      <MetricChart
        options={chartOption}
        chartData={chartData}
        themeColors={props.selectedThemeColors}
        layoutType='mainsection'
      />
    );
  } else if (chartOption.shape === 'text') {
    chart = (
      <TextChart
        options={chartOption}
        chartData={chartData}
        themeColors={props.selectedThemeColors}
        layoutType='mainsection'
      />
    );
  } else if (chartOption.shape === 'svg') {
    chart = (
      <ProcessModelView
        chartOption={chartOption}
        chartData={chartData}
        isPreviewOnDashboard={true}
      />
    );
  } else if (chartOption.shape === 'image') {
    chart = <ImageChart chartOptions={chartOption} layoutType='mainSection' />;
  } else if (chartOption.shape === 'table') {
    const tableHeaders =
      chartOption.dataToDisplay === 'USER_LIST' ? USER_LIST_REPORT_TABLE_HEADERS : [];
    chart = (
      <TableWidget
        tableHeaders={tableHeaders}
        tableData={chartOption?.chartData}
        layoutType='mainSection'
      />
    );
  } else if (chartOption.shape === 'bubble') {
    chart = (
      <BubbleChart
        options={chartOption}
        chartData={chartData}
        themeColors={props.selectedThemeColors}
        layoutType='mainSection'
      />
    );
  } else if (chartOption.shape === 'stackbar') {
    chart = (
      <StackBarChart chartData={chartData} themeColors={props.selectedThemeColors}></StackBarChart>
    );
  }

  return chart;
}
