import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LoadingSpinner from '../loader/LoadingSpinner';
import '../../styles/LoadingSpinner.scss';
import { Grid, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import '../../styles/DashboardLayout.css';
import ProcessModelConfigPanel from './ProcessModelConfigPanel';
import ProcessModelPreviewPanel from './ProcessModelPreviewPanel';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetProcessModelConfigValidationInfo,
  setIsSettingsPanelOpen,
  setProcessModelConfigValidationInfo,
  setZoomValue,
  setZoomValueFromDropDown
} from '../../Redux/slices/processModelCofigSlice';
import ApiService from '../../services/ApiService';
import { setMetricAndStatistics } from '../../Redux/slices/metricAndStatisticsSlice';
import { setSelectedVariantIndex } from '../../Redux/slices/variantSelectionSlice';
import { ToasterError } from '../../utils/toaster';
import WidgetConfigHeaderComponent from '../shared/WidgetConfigHeaderComponent/WidgetConfigHeaderComponent';

const ProcessModelConfigMainPanel = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSettingsPanelOpen = useSelector((state) => state.processModelConfig.isSettingsPanelOpen);
  const [loading, setLoading] = useState(false);
  const [chartOptions, setChartOptions] = React.useState({
    chartId: '',
    title: '',
    description: '',
    shape: 'svg',
    type: 'svg',
    dataToDisplay: '',
    nameColor: '',
    descriptionColor: '',
    xAxisType: 'category',
    yAxisType: 'value',
    xAxisTitle: '',
    yAxisTitle: '',
    frequency: '',
    selectedDefaultStatistics: {},
    selectedDefaultBusinessRule: {},
    selectedDefaultConformanceModel: {},
    isOkConformance: true,
    ruleId: '',
    modelId: '',
    showModel: false,
    statistic: '',
    distribution: '',
    chartData: [],
    filters: [],
    customFieldId: '',
    aggregation: {},
    limit: '',
    scope: '',
    metricFormat: '',
    unit: '',
    numberFormat: '',
    projectIds: '',
    metric: '',
    otherMetricId: '',
    orientation: 'portrait',
    legend: {}
  });

  useEffect(() => {
    setChartOptions({ ...props.chartOptions });
  }, []);

  useEffect(() => {
    if (chartOptions?.dataToDisplay?.length > 0) {
      dispatch(setIsSettingsPanelOpen(true));
    }
  }, [isSettingsPanelOpen]);

  useEffect(() => {
    if (chartOptions.projectIds && chartOptions.projectIds.length > 0) {
      fetchStatisticData();
    }
  }, [chartOptions.projectIds]);

  const fetchStatisticData = async () => {
    try {
      isLoading(true);
      const response = await ApiService.getStatisticData(
        props.workgroupId,
        chartOptions.projectIds[0]
      );
      dispatch(setMetricAndStatistics(response));
      isLoading(false);
    } catch (error) {
      isLoading(false);
      ToasterError(t('PROCESS_MODEL.FAILED_METRICS_AND_STATISTICS_API_ERROR'));
    }
  };

  const saveConfiguration = () => {
    if (validateProcessModelConfigFields()) {
      props.saveProcessModelConfig(chartOptions, chartOptions.chartData);
      dispatch(resetProcessModelConfigValidationInfo());
      props.onClose();
    } else {
      const validationObj = {
        dataSourceError: chartOptions.projectIds?.length > 0 ? false : true,
        chartNameError: chartOptions.title ? false : true,
        metricsError: chartOptions.metric ? false : true,
        statisticsError: chartOptions.statistic ? false : true,
        businessRuleError:
          chartOptions.metric === 'compliance' && chartOptions.ruleId ? false : true,
        modelForConformanceError:
          chartOptions.metric === 'conformance' && chartOptions.modelId ? false : true
      };
      dispatch(setProcessModelConfigValidationInfo(validationObj));
    }
  };

  const validateProcessModelConfigFields = () => {
    return (
      chartOptions.title &&
      chartOptions.projectIds?.length > 0 &&
      chartOptions.metric &&
      chartOptions.statistic &&
      ((chartOptions.metric === 'conformance' && chartOptions.modelId) ||
        (chartOptions.metric === 'compliance' && chartOptions.ruleId) ||
        (chartOptions.metric !== 'conformance' &&
          chartOptions.metric !== 'compliance' &&
          chartOptions.metric !== null))
    );
  };

  const closeConfigPopup = () => {
    dispatch(resetProcessModelConfigValidationInfo());
    dispatch(setZoomValueFromDropDown(0));
    dispatch(setZoomValue(0.10));
    dispatch(
      setSelectedVariantIndex({ firstSelectedVariantIndex: 0, lastSelectedVariantIndex: 0 })
    );
    props.onClose();
  };

  const isLoading = (isloading) => {
    setLoading(isloading);
  };

  return (
    <div>
      <Dialog
        onClose={closeConfigPopup}
        aria-labelledby='add-widget-dialog-title'
        open={open}
        fullScreen
        role='dialog'
      >
        <Grid container spacing={2} style={{ paddingTop: '26px' }} data-testid={'process-model-preview-header'} id='process-model-preview-header'>
          <WidgetConfigHeaderComponent
            headerTitle={chartOptions && chartOptions.chartId !== ''
              ? t('PROCESS_MODEL.EDIT_PROCESS_MODEL')
              : t('PROCESS_MODEL.NEW_PROCESS_MODEL')}
            onClose={closeConfigPopup}
            saveChartConfig={saveConfiguration}
            isExistFullScreen={!isSettingsPanelOpen}
          >
          </WidgetConfigHeaderComponent>
          <Grid
            item
            xs={isSettingsPanelOpen ? 8 : 12}
            id='process-model-preview-panel'
          >
            <ProcessModelPreviewPanel
              chartOptions={chartOptions}
              workgroupId={props.workgroupId}
              setChartOptions={setChartOptions}
              onExitFullscreen={closeConfigPopup}
            ></ProcessModelPreviewPanel>
          </Grid>
          {isSettingsPanelOpen && (
            <Grid item xs={4} md={4} sm={4} lg={4} xl={4} sx={{ borderLeft: '1px solid #e7e7e7' }}>
              <ProcessModelConfigPanel
                chartOptions={chartOptions}
                setChartOptions={setChartOptions}
                appId={props.appId}
                selectedDataSourceList={props.selectedDataSourceList}
                isLoading={isLoading}
              ></ProcessModelConfigPanel>
            </Grid>
          )}
        </Grid>
        {loading === true && <LoadingSpinner></LoadingSpinner>}
      </Dialog>
    </div>
  );
};

ProcessModelConfigMainPanel.propTypes = {
  onClose: PropTypes.func,
  chartOptions: PropTypes.object,
  appId: PropTypes.string,
  selectedDataSourceList: PropTypes.array,
  workgroupId: PropTypes.string,
  saveProcessModelConfig: PropTypes.func
};

export default ProcessModelConfigMainPanel;
