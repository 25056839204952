import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './DashboardTemplate.scss';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import { Popover } from '@mui/material';
import PropTypes from 'prop-types';

const DashboardTemplate = (props) => {
  const { t } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedwidget, setSelectedwidget] = useState([]);
  const [isWidgetMenuOpen, SetIsWidgetMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);

  useEffect(() => {
    if (props?.dashboardTemplates) {
      setSelectedTemplate(props?.dashboardTemplates[0]?.id);
    }
  }, [props?.dashboardTemplates]);

  const setDashboardTemplate = (event, templateId) => {
    setSelectedTemplate(templateId);
    props?.setDashboardTemplateId('dashboardTemplateId', templateId);
    event.stopPropagation();
  };

  const selectWidgetTemplate = (event, widgets) => {
    setSelectedwidget(widgets);
    SetIsWidgetMenuOpen(true);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const closeWidgetMenu = () => {
    SetIsWidgetMenuOpen(false);
    setAnchorEl(false);
  };

  return (
    <div className='dashboard-template-container'>
      <DashboardTypography typographyClass={'title-16-600'}>
        {t('DASHBOARD_TEMPLATE.SELECT_TEMPLATE')}
      </DashboardTypography>
      <div className='template-section'>
        {props?.dashboardTemplates?.length > 0 &&
          props?.dashboardTemplates?.map((template) => (
            <div
              key={template?.id}
              className={
                selectedTemplate === template?.id ? 'selected-template-card' : 'template-card'
              }
            >
              <DashboardTypography
                typographyClass={'title-14-600 text-overflow-60 display-inline-block'}
              >
                {template?.name}
              </DashboardTypography>
              <div className='template-description'>
                <DashboardTypography
                  typographyClass={'title-14-400 text-overflow-100 display-inline-block'}
                >
                  {template?.description}
                </DashboardTypography>
              </div>
              <div className='template-widgets-container'>
                {template?.widgetNames?.length > 0 && (
                  <div className='template-widget'>
                    <DashboardTypography typographyClass={'template-title-14-400 text-overflow-34'}>
                      <span onClick={(e) => selectWidgetTemplate(e, template?.widgetNames)}>
                        {t('WIDGETS')}
                      </span>
                    </DashboardTypography>
                  </div>
                )}
                <Popover
                  id='basic-menu'
                  open={isWidgetMenuOpen}
                  onClose={closeWidgetMenu}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  {selectedwidget?.map((widget, index) => (
                    <div key={index} className='widget-menu-item'>
                      <DashboardTypography typographyClass={'title-14-400 text-overflow-34'}>
                        {widget}
                      </DashboardTypography>
                    </div>
                  ))}
                </Popover>
                <div
                  className={
                    selectedTemplate === template?.id
                      ? 'template-selected-section'
                      : 'select-template-section'
                  }
                >
                  <DashboardTypography typographyClass={'template-title-14-400 text-overflow-34'}>
                    <span onClick={(e) => setDashboardTemplate(e, template?.id)}>
                      {selectedTemplate === template?.id
                        ? t('SELECTED')
                        : t('DASHBOARD_TEMPLATE.SELECT_TEMPLATE')}
                    </span>
                  </DashboardTypography>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

DashboardTemplate.propTypes = {
  setDashboardTemplateId: PropTypes.func,
  dashboardTemplates: PropTypes.array
};

export default DashboardTemplate;
