import React, { useEffect, useState } from 'react';
import i18n from '../../utils/i18n';
import { I18N_LANGUAGES } from '../../utils/Constants';
import { Select, MenuItem, FormControl } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setLanguageSelected } from '../../Redux/slices/languageSelectedSlice';
import ThemeMode from '../themeMode/themeMode';

const Languagedropdown = ({ selectedLanguage, onLanguageChange }) => {
  const [localSelectedLanguage, setLocalSelectedLanguage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setLocalSelectedLanguage(selectedLanguage || i18n.language || 'en');
  }, [selectedLanguage]);

  const handleChange = (event) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
    setLocalSelectedLanguage(newLanguage);
    dispatch(setLanguageSelected(newLanguage));
    onLanguageChange(newLanguage);
  };

  const filteredLanguages = I18N_LANGUAGES.filter(
    (option) => option.value === 'en' || option.value === 'fr'
  );
  const selectStyles = {
    height: '40px',
    width: '450%'
  };
  return (
    <div>
      <FormControl variant='outlined' className='language-dropdown-select'>
        <Select
          value={localSelectedLanguage}
          onChange={handleChange}
          data-testid='language-dropdown'
          style={selectStyles}
          labelId='language-select-label'
          id='language-select'
          key='language'
          fullWidth
        >
          <ThemeMode></ThemeMode>
          {filteredLanguages.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

Languagedropdown.propTypes = {
  selectedLanguage: PropTypes.string,
  onLanguageChange: PropTypes.func
};
export default Languagedropdown;
