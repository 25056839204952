import React from 'react';
import PropTypes from 'prop-types';
import './ImageChart.scss';

const ImageChart = (props) => {
  return (
    <div className='image-panel-container'>
      {props?.chartOptions?.imageData && (
        <img
          className={props.layoutType === 'mainSection' ? 'image-panel' : 'image-panel-view-mode'}
          src={props?.chartOptions?.imageData}
        ></img>
      )}
    </div>
  );
};

ImageChart.propTypes = {
  chartOptions: PropTypes.object,
  layoutType: PropTypes.string
};

export default ImageChart;
