import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { themeModes } from '../../utils/Constants';
import { darkThemeModeBackgroundColors, lightThemeModeBackgroundColors } from './idBackgroundColorMapping';
import { darkThemeModeTextColors, lightThemeModeTextColors } from '../shared/DashboardTypography/idTextColorMapping';
import { darkThemeModeIconColors, lightThemeModeIconColors } from './idIconColorMapping';
const ThemeMode = () => {
  const themeMode = useSelector(state => state?.themeMode?.themeMode);

  useEffect(() => {
    setBackground(themeMode === themeModes?.LIGHT_THEME ? lightThemeModeBackgroundColors : darkThemeModeBackgroundColors);
    setInputFieldsStyle(themeMode === themeModes?.LIGHT_THEME ? lightThemeModeTextColors : darkThemeModeTextColors);
    setLabelColor(themeMode === themeModes?.LIGHT_THEME ? lightThemeModeTextColors : darkThemeModeTextColors);
    setIconColor(themeMode === themeModes?.LIGHT_THEME ? lightThemeModeIconColors : darkThemeModeIconColors);
    setInputLabelStyle(themeMode === themeModes?.LIGHT_THEME ? lightThemeModeIconColors : darkThemeModeIconColors);
    setInputFieldsOutlineStyle(themeMode === themeModes?.LIGHT_THEME ? lightThemeModeTextColors : darkThemeModeTextColors);
  }, [themeMode]);

  const setBackground = (backgroundColors) => {
    if (backgroundColors) {
      for (const id of Object.keys(backgroundColors)) {
        const elements = document.querySelectorAll(`#${id}`);
        if (elements && elements.length) {
          elements.forEach(element => {
            if (id === 'spinner') {
              element.style.background = `rgba(255, 255, 255, 0.4)`;
            } else if ((id === 'workgroup-cards' || id === 'apps-card' || id === 'empty-screen-image-container' || id === 'workgroup-header' || id === 'delete-confirmation' || id === 'pagination-component' || id === 'view-as-table-dialog-title-container' || id === 'view-as-table-dialog-content-container' || id === 'view-as-table-container' || id === 'filter-modal' || id === 'add-dashboard' || id === 'delete-dashboard' || id === 'widget-actions-menu' || id === 'pagination-component' || id === 'view-as-table-dialog-title-container' || id === 'view-as-table-dialog-content-container' || id === 'view-as-table-container' || id === 'duplicate-widget' || id === 'data-warning' || id === 'create-theme' || id === 'variants-selection-panel-container' || id === 'process-model-settings-panel-container' || id === 'process-model-settings-panel-dialog-container' || id === 'pagination-component' || id === 'view-as-table-dialog-title-container' || id === 'view-as-table-dialog-content-container' || id === 'view-as-table-container' || id === 'add-dashboard' || id === 'delete-dashboard' || id === 'widget-actions-menu' || id === 'pagination-component' || id === 'view-as-table-dialog-title-container' || id === 'view-as-table-dialog-content-container' || id === 'view-as-table-container') && themeMode === themeModes?.DARK_THEME) {
              element.style.background = backgroundColors[id];
              element.style.border = 'unset';
            } else if (id === 'widget-config-header' || id === 'existing-chart-card' || id === 'add-filter' || id === 'add-global-filter-container' || id === 'add-global-filter-container-selected' || id === 'add-new-dashboard-button' || id === 'dashboard-card' || id === 'add-filter' || id === 'add-global-filter-container' || id === 'add-global-filter-container-selected' || id === 'text-widget-container' || id === 'user-first-name-label' || id === 'user-first-name-field' || id === 'user-last-name-field' || id === 'user-email-field' || id === 'image-info-container' || id === 'exclude-switch' || id === 'filters-container-tabs' || id === 'filters-main-container') {
              element.style.background = backgroundColors[id];
              element.style.border = `solid 1px ${backgroundColors[id + '-border']}`;
            } else if (id === 'widget-config-fields' || id === 'right-dashboard-config-section' || id === 'global-filter-container' || id === 'image-widegt-setting-panel') {
              element.style.background = backgroundColors[id];
              element.style.borderLeft = `solid 1px ${backgroundColors[id + '-border']}`;
            } else if (id === 'dashboard-header-container' || id === 'profile-page-header') {
              element.style.background = backgroundColors[id];
              element.style.borderBottom = `solid 1px ${backgroundColors[id + '-border']}`;
            } else if (id === 'file-upload-container') {
              element.style.background = backgroundColors[id];
              element.style.border = `dashed 3px ${backgroundColors[id + '-border']}`;
            } else if (id === 'select-filter-type' || id === 'filters-container-tab') {
              element.style.background = backgroundColors[id];
              element.style.border = `solid 1px ${backgroundColors[id + '-border']}`;
              element.style.color = `${backgroundColors[id + '-color']}`;
             } else {
              element.style.background = backgroundColors[id];
            }
          });
        }
      }
    }
    const polygons = document.querySelectorAll('polygon');
    if (polygons.length > 0) {
      polygons.forEach((polygon) => {
        polygon.style.fill = 'transparent';
      });
    }
  };

  const setIconColor = (iconColors) => {
    if (iconColors) {
      for (const id of Object.keys(iconColors)) {
        const elements = document.querySelectorAll(`#${id}`);
        if (elements && elements.length) {
          elements.forEach(element => {
            element.style.color = iconColors[id];
          });
        }
      }

      const selectElements = document.querySelectorAll(`.MuiSelect-icon`);
      selectElements.forEach(selectElement => {
        selectElement.style.color = iconColors['clear-search'];
      });
    }
  };

  const setInputLabelStyle = (colorMode) => {
    const elements = document.querySelectorAll(`.MuiFormLabel-root`);
    elements.forEach(element => {
      element.style.color = colorMode['input'];
    });
  };

  const setInputFieldsStyle = (colorMode) => {
    const elements = document.querySelectorAll(`.MuiInputBase-root`);
    elements.forEach(element => {
      element.style.color = '#858D9D';
      element.style.background = colorMode['input-field-background'];
      element.style.background = themeMode === themeModes?.LIGHT_THEME ? lightThemeModeBackgroundColors['input-field-background'] : darkThemeModeBackgroundColors['input-field-background'];
    });

    const formControls = document.querySelectorAll(`.MuiFormControl-root`);
    formControls.forEach(formControl => {
      formControl.style.background = 'transperant';
    });

    const helperTexts = document.querySelectorAll(`.MuiFormHelperText-root`);
    helperTexts.forEach(helperText => {
      helperText.style.color = '#667085';
      helperText.style.background = 'transperant';
    });

    const listElements = document.querySelectorAll(`.MuiList-root`);
    if (themeMode === themeModes?.LIGHT_THEME) {
      listElements.forEach(listElement => {
        listElement.style.background = 'unset';
        listElement.style.color = 'unset';
      });
    } else {
      listElements.forEach(listElement => {
        listElement.style.background = '#212330';
        listElement.style.color = '#858D9D';
      });
    }

  };

  const setInputFieldsOutlineStyle = (colorMode) => {
    const elements = document.querySelectorAll(`.MuiOutlinedInput-notchedOutline`);
    elements.forEach(element => {
      element.style.borderColor = colorMode['input-field-outline'];
    });
  };

  const setLabelColor = (colorMode) => {
    const elements = document.querySelectorAll(`label`);
    elements.forEach(element => {
      element.style.color = colorMode['label'];
    });
  };
};



export default ThemeMode;
