import { formatDuration } from './utils';

export const formatYAxisLabel = (value, chartOption, isFormatDuration = true) => {
  if (value === null) {
    return 'N/A';
  }

  if (value === '-') {
    return '-';
  }

  if (chartOption?.dataToDisplay === 'caseDuration') {
    return isFormatDuration ? formatDuration(value) : value;
  } else if (chartOption?.numberFormat === 'percent') {
    const percentageValue = (value * 100).toFixed(2).replace(/\.00$/, '');
    return `${percentageValue}%`;
  } else if (chartOption?.dataToDisplay === 'cost') {
    return chartOption?.unit ? `${value.toFixed(2)}${chartOption.unit}` : `${value.toFixed(2)}`;
  } else {
    return value;
  }
};

export const formatBubbleChartData = (value, unit) => {
  if (unit === 'duration') {
    if (value === 0) {
      return 'instant';
    } else {
      return formatDuration(value);
    }
  } else if (unit === 'frequency') {
    return value % 1 === 0 ? value : value?.toFixed(2);
  } else if (unit !== 'frequency' && unit !== 'duration') {
    return value % 1 === 0 ? `${value}${unit}` : `${value?.toFixed(1)}${unit}`;
  } else {
    return value;
  }
};
