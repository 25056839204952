import { formatBubbleChartData } from '../utils/chartUtils';
import { bubbleChartThemeColors } from '../utils/Constants';
import * as d3 from 'd3';

const BubbleChartConfiguration = {
  getBubbleChartOptions: (
    optionObj,
    data,
    themeColors,
    isDashboardEditMode,
    isWidgetConfigureMode,
    layoutType
  ) => {
    const getThemeColors = () => {
      let bubbleChartColors = [];
      if (themeColors?.length > 0) {
        bubbleChartThemeColors?.forEach((colors) => {
          themeColors?.push(colors);
        });
        bubbleChartColors = themeColors;
      } else {
        bubbleChartColors = bubbleChartThemeColors;
      }
      return bubbleChartColors;
    };

    const formatBubbleChartAxisLabel = (axisName) => {
      const modifiedAxisName = axisName.substring(0, 24);
      return modifiedAxisName.charAt(0).toUpperCase() + modifiedAxisName.slice(1);
    };

    const getBottomGridValue = () => {
      if (!isDashboardEditMode) {
        return '25%';
      } else if (isWidgetConfigureMode) {
        return 20;
      } else {
        return 70;
      }
    };

    function addDataZoomToChartsConfig(DashboardEditMode, WidgetConfigureMode) {
      if (!DashboardEditMode || WidgetConfigureMode) {
        return [
          {
            type: 'inside',
            xAxisIndex: 0,
            minSpan: 5,
            height: 20,
            bottom: 50
          },
          {
            type: 'slider',
            xAxisIndex: 0,
            minSpan: 5,
            height: 20,
            bottom: WidgetConfigureMode ? 5 : 50
          }
        ];
      } else {
        return [];
      }
    }
    const bubbleThemeColors = getThemeColors();
    return {
      grid: {
        left: '5%',
        top: '13%',
        bottom: getBottomGridValue(),
        right: layoutType === 'editSection' ? '20%' : '5%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        name: optionObj?.dataToDisplay,
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        },
        nameLocation: layoutType === 'editSection' ? 'end' : 'middle',
        nameGap: layoutType === 'editSection' ? 15 : 35,
        nameTextStyle: {
          fontWeight: 'bold',
          Padding: '30px'
        },
        axisLabel: {
          show: true,
          width: 30,
          fontSize: layoutType === 'editSection' ? 10 : 8,
          fontWeight: 'bold',
          interval: 'auto',
          hideOverlap: layoutType === 'editSection' ? false : true,
          overflow: layoutType === 'editSection' ? '' : 'truncate',
          rotate: 45
        }
      },
      yAxis: {
        name: formatBubbleChartAxisLabel(optionObj?.yAxisTitle),
        nameLocation: 'end',
        nameGap: 20,
        nameTextStyle: {
          fontWeight: 'bold',
          align: 'left'
        },
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        },
        axisLabel: {
          show: true,
          fontSize: layoutType === 'editSection' ? 10 : 8,
          fontWeight: 500,
          formatter: function (params) {
            const formattedValue = formatBubbleChartData(params, optionObj?.yAxisMetricsUnit);
            return formattedValue;
          }
        },
        scale: true
      },
      tooltip: {
        show: true,
        trigger: 'item',
        appendToBody: true,
        formatter: function (params) {
          const toolTipHeader = params?.value[0];
          const xAxisValue = formatBubbleChartData(params?.value[2], optionObj?.bubbleMetricsUnit);
          const yAxisValue = formatBubbleChartData(params?.value[1], optionObj?.yAxisMetricsUnit);
          return `${toolTipHeader} <br>  ${optionObj?.xAxisTitle}: ${xAxisValue} <br> ${optionObj?.yAxisTitle}: ${yAxisValue}`;
        }
      },
      legend: {
        show: layoutType === 'editSection' ? true : false,
        orient: 'vertical',
        right: '1%',
        icon: 'circle',
        type: 'scroll'
      },
      dataZoom: [...addDataZoomToChartsConfig(isDashboardEditMode, isWidgetConfigureMode)],
      series: data.map((obj, index) => ({
        name: obj[0],
        data: [obj],
        type: 'scatter',
        itemStyle: {
          color: bubbleThemeColors[index % bubbleThemeColors?.length]
        },
        symbolSize: (currentVal) => {
          const minValue = Math.min(...data.map((dataVal) => dataVal[2]));
          const maxValue = Math.max(...data.map((dataVal) => dataVal[2]));
          const scale = d3
            .scaleSqrt()
            .domain([minValue * 0.5, maxValue])
            .range([5, 100]);
          return maxValue - minValue === 0 ? 25 : scale(currentVal[2]);
        }
      }))
    };
  }
};

export default BubbleChartConfiguration;
