import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import AddEditWidget from '../../addEditWidget/AddEditWidget';
import { getUuid } from '../../../utils/utils.js';
import NoWidgetToDisplay from '../../dashboard/DashboardContainer/WidgetContainer/NoWidgetToDisplay/NoWidgetToDisplay.js';
import WidgetGridLayout from '../../dashboard/DashboardContainer/WidgetContainer/WidgetGridLayout/WidgetGridLayout.js';
import WidgetCard from '../../dashboard/DashboardContainer/WidgetContainer/WidgetCard/WidgetCard.js';
import '../../../styles/DashboardLayout.scss';
import '../../../styles/DashboardLayout.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  setLeftSectionContainerWidthForGrid,
  setLeftSectionWidth
} from '../../../Redux/slices/dashboardSlice.js';
import '../../../styles/LeftSection.scss';
import ApiService from '../../../services/ApiService';
import { useTranslation } from 'react-i18next';
import ProcessModelConfigMainPanel from '../../processModelConfiguration/ProcessModelConfigMainPanel.js';
import { useParams } from 'react-router';
import { configVariables } from '../../../utils/ConfigurationVariables.js';
import { setIsSettingsPanelOpen } from '../../../Redux/slices/processModelCofigSlice.js';
import ConfigureImageWidget from '../../charts/chartsConfigurationComponents/addImageWidget/configureImageWidget.js';
import LoadingSpinner from '../../loader/LoadingSpinner.js';
import { ToasterError, ToasterSuccess } from '../../../utils/toaster.js';
import html2canvas from 'html2canvas';
import CustomTextComponent from '../../charts/customText/customTextComponent.js';
import {
  setAddCustomTextWiget,
  setChartData
} from '../../../Redux/slices/selectedWidgetDataSlice.js';
import { setSelectedExistingWidget } from '../../../Redux/slices/existingWidgetSlice.js';
import ViewAsTableDialog from '../../Dailogs/viewAsTable/viewAsTableDialog.js';
import ReportConfigMainPanel from '../../reports/reportsConfigMainPanel.js';
import {
  bubbleChartShape,
  svgChartShape,
  textChartShape,
  imageChartShape,
  reportChartType
} from '../../../utils/Constants.js';

const LeftSection = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDashboardEditMode = useSelector((state) => state.dashboard.isDashboardEditMode);
  const isSaveScreenShot = useSelector((state) => state.dashboard.isSaveScreenShot);
  const isAddTextWidget = useSelector((state) => state.selectedWidget.isAddTextWidget);
  const selectedExistingWidget = useSelector(
    (state) => state.existingWidget.selectedExistingWidget
  );
  const routerParams = useParams();
  const [loading, setLoading] = useState(false);
  const [chartOptions, setChartOptions] = useState({
    chartId: '',
    title: '',
    description: '',
    shape: '',
    type: '',
    dataToDisplay: '',
    nameColor: '',
    descriptionColor: '',
    xAxisType: 'category',
    yAxisType: 'value',
    xAxisTitle: '',
    yAxisTitle: '',
    frequency: '',
    statistic: '',
    distribution: '',
    chartData: [],
    imageData: '',
    filters: [],
    customFieldId: '',
    aggregation: {},
    limit: '',
    scope: '',
    metricFormat: '',
    projectIds: [],
    metric: '',
    otherMetricId: '',
    orientation: 'portrait',
    legend: {}
  });
  const echartsRef = useRef(null);

  const [isAddEditChartDialogOpen, setIsAddEditChartDialogOpen] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [selectedChartId, setSelectedChartId] = useState(null);

  const RightSidePanelWidth = window.innerWidth >= 1440 ? 383 : 377;

  const handleResize = () => {
    const leftSectionWidth = document.getElementById('left-section').offsetWidth;
    dispatch(setLeftSectionContainerWidthForGrid(leftSectionWidth));

    if (isDashboardEditMode) {
      dispatch(setLeftSectionWidth(window.innerWidth - RightSidePanelWidth));
    } else {
      dispatch(setLeftSectionWidth(window.innerWidth));
    }
  };

  useEffect(() => {
    if (isSaveScreenShot) {
      generateFile();
    }
  }, [isSaveScreenShot]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    if (props.addChart) {
      addChart();
      props.setAddChart(false);
    }
  }, [props.addChart]);

  useEffect(() => {
    if (props.isImageDialogOpen) {
      addChart(imageChartShape);
      props.setIsImageDialogOpen(false);
    }
  }, [props.isImageDialogOpen]);

  useEffect(() => {
    if (props.addProcessModel) {
      addChart(svgChartShape);
      props.setAddProcessModel(false);
    }
  }, [props.addProcessModel]);

  useEffect(() => {
    if (props.addReportWidget) {
      addChart('designReport');
      props.setAddReportWidget(false);
    }
  }, [props.addReportWidget]);

  useEffect(() => {
    if (isAddTextWidget) {
      addChart(textChartShape);
    }
    dispatch(setAddCustomTextWiget(false));
  }, [isAddTextWidget]);

  useEffect(() => {
    if (selectedExistingWidget.dashboardId && selectedExistingWidget.chartId) {
      const sourceDashboard = props.dashboards?.filter(
        (dashboard) => dashboard.id === selectedExistingWidget.dashboardId
      );
      const selectedWidgetChartOption = getChartOptions(
        sourceDashboard[0],
        selectedExistingWidget.chartId
      );
      const selectedWidgetLayout = getChartLayout(
        sourceDashboard[0],
        selectedExistingWidget.chartId
      );
      const selectedWidgetUpdatedChartOption = {
        ...selectedWidgetChartOption,
        title: 'Copy of ' + selectedWidgetChartOption.title
      };
      addDuplicateWidget(
        props.dashboardData?.id,
        selectedWidgetUpdatedChartOption,
        selectedWidgetLayout,
        true
      );
      dispatch(
        setSelectedExistingWidget({
          dashboardId: '',
          chartId: ''
        })
      );
    }
  }, [selectedExistingWidget, selectedExistingWidget.dashboardId]);

  const handleClickOpen = (chartId) => {
    dispatch(setChartData(getChartOptions(props?.dashboardData, chartId)));
    dispatch(setIsSettingsPanelOpen(true));
    setSelectedChartId(chartId);
    setIsAddEditChartDialogOpen(true);
  };

  const openProcessModelPreviewPanel = (chartId) => {
    dispatch(setIsSettingsPanelOpen(false));
    setSelectedChartId(chartId);
    setIsAddEditChartDialogOpen(true);
  };

  const handleClose = () => {
    const selectedChartOption = getChartOptions(props?.dashboardData, selectedChartId);
    if (
      (selectedChartOption?.shape === svgChartShape &&
        (!selectedChartOption?.chartData || selectedChartOption?.chartData.length === 0)) ||
      (selectedChartOption?.shape === textChartShape && !selectedChartOption?.description)
    ) {
      props.deleteChart(selectedChartId);
      setSelectedChartId(null);
    }
    if (
      selectedChartOption?.shape === imageChartShape &&
      (!selectedChartOption?.imageData || selectedChartOption?.imageData === '')
    ) {
      props.deleteChart(selectedChartId);
      setSelectedChartId(null);
    }
    if (
      selectedChartOption?.type === 'designReport' &&
      (!selectedChartOption?.chartData || selectedChartOption?.chartData.length === 0)
    ) {
      props.deleteChart(selectedChartId);
      setSelectedChartId(null);
    }
    setIsAddEditChartDialogOpen(false);
  };

  const generateFile = () => {
    const input = echartsRef.current;

    html2canvas(input, {
      scrollY: -window.scrollY,
      useCORS: true,
      allowTaint: true,
      backgroundColor: '#ffffff',
      logging: true,
      height: 600
    }).then((canvas) => {
      const imgData = canvas.toDataURL(`image/png`);
      const splitedBase64String = imgData.split(',');
      const requestBody = {
        thumbnail: splitedBase64String[splitedBase64String.length - 1]
      };
      saveThumbnail(requestBody);
    });
  };

  const saveThumbnail = async (requestBody) => {
    try {
      await ApiService.addThumbnailToApp(routerParams.appId, requestBody);
    } catch (error) {
      ToasterError(t('DASHBOARD.DASHBOARD_THUMBNAIL_SAVE_FAILED'));
    }
  };

  const handleLayoutChange = (newLayouts) => {
    const resizingLayout = newLayouts.find((item) => item.resizing);
    setIsResizing(Boolean(resizingLayout));
    const updatedArray = newLayouts.map((item) => {
      const { i, h, w, x, y } = item;
      const matchedIndex = props.dashboardData?.layout?.layouts?.findIndex((obj) => obj.i === i);

      if (matchedIndex !== -1) {
        return {
          ...props.dashboardData?.layout?.layouts[matchedIndex],
          h: h !== undefined ? h : props.dashboardData.layout?.layouts[matchedIndex].h,
          w: w !== undefined ? w : props.dashboardData.layout?.layouts[matchedIndex].w,
          x: x !== undefined ? x : props.dashboardData.layout?.layouts[matchedIndex].x,
          y: y !== undefined ? y : props.dashboardData.layout?.layouts[matchedIndex].y
        };
      } else {
        return item;
      }
    });
    props.updateDashboardLayout('layouts', updatedArray);
  };

  const updateDashboardData = (key, value) => {
    props.updateDashboardData(key, value);
  };

  const addChart = (chartType) => {
    const currentLayoutCount = props.dashboardData?.layout?.layouts?.length;
    const layoutsPerRow = 3;

    const currentRowCount = Math.floor(currentLayoutCount / layoutsPerRow);
    const currentColumnCount = currentLayoutCount % layoutsPerRow;

    const newX = currentColumnCount * 4;
    const newY = currentRowCount * 4.5;
    const chartId = `chart-` + getUuid();
    const layoutId = `layout-` + getUuid();
    const chartOption = { ...chartOptions };
    chartOption.chartId = chartId;
    chartOption.shape = chartType ? chartType : '';
    chartOption.type = chartType ? chartType : '';

    const chartTypeWidths = {
      svgChartShape: 6,
      designReport: 6
    };

    const chartTypeHeights = {
      svgChartShape: 7,
      designReport: 5
    };

    const defaultWidth = 4;
    const defaultHeight = 5;

    const newLayout = {
      chartId: chartId,
      i: layoutId,
      x: newX,
      y: newY,
      w: chartTypeWidths[chartType] || defaultWidth,
      h: chartTypeHeights[chartType] || defaultHeight,
      minW: 4,
      minH: 5,
      isNewLayout: true,
      moved: false,
      static: false
    };
    //update dashboard obj properties like Layout, charts
    props.updateDashboardLayout('layouts', newLayout);
    updateDashboardData('charts', chartOption);
    if (
      chartOption.type === svgChartShape ||
      chartOption.type === textChartShape ||
      chartOption.type === imageChartShape ||
      chartOption.type === reportChartType
    ) {
      handleClickOpen(chartId);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    addChart();
  };

  const handleSaveChanges = (updatedChartOptions, updatedChartData) => {
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      updatedChartOptions
    }));
    if (updatedChartOptions?.shape === bubbleChartShape) {
      props?.dashboardData?.layout?.layouts.forEach((chart) => {
        chart.h = 6;
        chart.w = 6;
      });
    }
    updatedChartOptions.chartData = updatedChartData;
    updateDashboardData('charts', updatedChartOptions);
  };

  const handleSaveImageWidget = (updatedChartOptions, updatedChartData) => {
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      updatedChartOptions
    }));
    chartOptions.imageData = updatedChartData;
    updatedChartOptions.imageData = updatedChartData;
    updateDashboardData('charts', updatedChartOptions);
  };

  const getChartOptions = (selectedDashboard, chartId) => {
    if (selectedDashboard?.charts.length > 0) {
      for (const chart of selectedDashboard.charts) {
        if (chart.chartId === chartId) {
          return chart;
        }
      }
    }
  };

  const getChartLayout = (selectedDashboard, chartId) => {
    if (selectedDashboard?.layout?.layouts.length > 0) {
      for (const layoutItem of selectedDashboard.layout.layouts) {
        if (layoutItem.chartId === chartId) {
          return layoutItem;
        }
      }
    }
  };

  const handleDeleteChart = (chartId) => {
    props.deleteChart(chartId);
  };

  const addDuplicateWidget = async (
    destinationDashboardId,
    selectedWidgetChartOption,
    selectedWidgetLayout,
    isAddedFromExistingWidgets
  ) => {
    const destinationDashboard = props.dashboards?.filter(
      (dashboard) => dashboard.id === destinationDashboardId
    );
    const currentLayoutCount = destinationDashboard[0]?.layout?.layouts?.length;
    const layoutsPerRow = 3;

    const currentRowCount = Math.floor(currentLayoutCount / layoutsPerRow);
    const currentColumnCount = currentLayoutCount % layoutsPerRow;

    const newX = currentColumnCount * 4;
    const newY = currentRowCount * 4.5;
    const chartId = `chart-` + getUuid();
    const layoutId = `layout-` + getUuid();
    const chartOption = { ...selectedWidgetChartOption, chartId: chartId };

    const newLayout = {
      ...selectedWidgetLayout,
      chartId: chartId,
      i: layoutId,
      x: newX,
      y: newY,
      isNewLayout: true
    };

    const duplicateWidgetPayload = {
      sourceId: props.dashboardData?.id,
      destinationId: destinationDashboardId,
      widgets: [chartOption],
      layouts: [newLayout]
    };

    try {
      setLoading(true);
      const response = await ApiService.createDuplicateWidget(duplicateWidgetPayload);
      setLoading(false);
      ToasterSuccess(
        isAddedFromExistingWidgets
          ? t('WIDGET.ADD_EXISTING_WIDGET_SUCCESS')
          : t('WIDGET.DUPLICATE_WIDGET_API_SUCCESS')
      );
      if (props.dashboardData?.id === response?.id) {
        props.refreshDashboard(props.dashboardData?.id);
      }
    } catch (error) {
      setLoading(false);
      ToasterError(
        isAddedFromExistingWidgets
          ? t('WIDGET.ADD_EXISTING_WIDGET_FAILED')
          : t('WIDGET.DUPLICATE_WIDGET_API_FAILED')
      );
    }
  };

  const renderGrid = () => {
    return props.dashboardData?.layout?.layouts?.map((layoutItem) => {
      const chartDimensions = {
        layoutId: layoutItem.i,
        width: layoutItem.w,
        height: layoutItem.h
      };
      const chartOption = props.dashboardData
        ? getChartOptions(props?.dashboardData, layoutItem.chartId)
        : chartOptions;

      const widgetLayoutConfig = configVariables[chartOption?.shape];

      const updatedLayoutItem = {
        ...layoutItem,
        minW:
          (widgetLayoutConfig && chartOption?.chartData?.length !== 0) ||
          chartOption?.shape === svgChartShape
            ? widgetLayoutConfig.minWidth
            : 2,
        minH:
          (widgetLayoutConfig && chartOption?.chartData?.length !== 0) ||
          chartOption?.shape === svgChartShape
            ? widgetLayoutConfig.minHeight
            : 5
      };
      let chartData = [];
      if (chartOption?.type !== imageChartShape || chartOption?.type !== textChartShape) {
        chartData = chartOption?.chartData;
      } else {
        chartData = [];
      }

      return (
        <div
          key={updatedLayoutItem.i}
          data-grid={updatedLayoutItem}
          className={isDashboardEditMode ? 'widget' : null}
        >
          <WidgetCard
            {...props}
            chartOption={chartOption}
            chartData={chartData}
            layoutItem={updatedLayoutItem}
            handleDeleteChart={handleDeleteChart}
            handleClickOpen={handleClickOpen}
            openProcessModelPreviewPanel={openProcessModelPreviewPanel}
            addDuplicateWidget={addDuplicateWidget}
          ></WidgetCard>
          {isAddEditChartDialogOpen &&
          selectedChartId === updatedLayoutItem.chartId &&
          chartOption?.shape !== svgChartShape &&
          chartOption?.shape !== textChartShape &&
          chartOption?.type !== reportChartType &&
          chartOption?.shape !== imageChartShape ? (
            <AddEditWidget
              open={isAddEditChartDialogOpen}
              onClose={handleClose}
              onSaveChanges={handleSaveChanges}
              chartOptions={getChartOptions(props?.dashboardData, updatedLayoutItem.chartId)}
              chartDimensions={chartDimensions}
              themeColors={props.selectedThemeColors}
              appId={props.appId}
              projectId={props.projectId}
              selectedProjectIds={props.selectedProjectIds}
              selectedDataSourceList={props.selectedDataSourceList}
            />
          ) : null}

          {isAddEditChartDialogOpen &&
          selectedChartId === updatedLayoutItem.chartId &&
          chartOption?.shape === svgChartShape ? (
            <ProcessModelConfigMainPanel
              onClose={handleClose}
              saveProcessModelConfig={handleSaveChanges}
              chartOptions={getChartOptions(props?.dashboardData, updatedLayoutItem.chartId)}
              chartDimensions={chartDimensions}
              appId={props.appId}
              selectedDataSourceList={props.selectedDataSourceList}
              workgroupId={routerParams.workgroupId}
            />
          ) : null}

          {isAddEditChartDialogOpen &&
          selectedChartId === updatedLayoutItem.chartId &&
          chartOption?.type === 'designReport' ? (
            <ReportConfigMainPanel
              onClose={handleClose}
              openReportConfigDialog={isAddEditChartDialogOpen}
              saveReportConfig={handleSaveChanges}
              chartOptions={getChartOptions(props?.dashboardData, updatedLayoutItem.chartId)}
              chartDimensions={chartDimensions}
              themeColors={props.selectedThemeColors}
              appId={props.appId}
              selectedDataSourceList={props.selectedDataSourceList}
              workgroupId={routerParams.workgroupId}
            />
          ) : null}

          {isAddEditChartDialogOpen &&
          selectedChartId === updatedLayoutItem.chartId &&
          chartOption?.shape === imageChartShape ? (
            <ConfigureImageWidget
              openImageDialogContainer={isAddEditChartDialogOpen}
              onClose={handleClose}
              chartOptions={getChartOptions(props?.dashboardData, updatedLayoutItem.chartId)}
              saveImageWidgetConfig={handleSaveImageWidget}
            />
          ) : null}
          {isAddEditChartDialogOpen &&
            selectedChartId === updatedLayoutItem.chartId &&
            chartOption?.shape === textChartShape && (
              <CustomTextComponent
                onClose={handleClose}
                chartOptions={getChartOptions(props?.dashboardData, updatedLayoutItem.chartId)}
                open={isAddEditChartDialogOpen}
                setIsAddEditChartDialogOpen={setIsAddEditChartDialogOpen}
                onSaveChanges={handleSaveChanges}
              />
            )}
        </div>
      );
    });
  };

  return (
    <div
      className={isDashboardEditMode ? 'left-sectionScrolling' : 'left-section'}
      data-testid='left-section'
      id='left-section-container'
      style={{
        backgroundColor: isResizing ? 'white' : '#F9F9FC'
      }}
    >
      <Paper elevation={0} sx={{ backgroundColor: isResizing ? 'white' : '#F9F9FC' }}>
        <div
          onDrop={isDashboardEditMode ? handleDrop : null}
          onDragOver={isDashboardEditMode ? (event) => event.preventDefault() : null}
          id='left-section'
          ref={echartsRef}
        >
          {!props.dashboardData || props.dashboardData?.layout?.layouts?.length === 0 ? (
            <NoWidgetToDisplay></NoWidgetToDisplay>
          ) : null}
          {props.dashboardData &&
          props.dashboardData?.layout?.layouts?.length > 0 &&
          props.isLoading ? (
            <WidgetGridLayout
              isResizing={isResizing}
              handleLayoutChange={handleLayoutChange}
              setIsResizing={setIsResizing}
              renderGrid={renderGrid}
              layout={props.dashboardData.layout.updatedLayoutItem}
            ></WidgetGridLayout>
          ) : null}
        </div>
      </Paper>
      {loading === true && <LoadingSpinner></LoadingSpinner>}
      <ViewAsTableDialog></ViewAsTableDialog>
    </div>
  );
};

LeftSection.propTypes = {
  updateDashboardData: PropTypes.func,
  updateDashboardLayout: PropTypes.func,
  dashboardData: PropTypes.object,
  selectedThemeColors: PropTypes.array,
  isLoading: PropTypes.func,
  appId: PropTypes.string,
  projectId: PropTypes.string,
  deleteChart: PropTypes.func,
  addChart: PropTypes.bool,
  setAddChart: PropTypes.func,
  addProcessModel: PropTypes.bool,
  setAddProcessModel: PropTypes.func,
  addReportWidget: PropTypes.bool,
  setAddReportWidget: PropTypes.func,
  selectedProjectIds: PropTypes.array,
  openProcessModelPreviewPanel: PropTypes.func,
  selectedDataSourceList: PropTypes.array,
  isImageDialogOpen: PropTypes.bool,
  setIsImageDialogOpen: PropTypes.func,
  dashboards: PropTypes.array,
  refreshDashboard: PropTypes.func
};

export default LeftSection;
