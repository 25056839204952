import React, { useEffect, useState } from 'react';
import { TextField, Button, Avatar, Tooltip, Grid } from '@mui/material';
import { useKeycloak } from '../../utils/KeycloakWrapper';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import Languagedropdown from '../languagedropdown/languagedropdown';
import ApiService from '../../services/ApiService';
import { toast } from 'react-toastify';
import { I18N_LANGUAGES } from '../../utils/Constants';
import Header from '../header/Header';
import { Link } from 'react-router-dom';
import { ToasterError, ToasterSuccess } from '../../utils/toaster';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import ThemeMode from '../themeMode/themeMode';
import './ProfilePageDialog.scss';

const ProfilePageDialog = () => {
  const { t } = useTranslation();
  const { keycloak, initialized } = useKeycloak();
  const [formData] = useState({
    firstName: keycloak ? keycloak.idTokenParsed?.given_name : '',
    lastName: keycloak ? keycloak.idTokenParsed?.family_name : '',
    email: keycloak ? keycloak.idTokenParsed?.email : ''
  });
  const [profilePhoto, setProfilePhoto] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [userData, setUserData] = useState(null);

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
  };

  const handleSaveClick = async () => {
    try {
      const updatedUserData = { ...userData, language: selectedLanguage };
      await ApiService.saveUserProfileDetails(updatedUserData);
      const languageDisplayName =
        I18N_LANGUAGES.find((lang) => lang.value === selectedLanguage)?.label || selectedLanguage;
      ToasterSuccess(t('LANGUAGE_CHANGED') + languageDisplayName);
    } catch (error) {
      ToasterError(t('FAILED_USER_PROFILE_SAVE_API'));
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (keycloak.authenticated) {
          await keycloak.updateToken();

          const userProfileData = await ApiService.getUserProfileDetails();

          if (userProfileData.logo) {
            setProfilePhoto(userProfileData.logo);
          }
          setUserData(userProfileData);
        }
      } catch (error) {
        toast.error(t('FAILED_USER_PROFILE_FETCH_API'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: 'light'
        });
      }
    };

    if (keycloak) {
      fetchUserData();
    }
  }, [initialized, keycloak]);

  return (
    <div data-testid='profile-dialog'>
      <Header />
      <ThemeMode />
      <div id='profile-page-container'>
        <div className='page-header' id='profile-page-header'>
          <Tooltip title={t('BACK')} arrow>
            <Link to='/'>
            <ThemeMode />
              <ArrowBackIosIcon
                sx={{ marginTop: '6px', fontSize: '20px' }}
                data-testid='close-profile-dialog'
                id='back-arrow-icon'
              />
            </Link>
          </Tooltip>
          <DashboardTypography typographyClass={'title-18-400 profile-header'} id='profile-header'>{t('PROFILE')}</DashboardTypography>
        </div>
        <div className='profile-content-container' id='profile-content-container'>
          <div className='profile-icon-container'>
            <div data-testid='avatar' className='profile-icon-inner-container'>
              <Avatar src={profilePhoto} className='profile-image'/>
            </div>
          </div>
          <Grid  container spacing={2} className='profile-attributes' data-testid='profileAttributes'>
            <Grid item md={6}>
              <DashboardTypography id='user-first-name' typographyClass='title-14-400'>{t('FIRST_NAME')}</DashboardTypography>
              <TextField
                value={formData.firstName}
                className='profile-page-input-fields'
                id='user-first-name-field'
              />
            </Grid>
            <Grid item md={6}>
              <DashboardTypography id='user-last-name' typographyClass='title-14-400'>{t('LAST_NAME')}</DashboardTypography>
              <TextField
                value={formData.lastName}
                className='profile-page-input-fields'
                id='user-last-name-field'
              />
            </Grid>
          </Grid>
          <div>
            <DashboardTypography id='user-email' typographyClass='title-14-400 email'>{t('EMAIL')}</DashboardTypography>
            <TextField
              value={formData.email}
              fullWidth
              className='profile-page-input-fields'
              id='user-email-field'
            />
          </div>
          <div className='profile-language-dropdown'>
            <DashboardTypography id='user-language' typographyClass='title-14-400'>{t('LANGUAGE')}</DashboardTypography>
           <div className='language'>
            <Languagedropdown
              selectedLanguage={selectedLanguage}
              onLanguageChange={handleLanguageChange}
            />
            </div>
          </div>
          <Button
            variant='contained'
            sx={{
              boxShadow: 'unset',
              backgroundColor: '#3648C7',
              borderRadius: '8px',
              textTransform: 'unset',
              padding: '10px 14px 10px 14px !important',
              height: '40px',
              width: '86px',
              minWidth: 'unset',
              marginTop: '7%',
              marginLeft: '42%',
              ':hover': {
                boxShadow: 'unset'
              }
            }}
            onClick={handleSaveClick}
            data-testid='save-profile-button'
            inputprops={{ 'data-testid': 'save-profile-button' }}
          >
            {t('SAVE')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfilePageDialog;
