import React from 'react';
import PropTypes from 'prop-types';
import './ProcessModelLegend.scss';

const ProcessModelLegend = (props) => {
  const { chartOption } = props;
  return (
    <div className='legend-container'>
      <div className='legend-value-container'>
        {chartOption?.legend &&
          chartOption?.legend?.percentiles?.map((item, index) => (
            <span key={index}>
              {item}
            </span>
          ))}
      </div>
      <div
        className='legend-gradient'
        style={{
          backgroundImage: `linear-gradient(to right, ${chartOption.legend.minColor},${chartOption.legend.maxColor})`
        }}
      >
      </div>
    </div>
  );
};

ProcessModelLegend.propTypes = {
  chartOption: PropTypes.object,
};

export default ProcessModelLegend;