import { createSlice } from '@reduxjs/toolkit';

const initialProcessModelConfigValidationInfo = {
  dataSourceError: false,
  chartNameError: false,
  metricsError: false,
  statisticsError: false,
  businessRuleError: false,
  modelForConformanceError: false,
};

const initialprocessModelConfigState = {
  isSettingsPanelOpen: false,
  svgZoomValue: 0.10,
  svgZoomValueFromDropDown: 0,
  processModelConfigValidationInfo: initialProcessModelConfigValidationInfo,
  isResetZoomTriggerOnFullScreen: false,
  resetWidgetSvg: {
    isResetZoomTriggerOnWidget: false,
    chartId: ''
  },
  zoomLevelForSVGWidgets: [
    {
      chartId: '',
      zoomLevel: 0
    }
  ],
  zoomLevelForSVGWidgetsDropdown: [
    {
      chartId: '',
      zoomLevel: 0
    }
  ],
};

const processModelConfigSlice = createSlice({
  name: 'processModelConfig',
  initialState: initialprocessModelConfigState,
  reducers: {
    setIsSettingsPanelOpen: (state, action) => {
      state.isSettingsPanelOpen = action.payload;
    },
    setProcessModelConfigValidationInfo: (state, action) => {
      state.processModelConfigValidationInfo = action.payload;
    },
    resetProcessModelConfigValidationInfo: (state) => {
      state.processModelConfigValidationInfo = initialProcessModelConfigValidationInfo;
    },

    setZoomValue: (state, action) => {
      state.svgZoomValue = action.payload;
    },

    setZoomValueFromDropDown: (state, action) => {
      state.svgZoomValueFromDropDown = action.payload;
    },

    setIsResetZoomTriggerOnFullScreen: (state, action) => {
      state.isResetZoomTriggerOnFullScreen = action.payload;
    },

    setIsResetZoomTriggerOnWidget: (state, action) => {
      state.resetWidgetSvg.isResetZoomTriggerOnWidget = action.payload.isResetZoomTriggerOnWidget;
      state.resetWidgetSvg.chartId = action.payload.chartId;
    },

    setZoomLevelWidgetSVG: (state, action) => {
      const index = state.zoomLevelForSVGWidgets?.findIndex((zoomLevelForSVGWidget) => zoomLevelForSVGWidget.chartId === action.payload.chartId);
      if (index > -1) {
        state.zoomLevelForSVGWidgets[index].zoomLevel = action.payload.zoomLevel;
      } else {
        state.zoomLevelForSVGWidgets.push(
          {
            zoomLevel: action.payload.zoomLevel,
            chartId: action.payload.chartId
          }
        );
      }
    },

    setZoomLevelForSVGWidgetsDropdown: (state, action) => {
      const index = state.zoomLevelForSVGWidgetsDropdown?.findIndex((zoomLevelForSVGWidgetDropdown) => zoomLevelForSVGWidgetDropdown.chartId === action.payload.chartId);
      if (index > -1) {
        state.zoomLevelForSVGWidgetsDropdown[index].zoomLevel = action.payload.zoomLevel;
      } else {
        state.zoomLevelForSVGWidgetsDropdown.push(
          {
            zoomLevel: action.payload.zoomLevel,
            chartId: action.payload.chartId
          }
        );
      }
    },
  }
});

export const {
  setIsSettingsPanelOpen,
  setProcessModelConfigValidationInfo,
  resetProcessModelConfigValidationInfo,
  setZoomValue,
  setZoomValueFromDropDown,
  setIsResetZoomTriggerOnFullScreen,
  setIsResetZoomTriggerOnWidget,
  setZoomLevelWidgetSVG,
  setZoomLevelForSVGWidgetsDropdown
} = processModelConfigSlice.actions;
export default processModelConfigSlice.reducer;
