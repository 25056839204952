import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { setViewAsTableOpenDialog } from '../../../Redux/slices/viewAsTableDialog';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import './viewAsTableDialog.scss';
import PaginationComponent from '../../shared/Pagination/PaginationComponent';
import { useState, useEffect } from 'react';
import { formatYAxisLabel } from '../../../utils/chartUtils';
import { CSVLink } from 'react-csv';
import DashboardButton from '../../shared/Buttons/DashboardButton';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Search, Clear } from '@mui/icons-material';
import DashboardTypography from '../../shared/DashboardTypography/DashboardTypography';
import ThemeMode from '../../themeMode/themeMode';
import { LICENSE_COUNT_REPORT_TABLE_HEADERS, licenseTypes } from '../../../utils/Constants';

const ViewAsTableDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isOpen, chartOption } = useSelector((state) => state.dialog);
  const [pageSize, setPageSize] = useState(12);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
  const [dataListToShow, setDataListToShow] = useState({});
  const [searchText, setSearchText] = useState('');
  const [initiateDownload, setInitiateDownload] = useState(true);
  const [chartOptionToDisplay, setChartOptionToDisplay] = useState(chartOption);
  const languageSelected = useSelector((state) => state.selectedLanguage.languageSelected);
  const [tableContent, setTableContent] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [selectedLicenseType, setSelectedLicenseType] = useState('all');

  useEffect(() => {
    const tableData =
      chartOptionToDisplay?.type === 'designReport' &&
      chartOptionToDisplay?.dataToDisplay === 'LICENSE_EFFECTIVE'
        ? chartOptionToDisplay?.tableViewData
        : chartOptionToDisplay?.chartData;
    setTableContent(tableData);
    setTableHeadersData();
    setTableData(tableData, 0, pageSize);
  }, [chartOptionToDisplay, chartOptionToDisplay?.chartData, chartOptionToDisplay?.tableViewData]);

  useEffect(() => {
    setInitiateDownload(true);
  }, [initiateDownload]);

  useEffect(() => {
    setChartOptionToDisplay(chartOption);
  }, [chartOption?.chartData, chartOption?.tableViewData]);

  const setTableData = (tableData, selectedPageNumber, selectedPageSize) => {
    setDataListToShow({
      content: tableData?.slice(
        selectedPageNumber * selectedPageSize,
        selectedPageNumber * selectedPageSize + selectedPageSize
      ),
      totalPages: Math.ceil(tableData?.length / selectedPageSize),
      totalElements: tableData?.length
    });
  };

  const dataToDisplayHeaders = {
    LICENSE_EFFECTIVE: LICENSE_COUNT_REPORT_TABLE_HEADERS
  };

  const setTableHeadersData = () => {
    const headers =
      chartOptionToDisplay?.type === 'designReport' &&
      chartOptionToDisplay?.dataToDisplay === 'LICENSE_EFFECTIVE'
        ? dataToDisplayHeaders[chartOptionToDisplay?.dataToDisplay] || []
        : [chartOptionToDisplay?.xAxisTitle, chartOptionToDisplay?.yAxisTitle];

    setTableHeaders(headers);
  };

  const getCsvData = () => {
    if (!tableContent?.length) return [];

    if (
      chartOptionToDisplay?.type === 'designReport' &&
      chartOptionToDisplay?.dataToDisplay === 'LICENSE_EFFECTIVE'
    ) {
      return tableContent;
    }

    return tableContent.map((chartData) => ({
      label: chartData?.label,
      data: formatYAxisLabel(chartData?.data, chartOptionToDisplay, false)
    }));
  };

  const getCsvHeaders = () => {
    if (
      chartOptionToDisplay?.type === 'designReport' &&
      chartOptionToDisplay?.dataToDisplay === 'LICENSE_EFFECTIVE'
    ) {
      return tableHeaders.map((header) => ({
        label: t('TABLE_HEADERS.' + header),
        key: header
      }));
    }

    return [
      {
        label: chartOptionToDisplay?.xAxisTitle ? chartOptionToDisplay?.xAxisTitle : 'label',
        key: 'label'
      },
      {
        label: chartOptionToDisplay?.yAxisTitle ? chartOptionToDisplay?.yAxisTitle : 'data',
        key: 'data'
      }
    ];
  };

  const handleClose = () => {
    dispatch(setViewAsTableOpenDialog(false));
    setSearchText(null);
    setChartOptionToDisplay(chartOption);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    const selectedLanguage = languageSelected === 'en' ? 'en-GB' : languageSelected;
    return date.toLocaleDateString(selectedLanguage, options);
  };

  const getStartingDataNumber = () => {
    return currentPageNumber * pageSize + 1;
  };

  const getEndingDataNumber = () => {
    if (currentPageNumber + 1 === Math.ceil(tableContent?.length / pageSize)) {
      return (
        tableContent?.slice(currentPageNumber * pageSize, currentPageNumber * pageSize + pageSize)
          .length +
        getStartingDataNumber() -
        1
      );
    }
    return (currentPageNumber + 1) * pageSize;
  };

  const handlePageSizeChange = async (selectedPageSize) => {
    setPageSize(selectedPageSize);
    setCurrentPageNumber(0);
    setTableData(tableContent, 0, selectedPageSize);
  };

  const handlePageChange = async (event, pageNumber) => {
    const selectedPageNumber = pageNumber - 1;
    setCurrentPageNumber(selectedPageNumber);
    setTableData(tableContent, selectedPageNumber, pageSize);
  };

  const searchWidgetData = (event) => {
    const searchString = event.target.value?.toString().toLowerCase().trim();
    setSearchText(event.target.value?.toString().toLowerCase());
    setCurrentPageNumber(0);
    if (!searchString) {
      clearSearchText();
    } else {
      const chartOptionsCopy = JSON.parse(JSON.stringify(chartOption));
      if (
        chartOptionToDisplay?.type === 'designReport' &&
        chartOptionToDisplay?.dataToDisplay === 'LICENSE_EFFECTIVE'
      ) {
        let filteredTableViewData = chartOptionsCopy?.tableViewData?.filter((tableData) =>
          Object.values(tableData).some((value) => value.toLowerCase().includes(searchString))
        );
        if (selectedLicenseType !== 'all') {
          filteredTableViewData = filteredTableViewData?.filter(
            (tableData) => tableData.userLicense === selectedLicenseType
          );
        }
        chartOptionsCopy.tableViewData = filteredTableViewData;
      } else {
        const filteredChartData = chartOptionsCopy?.chartData?.filter((chartOpt) => {
          if (chartOption?.shape === 'line') {
            return (
              formatDate(chartOpt?.label)?.toString().toLowerCase().includes(searchString) ||
              formatYAxisLabel(chartOpt?.data ? chartOpt?.data : '-', chartOptionToDisplay)
                ?.toString()
                .toLowerCase()
                .includes(searchString)
            );
          } else {
            return (
              chartOpt?.label?.toString().toLowerCase().includes(searchString) ||
              formatYAxisLabel(chartOpt?.data ? chartOpt?.data : '-', chartOptionToDisplay)
                ?.toString()
                .toLowerCase()
                .includes(searchString)
            );
          }
        });
        chartOptionsCopy.chartData = filteredChartData;
      }
      setChartOptionToDisplay(chartOptionsCopy);
      if (initiateDownload) {
        setInitiateDownload(false);
      }
    }
  };

  const clearSearchText = () => {
    setSearchText('');
    setCurrentPageNumber(0);
    let filteredData = [];
    const chartOptionsCopy = JSON.parse(JSON.stringify(chartOption));
    if (
      chartOptionToDisplay?.type === 'designReport' &&
      chartOptionToDisplay?.dataToDisplay === 'LICENSE_EFFECTIVE' &&
      selectedLicenseType !== 'all'
    ) {
      filteredData = chartOptionsCopy?.tableViewData?.filter(
        (tableData) => tableData.userLicense === selectedLicenseType
      );
      chartOptionsCopy.tableViewData = filteredData;
    }
    setChartOptionToDisplay(filteredData?.length > 0 ? chartOptionsCopy : chartOption);
  };

  const getCellContent = (cellValue) => {
    if (typeof cellValue === 'boolean') {
      return cellValue ? 'TRUE' : 'FALSE';
    } else {
      return cellValue;
    }
  };

  const licenseTypeSelectionChange = (event) => {
    const licenseType = event.target.value;
    setSelectedLicenseType(licenseType);
    setCurrentPageNumber(0);
    const chartOptionsCopy = JSON.parse(JSON.stringify(chartOption));
    let filteredData = [];
    if (licenseType === 'all') {
      if (searchText) {
        filteredData = chartOptionsCopy?.tableViewData?.filter((tableData) =>
          Object.values(tableData).some((value) => value.toLowerCase().includes(searchText))
        );
      }
      chartOptionsCopy.tableViewData = searchText ? filteredData : chartOption?.tableViewData;
    } else {
      filteredData = chartOptionsCopy?.tableViewData?.filter(
        (tableData) => tableData.userLicense === licenseType
      );

      if (searchText) {
        filteredData = filteredData?.filter((tableData) =>
          Object.values(tableData).some((value) => value.toLowerCase().includes(searchText))
        );
      }
      chartOptionsCopy.tableViewData = filteredData;
    }
    setChartOptionToDisplay(chartOptionsCopy);
  };

  return (
    <div>
      <ThemeMode></ThemeMode>
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={isOpen}
        onClose={handleClose}
        data-testid='view-as-table-dialog'
      >
        <ThemeMode></ThemeMode>
        <DialogTitle id='view-as-table-dialog-title-container'>
          <div className='dialog-title-content'>
            <Tooltip title={chartOption?.title} arrow>
              <DashboardTypography
                id='view-as-table-title'
                typographyClass={'title-16-700 text-overflow-80'}
              >
                {chartOption?.title}
              </DashboardTypography>
            </Tooltip>
            <IconButton data-testid='close-view-as-table-dialog-button' onClick={handleClose}>
              <ThemeMode></ThemeMode>
              <CloseIcon id='close-icon' />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent id='view-as-table-dialog-content-container'>
          <Grid container spacing={2} className='search-export-container'>
            <Grid item xs={3}>
              <TextField
                id='search'
                value={searchText}
                placeholder={t('SEARCH_WORKGROUP_PLACEHOLDER')}
                data-testid='search-widget-data'
                onChange={searchWidgetData}
                size='small'
                className='widget-search-text-field'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: searchText && (
                    <InputAdornment
                      position='end'
                      onClick={clearSearchText}
                      style={{ cursor: 'pointer' }}
                      data-testid='clear-search-text'
                    >
                      <Clear sx={{ width: 24, height: 24 }} />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: '8px'
                  }
                }}
              />
            </Grid>
            <Grid item xs={7}>
              {chartOption?.type === 'designReport' &&
                chartOptionToDisplay?.dataToDisplay === 'LICENSE_EFFECTIVE' && (
                  <FormControl fullWidth size='small'>
                    <InputLabel id='license-type-select-label'>
                      <DashboardTypography id='field-label' typographyClass={'title-14-400'}>
                        {t('REPORT.LICENSE_TYPE')}
                      </DashboardTypography>
                    </InputLabel>
                    <Select
                      labelId='license-type-select-label'
                      id='license-type-select'
                      value={selectedLicenseType}
                      label='License Type'
                      className='license-type'
                      onChange={licenseTypeSelectionChange}
                    >
                      <ThemeMode></ThemeMode>
                      {licenseTypes?.length > 0 &&
                        licenseTypes?.map((option, index) => (
                          <MenuItem key={index} value={option.key}>
                            {option.value}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
            </Grid>
            <Grid item xs={2} className='export-csv'>
              {initiateDownload && (
                <CSVLink
                  data={getCsvData()}
                  headers={getCsvHeaders()}
                  filename={chartOption?.title + '.csv'}
                >
                  <DashboardButton
                    color='secondary'
                    dataTestid='export-to-csv'
                    startIcon={<InsertDriveFileIcon />}
                  >
                    {t('EXPORT_CSV')}
                  </DashboardButton>
                </CSVLink>
              )}
            </Grid>
          </Grid>
          <TableContainer component={Paper} id='view-as-table-container'>
            <Table>
              {chartOption?.shape !== 'pie' && (
                <TableHead id='view-as-table-header-container'>
                  <TableRow>
                    {tableHeaders?.length > 0 &&
                      chartOption?.type === 'designReport' &&
                      chartOptionToDisplay?.dataToDisplay === 'LICENSE_EFFECTIVE' &&
                      tableHeaders?.map((columnHeader) => (
                        <TableCell key={columnHeader}>
                          <DashboardTypography
                            typographyClass={'title-14-500'}
                            id='table-header-title'
                          >
                            {t('TABLE_HEADERS.' + columnHeader)}
                          </DashboardTypography>
                        </TableCell>
                      ))}
                    {tableHeaders?.length > 0 &&
                      (chartOption?.type !== 'designReport' ||
                        (chartOption?.type === 'designReport' &&
                          chartOptionToDisplay?.dataToDisplay !== 'LICENSE_EFFECTIVE')) &&
                      tableHeaders?.map((columnHeader) => (
                        <TableCell key={columnHeader}>
                          <DashboardTypography
                            typographyClass={'title-14-500'}
                            id='table-header-title'
                          >
                            {columnHeader}
                          </DashboardTypography>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {dataListToShow?.content?.length > 0 &&
                  chartOption?.type === 'designReport' &&
                  chartOptionToDisplay?.dataToDisplay === 'LICENSE_EFFECTIVE' &&
                  dataListToShow?.content?.map((row) => (
                    <>
                      <ThemeMode></ThemeMode>
                      <TableRow key={row.label}>
                        {tableHeaders?.map((columnHeader) => (
                          <TableCell key={row[columnHeader]}>
                            <DashboardTypography
                              typographyClass={'title-14-500'}
                              id='table-cell-data'
                            >
                              {getCellContent(row[columnHeader])}
                            </DashboardTypography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </>
                  ))}
                {dataListToShow?.content?.length > 0 &&
                  (chartOption?.type !== 'designReport' ||
                    (chartOption?.type === 'designReport' &&
                      chartOptionToDisplay?.dataToDisplay !== 'LICENSE_EFFECTIVE')) &&
                  dataListToShow?.content?.map((row) => (
                    <>
                      <ThemeMode></ThemeMode>
                      <TableRow key={row.label}>
                        <TableCell component='th' scope='row'>
                          <DashboardTypography
                            typographyClass={'title-14-500'}
                            id='table-data-format-date'
                          >
                            {chartOption?.shape === 'line' ? formatDate(row.label) : row.label}
                          </DashboardTypography>
                        </TableCell>
                        <TableCell>
                          <DashboardTypography
                            typographyClass={'title-14-500'}
                            id='table-data-to-display'
                          >
                            {formatYAxisLabel(row.data ? row.data : '-', chartOptionToDisplay)}
                          </DashboardTypography>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                {dataListToShow?.content?.length === 0 && (
                  <TableCell colSpan={2}>
                    <DashboardTypography typographyClass={'title-16-500'}>
                      <div className='no-data-list'> {t('NO_DATA_FOUND')}</div>
                    </DashboardTypography>
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions id='pagination-component'>
          {tableContent?.length > 0 && dataListToShow && (
            <div data-testid='paginationView' className='view-as-table-pagination'>
              <ThemeMode></ThemeMode>
              <PaginationComponent
                data={dataListToShow}
                pageSize={pageSize}
                handlePageSizeChange={handlePageSizeChange}
                getStartingDataNumber={getStartingDataNumber}
                getEndingDataNumber={getEndingDataNumber}
                currentPageNumber={currentPageNumber}
                handlePageChange={handlePageChange}
              ></PaginationComponent>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewAsTableDialog;
