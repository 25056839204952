import React from 'react';
import './WidgetConfigHeaderComponent.scss';
import PropTypes from 'prop-types';
import { Grid, Tooltip } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DashboardButton from '../../shared/Buttons/DashboardButton';
import { useTranslation } from 'react-i18next';
import DashboardTypography from '../DashboardTypography/DashboardTypography';
import ThemeMode from '../../themeMode/themeMode';

const WidgetConfigHeaderComponent = (props) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={2}
      className='widget-config-header-component'
      data-testid='widget-config-header'
      id='widget-config-header'
    >
      <Grid item xs={6} md={6} sm={6} lg={6} xl={6} className='add-edit-new-chart'>
        <Tooltip title={t('BACK')} arrow sx={{ display: 'inline-block' }}>
          <ThemeMode />
          <ArrowBackIosIcon
            sx={{
              fontSize: '20px',
              display: 'inline-block',
              cursor: 'pointer'
            }}
            data-testid='cancel-chart-configuration'
            onClick={props.onClose}
            id='back-arrow-icon'
          />
        </Tooltip>
        <DashboardTypography testId='dialog-config-title' id='chart-config-header-title' typographyClass={'title-24-700'}>{props.headerTitle}</DashboardTypography>
      </Grid>
      <Grid item xs={6} md={6} sm={6} lg={6} xl={6} className='add-edit-new-chart-action-buttons'>
        {props.isExistFullScreen && <DashboardButton color='secondary' dataTestid='pmv-exit-fullscreen-btn' eventHandler={props.onClose}>
          {t('PROCESS_MODEL.EXIT_FULLSCREEN')}
        </DashboardButton>}

        {!props.isExistFullScreen && <DashboardButton color='secondary' dataTestid='cancel-btn' eventHandler={props.onClose}>
          {t('DISCARD')}
        </DashboardButton>}

        {!props.isExistFullScreen && <DashboardButton
          color='primary'
          dataTestid='save-widget'
          startIcon={<SaveOutlinedIcon />}
          eventHandler={props.saveChartConfig}
        >
          {t('SAVE')}
        </DashboardButton>}
      </Grid>
    </Grid>
  );
};

WidgetConfigHeaderComponent.propTypes = {
  headerTitle: PropTypes.string,
  onClose: PropTypes.func,
  saveChartConfig: PropTypes.func,
  isExistFullScreen: PropTypes.bool
};

export default WidgetConfigHeaderComponent;
