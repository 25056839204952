const getChartOptionObject = (shape, type, dataToDisplay, xAxisType = '', yAxisType = '') => {
  return {
    chartId: '',
    title: '',
    description: '',
    shape,
    type,
    dataToDisplay,
    nameColor: '',
    descriptionColor: '',
    xAxisType,
    yAxisType,
    xAxisTitle: '',
    yAxisTitle: '',
    frequency: '',
    statistic: '',
    distribution: '',
    chartData: [],
    filters: [],
    customFieldId: '',
    aggregation: {},
    limit: '',
    scope: '',
    metricFormat: '',
    projectIds: [],
    metric: '',
    otherMetricId: '',
    orientation: '',
    legend: {},
    imageData: '',
    imageName: '',
    bound: '',
    bubbleType: '',
    bubbleMetricId: '',
    yAxisMetricId: '',
    bubbleStatistic: '',
    yAxisStatistic: '',
    bubbleMetricsUnit: '',
    yAxisMetricsUnit: '',
    dimensionId: ''
  };
};

export default getChartOptionObject;
