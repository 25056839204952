import React from 'react';
import './BackgroundGrid.scss';
import { useSelector } from 'react-redux';

const BackgroundGrid = () => {
  const totalColumns = 12;
  const leftSectionContainerWidthForGrid = useSelector(state => state.dashboard.leftSectionContainerWidthForGrid);
  return (
    <div className='background-grid'
      style={{ width: leftSectionContainerWidthForGrid - 13 }}>
      {Array.from({ length: totalColumns }).map((_, index) => (
        <div
          key={index}
          className='background-grid-column'
          data-testid='background-grid-column'
        />
      ))}
    </div>
  );
};

export default BackgroundGrid;