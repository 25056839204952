import React from 'react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import './WidgetCard.scss';
import './WidgetActionMenu.scss';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { MenuItem, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSelector, useDispatch } from 'react-redux';
import TableChartIcon from '@mui/icons-material/TableChart';
import { setViewAsTableOpenDialog } from '../../../../../Redux/slices/viewAsTableDialog';

function WidgetActionMenu(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isCurrentDashboardUpdated = useSelector(
    (state) => state.allDashboard.isCurrentDashboardUpdated
  );

  return (
    <>
      <Tooltip
        title={isCurrentDashboardUpdated ? t('WIDGET.DUPLICATE_WIDGET_MENU_DISABLED') : ''}
        arrow
        placement='top'
      >
        <span>
          <MenuItem
            onClick={(event) => {
              event.preventDefault();
              props.createDuplicateWidget(props.chartId);
            }}
            data-testid='chart-duplicate-menu'
            className='edit-delete-menu'
            disabled={isCurrentDashboardUpdated ? true : false}
          >
            <ContentCopyIcon className='edit-delete-menu-icon'/>
            {t('WIDGET.DUPLICATE_WIDGET')}    
            </MenuItem>
        </span>
      </Tooltip>
      <MenuItem
        onClick={(event) => {
          event.preventDefault();
          props.handleDeleteChart(props.chartId);
        }}
        data-testid='chart-delete-menu'
        className='edit-delete-menu'
      >
        <DeleteOutlinedIcon className='action-icons' />
       
        {t('DELETE_Widget')}  
          </MenuItem>
      {(props.chartType === 'bar' || props.chartType === 'line' || props.chartType === 'pie') && (
        <MenuItem
          onClick={() => {
            props.closeWidgetMenu();
            dispatch(setViewAsTableOpenDialog(true));
            props.setChartData(props.chartId);
          }}
          data-testid='chart-delete-menu'
          className='edit-delete-menu'
        >
          <TableChartIcon className='action-icons' />
          {t('VIEW_AS_TABLE')}
        </MenuItem>
      )}
    </>
  );
}

WidgetActionMenu.propTypes = {
  chartId: PropTypes.string,
  handleDeleteChart: PropTypes.func,
  handleClickOpen: PropTypes.func,
  createDuplicateWidget: PropTypes.func,
  setChartData: PropTypes.func,
  chartType: PropTypes.string,
  closeWidgetMenu: PropTypes.func
};

export default WidgetActionMenu;
