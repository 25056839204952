import React, { useState } from 'react';
import dashboardStyles from '../../../styles/dashboardStyles';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatPaintOutlinedIcon from '@mui/icons-material/FormatPaintOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

const MiddleSection = ({ onSelectComponent }) => {
  const { t } = useTranslation();
  const [selectedComponent, setSelectedComponent] = useState(t('WIDGETCOMPONENTS'));

  const ontoggleSelect = (componentName) => {
    setSelectedComponent(componentName);
  };

  const isComponentSelected = (componentName) => {
    return selectedComponent === componentName;
  };

  return (
    <div className='middleSection-container' data-testid='middle-section' id='middle-section-actions'>
      <div className='icon-container'>
        <Tooltip title={t('DASHBOARDS')} arrow>
          <LayersOutlinedIcon
            style={{
              ...dashboardStyles.middleSectionIcons,
              ...(isComponentSelected(t('DASHBOARDS'))
                ? dashboardStyles.middleSectionIconsActive
                : {})
            }}
            data-testid='dashboard-icon'
            onClick={() => {
              onSelectComponent(t('DASHBOARDS'));
              ontoggleSelect(t('DASHBOARDS'));
            }}
          />
        </Tooltip>
      </div>

      <div className='icon-container'>
        <Tooltip title={t('WIDGETCOMPONENTS')} arrow>
          <DashboardOutlinedIcon
            style={{
              ...dashboardStyles.middleSectionIcons,
              ...(isComponentSelected(t('WIDGETCOMPONENTS'))
                ? dashboardStyles.middleSectionIconsActive
                : {})
            }}
            data-testid='toc-icon'
            onClick={() => {
              onSelectComponent(t('WIDGETCOMPONENTS'));
              ontoggleSelect(t('WIDGETCOMPONENTS'));
            }}
          />
        </Tooltip>
      </div>

      <div className='icon-container'>
        <Tooltip title={t('THEMESECTION')} arrow>
          <FormatPaintOutlinedIcon
            style={{
              ...dashboardStyles.middleSectionIcons,
              ...(isComponentSelected(t('THEMESECTION'))
                ? dashboardStyles.middleSectionIconsActive
                : {})
            }}
            data-testid='hammer-icon'
            onClick={() => {
              onSelectComponent(t('THEMESECTION'));
              ontoggleSelect(t('THEMESECTION'));
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

MiddleSection.propTypes = {
  onSelectComponent: PropTypes.func
};

export default MiddleSection;
