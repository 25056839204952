import React from 'react';
import PropTypes from 'prop-types';
import { Responsive, WidthProvider } from 'react-grid-layout';
import BackgroundGrid from './BackgroundGrid/BackgroundGrid.js';
import { useSelector } from 'react-redux';
const ResponsiveGridLayout = WidthProvider(Responsive);
import './WidgetGridLayout.scss';

const WidgetGridLayout = (props) => {
  const { isResizing, handleLayoutChange, setIsResizing, renderGrid, layout } = props;
  const { isWidgetConfigOn, isDashboardEditMode, leftSectionWidth } = useSelector(state => state.dashboard);
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: isResizing ? 'white' : '#F9F9FC'
      }}
      id='widget-grid-layout'
    >
      {isResizing ? <BackgroundGrid /> : null}
      <div className='responsive-grid-layout'>
        <ResponsiveGridLayout
          layouts={{ lg: layout }}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 1 }}
          rowHeight={50}
          onLayoutChange={isDashboardEditMode ? handleLayoutChange : undefined}
          isResizable={isDashboardEditMode && !isWidgetConfigOn}
          isDraggable={isDashboardEditMode && !isWidgetConfigOn}
          onResizeStart={() => setIsResizing(true)}
          onResizeStop={() => setIsResizing(false)}
          width={leftSectionWidth}
          key={leftSectionWidth}
        >
          {renderGrid()}
        </ResponsiveGridLayout>
      </div>

    </div>
  );
};

WidgetGridLayout.propTypes = {
  isResizing: PropTypes.bool,
  handleLayoutChange: PropTypes.func,
  setIsResizing: PropTypes.func,
  renderGrid: PropTypes.func,
  layout: PropTypes.array,
};

export default WidgetGridLayout;