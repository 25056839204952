import { createSlice } from '@reduxjs/toolkit';

const initialDialogeState = {
  isOpen: false,
  chartOption: {}
};

const viewAsTableSlice = createSlice({
  name: 'viewAsTableDialog',
  initialState: initialDialogeState,
  reducers: {
    setViewAsTableOpenDialog: (state, action) => {
      state.isOpen = action.payload;
    },

    setChartDada: (state, action) => {
      state.chartOption = action.payload;
    },
  }
});

export const {
  setViewAsTableOpenDialog,
  setChartDada
} = viewAsTableSlice.actions;
export default viewAsTableSlice.reducer;
