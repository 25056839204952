import React from 'react';
import PropTypes from 'prop-types';
import './TableWidget.scss';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import TablePaginationActions from './TablePaginationActions';
import DashboardTypography from '../../shared/DashboardTypography/DashboardTypography';

const TableWidget = (props) => {
  const { t } = useTranslation();
  const { tableHeaders, tableData } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCellContent = (cellValue) => {
    if (typeof cellValue === 'boolean') {
      return cellValue ? 'TRUE' : 'FALSE';
    } else {
      return cellValue;
    }
  };

  return (
    <div className='table-panel-container'>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders?.length > 0 &&
                tableHeaders?.map((columnHeader) => (
                  <TableCell key={columnHeader}>{t('TABLE_HEADERS.' + columnHeader)}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.length > 0 && ((rowsPerPage > 0)
              ? tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : tableData
            )?.map((row) => (
              <TableRow key={row.id}>
                {tableHeaders?.map((columnHeader) => (
                  <TableCell key={row[columnHeader]}>{getCellContent(row[columnHeader])}</TableCell>
                ))}
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
            {tableData?.length < 1 && (
              <TableRow>
                <TableCell colSpan={tableHeaders.length} className='data-cell'>
                  <div className='data-not-found-cell'>
                    <DashboardTypography
                      id='data-not-found-msg'
                      typographyClass='title-20-400 data-not-found-msg'
                    >
                      {t('TABLE.DATA_NOT_FOUND')}
                    </DashboardTypography>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {tableData?.length > 0 && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      inputProps: {
                        'aria-label': t('TABLE.ROWS_PER_PAGE')
                      },
                      native: true
                    }
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

TableWidget.propTypes = {
  tableHeaders: PropTypes.array,
  tableData: PropTypes.array
};

export default TableWidget;
