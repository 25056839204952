export const getScope = (tabIndex) => {
  switch (tabIndex) {
    case 0:
      return 'TASKS';
    case 1:
      return 'CASE';
    case 2:
      return 'LINKS';
  }
};

export const checkIsValid = (itemIds, newIsValid, isCaseScope) => {
  return (newIsValid && (isCaseScope || (newIsValid && itemIds?.length > 0))) ? true : false;
};