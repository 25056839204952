import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/LoadingSpinner.scss';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../../styles/DashboardLayout.css';
import { capitalize } from '../../utils/utils.js';
import { METRICS, ORIENTATION, SHOW_MODEL } from '../../utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetProcessModelConfigValidationInfo,
  setProcessModelConfigValidationInfo
} from '../../Redux/slices/processModelCofigSlice.js';
import configVariables from '../../utils/ConfigurationVariables.js';
import ThemeMode from '../themeMode/themeMode.js';

const ProcessModelConfigPanel = (props) => {
  const { chartOptions, setChartOptions, selectedDataSourceList } = props;
  const { t } = useTranslation();
  const [statistics, setStatistics] = useState([]);
  const metricAndStatistics = useSelector((state) => state.metricAndStatistics.metricAndStatistics);
  const [selectedConformanceModel, setSelectedConformanceModel] = useState({});
  const [selectedBusinessRule, setSelectedBusinessRule] = useState({});
  const [businessRules, setBusinessRules] = useState([]);
  const [conformanceModels, setConformanceModels] = useState([]);
  const [selectedStatistics, setSelectedStatistics] = useState({});
  const [selectedMetrics, setSelectedMetrics] = useState({});
  const validationInfo = useSelector(
    (state) => state.processModelConfig.processModelConfigValidationInfo
  );

  const dispatch = useDispatch();

  const [titleLength, setTitleLength] = useState(0);
  const updateChartOptions = (key, val) => {
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      [key]: val
    }));
    updateTitleLength(props.chartOptions?.title?.length);
  };

  const updateDataSource = (key, val) => {
    updateChartOptions(key, val);
  };

  useEffect(() => {
    if (selectedDataSourceList?.length === 1) {
      updateDataSource('projectIds', [selectedDataSourceList[0]?.projectId]);
    }
    updateTitleLength(props.chartOptions?.title?.length);
  }, []);

  useEffect(() => {
    if (
      chartOptions.projectIds &&
      chartOptions.projectIds.length > 0 &&
      metricAndStatistics['statistics']?.length
    ) {
      fetchStatisticData();
    }
  }, [chartOptions.projectIds, metricAndStatistics.statistics]);

  const fetchStatisticData = () => {
    try {
      if (metricAndStatistics.metrics && metricAndStatistics.metrics.length > 0) {
        metricAndStatistics?.businessRule
          ? setBusinessRules(metricAndStatistics.businessRule)
          : setBusinessRules([]);
        const selectedMetric =
          chartOptions.metric === ''
            ? metricAndStatistics.metrics[0]
            : getCompleteMetricsObject(metricAndStatistics.metrics);
        updateMetric(selectedMetric, false);
        setStatisticsBasedOnMetrics(selectedMetric, false, metricAndStatistics);
      }
      props.isLoading(false);
    } catch (error) {
      props.isLoading(false);
    }
  };

  const setStatisticsBasedOnMetrics = (
    metric,
    isMetricSelectionChanged,
    allMetricAndStatistics
  ) => {
    if (metric?.id === 'compliance') {
      setStatistics(allMetricAndStatistics?.ruleComplianceStatistics);
      setBusinessRules(allMetricAndStatistics?.businessRule);
      const selectedStatistic =
        chartOptions.statistic === '' || isMetricSelectionChanged
          ? allMetricAndStatistics?.ruleComplianceStatistics[0]
          : getCompleteStatisticsObject(
            metric?.id,
            allMetricAndStatistics?.ruleComplianceStatistics
          );
      updateSelectedStatistics(selectedStatistic);
      const selectedRule =
        chartOptions.ruleId === '' || isMetricSelectionChanged
          ? allMetricAndStatistics?.businessRule[0]
          : allMetricAndStatistics?.businessRule.find((rule) => rule.id === chartOptions.ruleId);
      updateBusinessRule(selectedRule);
    } else if (metric?.id === 'conformance') {
      setStatistics(allMetricAndStatistics?.conformanceStatistics);
      setConformanceModels(allMetricAndStatistics?.validModels);
      const selectedStatistic =
        chartOptions.statistic === '' || isMetricSelectionChanged
          ? allMetricAndStatistics?.conformanceStatistics[0]
          : getCompleteStatisticsObject(metric?.id, allMetricAndStatistics?.conformanceStatistics);
      updateSelectedStatistics(selectedStatistic);
      const selectedModel =
        chartOptions.modelId === '' || isMetricSelectionChanged
          ? allMetricAndStatistics?.validModels[0]
          : allMetricAndStatistics?.validModels.find((model) => model.id === chartOptions.modelId);
      updateConformanceModel(selectedModel);
      updateChartOptions('showModel', isMetricSelectionChanged ? false : chartOptions.showModel);
    } else {
      setStatistics(allMetricAndStatistics?.statistics);
      const selectedStatistic =
        chartOptions.statistic === '' || isMetricSelectionChanged
          ? allMetricAndStatistics?.statistics[0]
          : getCompleteStatisticsObject(metric?.id, allMetricAndStatistics?.statistics);
      updateSelectedStatistics(selectedStatistic);
    }
  };

  const updateMetric = (metric, isMetricSelectionChanged) => {
    setSelectedMetrics(metric);
    if (METRICS.includes(metric.id)) {
      updateChartOptions('metric', metric.id);
      updateChartOptions('otherMetricId', '');
    } else {
      updateChartOptions('metric', 'metric');
      updateChartOptions('otherMetricId', metric.id);
    }
    if (metricAndStatistics?.statistics?.length) {
      setStatisticsBasedOnMetrics(metric, isMetricSelectionChanged, metricAndStatistics);
    }
  };

  const updateSelectedStatistics = (updatedStatistics) => {
    setSelectedStatistics(updatedStatistics);
    updateChartOptions('selectedDefaultStatistics', updatedStatistics);
    updateChartOptions('statistic', updatedStatistics?.name);
    updateChartOptions('isOkConformance', updatedStatistics?.id?.includes('non-') ? false : true);
  };

  const updateBusinessRule = (selectedRule) => {
    setSelectedBusinessRule(selectedRule);
    updateChartOptions('selectedDefaultBusinessRule', selectedRule);
    updateChartOptions('ruleId', selectedRule?.id);
  };

  const updateConformanceModel = (selectedModel) => {
    setSelectedConformanceModel(selectedModel);
    updateChartOptions('selectedDefaultConformanceModel', selectedModel);
    updateChartOptions('modelId', selectedModel?.id);
  };

  const getCompleteMetricsObject = (allMetrics) => {
    let item = null;
    if (allMetrics.length > 0 && chartOptions.metric === 'metric') {
      item = allMetrics.find((v) => v.id === chartOptions.otherMetricId);
    } else {
      item = allMetrics.find((v) => v.id === chartOptions.metric);
    }
    return item;
  };

  const getCompleteStatisticsObject = (selectedMetric, allStatistics) => {
    let item = null;
    if (selectedMetric === 'compliance' || selectedMetric === 'conformance') {
      const allMatchingStatistics = allStatistics.filter((v) => v.name === chartOptions.statistic);
      item = allMatchingStatistics.find((statistic) =>
        chartOptions.isOkConformance
          ? !statistic?.id?.includes('non-')
          : statistic?.id?.includes('non-')
      );
    } else {
      item = allStatistics.find((statistic) => statistic.name === chartOptions.statistic);
    }
    return item;
  };

  const updateTitleLength = (characterLength) => {
    setTitleLength(characterLength);
  };

  return (
    <div className='chartProperties-grid'>
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 700,
          color: '#667085',
          position: 'relative',
          top: '-5px'
        }}
      >
        {t('PROCESS_MODEL.PROCESS_MODEL_VIEW')}
      </Typography>
      <Box
        component='form'
        sx={{
          '& .MuiFormControl-root': { marginTop: 1.5, marginBottom: 1.5, width: '100%' }
        }}
        noValidate
        autoComplete='off'
      >
        <div className='addEditWdiget-container'>
          <FormControl fullWidth size='small' error={validationInfo?.dataSourceError}>
            <InputLabel required id='data-to-display-select-label'>
              {t('DATA_SOURCE')}
            </InputLabel>
            <Select
              label='Data Source'
              labelId='data-source-label'
              id='data-source-label'
              key='data-source'
              data-testid='data-source-select'
              value={chartOptions.projectIds}
              onChange={(event) => {
                updateDataSource('projectIds', [event.target.value]);
                dispatch(resetProcessModelConfigValidationInfo());
              }}
            >
              <ThemeMode></ThemeMode>
              {selectedDataSourceList && selectedDataSourceList.length > 0 ? (
                selectedDataSourceList.map((project) => (
                  <MenuItem key={project.projectId} value={project.projectId}>
                    {project.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value='no_data' disabled>
                  {t('APP.EMPTY_DATA_SOURCE_MESSAGE')}
                </MenuItem>
              )}
            </Select>
            {validationInfo?.dataSourceError && (
              <FormHelperText>{t('DATA_SOURCE_ERROR_MESSAGE')}</FormHelperText>
            )}
          </FormControl>

          {chartOptions && chartOptions?.projectIds?.length > 0 ? (
            <>
              <TextField
                required
                key='title'
                error={validationInfo?.chartNameError}
                helperText={validationInfo?.chartNameError ? t('CHART_NAME_ERROR_MESSAGE') : null}
                id='chart-label'
                label={t('CHART_NAME')}
                placeholder={t('ENTER')}
                size='small'
                style={{
                  width: '100%'
                }}
                data-testid='chart-name'
                className='add-textTitle'
                value={chartOptions.title}
                inputProps={{
                  maxLength: configVariables?.default?.maxtitleAllowedChar
                }}
                onChange={(event) => {
                  var regex = /^$|^\S+.*/;
                  const labelCheck = regex.test(event.target.value);
                  if (labelCheck) {
                    updateChartOptions(
                      'title',
                      event.target.value.slice(0, configVariables.default.maxtitleAllowedChar)
                    );
                    updateTitleLength(event.target.value.length);
                    dispatch(
                      setProcessModelConfigValidationInfo({
                        ...validationInfo,
                        chartNameError: event ? false : true
                      })
                    );
                  }
                }}
              />
              <div className='character-section MuiFormHelperText-root'>
                {t('MAX_CHARACTERS_MESSAGE', {
                  characterLength: titleLength,
                  maxAllowdedCharLength: configVariables?.default.maxtitleAllowedChar
                })}
              </div>
              <FormControl fullWidth size='small' error={validationInfo?.metricsError}>
                <InputLabel required id='metric-select-label'>
                  {t('PROCESS_MODEL.DEFAULT_METRIC')}
                </InputLabel>
                <Select
                  labelId='metric-select-label'
                  id='metric-select'
                  value={selectedMetrics}
                  label='Default Metrics'
                  data-testid='matrics select'
                  onChange={(event) => {
                    updateMetric(event.target.value, true);
                  }}
                >
                  <ThemeMode></ThemeMode>
                  {metricAndStatistics?.metrics && metricAndStatistics?.metrics.length > 0 ? (
                    metricAndStatistics.metrics.map((option) => (
                      <MenuItem key={option.id} value={option}>
                        {capitalize(option.name)}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value='no_data' disabled>
                      {t('APP.EMPTY_METRICS_MESSAGE')}
                    </MenuItem>
                  )}
                </Select>
                {validationInfo?.metricsError && (
                  <FormHelperText>{t('PROCESS_MODEL.METRICS_ERROR_MESSAGE')}</FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth size='small' error={validationInfo?.statisticsError}>
                <InputLabel required id='statistic-select-label'>
                  {t('PROCESS_MODEL.DEFAULT_STATISTICS')}
                </InputLabel>
                <Select
                  labelId='statistic-select-label'
                  id='statistic-select'
                  value={selectedStatistics}
                  label='Default Statistic'
                  data-testid='statistic-select'
                  onChange={(event) => {
                    updateSelectedStatistics(event.target.value);
                  }}
                >
                  <ThemeMode></ThemeMode>
                  {statistics && statistics.length > 0 ? (
                    statistics.map((option) => (
                      <MenuItem key={option.name} value={option}>
                        {option.id}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value='no_data' disabled>
                      {t('APP.EMPTY_STATISTIC_MESSAGE')}
                    </MenuItem>
                  )}
                </Select>
                {validationInfo?.statisticsError && (
                  <FormHelperText>{t('PROCESS_MODEL.STATISTICS_ERROR_MESSAGE')}</FormHelperText>
                )}
              </FormControl>

              {chartOptions.metric === 'conformance' && (
                <>
                  <FormControl
                    fullWidth
                    size='small'
                    error={validationInfo?.modelForConformanceError}
                  >
                    <InputLabel required id='conformance-model-select-label'>
                      {t('PROCESS_MODEL.MODEL_FOR_CONFORMANCE')}
                    </InputLabel>
                    <Select
                      labelId='model-for-conformance-select-label'
                      id='model-for-conformance-select'
                      data-testid='model-for-conformance-select'
                      label='Model for conformance'
                      value={selectedConformanceModel}
                      onChange={(event) => {
                        updateConformanceModel(event.target.value);
                      }}
                    >
                      <ThemeMode></ThemeMode>
                      {conformanceModels && conformanceModels.length > 0 ? (
                        conformanceModels.map((option) => (
                          <MenuItem key={option.id} value={option}>
                            {option.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value='no_data' disabled>
                          {t('PROCESS_MODEL.EMPTY_CONFORMANCE_MESSAGE')}
                        </MenuItem>
                      )}
                    </Select>
                    {validationInfo?.modelForConformanceError && (
                      <FormHelperText>
                        {t('PROCESS_MODEL.MODEL_FOR_CONFORMANCE_ERROR_MESSAGE')}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl fullWidth size='small'>
                    <InputLabel required id='show-model-select-label'>
                      {t('PROCESS_MODEL.SHOW_MODEL')}
                    </InputLabel>
                    <Select
                      labelId='show-model-select-label'
                      id='show-model-select'
                      data-testid='show-model-select'
                      label='Show model'
                      value={chartOptions.showModel}
                      onChange={(event) => {
                        updateChartOptions('showModel', event.target.value);
                      }}
                    >
                      <ThemeMode></ThemeMode>
                      {SHOW_MODEL &&
                        SHOW_MODEL.map((option) => (
                          <MenuItem key={option.key} value={option.key}>
                            {option.value}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </>
              )}

              {chartOptions.metric === 'compliance' && metricAndStatistics && (
                <FormControl fullWidth size='small' error={validationInfo?.businessRuleError}>
                  <InputLabel required id='business-rule-select-label'>
                    {t('FILTER.BUSINESSRULE')}
                  </InputLabel>
                  <Select
                    labelId='business-rule-select-label'
                    id='business-rule-select'
                    value={selectedBusinessRule}
                    label='Business rule'
                    onChange={(event) => {
                      updateBusinessRule(event.target.value);
                    }}
                  >
                    <ThemeMode></ThemeMode>
                    {businessRules && businessRules?.length > 0 ? (
                      businessRules.map((option) => (
                        <MenuItem key={option.id} value={option}>
                          {option.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value='no_data' disabled>
                        {t('PROCESS_MODEL.EMPTY_BUSINESS_RULE_MESSAGE')}
                      </MenuItem>
                    )}
                  </Select>
                  {validationInfo?.businessRuleError && (
                    <FormHelperText>
                      {t('PROCESS_MODEL.BUSINESS_RULE_ERROR_MESSAGE')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}

              <FormControl fullWidth size='small'>
                <InputLabel required id='orientation-select-label'>
                  {t('PROCESS_MODEL.ORIENTATION')}
                </InputLabel>
                <Select
                  labelId='orientation-select-label'
                  id='orientation-select'
                  value={chartOptions.orientation}
                  label='Orientation'
                  onChange={(event) => {
                    updateChartOptions('orientation', event.target.value);
                  }}
                >
                  <ThemeMode></ThemeMode>
                  {ORIENTATION &&
                    ORIENTATION.map((orientationMode) => (
                      <MenuItem key={orientationMode.key} value={orientationMode.key}>
                        {orientationMode.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </>
          ) : null}
        </div>
      </Box>
    </div>
  );
};

ProcessModelConfigPanel.propTypes = {
  chartOptions: PropTypes.object,
  appId: PropTypes.string,
  setChartOptions: PropTypes.func,
  selectedDataSourceList: PropTypes.array,
  workgroupId: PropTypes.string,
  isLoading: PropTypes.func
};

export default ProcessModelConfigPanel;
