import { svgData } from './svgMockData.js';

export const mockRequestSaveDashboard = {
  name: '',
  charts: [
    {
      chartId: 'chart-a49e5c73-7171-4617-9864-969aebc33a69',
      title: '',
      description: '',
      shape: 'bar',
      type: '',
      dataToDisplay: '',
      xAxisType: 'category',
      yAxisType: 'value',
      xAxisTitle: '',
      yAxisTitle: '',
      frequency: '',
      statistic: '',
      distribution: ''
    }
  ],
  layout: {
    rows: 1,
    columns: 12,
    layoutType: 'GridLayout',
    layouts: [
      {
        chartId: 'chart-a49e5c73-7171-4617-9864-969aebc33a69',
        i: 'layout-fa811676-c795-4e13-8045-dc522cfe070a',
        x: 0,
        y: 0,
        w: 4,
        h: 5.3,
        minW: 4,
        minH: 5.3,
        isNewLayout: true,
        moved: false,
        static: false
      }
    ]
  },
  isPublished: false,
  isPublic: false,
  themeId: 'themeID',
  globalThemeId: 'globalThemeId'
};

export const widgetsData = [
  {
    i: 'chart-1',
    x: 0,
    y: 0,
    w: 4,
    h: 5.3,
    minW: 4,
    minH: 5.3,
    chartType: '',
    isNewLayout: true,
    chartData: [],
    chartOptions: {
      chartLabel: 'Chart Label',
      chartType: 'bar',
      xAxisType: 'category',
      yAxisType: 'value'
    }
  }
];

export const lineChartOptionObj = {
  shape: 'line',
  xAxisType: 'category',
  yAxisType: 'value'
};
export const barChartOptionObj = {
  shape: 'bar',
  xAxisType: 'category',
  yAxisType: 'value'
};
export const pieChartOptionObj = {
  shape: 'pie',
  xAxisType: 'category',
  yAxisType: 'value'
};
export const undefinedChartOptionObj = {
  shape: 'undefined',
  xAxisType: 'category',
  yAxisType: 'value'
};
export const chartData = [
  { label: 'Label 1', data: 10 },
  { label: 'Label 2', data: 20 }
];
export const longLabelData = [
  { label: 'Very Long Label 1', data: 10 },
  { label: 'Very Long Label 2', data: 20 },
  { label: 'Very Long Label 3', data: 30 }
];

export const lineOrBarChartOptions = {
  xAxis: {
    type: 'category',
    data: ['Label 1', 'Label 2']
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      type: 'line',
      data: [10, 20]
    }
  ]
};
export const themeColors = [
  { mainColor: '#041AB9' },
  { mainColor: '#3648C7' },
  { mainColor: '#6876D5' },
  { mainColor: '#9BA3E3' },
  { mainColor: '#CDD1F1' }
];
export const mockWorkgroupId = 'a216d7c6-98b8-4b5c-b10e-9918999d9e2a';
export const mockAppId = 'd3097b35-1a88-4f9e-9ff7-6cc62af5f1de';
export const mockThemeId = '663ebc92-a790-4eae-8828-e6f687bcd9f8';
export const mockDashboardId = '763a97d1-4618-44c8-b577-913f0904a9c4';
export const mockProjectId = 'a216d7c6-98b8-4b5c-b10e-9918999d9e2a';
export const mockGlobalFilterId = '6bd2d572-3ab5-4ff1-841c-51ac4137f6fe';
export const mockDimensionId = 'eb5c1afc-71ee-4036-b818-6b1034698df2';
export const pieChartOptions = {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    right: 'right'
  },
  series: [
    {
      type: 'pie',
      data: [
        { value: 10, name: 'Label 1' },
        { value: 20, name: 'Label 2' }
      ],
      color: themeColors,
      label: {
        normal: {}
      },
      radius: [0, '50%'],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};

export const demoDataForDashboardHeader = {
  id: 'fa718dcc-8dfb-4353-8845-163325af375f',
  name: 'Demo App',
  description: 'Test',
  isPublic: true,
  theme: {
    id: '4b37ba59-4a82-45c6-a9d2-49cdfe5b6ddc',
    name: 'Rainbow',
    description: 'Rainbow theme',
    isDefault: true,
    colourList: []
  },
  connection: {
    type: 'LOGPICKR',
    projectId: 'debf82ca-8457-4230-a2a3-2337bffc387f',
    workgroupId: 'a216d7c6-98b8-4b5c-b10e-9918999d9e2a'
  },
  workgroupId: 'a216d7c6-98b8-4b5c-b10e-9918999d9e2a',
  workgroupName: 'App Builder Demo Workgroup'
};

export const DATA_SETS = {
  dashboardFields: [
    {
      fieldName: 'totalCaseCount',
      label: 'Total number of cases',
      shapes: [
        {
          label: 'Metric',
          widgetType: 'metric',
          pmKey: 'totalCaseCountNumeric'
        }
      ]
    },
    {
      fieldName: 'caseCountInTime',
      label: 'Number of new cases over time',
      shapes: [
        {
          label: 'Bar Chart',
          widgetType: 'barChart',
          pmKey: 'newCasesInTimeBarchart'
        },
        {
          label: 'Metric',
          widgetType: 'metric',
          pmKey: 'newCasesInTimeNumeric'
        },
        {
          label: 'Line Chart',
          widgetType: 'lineChart',
          pmKey: 'newCasesInTimeLine'
        },
        {
          label: 'Pie Chart',
          widgetType: 'pieChart',
          pmKey: 'newCasesInTimePiechart'
        }
      ]
    },
    {
      fieldName: 'totalEventsCount',
      label: 'Total number of events',
      shapes: [
        {
          label: 'Metric',
          widgetType: 'metric',
          pmKey: 'totalEventCountNumeric'
        }
      ]
    },
    {
      fieldName: 'newEventsInTimeNumeric',
      label: 'Number of new events over time',
      shapes: [
        {
          label: 'Metric',
          widgetType: 'metric',
          pmKey: 'newEventsInTimeNumeric'
        }
      ]
    },
    {
      fieldName: 'caseDuration',
      label: 'Duration of cases',
      shapes: [
        {
          label: 'Metric',
          widgetType: 'metric',
          pmKey: 'caseDurationNumeric'
        },
        {
          label: 'Bar Chart',
          widgetType: 'barChart',
          pmKey: 'caseDurationBarchart'
        },
        {
          label: 'Line Chart',
          widgetType: 'lineChart',
          pmKey: 'caseDurationLine'
        }
      ]
    },
    {
      fieldName: 'effectiveRateNumeric',
      label: 'Efficiency rate',
      shapes: [
        {
          label: 'Metric',
          widgetType: 'metric',
          pmKey: 'effectiveRateNumeric'
        }
      ]
    },
    {
      fieldName: 'variants',
      label: 'Variants',
      shapes: [
        {
          label: 'Bar Chart',
          widgetType: 'barChart',
          pmKey: 'variantsBarchart'
        },
        {
          label: 'Metric',
          widgetType: 'metric',
          pmKey: 'variantsNumeric'
        }
      ]
    },
    {
      fieldName: 'customText',
      label: 'Custom Text',
      shapes: [
        {
          label: 'Text',
          widgetType: 'text',
          pmKey: 'text'
        }
      ]
    }
  ],
  metrics: [
    {
      id: 'a0bc0b7a-9350-4ea0-81e2-eba603ac627a',
      name: 'cost',
      unit: '$',
      hasCaseAggregation: true,
      shapes: [
        {
          label: 'Metric',
          widgetType: 'metric',
          pmKey: 'metricNumeric',
          fieldName: 'cost'
        },
        {
          label: 'Bar chart',
          widgetType: 'barChart',
          pmKey: 'metricBarchart',
          fieldName: 'cost'
        },
        {
          label: 'Line chart',
          widgetType: 'lineChart',
          pmKey: 'metricLine',
          fieldName: 'cost'
        }
      ],
      caseScope: false
    }
  ],
  dimensions: [
    {
      id: 'bab0fb9c-fca9-4345-b7f8-dc6347f67b1f',
      name: 'role',
      hasCaseAggregation: true,
      shapes: [
        {
          label: 'Pie chart',
          widgetType: 'pieChart',
          pmKey: 'dimensionPie',
          fieldName: 'role'
        },
        {
          label: 'Bar chart',
          widgetType: 'barChart',
          pmKey: 'dimensionBarChart',
          fieldName: 'role'
        }
      ],
      caseScope: false
    },
    {
      id: '0ce20ba0-ef66-49e4-9858-f140416ceae5',
      name: 'ressource',
      hasCaseAggregation: true,
      shapes: [
        {
          label: 'Pie chart',
          widgetType: 'pieChart',
          pmKey: 'dimensionPie',
          fieldName: 'ressource'
        },
        {
          label: 'Bar chart',
          widgetType: 'barChart',
          pmKey: 'dimensionBarchart',
          fieldName: 'ressource'
        }
      ],
      caseScope: false
    }
  ]
};

export const AVAILABLE_OPTIONS_WITH_FREQUENCY = { frequency: ['day', 'week', 'month', 'year'] };

export const AVAILABLE_OPTIONS_WITH_DISTRIBUTION = { distribution: ['byHourOfDay', 'byDayOfWeek'] };

export const AVAILABLE_OPTIONS_WITH_FREQUENCY_AND_STATISTIC = {
  frequency: ['day', 'week', 'month', 'year'],
  statistic: ['avg', 'min', 'max', 'total']
};

export const AVAILABLE_OPTIONS_WITH_NUMERIC_AND_STATISTIC = {
  metricFormat: ['number'],
  statistic: ['total']
};

export const chartOptionsWithDistribution = {
  chartId: '',
  title: 'Chart Title',
  description: 'Chart Description',
  shape: 'bar',
  type: 'newCasesInTimeBarchart',
  dataToDisplay: 'caseCountInTime',
  xAxisType: 'category',
  yAxisType: 'value',
  xAxisTitle: '',
  yAxisTitle: '',
  frequency: '',
  statistic: '',
  distribution: 'byHourOfDay',
  chartData: [
    { label: '00', data: 2 },
    { label: '01', data: 8 },
    { label: '02', data: 5 },
    { label: '03', data: 1 }
  ],
  projectIds: ['40de743b-b0f9-4e76-bed8-4fafa5287f3d']
};

export const chartOptionsWithFrequency = {
  chartId: '',
  title: 'Chart Title',
  description: 'Chart Description',
  shape: 'line',
  type: 'newCasesInTimeLine',
  dataToDisplay: 'caseCountInTime',
  xAxisType: 'category',
  yAxisType: 'value',
  xAxisTitle: 'Time',
  yAxisTitle: 'Number of new cases',
  frequency: 'week',
  statistic: '',
  distribution: '',
  chartData: [
    {
      label: '2017-06-19T00:00:00.000Z',
      data: 1
    },
    {
      label: '2017-06-26T00:00:00.000Z',
      data: 3
    }
  ],
  projectIds: ['40de743b-b0f9-4e76-bed8-4fafa5287f3d']
};

export const chartOptionsWithFrequencyAndStatistic = {
  chartId: '',
  title: 'Chart Title',
  description: 'Chart Description',
  shape: 'line',
  type: 'caseDurationLine',
  dataToDisplay: 'caseDuration',
  xAxisType: 'category',
  yAxisType: 'value',
  xAxisTitle: '',
  yAxisTitle: '',
  frequency: 'week',
  statistic: 'max',
  distribution: '',
  chartData: [
    {
      label: '2017-06-19T00:00:00.000Z',
      data: 1
    },
    {
      label: '2017-06-26T00:00:00.000Z',
      data: 1
    }
  ],
  projectIds: ['40de743b-b0f9-4e76-bed8-4fafa5287f3d']
};

export const pieChartOptionsWithData = {
  chartId: '',
  title: 'Chart Title',
  description: 'Chart Description',
  shape: 'pie',
  type: 'newCasesInTimePiechart',
  dataToDisplay: 'caseCountInTime',
  xAxisType: 'category',
  yAxisType: 'value',
  xAxisTitle: '',
  yAxisTitle: '',
  frequency: '',
  statistic: '',
  distribution: 'byHourOfDay',
  chartData: [
    { value: 10, name: 'Label 1' },
    { value: 20, name: 'Label 2' }
  ],
  projectIds: ['40de743b-b0f9-4e76-bed8-4fafa5287f3d']
};

export const metricChartOptionsWithData = {
  chartId: 'chart-36326c65-bd20-4a26-8d32-bf844bf30da6',
  title: 'Metric Chart',
  description: 'Metric Chart description',
  shape: 'numeric',
  type: 'totalCaseCountNumeric',
  dataToDisplay: 'totalCaseCount',
  xAxisType: 'category',
  yAxisType: 'value',
  xAxisTitle: '',
  yAxisTitle: '',
  frequency: '',
  statistic: 'total',
  distribution: '',
  chartData: [608.2],
  metricFormat: 'number',
  projectIds: ['40de743b-b0f9-4e76-bed8-4fafa5287f3d']
};

export const metricChartOptionsWithPercentFormat = {
  chartId: 'chart-36326c65-bd20-4a26-8d32-bf844bf30da6',
  title: 'Metric Chart',
  description: 'Metric Chart description',
  shape: 'numeric',
  type: 'totalCaseCountNumeric',
  dataToDisplay: 'totalCaseCount',
  xAxisType: 'category',
  yAxisType: 'value',
  xAxisTitle: '',
  yAxisTitle: '',
  frequency: '',
  statistic: 'total',
  distribution: '',
  chartData: [608.2],
  metricFormat: 'percent',
  projectIds: ['40de743b-b0f9-4e76-bed8-4fafa5287f3d']
};

export const metricChartOptionsWithTimeFormat = {
  chartId: 'chart-36326c65-bd20-4a26-8d32-bf844bf30da6',
  title: 'Metric Chart',
  description: 'Metric Chart description',
  shape: 'numeric',
  type: 'caseDurationNumeric',
  dataToDisplay: 'totalCaseCount',
  xAxisType: 'category',
  yAxisType: 'value',
  xAxisTitle: '',
  yAxisTitle: '',
  frequency: '',
  statistic: 'total',
  distribution: '',
  chartData: [38520000],
  metricFormat: 'time',
  projectIds: ['40de743b-b0f9-4e76-bed8-4fafa5287f3d']
};

export const textChartOptionsWithData = {
  chartId: 'chart-8bd0691a-e316-4074-ba27-8a319f1a6559',
  title: 'Test Data',
  description: 'Dummy Data',
  shape: 'text',
  type: 'text',
  dataToDisplay: 'customText',
  nameColor: '#bd10e0',
  descriptionColor: '#d0021b',
  xAxisType: 'category',
  yAxisType: 'value',
  xAxisTitle: '',
  yAxisTitle: '',
  frequency: '',
  statistic: '',
  distribution: '',
  chartData: [],
  filters: [],
  projectIds: ['40de743b-b0f9-4e76-bed8-4fafa5287f3d']
};

export const chartOptionsWithEmptyData = {
  chartId: '',
  title: 'Chart Title',
  description: 'Chart Description',
  shape: 'bar',
  type: '',
  dataToDisplay: 'caseCountInTime',
  xAxisType: 'category',
  yAxisType: 'value',
  xAxisTitle: '',
  yAxisTitle: '',
  frequency: '',
  statistic: '',
  distribution: '',
  chartData: [],
  projectIds: ['e9f7778d-2cbb-4094-8740-dc01383d722a']
};
export const metricChartOptionsWithEmptyData = {
  chartId: '',
  title: 'Chart Title',
  description: 'Chart Description',
  shape: 'numeric',
  type: '',
  dataToDisplay: 'caseCountInTime',
  xAxisType: 'category',
  yAxisType: 'value',
  xAxisTitle: '',
  yAxisTitle: '',
  frequency: '',
  statistic: '',
  distribution: '',
  chartData: [],
  projectIds: ['40de743b-b0f9-4e76-bed8-4fafa5287f3d']
};

export const commonChartDataForBarAndPie = [
  {
    label: '00',
    data: 25.0
  },
  {
    label: '01',
    data: 28.0
  },
  {
    label: '02',
    data: 21.0
  },
  {
    label: '03',
    data: 26.0
  },
  {
    label: '04',
    data: 23.0
  },
  {
    label: '05',
    data: 26.0
  },
  {
    label: '06',
    data: 31.0
  },
  {
    label: '07',
    data: 28.0
  },
  {
    label: '08',
    data: 26.0
  },
  {
    label: '09',
    data: 21.0
  },
  {
    label: '10',
    data: 22.0
  },
  {
    label: '11',
    data: 23.0
  },
  {
    label: '12',
    data: 25.0
  },
  {
    label: '13',
    data: 25.0
  },
  {
    label: '14',
    data: 26.0
  },
  {
    label: '15',
    data: 22.0
  },
  {
    label: '16',
    data: 21.0
  },
  {
    label: '17',
    data: 23.0
  },
  {
    label: '18',
    data: 23.0
  },
  {
    label: '19',
    data: 28.0
  },
  {
    label: '20',
    data: 31.0
  },
  {
    label: '21',
    data: 28.0
  },
  {
    label: '22',
    data: 28.0
  },
  {
    label: '23',
    data: 31.0
  }
];

export const chartDataForDistribution = {
  widget: {
    distribution: 'byHourOfDay',
    type: 'newCasesInTimeBarchart'
  },
  widgetData: {
    chartData: commonChartDataForBarAndPie,
    xAxisLabel: 'HOUR',
    yAxisLabel: 'CASE_NUMBER'
  },
  widgetSource: 'caseCountInTime',
  widgetShape: 'bar',
  widgetType: 'newCasesInTimeBarchart'
};

export const chartDataForPieChart = {
  widget: {
    distribution: 'byHourOfDay',
    type: 'newCasesInTimePiechart'
  },
  widgetData: {
    chartData: commonChartDataForBarAndPie,
    xAxisLabel: 'HOUR',
    yAxisLabel: 'CASE_NUMBER'
  },
  widgetSource: 'caseCountInTime',
  widgetShape: 'pie',
  widgetType: 'newCasesInTimePiechart'
};

export const chartDataForMetric = {
  widget: {
    frequency: 'month',
    type: 'newCasesInTimeNumeric',
    statistic: 'avg'
  },
  widgetData: {
    data: 16850.8
  },
  widgetSource: 'caseCountInTime',
  widgetShape: 'metric',
  widgetType: 'newCasesInTimeNumeric'
};

export const chartDataForLineChart = {
  widget: {
    frequency: 'week',
    type: 'newCasesInTimeLine'
  },
  widgetData: {
    chartData: [
      {
        label: '2010-12-27T00:00:00.000Z',
        data: 23.0
      },
      {
        label: '2011-01-03T00:00:00.000Z',
        data: 50.0
      },
      {
        label: '2011-01-10T00:00:00.000Z',
        data: 42.0
      },
      {
        label: '2011-01-17T00:00:00.000Z',
        data: 36.0
      },
      {
        label: '2011-01-24T00:00:00.000Z',
        data: 35.0
      },
      {
        label: '2011-01-31T00:00:00.000Z',
        data: 24.0
      },
      {
        label: '2011-02-07T00:00:00.000Z',
        data: 28.0
      },
      {
        label: '2011-02-14T00:00:00.000Z',
        data: 17.0
      },
      {
        label: '2011-02-21T00:00:00.000Z',
        data: 26.0
      },
      {
        label: '2011-02-28T00:00:00.000Z',
        data: 14.0
      },
      {
        label: '2011-03-07T00:00:00.000Z',
        data: 17.0
      },
      {
        label: '2011-03-14T00:00:00.000Z',
        data: 11.0
      },
      {
        label: '2011-03-21T00:00:00.000Z',
        data: 16.0
      },
      {
        label: '2011-03-28T00:00:00.000Z',
        data: 10.0
      },
      {
        label: '2011-04-04T00:00:00.000Z',
        data: 8.0
      },
      {
        label: '2011-04-11T00:00:00.000Z',
        data: 14.0
      }
    ],
    xAxisLabel: 'WEEK',
    yAxisLabel: 'CASE_NUMBER'
  },
  widgetSource: 'caseCountInTime',
  widgetShape: 'line',
  widgetType: 'newCasesInTimeLine'
};

export const dashboardData = {
  id: '763a97d1-4618-44c8-b577-913f0904a9c4',
  name: 'Line Chart Dashboard',
  charts: [
    {
      type: 'newCasesInTimeLine',
      title: 'Dummy Line Chart',
      shape: 'line',
      statistic: '',
      frequency: 'week',
      chartId: 'chart-4a7b768c-0596-499e-ab5d-3ab48ec73629',
      description: 'aaa',
      dataToDisplay: 'caseCountInTime',
      xAxisType: 'category',
      yAxisType: 'value',
      xAxisTitle: '',
      yAxisTitle: '',
      chartData: [
        {
          label: '2017-06-19T00:00:00.000Z',
          data: 1
        },
        {
          label: '2017-06-26T00:00:00.000Z',
          data: 2
        },
        {
          label: '2017-07-03T00:00:00.000Z',
          data: 4
        },
        {
          label: '2017-07-10T00:00:00.000Z',
          data: 3
        }
      ],
      projectIds: ['b544beb9-db4f-4a61-ac90-c66dbbbd8ef6'],
      filters: [
        {
          filterType: 'variants',
          isExcluded: false,
          name: [
            'FILTER.VARIANTS',
            'FILTER.INCLUDE',
            'TECH_VOC.VARIANT',
            ' 1',
            ', ',
            'TECH_VOC.VARIANT',
            ' 2'
          ],
          variantIds: [
            '4156df0a-97dd-3c4d-87b2-8ba5aafc4433',
            '5b976a9b-2cd7-3526-b39d-f9ab9f2b03e8'
          ],
          isGlobalFilter: true,
          globalFilterUniqueId: '29b089ad-6d90-4e05-b62e-09421536b981'
        }
      ],
      legend: {
        minColor: 'rgb(147, 200, 236)',
        maxColor: 'rgb(27, 102, 152)',
        percentiles: ['1', '1.5', '2']
      }
    },
    {
      chartId: 'chart-a216d7c6-98b8-4b5c-b10e-9918999d9e2a',
      title: 'Test pmv',
      description: '',
      shape: 'svg',
      type: 'svg',
      dataToDisplay: '',
      nameColor: '',
      descriptionColor: '',
      xAxisType: 'category',
      yAxisType: 'value',
      xAxisTitle: '',
      yAxisTitle: '',
      frequency: '',
      statistic: 'avg',
      distribution: '',
      chartData: svgData,
      filters: [],
      customFieldId: '',
      aggregation: {},
      limit: '',
      scope: '',
      metricFormat: '',
      unit: '',
      numberFormat: '',
      projectIds: '',
      metric: 'duration',
      otherMetricId: '',
      orientation: 'portrait'
    }
  ],
  layout: {
    layoutType: 'GridLayout',
    rows: 1,
    columns: 12,
    layouts: [
      {
        w: 4,
        h: 5,
        x: 0,
        y: 0,
        i: 'layout-8c645a2a-bf1c-4b08-812a-1ef8f2dd0983',
        moved: false,
        chartId: 'chart-4a7b768c-0596-499e-ab5d-3ab48ec73629',
        static: false
      },
      {
        w: 6,
        h: 6,
        x: 4,
        y: 0,
        i: 'layout-8c645a2a-bf1c-4b08-812a-1ef8f2dd0981',
        moved: false,
        chartId: 'chart-a216d7c6-98b8-4b5c-b10e-9918999d9e2a',
        static: false
      }
    ]
  },
  connection: {
    type: 'LOGPICKR',
    projectId: '971cc050-7741-4cbc-bcf7-7906ec70e9f1',
    workgroupId: 'ae998d04-e00a-4f68-b881-2c8d547befcf'
  },
  applicationId: '8f8fa9ab-d210-41dc-8010-12db68656e05',
  isFavourite: true
};

export const mockSelectedDataSourceList = [
  {
    projectId: '40de743b-b0f9-4e76-bed8-4fafa5287f3d',
    name: 'p2p-ch'
  },
  {
    projectId: '86f823f5-8af1-4067-b182-ff1f5f9f4365',
    name: 'test'
  },
  {
    projectId: 'e9f7778d-2cbb-4094-8740-dc01383d722a',
    name: 'bk'
  },
  {
    projectId: 'b544beb9-db4f-4a61-ac90-c66dbbbd8ef6',
    name: 'Bank loan'
  }
];

export const mockGlobalFilterData = [
  {
    id: '6bd2d572-3ab5-4ff1-841c-51ac4137f6fe',
    globalFilterName: 'Global Filter',
    projectIds: ['b544beb9-db4f-4a61-ac90-c66dbbbd8ef6'],
    filters: [
      {
        filterType: 'variants',
        isExcluded: false,
        name: [
          'FILTER.VARIANTS',
          'FILTER.INCLUDE',
          'TECH_VOC.VARIANT',
          ' 1',
          ', ',
          'TECH_VOC.VARIANT',
          ' 2'
        ],
        variantIds: ['4156df0a-97dd-3c4d-87b2-8ba5aafc4433', '5b976a9b-2cd7-3526-b39d-f9ab9f2b03e8']
      }
    ]
  }
];

export const mockGlobalFilterInitialData = [
  {
    id: '57616ed1-7058-46bc-9400-19b4a2e36d4b',
    globalFilterName: 'Variant',
    projectIds: ['b544beb9-db4f-4a61-ac90-c66dbbbd8ef6'],
    filters: [
      {
        filterType: 'variants',
        isExcluded: false,
        name: [
          'FILTER.VARIANTS',
          'FILTER.INCLUDE',
          'TECH_VOC.VARIANT',
          ' 1',
          ', ',
          'TECH_VOC.VARIANT',
          ' 2'
        ],
        variantIds: ['4156df0a-97dd-3c4d-87b2-8ba5aafc4433', '5b976a9b-2cd7-3526-b39d-f9ab9f2b03e8']
      }
    ]
  },
  {
    id: 'fb826cdc-bf02-4e8f-9d29-1eeaa3c0502e',
    globalFilterName: 'Case',
    projectIds: ['b544beb9-db4f-4a61-ac90-c66dbbbd8ef6'],
    filters: [
      {
        filterType: 'case',
        isExcluded: false,
        name: [],
        caseIds: ['2800000132M0022014', '2000001188M0062014']
      }
    ]
  }
];

export const allSavedApps = {
  content: [
    {
      id: '69a7ace9-ad04-4017-8526-873d0daa987d',
      name: 'abc dashboard',
      charts: [
        {
          type: 'newCasesInTimeBarchart',
          title: 'Abc',
          shape: 'bar',
          statistic: '',
          frequency: 'week',
          chartId: 'chart-19cf12ea-e07e-4e20-8773-bbbca2bde941',
          description: 'abc desc',
          dataToDisplay: 'caseCountInTime',
          xAxisType: 'category',
          yAxisType: 'value',
          xAxisTitle: '',
          yAxisTitle: ''
        },
        {
          type: 'newCasesInTimeLine',
          title: 'Line chart',
          shape: 'line',
          statistic: '',
          frequency: 'day',
          chartId: 'chart-6ac77a32-4c7c-4f94-87a1-1421491c54da',
          description: 'Line chart description',
          dataToDisplay: 'caseCountInTime',
          xAxisType: 'category',
          yAxisType: 'value',
          xAxisTitle: '',
          yAxisTitle: '',
          distribution: ''
        },
        {
          type: 'newCasesInTimeNumeric',
          title: 'Metric chart Label',
          shape: 'numeric',
          statistic: 'avg',
          frequency: 'month',
          chartId: 'chart-097a2580-3881-4dd5-87f9-58d94e5e4c5f',
          description: 'Metric chart description',
          dataToDisplay: 'caseCountInTime',
          xAxisType: 'category',
          yAxisType: 'value',
          xAxisTitle: '',
          yAxisTitle: '',
          distribution: ''
        },
        {
          type: 'newCasesInTimePiechart',
          title: 'Pie chart Label',
          shape: 'pie',
          statistic: '',
          frequency: '',
          chartId: 'chart-975aacab-a49d-45d9-9260-c80e72efe865',
          description: 'Pie chart description',
          dataToDisplay: 'caseCountInTime',
          xAxisType: 'category',
          yAxisType: 'value',
          xAxisTitle: '',
          yAxisTitle: '',
          distribution: 'byHourOfDay'
        }
      ],
      layout: {
        layoutType: 'GridLayout',
        rows: 1,
        columns: 12,
        layouts: [
          {
            w: 4,
            h: 5,
            x: 0,
            y: 0,
            i: 'layout-3d4d9fd4-395a-43e8-aab4-9872c019f2c0',
            moved: false,
            chartId: 'chart-19cf12ea-e07e-4e20-8773-bbbca2bde941',
            static: false
          },
          {
            w: 4,
            h: 5,
            x: 4,
            y: 0,
            i: 'layout-bb69c472-1495-478e-b740-001c7074695a',
            moved: false,
            chartId: 'chart-6ac77a32-4c7c-4f94-87a1-1421491c54da',
            static: false
          },
          {
            w: 4,
            h: 5,
            x: 8,
            y: 0,
            i: 'layout-7e066c8c-4a1d-44ba-8ce7-12fe0b790a25',
            moved: false,
            chartId: 'chart-097a2580-3881-4dd5-87f9-58d94e5e4c5f',
            static: false
          },
          {
            w: 4,
            h: 5,
            x: 0,
            y: 5,
            i: 'layout-201b23b8-964b-49a9-bcb4-2ead9f996aec',
            moved: false,
            chartId: 'chart-975aacab-a49d-45d9-9260-c80e72efe865',
            static: false
          }
        ]
      },
      connection: {
        type: 'LOGPICKR',
        projectId: '971cc050-7741-4cbc-bcf7-7906ec70e9f1',
        workgroupId: 'ae998d04-e00a-4f68-b881-2c8d547befcf'
      }
    },
    {
      id: 'c705c356-ec2f-484a-8d86-c6b71cb1c489',
      name: 'Process mining',
      charts: [
        {
          type: 'newCasesInTimeBarchart',
          title: 'bbb',
          shape: 'bar',
          statistic: '',
          frequency: '',
          chartId: 'chart-4a7b768c-0596-499e-ab5d-3ab48ec73629',
          description: 'aaa',
          dataToDisplay: 'caseCountInTime',
          xAxisType: 'category',
          yAxisType: 'value',
          xAxisTitle: '',
          yAxisTitle: ''
        }
      ],
      layout: {
        layoutType: 'GridLayout',
        rows: 1,
        columns: 12,
        layouts: [
          {
            w: 4,
            h: 5,
            x: 0,
            y: 0,
            i: 'layout-8c645a2a-bf1c-4b08-812a-1ef8f2dd0983',
            moved: false,
            chartId: 'chart-4a7b768c-0596-499e-ab5d-3ab48ec73629',
            static: false
          }
        ]
      },
      connection: {
        type: 'LOGPICKR',
        projectId: '971cc050-7741-4cbc-bcf7-7906ec70e9f1',
        workgroupId: 'ae998d04-e00a-4f68-b881-2c8d547befcf'
      }
    },
    {
      id: '6225d5d7-3155-489f-a9e3-a1a2fd144244',
      name: 'Process mining',
      charts: [
        {
          type: 'newCasesInTimeBarchart',
          title: 'bbb',
          shape: 'line',
          statistic: '',
          frequency: '',
          chartId: 'chart-4a7b768c-0596-499e-ab5d-3ab48ec73629',
          description: 'aaa',
          dataToDisplay: 'caseCountInTime',
          xAxisType: 'category',
          yAxisType: 'value',
          xAxisTitle: '',
          yAxisTitle: ''
        }
      ],
      layout: {
        layoutType: 'GridLayout',
        rows: 1,
        columns: 12,
        layouts: [
          {
            w: 4,
            h: 5,
            x: 0,
            y: 0,
            i: 'layout-8c645a2a-bf1c-4b08-812a-1ef8f2dd0983',
            moved: false,
            chartId: 'chart-4a7b768c-0596-499e-ab5d-3ab48ec73629',
            static: false
          }
        ]
      },
      connection: {
        type: 'LOGPICKR',
        projectId: '971cc050-7741-4cbc-bcf7-7906ec70e9f1',
        workgroupId: 'ae998d04-e00a-4f68-b881-2c8d547befcf'
      }
    }
  ],
  pageable: {
    sort: {
      empty: true,
      sorted: false,
      unsorted: true
    },
    offset: 0,
    pageSize: 10,
    pageNumber: 0,
    unpaged: false,
    paged: true
  },
  last: true,
  totalElements: 3,
  totalPages: 1,
  size: 10,
  number: 0,
  sort: {
    empty: true,
    sorted: false,
    unsorted: true
  },
  first: true,
  numberOfElements: 3,
  empty: false
};

export const totalApps = {
  appNames: [
    'Test1',
    'Test2',
    'Test3',
    'Test4',
    'Test6',
    'Test7',
    'Test8',
    'Test9',
    'Test10',
    'Test11',
    'Test12',
    'Test13',
    'Test14',
    'Test15',
    'Test16'
  ]
};

export const emptyAllSavedApps = {
  content: [],
  pageable: {
    sort: {
      empty: true,
      sorted: false,
      unsorted: true
    },
    offset: 0,
    pageSize: 10,
    pageNumber: 0,
    unpaged: false,
    paged: true
  },
  last: true,
  totalElements: 0,
  totalPages: 0,
  size: 10,
  number: 0,
  sort: {
    empty: true,
    sorted: false,
    unsorted: true
  },
  first: true,
  numberOfElements: 0,
  empty: true
};

export const allWorkgroups = [
  {
    id: 'f2bbee2a-c39b-4153-b90e-816a5644bbe4',
    name: 'Automation Test'
  },
  {
    id: '018cb05a-cb44-4117-b6f5-c5327c4f50d5',
    name: '12345'
  },
  {
    //id: 'a216d7c6-98b8-4b5c-b10e-9918999d9e2a',
    id: 'ae998d04-e00a-4f68-b881-2c8d547befcf',
    name: 'Baptiste Test Projects'
  },
  {
    id: '23fac996-54c6-491c-807e-0982b783bd8b',
    name: 'bugs'
  },
  {
    id: '13f12380-93f1-4fab-b485-1e112082e67c',
    name: 'bulkdevelop'
  },
  {
    id: '053a8dbc-9efe-49f0-991b-0060b0f92eb1',
    name: 'c210203487cuo'
  },
  {
    id: 'd9ba8315-6a5e-48a1-bc37-90f4bd205eac',
    name: 'CA'
  },
  {
    id: '3ffbae13-1f5c-48a9-a8eb-859b63ccbb0d',
    name: 'd191009653szr'
  },
  {
    id: '89c1ff11-aa93-4fae-b153-b23ffb4abdf0',
    name: 'd220718413jyv'
  },
  {
    id: 'ced8b8c8-f62c-48de-b78e-13c4ff3cf787',
    name: 'd220718413yzl'
  }
];

export const allAppThemes = [
  {
    id: '067b7787-8ec7-438f-8be3-aacb6733e177',
    description: 'Sky theme',
    createdBy: 'User1',
    createdAt: '2023-09-08T10:29:00.858+00:00',
    modifiedBy: 'User1',
    modifiedAt: '2023-09-14T10:29:00.858+00:00',
    name: 'Sky',
    isDefault: true,
    colourList: ['#041AB9', '#3648C7', '#6876D5', '#9BA3E3', '#CDD1F1', '#EFEFFD']
  },
  {
    id: '4c9eff99-bfe5-4958-a248-57c90dcabe5c',
    description: 'Theme 3',
    createdBy: 'Sagar Sathe',
    name: 'Theme 3',
    isDefault: false,
    colourList: ['#db1313', '#ee3a3a', '#dd5a5a', '#f0acac']
  },
  {
    id: '41933804-2bc1-4cdc-b0fc-55fde4603d24',
    description: 'Theme 2',
    name: 'Theme 2',
    isDefault: false,
    colourList: ['#ca1111', '#ee9797']
  },
  {
    id: '2d7c2679-f20c-4e7c-8ca7-b5076005e6ec',
    description: 'Theme 4',
    name: 'Theme 4',
    isDefault: false,
    colourList: ['#ec9607', '#faab2a', '#fabc55', '#f8d08c']
  }
];

export const filterTypesList = [
  {
    filterType: 'variants'
  },
  {
    filterType: 'period'
  },
  {
    filterType: 'globalSelection'
  },
  {
    filterType: 'duration'
  },
  {
    filterType: 'task'
  },
  {
    filterType: 'case'
  },
  {
    filterType: 'link'
  },
  {
    filterType: 'occurrencesInCase'
  },
  {
    filterType: 'rework'
  },
  {
    filterType: 'conformance'
  },
  {
    filterType: 'metric',
    customFieldId: '675f32b1-6dd8-488d-83bf-106af94a5a78',
    customFieldName: 'cost',
    customFieldIsCaseScope: false,
    customFieldHasCaseAggregation: true
  },
  {
    filterType: 'metric',
    customFieldId: '5fcf59bb-5a50-4353-a6d3-b65eb11f3424',
    customFieldName: 'other_cost',
    customFieldIsCaseScope: false,
    customFieldHasCaseAggregation: true
  },
  {
    filterType: 'dimension',
    customFieldId: '7bf1e28f-be1d-47a0-af19-e5d82d9e5aca',
    customFieldName: 'role',
    customFieldIsCaseScope: false,
    customFieldHasCaseAggregation: true
  },
  {
    filterType: 'dimension',
    customFieldId: '1bd8d878-f562-4794-bb45-6825d014f076',
    customFieldName: 'ressource',
    customFieldIsCaseScope: false,
    customFieldHasCaseAggregation: true
  }
];
export const allWorkgroupsPageable = {
  content: [
    {
      id: 'f2bbee2a-c39b-4153-b90e-816a5644bbe4',
      name: 'Dummy Data 1'
    },
    {
      id: '018cb05a-cb44-4117-b6f5-c5327c4f50d5',
      name: 'Automation Test'
    },
    {
      id: 'a216d7c6-98b8-4b5c-b10e-9918999d9e2a',
      name: 'Baptiste Test Projects'
    },
    {
      id: '23fac996-54c6-491c-807e-0982b783bd8b',
      name: 'bugs'
    },
    {
      id: '13f12380-93f1-4fab-b485-1e112082e67c',
      name: 'bulkdevelop'
    },
    {
      id: '053a8dbc-9efe-49f0-991b-0060b0f92eb1',
      name: 'c210203487cuo'
    },
    {
      id: 'd9ba8315-6a5e-48a1-bc37-90f4bd205eac',
      name: 'CA'
    },
    {
      id: '3ffbae13-1f5c-48a9-a8eb-859b63ccbb0d',
      name: 'd191009653szr'
    },
    {
      id: '89c1ff11-aa93-4fae-b153-b23ffb4abdf0',
      name: 'd220718413jyv'
    },
    {
      id: 'ced8b8c8-f62c-48de-b78e-13c4ff3cf787',
      name: 'd220718413yzl'
    }
  ],
  pageable: {
    sort: {
      empty: true,
      sorted: false,
      unsorted: true
    },
    offset: 0,
    pageNumber: 0,
    pageSize: 10,
    paged: true,
    unpaged: false
  },
  last: false,
  totalElements: 49,
  totalPages: 5,
  size: 10,
  number: 0,
  sort: {
    empty: true,
    sorted: false,
    unsorted: true
  },
  first: true,
  numberOfElements: 10,
  empty: false
};

export const selectedProjectIds = [
  '92e18a34-d634-4081-a166-22724e5f228f',
  '39387d7e-c908-4624-b4a2-6c20cd86efc4',
  'debf82ca-8457-4230-a2a3-2337bffc387f'
];

export const selectedWorkgroupProjects = [
  {
    projectId: '92e18a34-d634-4081-a166-22724e5f228f',
    name: 'testtt'
  },
  {
    projectId: '39387d7e-c908-4624-b4a2-6c20cd86efc4',
    name: 'multi-instance'
  },
  {
    projectId: 'e613aef4-e0d9-46ab-b3fb-4aaa5dcb1b1c',
    name: 'test data page'
  },
  {
    projectId: '5433615a-aacd-4f4e-99c4-09b22daed6a3',
    name: 'dashTest'
  },
  {
    projectId: '98fcd1ae-70bf-40c8-a9f2-59e292bc0182',
    name: 'dimensions et métriques nulles'
  },
  {
    projectId: '5aac1e16-2e05-4e03-ba78-18714f14759a',
    name: 'test long name de fou la ça va pas la ijzrgonzonzfoin,zrofniiozenfzefljnzeofnozief'
  },
  {
    projectId: '9df7622e-c33e-4e61-be83-f983110b1df6',
    name: 'testhv'
  },
  {
    projectId: 'b6a28721-67ba-4fb3-8742-e2c27c329496',
    name: 'bk-delete-dimension'
  },
  {
    projectId: 'debf82ca-8457-4230-a2a3-2337bffc387f',
    name: 'P2P+cost'
  },
  {
    projectId: '4141ad0b-78ee-4565-8f34-3f63da8314f5',
    name: 'indexation'
  },
  {
    projectId: '980e654a-5ca2-4bf2-a9a5-a2eae7e73789',
    name: 'test bug william'
  },
  {
    projectId: 'e10d080a-2e12-40a9-9dd9-f0df1051b877',
    name: 'Purchase 2 Pay + cost'
  },
  {
    projectId: '84d1a7bc-b1d9-4b8a-b004-5433c6809109',
    name: 'defaultDash'
  }
];

export const selectedWorkgroupAppsPageable = {
  content: [
    {
      id: '26a8e481-d1d2-4aff-84a5-6faefbeb1b38',
      name: 'Demo App 1',
      description: 'Demo App 1 Description',
      isPublic: true,
      theme: {
        id: '4b37ba59-4a82-45c6-a9d2-49cdfe5b6ddc',
        name: 'Rainbow',
        description: 'Rainbow theme',
        createdBy: 'Radhika',
        createdAt: '2023-09-08T10:29:00.858+00:00',
        modifiedBy: 'Radhika',
        modifiedAt: '2023-09-14T10:29:00.858+00:00',
        isDefault: true,
        colourList: ['#417c42', '#df9404']
      },
      connection: {
        type: 'LOGPICKR',
        projectId: 'debf82ca-8457-4230-a2a3-2337bffc387f',
        workgroupId: 'a216d7c6-98b8-4b5c-b10e-9918999d9e2a'
      }
    },
    {
      id: 'ad71f48e-f9f2-4f92-97ff-1585bf1edfbd',
      name: 'Demo App 2',
      description: 'Demo App 3',
      isPublic: true,
      theme: {
        id: '4b37ba59-4a82-45c6-a9d2-49cdfe5b6ddc',
        name: 'Rainbow',
        description: 'Rainbow theme',
        createdBy: 'Radhika',
        createdAt: '2023-09-08T10:29:00.858+00:00',
        modifiedBy: 'Radhika',
        modifiedAt: '2023-09-14T10:29:00.858+00:00',
        isDefault: true,
        colourList: ['#417c42', '#df9404']
      },
      connection: {
        type: 'LOGPICKR',
        projectId: 'e10d080a-2e12-40a9-9dd9-f0df1051b877',
        workgroupId: 'a216d7c6-98b8-4b5c-b10e-9918999d9e2a'
      }
    },
    {
      id: '1005b210-d8f4-46d2-a77a-0d62b48516df',
      name: 'Demo App 3',
      description: 'App 3 description',
      isPublic: true,
      theme: {
        id: '4b37ba59-4a82-45c6-a9d2-49cdfe5b6ddc',
        name: 'Rainbow',
        description: 'Rainbow theme',
        createdBy: 'Radhika',
        createdAt: '2023-09-08T10:29:00.858+00:00',
        modifiedBy: 'Radhika',
        modifiedAt: '2023-09-14T10:29:00.858+00:00',
        isDefault: true,
        colourList: ['#417c42', '#df9404']
      },
      connection: {
        type: 'LOGPICKR',
        projectId: 'e10d080a-2e12-40a9-9dd9-f0df1051b877',
        workgroupId: 'a216d7c6-98b8-4b5c-b10e-9918999d9e2a'
      }
    },
    {
      id: '1a5fc016-1fe7-4786-9155-7aeb36444a60',
      name: 'App 4',
      description: 'App 4 Description',
      isPublic: true,
      theme: {
        id: '4b37ba59-4a82-45c6-a9d2-49cdfe5b6ddc',
        name: 'Rainbow',
        description: 'Rainbow theme',
        createdBy: 'Radhika',
        createdAt: '2023-09-08T10:29:00.858+00:00',
        modifiedBy: 'Radhika',
        modifiedAt: '2023-09-14T10:29:00.858+00:00',
        isDefault: true,
        colourList: ['#417c42', '#df9404']
      },
      connection: {
        type: 'LOGPICKR',
        projectId: 'debf82ca-8457-4230-a2a3-2337bffc387f',
        workgroupId: 'a216d7c6-98b8-4b5c-b10e-9918999d9e2a'
      }
    }
  ],
  pageable: {
    sort: {
      empty: true,
      sorted: false,
      unsorted: true
    },
    offset: 0,
    pageNumber: 0,
    pageSize: 10,
    paged: true,
    unpaged: false
  },
  last: true,
  totalElements: 4,
  totalPages: 1,
  size: 10,
  number: 0,
  sort: {
    empty: true,
    sorted: false,
    unsorted: true
  },
  first: true,
  numberOfElements: 4,
  empty: false
};

export const createNewAppResponse = {
  id: 'b1d01c74-09a4-4a08-97ae-45eb22a4ab27',
  name: 'App 5',
  description: 'App 5 Description',
  isPublic: true,
  connection: {
    type: 'LOGPICKR',
    projectId: 'e10d080a-2e12-40a9-9dd9-f0df1051b877',
    workgroupId: 'a216d7c6-98b8-4b5c-b10e-9918999d9e2a'
  }
};

export const createNewAppPayload = {
  name: 'App 5',
  description: 'App 5 Description',
  isPublic: true,
  connection: {
    type: 'LOGPICKR',
    projectId: 'e10d080a-2e12-40a9-9dd9-f0df1051b877',
    workgroupId: 'a216d7c6-98b8-4b5c-b10e-9918999d9e2a'
  }
};

export const createNewThemePayload = {
  name: 'Theme 4',
  description: 'Theme 4',
  isDefault: false,
  colourList: ['#ec9607', '#faab2a', '#fabc55', '#f8d08c']
};

export const createNewThemeResponse = {
  id: '663ebc92-a790-4eae-8828-e6f687bcd9f8',
  name: 'Theme 4',
  description: 'Theme 4',
  isDefault: false,
  colourList: ['#ec9607', '#faab2a', '#fabc55', '#f8d08c']
};

export const getAppDetailsResponse = {
  id: 'd3097b35-1a88-4f9e-9ff7-6cc62af5f1de',
  name: 'Demo App',
  description: 'Demo App',
  isPublic: true,
  theme: {
    id: '4b37ba59-4a82-45c6-a9d2-49cdfe5b6ddc',
    name: 'Rainbow',
    description: 'Rainbow theme',
    isDefault: true,
    colourList: ['#ec9607', '#faab2a']
  },
  connection: {
    type: 'LOGPICKR',
    projectId: 'debf82ca-8457-4230-a2a3-2337bffc387f',
    workgroupId: 'a216d7c6-98b8-4b5c-b10e-9918999d9e2a'
  }
};

export const taskFilterTypeObject = {
  filterType: 'task',
  name: [],
  isExcluded: false,
  taskIds: ['4830d1a3-e486-3219-81f8-9ca50ff20d09', 'd2f07faf-4c4c-3858-8fde-a0b0ddb7195e']
};

export const variantFilterTypeObject = {
  filterType: 'variants',
  name: [],
  isExcluded: false,
  variantIds: ['368a843a-a418-3bfd-9ba0-bf7a487966b8', 'd593b81c-ce40-3282-9ae0-2d4f993e87fb']
};

export const BusinessRuleFilterTypeObject = {
  businessRuleId: '02d08603-58b0-416e-b0d9-c497ebcf011d',
  filterType: 'businessRule',
  name: [],
  isCompliant: false
};

export const caseFilterTypeObject = {
  filterType: 'case',
  name: [],
  isExcluded: false,
  caseIds: ['2800000132M0022014', '2000001188M0062014']
};

export const linkFilterTypeObject = {
  filterType: 'link',
  name: [],
  isExcluded: false,
  linkIds: ['34d60cc7-38a6-3f21-a4ef-a412bf1e6a7c', '1b256faf-9b48-3cfe-afbe-9540f98353dd']
};

export const periodFilterTaskTypeObject = {
  filterType: 'period',
  isExcluded: false,
  name: [],
  scope: 'TASKS',
  processScope: 'ALL',
  periodType: 'CUSTOM_PERIOD',
  startPeriod: '2011-01-02T00:00:00.000Z',
  endPeriod: '2011-10-14T15:31:00.000Z',
  taskIds: ['90209476-8cbf-36e8-be99-80c2c59344b4', '1b2a6d9b-45a8-3709-8edc-48959b6a7f25']
};

export const periodFilterLinkTypeObject = {
  name: [],
  filterType: 'period',
  isExcluded: false,
  scope: 'LINKS',
  linkIds: ['446cc10f-5609-313b-8431-9c97ebfdea2c', '5b63a789-c340-3836-bbdf-e78b53984e53'],
  processScope: 'ALL',
  periodType: 'CUSTOM_PERIOD',
  startPeriod: '2011-01-02T00:00:00.000Z',
  endPeriod: '2011-10-13T15:31:00.000Z'
};

export const occurrencesFilterTaskTypeObject = {
  name: [],
  filterType: 'occurrencesInCase',
  scope: 'TASKS',
  itemIds: ['90209476-8cbf-36e8-be99-80c2c59344b4', '1b2a6d9b-45a8-3709-8edc-48959b6a7f25'],
  operator: '>',
  value: 2
};

export const occurrencesFilterLinkTypeObject = {
  name: [],
  filterType: 'occurrencesInCase',
  scope: 'LINKS',
  itemIds: ['446cc10f-5609-313b-8431-9c97ebfdea2c', '5b63a789-c340-3836-bbdf-e78b53984e53'],
  operator: '>',
  value: 2
};

export const durationFilterTaskTypeObject = {
  filterType: 'duration',
  isExcluded: false,
  name: [],
  scope: 'TASKS',
  operator: '>',
  value: 1,
  scale: 'HOUR',
  taskIds: ['44b51d9d-3369-3f33-aaea-00a3fb3e5556', 'b5a98047-7537-38e3-81d4-ab62458d73a9']
};

export const durationFilterLinkTypeObject = {
  filterType: 'duration',
  isExcluded: false,
  name: [],
  scope: 'LINKS',
  operator: '>',
  value: 1,
  scale: 'HOUR',
  linkIds: [
    'd34f63b7-bf65-369e-aa4d-03e0c9837ab2',
    'fc98a1e2-c462-310c-8a5a-6a4748adcd04',
    '31861430-4c77-3856-a05b-530768c758a8',
    '0b175c4d-8a60-3644-b9c0-f939af6b2e95'
  ]
};
export const dimensionFilterLinkTypeObject = {
  filterType: 'dimension',
  isExcluded: false,
  name: [],
  dimensionValues: [],
  scope: 'LINKS',
  operator: '>',
  value: 1,
  scale: 'HOUR',
  linkIds: [
    'd34f63b7-bf65-369e-aa4d-03e0c9837ab2',
    'fc98a1e2-c462-310c-8a5a-6a4748adcd04',
    '31861430-4c77-3856-a05b-530768c758a8',
    '0b175c4d-8a60-3644-b9c0-f939af6b2e95'
  ]
};

export const dimensionFilterTaskTypeObject = {
  filterType: 'dimension',
  isExcluded: false,
  name: [],
  dimensionValues: [],
  scope: 'TASKS',
  operator: '>',
  value: 1,
  scale: 'HOUR',
  taskIds: ['44b51d9d-3369-3f33-aaea-00a3fb3e5556', 'b5a98047-7537-38e3-81d4-ab62458d73a9']
};
export const dimensionFilterCaseTypeObject = {
  filterType: 'dimension',
  scope: 'CASE',
  isExcluded: false,
  dimensionValues: ['Financial Manager', 'Purchasing Agent']
};
export const reworkFilterTaskTypeObject = {
  filterType: 'rework',
  scope: 'TASKS',
  name: [],
  operator: '>',
  value: '1',
  taskIds: ['90209476-8cbf-36e8-be99-80c2c59344b4', '1b2a6d9b-45a8-3709-8edc-48959b6a7f25']
};

export const reworkFilterLinkTypeObject = {
  filterType: 'rework',
  name: [],
  operator: '>',
  value: '1',
  scope: 'LINKS',
  linkIds: ['446cc10f-5609-313b-8431-9c97ebfdea2c', '5b63a789-c340-3836-bbdf-e78b53984e53']
};

export const metricFilterTaskTypeObject = {
  name: [],
  filterType: 'metric',
  scope: 'TASKS',
  taskIds: ['90209476-8cbf-36e8-be99-80c2c59344b4', '1b2a6d9b-45a8-3709-8edc-48959b6a7f25'],
  operator: '>',
  metricValue: 1,
  metricName: 'cost',
  metricId: '8dc5bb6d-eb75-4122-b029-6b9a061edef8'
};

export const metricFilterLinkTypeObject = {
  name: [],
  filterType: 'metric',
  scope: 'LINKS',
  operator: '>',
  metricValue: 1,
  metricName: 'cost',
  metricId: '8dc5bb6d-eb75-4122-b029-6b9a061edef8',
  linksParameters: {
    bound: 'destination',
    linkIds: ['446cc10f-5609-313b-8431-9c97ebfdea2c', '5b63a789-c340-3836-bbdf-e78b53984e53']
  }
};

export const finishedCasesFilterObject = {
  filterType: 'completedCase',
  name: [],
  isCaseCompleted: true
};

export const appliedFiltersList = [
  {
    name: [],
    filterType: 'occurrencesInCase',
    scope: 'TASKS',
    itemIds: ['90209476-8cbf-36e8-be99-80c2c59344b4', '1b2a6d9b-45a8-3709-8edc-48959b6a7f25'],
    operator: '>',
    value: 2
  },
  {
    name: [],
    filterType: 'occurrencesInCase',
    scope: 'LINKS',
    itemIds: ['446cc10f-5609-313b-8431-9c97ebfdea2c', '5b63a789-c340-3836-bbdf-e78b53984e53'],
    operator: '>',
    value: 3
  },
  {
    filterType: 'task',
    isExcluded: false,
    name: [],
    taskIds: [
      '90209476-8cbf-36e8-be99-80c2c59344b4',
      '1b2a6d9b-45a8-3709-8edc-48959b6a7f25',
      '3b2ccd5f-bea0-31e7-981c-27d77da25afe',
      'f7855802-bb3f-3692-9136-a06f4f847cea'
    ]
  }
];

export const taskFilters = [
  {
    id: '4830d1a3-e486-3219-81f8-9ca50ff20d09',
    name: 'Credit check'
  },
  {
    id: 'd2f07faf-4c4c-3858-8fde-a0b0ddb7195e',
    name: 'Credit control'
  },
  {
    id: '2a6c0672-3b5f-3a82-98af-963e396b0d91',
    name: 'Customer Request'
  },
  {
    id: 'a87c8eb8-c503-34c5-a099-1b6e3cb45f0e',
    name: 'Customer request'
  },
  {
    id: '77fdb06a-d64b-3ca5-bfa4-f7a9e35cbb7c',
    name: 'Information collection'
  },
  {
    id: '41b1e5f5-d9d8-3409-a32a-0bd9121f404c',
    name: 'Information control'
  },
  {
    id: '542ea4e1-64d7-3b0a-aa9c-8dbd3bc106c9',
    name: 'Order entry'
  },
  {
    id: '1e353c9b-37e4-34cb-a83d-dadcf9d1300e',
    name: 'Refusal'
  },
  {
    id: '7cbc0c8c-4eea-3677-ac04-aae7d9edd0f1',
    name: 'Requirements review'
  }
];
export const variantFilters = [
  {
    variantId: '4156df0a-97dd-3c4d-87b2-8ba5aafc4433',
    variantName: '1'
  },
  {
    variantId: '5b976a9b-2cd7-3526-b39d-f9ab9f2b03e8',
    variantName: '2'
  },
  {
    variantId: '8f5c6b68-6572-38fa-aaab-2f7490b2237e',
    variantName: '3'
  },
  {
    variantId: '43a050b5-6fae-360d-a716-0e4b60b6ef49',
    variantName: '4'
  },
  {
    variantId: '8a5e6ab8-4cee-399c-8053-78ccbffd04b2',
    variantName: '5'
  },
  {
    variantId: 'ae22d4eb-550f-3191-835a-a4274a795139',
    variantName: '6'
  },
  {
    variantId: 'd098cbc0-f9da-360c-9425-fe34873c2e6f',
    variantName: '7'
  }
];

export const BusinessRuleFilterOptions = [
  {
    id: '02d08603-58b0-416e-b0d9-c497ebcf011d',
    name: 'Rule 1'
  },
  {
    id: '7e8924f6-c800-4db4-af25-ce4be0352952',
    name: 'Rule 10'
  },
  {
    id: '869a7cec-3082-453a-867d-e6b8a68d594f',
    name: 'Rule 5'
  },
  {
    id: '1ea6a5e1-c059-4d60-b2e6-cd99f0ddb1cf',
    name: 'Rule 7'
  },
  {
    id: '86f2d556-7385-459c-84f6-a69dfdb4d6f6',
    name: 'Rule 9'
  },
  {
    id: '9f1cb9fe-1465-4282-a909-96c8b2590ab7',
    name: 'Rule metric'
  },
  {
    id: 'a21004bf-88f4-4fca-9396-59228a6af39a',
    name: 'Rule ressource'
  },
  {
    id: '02dd00d4-9411-4e11-9602-47cfa71e06be',
    name: 'Rule role'
  },
  {
    id: '6072fe6c-fe6c-4ce0-bd48-b366565ce5ff',
    name: 'test'
  }
];

export const SavedFilterOptions = [
  {
    id: '0b97bd70-f9e0-495a-b7e4-8f8a118af919',
    name: 'Business rule 1 not compliant',
    filters: [
      {
        name: ['Rule 1', 'FILTER.COMPLIANT'],
        businessRuleId: '02d08603-58b0-416e-b0d9-c497ebcf011d',
        isCompliant: true,
        filterType: 'businessRule'
      }
    ]
  },
  {
    id: 'cb53cfa1-73b1-4b2b-aa14-972835a2e802',
    name: '445245',
    filters: [
      {
        name: ['FILTER.VARIANTS', 'FILTER.INCLUDE'],
        isExcluded: false,
        filterType: 'variants',
        variantIds: ['10081118-0653-300d-9385-297ebceb80ed', 'f0d5e899-9ce7-3646-b24f-0ed868ea3b7b']
      }
    ]
  }
];

export const periodFilters = { from: '2011-01-01T00:00:00.000Z', to: '2011-10-14T15:31:00.000Z' };

export const caseFilters = {
  totalItems: 10,
  caseIds: [
    '2800000132M0022014',
    '2000001188M0062014',
    '2000002701M0022013',
    '2000001416M0062013',
    '2000000561M0032014',
    '2000001865M0022013',
    '2000001368M0022013',
    '2000000502M0022013',
    '2109000443M0042013',
    '2000000247M0022013'
  ]
};
export const linkFilters = [
  {
    id: '34d60cc7-38a6-3f21-a4ef-a412bf1e6a7c',
    fromTask: {
      id: '77fdb06a-d64b-3ca5-bfa4-f7a9e35cbb7c',
      name: 'Information collection'
    },
    toTask: {
      id: '77fdb06a-d64b-3ca5-bfa4-f7a9e35cbb7c',
      name: 'Information collection'
    }
  },
  {
    id: '1b256faf-9b48-3cfe-afbe-9540f98353dd',
    fromTask: {
      id: '77fdb06a-d64b-3ca5-bfa4-f7a9e35cbb7c',
      name: 'Information collection'
    },
    toTask: {
      id: '41b1e5f5-d9d8-3409-a32a-0bd9121f404c',
      name: 'Information control'
    }
  },
  {
    id: '549fe7b0-1d4c-3d1a-9415-ebebf4a44c65',
    fromTask: {
      id: '77fdb06a-d64b-3ca5-bfa4-f7a9e35cbb7c',
      name: 'Information collection'
    },
    toTask: {
      id: '1e353c9b-37e4-34cb-a83d-dadcf9d1300e',
      name: 'Refusal'
    }
  },
  {
    id: '911deddc-ec69-37eb-ac6c-af7b81238d9b',
    fromTask: {
      id: '41b1e5f5-d9d8-3409-a32a-0bd9121f404c',
      name: 'Information control'
    },
    toTask: {
      id: '4830d1a3-e486-3219-81f8-9ca50ff20d09',
      name: 'Credit check'
    }
  },
  {
    id: '177c7b2d-43f5-3d50-b3b7-84092464e1c3',
    fromTask: {
      id: '41b1e5f5-d9d8-3409-a32a-0bd9121f404c',
      name: 'Information control'
    },
    toTask: {
      id: '77fdb06a-d64b-3ca5-bfa4-f7a9e35cbb7c',
      name: 'Information collection'
    }
  },
  {
    id: 'f6fe31fd-e9c5-3f42-901b-5ad9cdcca176',
    fromTask: {
      id: '542ea4e1-64d7-3b0a-aa9c-8dbd3bc106c9',
      name: 'Order entry'
    },
    toTask: {
      id: '4830d1a3-e486-3219-81f8-9ca50ff20d09',
      name: 'Credit check'
    }
  },
  {
    id: 'ba36b172-9d3c-34db-8f0c-5e074a846294',
    fromTask: {
      id: '542ea4e1-64d7-3b0a-aa9c-8dbd3bc106c9',
      name: 'Order entry'
    },
    toTask: {
      id: '77fdb06a-d64b-3ca5-bfa4-f7a9e35cbb7c',
      name: 'Information collection'
    }
  },
  {
    id: '6652cdd9-732d-34ea-b133-a0ff0e2904fd',
    fromTask: {
      id: '1e353c9b-37e4-34cb-a83d-dadcf9d1300e',
      name: 'Refusal'
    },
    toTask: {
      id: 'ace30ee1-e2a0-3c82-8229-c3e66f6f64c7',
      name: 'End'
    }
  }
];

export const dashboardDataForMetricChart = {
  id: 'b2cfc343-c655-468e-a926-337e9d9258de',
  createdBy: 'Sagar Sathe',
  name: 'Test App',
  charts: [
    {
      type: 'totalCaseCountNumeric',
      title: 'New Metric',
      shape: 'numeric',
      statistic: 'total',
      frequency: '',
      chartId: 'chart-f5153dad-f619-4465-b609-93454893bf9b',
      description: 'New Metric description',
      dataToDisplay: 'totalCaseCount',
      xAxisType: 'category',
      yAxisType: 'value',
      xAxisTitle: '',
      yAxisTitle: '',
      distribution: '',
      filters: []
    }
  ],
  layout: {
    layoutType: 'GridLayout',
    rows: 1,
    columns: 12,
    layouts: [
      {
        w: 4,
        h: 6,
        x: 0,
        y: 0,
        i: 'layout-f6dd4fca-c054-4815-81bb-a02aeba5f501',
        moved: false,
        chartId: 'chart-f5153dad-f619-4465-b609-93454893bf9b',
        static: false
      }
    ]
  },
  applicationId: '26a8e481-d1d2-4aff-84a5-6faefbeb1b38'
};
export const createNewDashboardPayload = {
  name: 'New Dashboard',
  charts: [],
  modificationDate: '2023-07-13',
  description: 'Test dashboard'
};

export const createNewDashboardResponse = {
  id: 123,
  name: 'New Dashboard',
  charts: [],
  modificationDate: '2023-07-13',
  description: 'Test dashboard'
};
export const allDashboards = {
  content: [
    {
      id: 'ffdd0134-386e-44b0-9cee-a70f1d96a487',
      createdBy: '59c096a4-9a0b-4fd4-9daa-16a88510942a',
      name: 'Default Dashboard',
      charts: [
        {
          type: 'totalEventCountNumeric',
          title: 'Metric Chart',
          shape: 'numeric',
          metricFormat: 'number',
          statistic: 'total',
          frequency: '',
          chartId: 'chart-78aa3fa4-ddbc-4073-9474-4c5696697032',
          description: 'Total number of events',
          dataToDisplay: 'totalEventsCount',
          xAxisType: 'category',
          yAxisType: 'value',
          xAxisTitle: '',
          yAxisTitle: '',
          distribution: '',
          aggregation: {},
          customFieldId: '',
          scope: '',
          filters: []
        }
      ],
      layout: {
        layoutType: 'GridLayout',
        rows: 1,
        columns: 12,
        layouts: [
          {
            w: 4,
            h: 5,
            x: 0,
            y: 0,
            i: 'layout-25ae5ed7-f7e3-488e-acb4-c84eea938b43',
            moved: false,
            chartId: 'chart-78aa3fa4-ddbc-4073-9474-4c5696697032',
            static: false
          }
        ]
      },
      applicationId: 'bd3dcdae-cee2-48d4-b4e0-a06ffbdefb65',
      isFavourite: false
    },
    {
      id: '8f22c3cd-7054-4008-9eb4-a2369754e47d',
      createdBy: '59c096a4-9a0b-4fd4-9daa-16a88510942a',
      name: 'Dashboard2',
      charts: [
        {
          type: 'newCasesInTimeBarchart',
          title: 'New cases by day',
          shape: 'bar',
          metricFormat: '',
          statistic: '',
          frequency: '',
          chartId: 'chart-31ae38ff-1a43-471d-ae81-94352396d5b5',
          description: '',
          dataToDisplay: 'caseCountInTime',
          xAxisType: 'category',
          yAxisType: 'value',
          xAxisTitle: 'days',
          yAxisTitle: 'case count',
          distribution: 'byHourOfDay',
          aggregation: {},
          customFieldId: '',
          scope: '',
          filters: []
        }
      ],
      layout: {
        layoutType: 'GridLayout',
        rows: 1,
        columns: 12,
        layouts: [
          {
            w: 4,
            h: 5,
            x: 0,
            y: 0,
            i: 'layout-af7c4c53-0722-4f6f-baee-45ae2cdd8a5f',
            moved: false,
            chartId: 'chart-31ae38ff-1a43-471d-ae81-94352396d5b5',
            static: false
          }
        ]
      },
      applicationId: 'ab3dcdae-cee2-48d4-b4e0-a06ffbdefb65',
      isFavourite: true
    },
    {
      id: '763a97d1-4618-44c8-b577-913f0904a9c4',
      name: 'Line Chart Dashboard',
      charts: [
        {
          type: 'newCasesInTimeLine',
          title: 'Dummy Line Chart',
          shape: 'line',
          statistic: '',
          frequency: 'week',
          chartId: 'chart-4a7b768c-0596-499e-ab5d-3ab48ec73629',
          description: 'aaa',
          dataToDisplay: 'caseCountInTime',
          xAxisType: 'category',
          yAxisType: 'value',
          xAxisTitle: '',
          yAxisTitle: '',
          chartData: [
            {
              label: '2017-06-19T00:00:00.000Z',
              data: 1
            },
            {
              label: '2017-06-26T00:00:00.000Z',
              data: 2
            },
            {
              label: '2017-07-03T00:00:00.000Z',
              data: 4
            },
            {
              label: '2017-07-10T00:00:00.000Z',
              data: 3
            }
          ]
        }
      ],
      layout: {
        layoutType: 'GridLayout',
        rows: 1,
        columns: 12,
        layouts: [
          {
            w: 4,
            h: 5,
            x: 0,
            y: 0,
            i: 'layout-8c645a2a-bf1c-4b08-812a-1ef8f2dd0983',
            moved: false,
            chartId: 'chart-4a7b768c-0596-499e-ab5d-3ab48ec73629',
            static: false
          }
        ]
      },
      applicationId: 'de3dcdae-cee2-48d4-b4e0-a06ffbdefb65',
      isFavourite: false
    }
  ],
  pageable: {
    sort: {
      empty: true,
      sorted: false,
      unsorted: true
    },
    offset: 0,
    pageSize: 10,
    pageNumber: 0,
    unpaged: false,
    paged: true
  },
  last: true,
  totalElements: 2,
  totalPages: 1,
  size: 10,
  number: 0,
  sort: {
    empty: true,
    sorted: false,
    unsorted: true
  },
  first: true,
  numberOfElements: 2,
  empty: false
};
export const mockRoleDimentions = {
  id: 'bab68c4e-e2f2-4586-a4eb-14fdd6a12866',
  name: 'role',
  isCaseScope: false,
  hasCaseAggregation: true,
  values: ['Financial Manager', 'Purchasing Agent', 'Requester', 'Requester Manager', 'Supplier']
};
export const mockConformanceModels = [
  {
    id: 'a1f1f3e9-99cd-4150-9c7e-696a05b9a400',
    name: '2023-06-27T11_37_17+05_30_Purchase2pay1'
  },
  {
    id: 'de82beb4-b181-4b5a-8fca-8affde7011ca',
    name: '1 (2) ',
    diagramResource: {
      diagramId: 9189,
      repositoryName: 'testRepo'
    }
  },
  {
    id: '75b63983-9b30-4845-8f44-8f0d3eb38c3c',
    name: 'Model 1'
  },
  {
    id: '4811949c-9261-4ea7-a0b5-bfcdf6b464ca',
    name: '2023-07-13T15_24_12+02_00_Purchase 2 Pay + cost(Variantes - Inclus Variante 2)'
  },
  {
    id: 'c49fe8c1-31fc-4f92-bc83-7fc61e411ba6',
    name: 'Test variante 2 '
  },
  {
    id: 'd193ef13-e08c-435f-95da-57cd5f4f6aa9',
    name: '2022-02-21T09_46_11+01_00_test OR GW '
  },
  {
    id: 'e0d6ea7c-1634-4cdf-a41a-6fd9d61d1b7a',
    name: 'Model 2'
  }
];

export const conformanceMockRequest = {
  modelId: 'c49fe8c1-31fc-4f92-bc83-7fc61e411ba6',
  modelName: 'Test variante 2',
  filterType: 'conformance',
  operator: '>=',
  value: 0.1,
  name: ['FILTER.CONFORMANCE', 'Test variante 2', '>=', '10%']
};

export const mockStatisticData = {
  metric: [
    {
      id: 'd9fd3821-f695-42de-95c9-47a4625a5161',
      name: 'cost',
      hasCaseAggregation: true,
      unit: '$',
      caseScope: false
    }
  ],
  statistics: [
    {
      id: 'Total',
      name: 'total'
    },
    {
      id: 'Maximum',
      name: 'max'
    },
    {
      id: 'Minimum',
      name: 'min'
    },
    {
      id: 'Average',
      name: 'avg'
    },
    {
      id: 'Median',
      name: 'median'
    }
  ]
};

export const conformanceFilterObject = {
  modelId: '',
  modelName: '',
  filterType: 'conformance',
  operator: '',
  value: 0,
  name: []
};

export const mockProcessModelRequest = {
  aggregation: {
    type: 'string',
    value: 'string'
  },
  customFieldId: 'string',
  distribution: 'string',
  filters: [],
  frequency: 'string',
  limit: 0,
  metric: 'duration',
  name: 'string',
  orientation: 'portrait',
  scope: 'string',
  statistic: 'avg',
  type: 'string'
};

export const mockProcessModel = {
  isTooComplex: false,
  svg: '',
  legend: {
    minColor: 'rgb(147, 200, 236)',
    maxColor: 'rgb(27, 102, 152)',
    percentiles: ['1', '1.5', '2']
  },
  variantsStatistic: [
    {
      variantId: '69088fb1-ea30-30a2-a388-43b8e55138d1',
      occurrences: 1,
      variantName: '1'
    },
    {
      variantId: '90a2a862-4af3-3b6b-955e-9a00da4c8e5c',
      occurrences: 1,
      variantName: '2'
    }
  ],
  sliderCombination: {
    firstVariantIndex: 0,
    lastVariantIndex: 1
  }
};

export const mockChartOptionSvg = {
  chartId: 'a216d7c6-98b8-4b5c-b10e-9918999d9e2a',
  title: 'Test pmv',
  description: '',
  shape: 'svg',
  type: 'svg',
  dataToDisplay: '',
  nameColor: '',
  descriptionColor: '',
  xAxisType: 'category',
  yAxisType: 'value',
  xAxisTitle: '',
  yAxisTitle: '',
  frequency: '',
  statistic: 'avg',
  distribution: '',
  chartData: svgData,
  filters: [],
  customFieldId: '',
  aggregation: {},
  limit: '',
  scope: '',
  metricFormat: '',
  unit: '',
  numberFormat: '',
  projectIds: selectedProjectIds,
  metric: 'duration',
  otherMetricId: '',
  orientation: 'portrait',
  legend: {
    minColor: 'rgb(147, 200, 236)',
    maxColor: 'rgb(27, 102, 152)',
    percentiles: ['1', '1.61', '2.22']
  }
};

export const metricAndStatistics = {
  metrics: [
    {
      id: 'frequency',
      name: 'Frequency',
      hasCaseAggregation: false,
      caseScope: false
    },
    {
      id: 'duration',
      name: 'Duration',
      hasCaseAggregation: false,
      caseScope: false
    },
    {
      id: '02367ea9-8318-41a3-b699-4d2e2158f11b',
      name: 'cost',
      hasCaseAggregation: true,
      caseScope: false
    }
  ],
  statistics: [
    {
      id: 'Total',
      name: 'total'
    },
    {
      id: 'Maximum',
      name: 'max'
    },
    {
      id: 'Minimum',
      name: 'min'
    },
    {
      id: 'Average',
      name: 'avg'
    },
    {
      id: 'Median',
      name: 'median'
    }
  ],
  conformanceStatistics: [
    {
      id: 'Percent conformed',
      name: 'percent'
    },
    {
      id: 'Percent non-conformed',
      name: 'percent'
    },
    {
      id: 'Total events conformed',
      name: 'total'
    },
    {
      id: 'Total events non-conformed',
      name: 'total'
    }
  ],
  ruleComplianceStatistics: [
    {
      id: 'Percent compliant',
      name: 'percent'
    },
    {
      id: 'Percent non-compliant',
      name: 'percent'
    },
    {
      id: 'Total cases compliant',
      name: 'total'
    },
    {
      id: 'Total cases non-compliant',
      name: 'total'
    }
  ]
};

export const selectedDataSources = [
  {
    projectId: '40de743b-b0f9-4e76-bed8-4fafa5287f3d',
    name: 'p2p-ch'
  },
  {
    projectId: '4123-b0f9-4e76-bed8-4fafa5287f3d',
    name: 'purchase to pay'
  }
];

export const savedFilterSets = [
  {
    id: '28fd3361-cadf-49a6-a8c1-67e2ef2753df',
    name: 'KP',
    filters: [
      {
        name: [
          'FILTER.VARIANTS',
          'FILTER.INCLUDE',
          'TECH_VOC.VARIANT',
          ' 1',
          ', ',
          'TECH_VOC.VARIANT',
          ' 2',
          ', ',
          'TECH_VOC.VARIANT',
          ' 3'
        ],
        isExcluded: false,
        filterType: 'variants',
        variantIds: [
          '1c9595ef-ee89-3a1a-b99d-aef65d17c944',
          '3c0250f7-d7e4-3f11-b9e3-b86d3a3d81aa',
          'cb1b7041-6f78-3f2f-a109-27210003364e'
        ]
      }
    ]
  }
];

export const mockImageWidgetChartOptions = {
  chartId: '',
  title: 'image chart',
  description: '',
  shape: 'image',
  type: 'image',
  dataToDisplay: '',
  nameColor: '',
  descriptionColor: '',
  xAxisType: 'category',
  yAxisType: 'value',
  xAxisTitle: '',
  yAxisTitle: '',
  frequency: '',
  selectedDefaultStatistics: {},
  selectedDefaultBusinessRule: {},
  selectedDefaultConformanceModel: {},
  imageData: 'data:image/png;base64,ivdggdudhk',
  imageName: 'Test.jpg',
  isOkConformance: true,
  ruleId: '',
  modelId: '',
  showModel: false,
  statistic: '',
  distribution: '',
  chartData: [],
  filters: [],
  customFieldId: '',
  aggregation: {},
  limit: '',
  scope: '',
  metricFormat: '',
  unit: '',
  numberFormat: '',
  projectIds: '',
  metric: '',
  otherMetricId: '',
  orientation: 'portrait',
  legend: {}
};

export const mockAppliedGlobalFilters = [
  {
    id: '1480e043-5c7b-49eb-9eed-fb30a147c3e9',
    globalFilterName: 'Filter 1',
    projectIds: ['633c8557-00c9-457b-892a-cf56538a11bf'],
    filters: [
      {
        filterType: 'variants',
        isExcluded: false,
        name: [
          'FILTER.VARIANTS',
          'FILTER.INCLUDE',
          'TECH_VOC.VARIANT',
          ' 1',
          ', ',
          'TECH_VOC.VARIANT',
          ' 2',
          ', ',
          'TECH_VOC.VARIANT',
          ' 3'
        ],
        variantIds: [
          '1c9595ef-ee89-3a1a-b99d-aef65d17c944',
          '3c0250f7-d7e4-3f11-b9e3-b86d3a3d81aa',
          'cb1b7041-6f78-3f2f-a109-27210003364e'
        ],
        isGlobalFilter: true,
        globalFilterUniqueId: '9fc74eec-642b-421c-bb3d-d9e472e080a7'
      }
    ],
    dashboardId: 'b5cd87ef-5df7-4400-8ac4-8fd23133267e'
  }
];

export const mockImageWidgetData = [
  {
    path: 'Screenshot 2023-09-14 155939.png',
    lastModified: 1694687378527,
    name: 'Screenshot 2023-09-14 155939.png',
    size: 42045,
    type: 'image/png',
    webkitRelativePath: ''
  }
];

export const userProfileDetails = {
  language: 'en',
  userId: '566997f3-a5bb-41a7-8c51-159b2c71d3fd',
  logo: 'dummy logo',
  id: 'b4ab30e0-f935-420c-93e5-fdd0d64e6ecf'
};

export const mockGenerateProcessModelViewRequestBody = {
  type: 'svg',
  statistic: 'avg',
  filters: [],
  metric: 'metric',
  otherMetricId: '3e9d1852-0360-42e1-aeb4-82553cdbb077',
  isOkConformance: true,
  orientation: 'portrait'
};

export const variantStatisticsForProcessModelView = {
  rework: 6.381578947368421,
  concurrency: 0.0,
  averageDuration: 1.8540681907894738e9,
  medianDuration: 1.030845e9,
  totalCases: 608,
  filteredCases: 608
};

export const userListReport = [
  {
    firstName: 'Nathaniel',
    lastName: 'Johnson',
    loginName: 'Nathaniel.Johnson@igrafx.com',
    id: 279,
    eMailAddress: 'Nathaniel.Johnson@igrafx.com',
    enabled: true,
    deletedFromRemoteDirectory: false,
    entryUUID: null
  },
  {
    firstName: 'Adminal',
    lastName: 'Ackbar',
    loginName: 'admin',
    id: 2,
    eMailAddress: 'asd',
    enabled: true,
    deletedFromRemoteDirectory: false,
    entryUUID: null
  },
  {
    firstName: 'Shahrukh',
    lastName: 'Khan',
    loginName: 'Shahrukh.Khan@igrafx.com',
    id: 360,
    eMailAddress: 'Shahrukh.Khan@igrafx.com',
    enabled: false,
    deletedFromRemoteDirectory: false,
    entryUUID: null
  },
  {
    firstName: 'Sagar',
    lastName: 'Sathe',
    loginName: 'Sagar.Sathe@igrafx.com',
    id: 626,
    eMailAddress: 'Sagar.Sathe@igrafx.com',
    enabled: true,
    deletedFromRemoteDirectory: false,
    entryUUID: null
  },
  {
    firstName: 'immodest',
    lastName: 'jelly_doughnut',
    loginName: 'user7',
    id: 136,
    eMailAddress: 'lee.rudd@gmail.com',
    enabled: false,
    deletedFromRemoteDirectory: false,
    entryUUID: null
  }
];

export const appInteractionsCountByUsername = {
  appInteractionsCountByUsername: {
    'jake.thornton@igrafx.com': 5,
    'benji.hofing@igrafx.com': 10,
    'hk.ouanesisouk@igrafx.com': 55,
    'timothy.romanowski@igrafx.com': 22,
    'nicole.peuser@igrafx.com': 75,
    'donatien.sattler@igrafx.com': 4,
    'samuel.greer@igrafx.com': 47,
    'autumn.stevens@igrafx.com': 185,
    'avery.norris@igrafx.com': 29,
    'guillaume.neupont@igrafx.com': 3,
    'alyssa.leingang@igrafx.com': 5,
    'tristan.james@igrafx.com': 449,
    'gokberk.dagasan@igrafx.com': 1,
    'trevor.hensel@igrafx.com': 30,
    'ken.mcintosh@igrafx.com': 19,
    'kim.scott@igrafx.com': 21,
    'gowrish.pandurangappa@igrafx.com': 17,
    'sagar.sathe@igrafx.com': 4,
    'radhika.jagdale@igrafx.com': 8,
    'nils.peuser@igrafx.com': 271,
    'scott.schanel@igrafx.com': 56,
    'marc.thayer@igrafx.com': 103,
    'kalpesh.pawar@igrafx.com': 105
  }
};

export const licenseUtilization = {
  platformExtensions: [
    'PlatformNew',
    'PerformanceManagementNew',
    'SAPNew',
    'AutomationNew',
    'APINew'
  ],
  userExtensions: {
    Viewer: 200000,
    CollaboratorUser: 50000,
    Designer: 100000,
    Architect: 25000
  },
  trial: false,
  licenses: [
    {
      activationKey: 'AJRM0P1J004E2G1H8K3N3Q6CWSN69ZKM2RHUSK',
      expired: false,
      expirationDate: null,
      activationDate: null
    }
  ]
};

export const mockBubbleChartMetricsAndDimensionsData = {
  dimensions: [
    {
      id: 'eb5c1afc-71ee-4036-b818-6b1034698df2',
      name: 'role',
      scope: 'TASK',
      caseAggregation: 'LAST',
      groupedTasksAggregation: 'LAST'
    },
    {
      id: '28cb9836-aed9-408a-afea-3395bd7a1c38',
      name: 'ressource',
      scope: 'TASK',
      caseAggregation: 'DISTINCT',
      groupedTasksAggregation: 'LAST'
    }
  ],
  metrics: [
    {
      id: 'ffecb6f9-7aef-4c34-a6a5-20482ff25eec',
      name: 'cost',
      scope: 'TASK',
      caseAggregation: 'SUM',
      groupedTasksAggregation: 'SUM',
      unit: '$'
    },
    {
      id: '038d696a-afc8-41d1-8ad0-08f449c7867e',
      name: 'other_cost',
      scope: 'TASK',
      caseAggregation: 'SUM',
      groupedTasksAggregation: 'SUM',
      unit: '€'
    }
  ]
};

export const mockBubbleData = {
  name: 'role',
  dimensions: [
    {
      name: 'Purchasing Agent',
      y: 6766604.127579737,
      r: 3198
    },
    {
      name: 'Requester',
      y: 850014.14427157,
      r: 2121
    },
    {
      name: 'Financial Manager',
      y: 344598.9304812834,
      r: 1309
    },
    {
      name: 'Requester Manager',
      y: 437252.39616613416,
      r: 1252
    },
    {
      name: 'Supplier',
      y: 30754673.123486683,
      r: 1239
    }
  ]
};

export const mockBubbleChartOptions = {
  chartId: 'chart-725ab551-901f-42a9-8dc9-ba67346f8c70',
  title: 'Bubble Chart',
  description: '',
  shape: 'bubble',
  type: 'dimensionBubblechart',
  dataToDisplay: 'role',
  nameColor: '',
  descriptionColor: '',
  xAxisType: 'category',
  yAxisType: 'duration',
  xAxisTitle: 'frequency',
  yAxisTitle: 'duration',
  frequency: '',
  statistic: '',
  distribution: '',
  chartData: [],
  imageData: '',
  filters: [],
  customFieldId: 'eb5c1afc-71ee-4036-b818-6b1034698df2',
  aggregation: {},
  limit: '100',
  scope: 'tasks',
  metricFormat: '',
  projectIds: ['4b32f1d4-a523-4c01-a256-8efb5eb98108'],
  metric: '',
  otherMetricId: '',
  orientation: 'portrait',
  legend: {},
  dimensionId: 'eb5c1afc-71ee-4036-b818-6b1034698df2',
  bubbleMetricsUnit: 'frequency',
  yAxisMetricsUnit: 'duration',
  bubbleStatistic: 'total',
  yAxisStatistic: 'avg',
  bubbleType: 'frequency'
};

export const modifiedBubbleChartData = [
  ['Purchasing Agent', 6766604.127579737, 3198],
  ['Requester', 850014.14427157, 2121],
  ['Financial Manager', 344598.9304812834, 1309],
  ['Requester Manager', 437252.39616613416, 1252],
  ['Supplier', 30754673.123486683, 1239]
];

export const mockDashboardTemplates = [
  {
    id: '0f664ad5-d0a1-4092-a898-88eebaaf09a2',
    description: 'Dashboard Template 2',
    name: 'Dashboard Template 2',
    widgetNames: [
      'Total number of Requester and Requester Manager',
      'Number of tasks finished',
      'Average cases duration',
      'Total number of Supplier',
      'Role repartition between first-half of Ressources',
      'Role repartition between last-half of Ressources',
      'Variants repartition',
      'Number of tasks by day'
    ]
  },
  {
    id: '2c6d6393-a06a-4e15-84b7-952f01dea320',
    description: 'Dashboard Template 3',
    name: 'Dashboard Template 3',
    widgetNames: [
      'Average cost by week',
      'Median cost by case',
      'Average cost by task',
      'Average cost by Role',
      'Average cost by Ressources'
    ]
  },
  {
    id: 'd57c3c90-ec53-4ea4-a357-e25c0d6e2578',
    description: 'Dashboard Template 1',
    name: 'Dashboard Template 1',
    widgetNames: [
      'New cases per day',
      'New events per day',
      'Average cases duration',
      'Efficiency rate',
      'Average duration of process by start day',
      'Variants repartition'
    ]
  }
];

export const userLoginsCountByUsername = {
  userLoginsCountByUsername: {
    'sagar.sathe@igrafx.com': 15,
    'radhika.jagdale@igrafx.com': 23,
    'sven.schwarz@igrafx.com': 90,
    'william.clem@igrafx.com': 123
  }
};

export const objectAccessCountByObjectName = {
  objectAccessCount: {
    'customer name': 15,
    'busincess services': 43,
    'sales team leader': 90,
    'enterprise architect': 105
  }
};

export const workgroupRepositories = [
  {
    name: 'Test Repo',
    shortname: 'testRepo',
    id: 1,
    description: '',
    enabled: true,
    guid: 'e5e3e226-feb6-43d3-adc9-b164b98b29ad',
    outdated: false
  },
  {
    name: 'bulkdev',
    shortname: 'bulkdev',
    id: 4,
    description: '',
    enabled: true,
    guid: '56aee759-5582-4ddd-8c23-1ca4f3dbcda1',
    outdated: false
  },
  {
    name: 'eZest',
    shortname: 'EZ',
    id: 5,
    description: '',
    enabled: true,
    guid: '95c7d054-8c09-4d83-9f31-8e82084da4ed',
    outdated: false
  }
];

export const tabsVisitCountByTabType = {
  tabsVisitCount: {
    assignments: 15,
    main: 43,
    history: 90,
    permissions: 105
  }
};

export const filtersAPIRequestPayload = {
  search: null,
  filterPageNo: '1',
  filterPageSize: '100',
  filterSortOrder: 'ASC'
};
