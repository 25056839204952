import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import FiltersList from '../filters/FiltersList';
import configVariables from '../../utils/ConfigurationVariables.js';
import { getUuid } from '../../utils/utils.js';
import {
  setGlobalFiltersInSessionStorage,
  getGlobalFiltersFromSessionStorage
} from '../../utils/GlobalFilterUtils.js';
import ApiService from '../../services/ApiService';
import { ToasterError, ToasterSuccess } from '../../utils/toaster';
import LoadingSpinner from '../loader/LoadingSpinner';
import DashboardButton from '../shared/Buttons/DashboardButton.js';
import {
  TextField,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  MenuItem,
  Tooltip
} from '@mui/material';
import './AddGlobalFilterForm.scss';
import { useSelector } from 'react-redux';
import { getFilterName } from '../../utils/FilterNameUtils.js';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography.js';
import ThemeMode from '../themeMode/themeMode.js';
import { useParams } from 'react-router';

const AddGlobalFilterForm = (props) => {
  const { t } = useTranslation();
  const [filterNameError, setFilterNameError] = useState(false);
  const [filterNameDuplicateError, setFilterNameDuplicateError] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [chartDataSourceError, setChartDataSourceError] = useState(false);
  const [projectId, setProjectId] = useState([]);
  const [isFilterListdisplays, setIsFilterListdisplays] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filterListError, setFilterListError] = useState(false);
  const globalFilterKey = 'globalFilters';
  const variantList = useSelector((state) => state.variantList?.variants);
  const taskList = useSelector((state) => state.variantList?.tasks);
  const links = useSelector((state) => state.variantList?.links);
  const routerParams = useParams();

  useEffect(() => {
    if (props.isGlobalFilterEdit) {
      fetchSavedFilters(props.editedGlobalFilter.projectIds[0]);
      setFilterName(props.editedGlobalFilter.globalFilterName);
      setProjectId(props.editedGlobalFilter.projectIds);
      setFilters(props.editedGlobalFilter.filters);
      setIsFilterListdisplays(true);
    }
    if (props.isGlobalFilterEdit === false) {
      if (props?.savedGlobalFilters?.length > 0) {
        const filterLength = props?.savedGlobalFilters?.length + 1;
        const newFilterName = 'Filter' + ' ' + filterLength;
        setFilterName(newFilterName);
      } else {
        const newFilterName = 'Filter' + ' ' + 1;
        setFilterName(newFilterName);
      }
    }
    if (props?.selectedDataSourceList?.length === 1) {
      setProjectId([props?.selectedDataSourceList[0]?.projectId]);
      fetchSavedFilters(props?.selectedDataSourceList[0]?.projectId);
      setIsFilterListdisplays(true);
    }
  }, []);

  const fetchSavedFilters = async (dataSourceId) => {
    try {
      setIsFilterListdisplays(false);
      setLoading(true);
      const response = await ApiService.getSavedFilter(dataSourceId);
      setSavedFilters(response);
      setIsFilterListdisplays(true);
    } catch (error) {
      ToasterError(t('FAILED_SAVED_FILTERS'));
    } finally {
      setLoading(false);
    }
  };

  const setGlobalFilterName = (globalFilterName) => {
    setFilterName(globalFilterName);
    if (globalFilterName && props.isGlobalFilterEdit === false) {
      let filterNameArr = [];
      filterNameArr = props.savedGlobalFilters?.filter(
        (filter) => filter.globalFilterName === globalFilterName
      );
      if (filterNameArr?.length > 0) {
        setFilterNameDuplicateError(true);
      } else {
        setFilterNameDuplicateError(false);
      }
    }
    if (globalFilterName && props.isGlobalFilterEdit === true) {
      let filterNameArr = [];
      filterNameArr = props.savedGlobalFilters?.filter(
        (filter) =>
          filter.globalFilterName === globalFilterName &&
          filter.id !== props?.editedGlobalFilter?.id
      );
      if (filterNameArr?.length > 0) {
        setFilterNameDuplicateError(true);
      } else {
        setFilterNameDuplicateError(false);
      }
    }
  };

  const setFilterData = (dataSourceId) => {
    if (dataSourceId?.length > 0) {
      setChartDataSourceError(false);
    }
    setProjectId(dataSourceId);
    fetchSavedFilters(dataSourceId[0]);
  };

  const handleFilterChange = (filter) => {
    if (filter) {
      setFilterListError(false);
    }
    setFilters(filter);
  };

  const addGlobalFilter = async () => {
    if (filterName === '') {
      setFilterNameError(true);
    }
    if (projectId?.length === 0) {
      setChartDataSourceError(true);
    }
    if (filters?.length === 0) {
      setFilterListError(true);
    }

    if (filters.length > 0) {
      filters.forEach((filter) => {
        filter.name = getFilterName(filter, variantList, taskList, links);
      });
    }

    if (
      filterName !== '' &&
      projectId?.length > 0 &&
      filters?.length > 0 &&
      filterNameDuplicateError === false &&
      props?.isGlobalFilterEdit === false
    ) {
      setFilterNameError(false);
      setChartDataSourceError(false);
      setFilterListError(false);
      const requestBody = {
        id: getUuid(),
        globalFilterName: filterName,
        projectIds: projectId,
        filters: filters
      };
      try {
        setLoading(true);
        await ApiService.saveGlobalFilters(props.dashboardId, requestBody);
        applySelectedGlobalFilter(requestBody);
        props.fetchGlobalFilter(props.dashboardId);
        props.setFilterForm(true);
        ToasterSuccess(t('GLOBAL_FILTER_CREATE'));
      } catch (error) {
        ToasterError(t('FAILED_GLOBAL_FILTERS'));
      } finally {
        setLoading(false);
      }
    }
    if (
      filterName !== '' &&
      projectId?.length > 0 &&
      filters?.length > 0 &&
      filterNameDuplicateError === false &&
      props?.isGlobalFilterEdit === true
    ) {
      let editedGlobalFilterArr = [];
      editedGlobalFilterArr = props?.savedGlobalFilters?.filter(
        (savedGlobalFilter) => savedGlobalFilter.id === props?.editedGlobalFilter?.id
      );
      const requestBody = {
        id: editedGlobalFilterArr[0]?.id,
        globalFilterName: filterName,
        projectIds: projectId,
        filters: filters
      };
      try {
        setLoading(true);
        await ApiService.updateGlobalFilters(props.dashboardId, requestBody);
        applySelectedGlobalFilter(requestBody);
        props.fetchGlobalFilter(props.dashboardId);
        props.setFilterForm(true);
        props.setIsGlobalFilterEdit(false);
        ToasterSuccess(t('GLOBAL_FILTER_UPDATE'));
      } catch (error) {
        ToasterError(t('FAILED_GLOBAL_FILTER_UPDATE'));
      } finally {
        setLoading(false);
      }
    }
  };

  const applySelectedGlobalFilter = (filterToApply) => {
    filterToApply.dashboardId = props?.dashboardId;
    filterToApply?.filters?.forEach((filter) => {
      filter.isGlobalFilter = true;
      filter.globalFilterUniqueId = getUuid();
    });
    const appliedGlobalFilters = getGlobalFiltersFromSessionStorage(globalFilterKey);
    const appliedFilters = appliedGlobalFilters?.length > 0 ? appliedGlobalFilters : [];
    if (appliedFilters?.length > 0) {
      const isGlobalFilterIndex = appliedFilters?.findIndex((appliedFilter) => {
        return appliedFilter?.id === filterToApply?.id;
      });
      if (isGlobalFilterIndex > -1) {
        appliedFilters[isGlobalFilterIndex] = filterToApply;
      } else {
        appliedFilters.push(filterToApply);
      }
    } else {
      appliedFilters.push(filterToApply);
    }
    setGlobalFiltersInSessionStorage(globalFilterKey, appliedFilters);
    props.applyGlobalFilter(appliedFilters);
  };

  return (
    <>
      <div className='global-filter-main-section'>
        {props?.isGlobalFilterEdit && (
          <span className='back-arrow'>
            <ThemeMode />
            <Tooltip title={t('BACK')}>
              <ArrowBackIosIcon onClick={() => props.setFilterForm(true)} id='arrow-back-icon' />
            </Tooltip>
          </span>
        )}
        <div className='add-new-filter'>
          <DashboardTypography id='global-filter-heading' typographyClass={'title-16-700'}>
            {props?.isGlobalFilterEdit ? t('EDIT_FILTER') : t('ADD_FILTER')}
          </DashboardTypography>
        </div>
        <div className='new-filter-form-section'>
          <TextField
            variant='outlined'
            fullWidth
            required
            data-testid='global-filter-name'
            label={t('FILTER_NAME')}
            error={filterNameError}
            id='global-filter-name'
            labelId='global-filter-name'
            placeholder={t('FILTER_NAME')}
            size='small'
            value={filterName}
            onChange={(event) => {
              var regex = /^$|^\S+.*/;
              const labelCheck = regex.test(event.target.value);
              if (labelCheck) {
                setGlobalFilterName(
                  event.target.value.slice(
                    0,
                    configVariables?.globalFilter?.maxGlobalFilterAllowedChar
                  )
                );
                event ? setFilterNameError(false) : setFilterNameError(true);
              }
            }}
          />
          <FormHelperText>
            <DashboardTypography id='filter-helper-text' typographyClass={'title-12-400'}>
              {filterNameError
                ? t('FILTER_NAME_ERROR_MESSAGE')
                : t('MAX_CHARACTERS_MESSAGE', {
                    characterLength: filterName?.length,
                    maxAllowdedCharLength: configVariables?.globalFilter?.maxGlobalFilterAllowedChar
                  })}
            </DashboardTypography>
          </FormHelperText>
          {filterNameDuplicateError && (
            <div className='filter-name-duplicate'>
              <DashboardTypography id='duplicate-filter-name' typographyClass={'title-12-500'}>
                {t('FILTER_NAME_DUPLICATE')}
              </DashboardTypography>
            </div>
          )}
          <div className='filter-data-source'>
            <FormControl fullWidth size='small' error={chartDataSourceError}>
              <InputLabel required id='data-to-display-select-label'>
                {t('DATA_SOURCE')}
              </InputLabel>
              <Select
                label='Data Source'
                labelId='data-source-label'
                id='data-source-label'
                data-testid='global-data-source'
                value={projectId}
                onChange={(event) => {
                  setFilterData([event.target.value]);
                }}
              >
                <ThemeMode></ThemeMode>
                {props?.selectedDataSourceList?.length > 0 ? (
                  props?.selectedDataSourceList?.map((project) => (
                    <MenuItem key={project.projectId} value={project.projectId}>
                      {project.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value='no_data' disabled>
                    {t('APP.EMPTY_DATA_SOURCE_MESSAGE')}
                  </MenuItem>
                )}
              </Select>
              {chartDataSourceError && (
                <FormHelperText>
                  <DashboardTypography
                    id='data-source-helper-text'
                    typographyClass={'title-12-400'}
                  >
                    {t('PLEASE_SELECT_DATA_SOURCE')}
                  </DashboardTypography>
                </FormHelperText>
              )}
            </FormControl>
          </div>
          {isFilterListdisplays && (
            <div className='saved-filters'>
              <FiltersList
                workgroupId={routerParams.workgroupId}
                projectId={projectId[0]}
                filters={filters}
                updateFilters={handleFilterChange}
                savedFilters={savedFilters}
              />
              {filterListError && (
                <DashboardTypography
                  id='filter-list-error-msg'
                  typographyClass={'title-12-500 filter-list-error-msg'}
                >
                  {t('FILTER_LIST_EMPTY')}
                </DashboardTypography>
              )}
            </div>
          )}
        </div>
      </div>
      <div className='global-filter-section global-filter-btn-section'>
        <DashboardButton
          variant='outlined'
          btnClassName='generic-global-filter-btn apply-all-btn'
          dataTestid='cancel-global-filter'
          eventHandler={() => props.setFilterForm(true)}
        >
          {t('CANCEL')}
        </DashboardButton>
        <DashboardButton
          variant='contained'
          btnClassName='generic-global-filter-btn filter-apply-btn'
          dataTestid='add-global-filter-btn'
          eventHandler={addGlobalFilter}
          disabled={
            filterName === '' ||
            projectId?.length === 0 ||
            filters?.length === 0 ||
            filterNameDuplicateError === true
          }
        >
          {props?.isGlobalFilterEdit ? t('Update_AND_APPLY') : t('SAVE_AND_APPLY')}
        </DashboardButton>
      </div>
      {loading === true && <LoadingSpinner></LoadingSpinner>}
    </>
  );
};

AddGlobalFilterForm.propTypes = {
  setFilterForm: PropTypes.func,
  savedGlobalFilters: PropTypes.array,
  isGlobalFilterEdit: PropTypes.bool,
  editedGlobalFilter: PropTypes.object,
  selectedDataSourceList: PropTypes.array,
  setIsGlobalFilterEdit: PropTypes.func,
  dashboardId: PropTypes.string,
  fetchGlobalFilter: PropTypes.func,
  applyGlobalFilter: PropTypes.func
};

export default AddGlobalFilterForm;
