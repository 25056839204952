import { createSlice } from '@reduxjs/toolkit';

const initialReportConfigValidationInfo = {
  reportNameError: false,
  reportTypeError: false,
  chartTypeError: false,
  repositoryError: false
};

const initialReportConfigState = {
  reportConfigValidationInfo: initialReportConfigValidationInfo
};

const reportConfigSlice = createSlice({
  name: 'reportConfig',
  initialState: initialReportConfigState,
  reducers: {
    setReportConfigValidationInfo: (state, action) => {
      state.reportConfigValidationInfo = action.payload;
    },
    resetReportConfigValidationInfo: (state) => {
      state.reportConfigValidationInfo = initialReportConfigValidationInfo;
    }
  }
});

export const { setReportConfigValidationInfo, resetReportConfigValidationInfo } =
  reportConfigSlice.actions;
export default reportConfigSlice.reducer;
