import React, { useEffect, useState } from 'react';
import { Dialog, Grid, TextField } from '@mui/material';
import { setIsWidgetConfigOn } from '../../../Redux/slices/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import configVariables from '../../../utils/ConfigurationVariables';
import WidgetConfigHeaderComponent from '../../shared/WidgetConfigHeaderComponent/WidgetConfigHeaderComponent';
import './customTextComponent.scss';
import getChartOptionObject from '../../../utils/getChartOptionObject';
import ThemeMode from '../../themeMode/themeMode';
import DashboardTypography from '../../shared/DashboardTypography/DashboardTypography';

const CustomTextComponent = (props) => {
  const dispach = useDispatch();
  const reactQuillRef = React.useRef();
  const { t } = useTranslation();
  const chartData = useSelector((state) => state.selectedWidget.chartData);
  const [text, setText] = useState('');
  const [chartLabelError, setChartLabelError] = React.useState(false);
  const [titleLength, setTitleLength] = useState(0);
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [chartDimensions] = React.useState({
    width: 600,
    height: 450
  });
  const [chartOptions, setChartOptions] = useState({
    ...getChartOptionObject('text', 'text', 'customText')
  });

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'color',
    'background',
    'link',
    'image',
    'align',
    'clean'
  ];

  const modules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }],
        [{ font: [], id: 'toolbar-font' }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        [
          { color: [], id: 'toolbar-color' },
          { background: [], id: 'toolbar-background' }
        ],
        [{ align: [], id: 'toolbar-align' }],
        ['link', 'image'],
        ['clean']
      ]
    }
  };

  useEffect(() => {
    updateChartOptions('chartId', props.chartOptions.chartId);
    dispach(setIsWidgetConfigOn(true));
  }, []);

  useEffect(() => {
    if (chartData?.title && chartData?.description) {
      setText(chartData?.description);
      updateChartOptions('description', chartData?.description);
      updateChartOptions('title', chartData?.title);
      updateTitleLength(chartData?.title.length);
    }
  }, [chartData]);

  const updateChartOptions = (key, value) => {
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      [key]: value
    }));
  };

  useEffect(() => {
    updateChartOptions('description', text);
  }, [text]);

  const updateTitleLength = (characterLength) => {
    setTitleLength(characterLength);
  };

  const saveCustomTextWidget = () => {
    if (chartOptions?.title && chartOptions?.description) {
      props.onSaveChanges(chartOptions, null, chartDimensions);
      closeDialog();
    } else {
      setChartLabelError(true);
    }
  };

  const closeDialog = () => {
    dispach(setIsWidgetConfigOn(false));
    props.onClose();
    props.setIsAddEditChartDialogOpen(false);
  };

  const setCharacterLimit = (event) => {
    const isPaste = event.type === 'paste';
    const currentLength = descriptionLength;
    const maxAllowedLength = configVariables.text.maxDescriptionAllowedChar;
    const remainingChars = maxAllowedLength - currentLength;

    if (remainingChars <= 0 && event.key !== 'Backspace' && !isPaste) {
      event.preventDefault();
    } else if (isPaste) {
      event.preventDefault();
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedText = clipboardData.getData('Text');
      const allowedText = pastedText.slice(0, remainingChars);
      const newText = text + allowedText;

      setText(newText);
      setDescriptionLength(newText.length);
      setSelectionAtEnd();
    }
  };

  const extractContent = (description) => {
    const span = document.createElement('span');
    span.innerHTML = description;
    const textContent = span.textContent || span.innerText || '';
    const totalLength = textContent.length;

    if (totalLength <= configVariables.text.maxDescriptionAllowedChar) {
      setText(description);
      setDescriptionLength(totalLength);
    } else {
      const truncatedText = textContent.slice(0, configVariables.text.maxDescriptionAllowedChar);
      setText(truncatedText);
      setDescriptionLength(configVariables.text.maxDescriptionAllowedChar);
    }
    setSelectionAtEnd();
  };

  const setSelectionAtEnd = () => {
    const editor = reactQuillRef.current?.getEditor();
    if (editor) {
      const range = editor.getSelection();
      if (range) {
        range.index = editor.getLength() - 1;
        editor.setSelection(range, 'user');
      }
    }
  };

  return (
    <Dialog
      onClose={closeDialog}
      open={props.open}
      fullScreen
      role='dialog'
      data-testid='custom-text-editor'
    >
      <ThemeMode />
      <Grid container spacing={2} style={{ paddingTop: '26px' }} id='text-widget-container'>
        <WidgetConfigHeaderComponent
          headerTitle={t('TEXT_WIDGET_CONFIG')}
          onClose={closeDialog}
          saveChartConfig={saveCustomTextWidget}
        ></WidgetConfigHeaderComponent>

        <Grid item xs={2}>
          {/* Added for offset */}
        </Grid>
        <Grid item xs={10} style={{ paddingLeft: 'unset' }}>
          <TextField
            required
            key='title'
            error={chartLabelError}
            helperText={chartLabelError ? t('CHART_NAME_ERROR_MESSAGE') : null}
            id='custom-text-chart-label'
            label={t('CHART_NAME')}
            placeholder={t('ENTER')}
            size='small'
            value={chartOptions.title}
            inputProps={{
              maxLength: chartOptions.shape
                ? configVariables[chartOptions.shape]?.maxtitleAllowedChar
                : configVariables?.default?.maxtitleAllowedChar
            }}
            onChange={(event) => {
              const regex = /^$|^\S+.*/;
              const labelCheck = regex.test(event.target.value);
              if (labelCheck) {
                updateChartOptions('title', event.target.value);
                updateTitleLength(event.target.value.length);
                event ? setChartLabelError(false) : setChartLabelError(true);
              }
            }}
          />
          <div className='character-limit-msg'>
            <DashboardTypography typographyClass='title-12-500' id='character-limit-msg'>
              {t('MAX_CHARACTERS_MESSAGE', {
                characterLength: titleLength,
                maxAllowdedCharLength: configVariables[chartOptions.shape]?.maxtitleAllowedChar
              })}
            </DashboardTypography>
          </div>
        </Grid>

        <Grid item xs={2}>
          {/* Added for offset */}
        </Grid>

        <Grid item xs={8} style={{ marginTop: '15px', padding: 'unset', position: 'static' }}>
          <div className='text-editor' data-testid='editor'>
            <ReactQuill
              value={text}
              onChange={extractContent}
              modules={modules}
              formats={formats}
              theme={'snow'}
              onKeyDown={setCharacterLimit}
              ref={reactQuillRef}
              id='text-editor'
            />
          </div>
          <div className='text-description-limit-message'>
            {t('MAX_CHARACTERS_MESSAGE', {
              characterLength: descriptionLength,
              maxAllowdedCharLength: configVariables.text.maxDescriptionAllowedChar
            })}
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

CustomTextComponent.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  setIsAddEditChartDialogOpen: PropTypes.func,
  onSaveChanges: PropTypes.func,
  chartOptions: PropTypes.object
};

export default CustomTextComponent;
