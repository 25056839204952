import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';

const AddIconComponent = (props) => {
  const { eventHandler } = props;

  return (
    <AddIcon
      onClick={eventHandler}
      sx={{
        color: '#041AB9',
        cursor: 'pointer',
        fontWeight: 'bolder'
      }}
    />
  );
};

AddIconComponent.propTypes = {
  eventHandler: PropTypes.func,
};
export default AddIconComponent;
