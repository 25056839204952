import { createSlice } from '@reduxjs/toolkit';

const initialDashboardState = {
  isAddNewDashboardBtnClicked: false,
  selectedDashboardData: {},
  selectedDataSources: [],
  isEditDashboardNameBtnClicked: false,
  isDeleteDashboardBtnClicked: false,
  isFavouriteDashboardBtnClicked: false,
  isDuplicateDashboardBtnClicked: false,
  isDashboardRefreshed: false,
  isCurrentDashboardUpdated: false
};

const allDashboardSlice = createSlice({
  name: 'allDashboard',
  initialState: initialDashboardState,
  reducers: {
    setIsAddNewDashboardBtnClicked: (state, action) => {
      state.isAddNewDashboardBtnClicked = action.payload;
    },
    setIsEditDashboardNameBtnClicked: (state, action) => {
      state.isEditDashboardNameBtnClicked = action.payload;
    },
    setSelectedDashboardData: (state, action) => {
      state.selectedDashboardData = action.payload;
    },
    setSelectedDataSources: (state, action) => {
      state.selectedDataSources = action.payload;
    },
    setIsDeleteDashboardBtnClicked: (state, action) => {
      state.isDeleteDashboardBtnClicked = action.payload;
    },
    setIsFavouriteDashboardBtnClicked: (state, action) => {
      state.isFavouriteDashboardBtnClicked = action.payload;
    },
    setIsDuplicateDashboardBtnClicked: (state, action) => {
      state.isDuplicateDashboardBtnClicked = action.payload;
    },
    setIsDashboardRefreshed: (state, action) => {
      state.isDashboardRefreshed = action.payload;
    },
    setIsCurrentDashboardUpdated: (state, action) => {
      state.isCurrentDashboardUpdated = action.payload;
    },
    resetDashboardState: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialDashboardState[key];
      });
    }
  }
});

export const {
  setIsAddNewDashboardBtnClicked,
  setIsEditDashboardNameBtnClicked,
  setSelectedDashboardData,
  setSelectedDataSources,
  setIsDeleteDashboardBtnClicked,
  setIsFavouriteDashboardBtnClicked,
  setIsDuplicateDashboardBtnClicked,
  setIsDashboardRefreshed,
  setIsCurrentDashboardUpdated,
  resetDashboardState
} = allDashboardSlice.actions;

export default allDashboardSlice.reducer;
