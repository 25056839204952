import { createSlice } from '@reduxjs/toolkit';

const initialRepositoriesState = {
  repositories: []
};

const repositoriesSlice = createSlice({
  name: 'workgroupRepositories',
  initialState: initialRepositoriesState,
  reducers: {
    setRepositories: (state, action) => {
      state.repositories = action.payload;
    }
  }
});

export const { setRepositories } = repositoriesSlice.actions;
export default repositoriesSlice.reducer;
