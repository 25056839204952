import React from 'react';
import PropTypes from 'prop-types';
import ChartRenderer from '../ChartRenderer.js';
import chartService from '../../../services/chartService.js';
import { useSelector } from 'react-redux';

const BarChart = (props) => {
  const isDashboardEditMode = useSelector((state) => state.dashboard.isDashboardEditMode);
  const option = chartService.getChartOption(props.options, props.chartData, props.themeColors, isDashboardEditMode, props.isWidgetConfigureMode);
  return <ChartRenderer width={props.width} height={props.height} option={option} />;
};

BarChart.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  options: PropTypes.object,
  chartData: PropTypes.array,
  themeColors: PropTypes.array,
  isWidgetConfigureMode: PropTypes.bool,
};

export default BarChart;
