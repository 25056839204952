import React from 'react';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts';
import '../../styles/ChartRenderer.scss';

const ChartRenderer = (props) => {
  return (
    <div className='chart-container' width={props.width} height={props.height} data-testid='chart'>
      <ReactEChartsCore
        className='charts-section'
        echarts={echarts}
        option={props.option}
        opts={{ renderer: 'svg' }}
      />
    </div>
  );
};

ChartRenderer.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  option: PropTypes.object
};

export default ChartRenderer;
