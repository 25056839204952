import makeStyles from '@mui/styles/makeStyles';
export const useStyles = makeStyles((theme) => ({
  selectedTab: {
    backgroundColor: '#CDD1F1 !important',
    color: '#4543AE !important',
    fontWeight: '600 !important'
  },
  '& .MuiTabs-root': {
    minHeight: '35px'
  },
  '& .MuiTab-root': {
    minHeight: '35px'
  },
  customPeriod: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '4%'
  },
  endPeriod: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '3%'
  },
  periodType: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2%'
  },
  periodTypeContainer: {
    width: '380px'
  },
  tabsContainer: {
    height: 'auto',
    marginTop: '5px',
    borderRadius: '6px',
    width: '100%'
  },
  tabContent: {
    margin: '2%'
  },
  width48: {
    width: '48%'
  },
  occurrencesField: {
    display: 'grid',
    width: '48%'
  },
  metricLink: {
    display: 'flex',
    alignItems: 'center',
    width: '48%'
  },
  metricLinkSelect: {
    width: '50%'
  }
}));
