import ApiService from '../../services/ApiService';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Select, FormControl, MenuItem, Grid, TextField, Typography } from '@mui/material';
import '../../styles/AddNewApp.css';
import { toast } from 'react-toastify';
import LoadingSpinner from '../loader/LoadingSpinner';
import '../../styles/LoadingSpinner.css';
import '../../styles/FiltersLayout.scss';
import { OPERATORS } from '../../utils/Constants';
import InputAdornment from '@mui/material/InputAdornment';
import ThemeMode from '../themeMode/themeMode';

const ConformanceFilter = (props) => {
  const { t } = useTranslation();
  const [conformanceModels, setConformanceModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState({
    modelId: '',
    modelName: '',
    filterType: 'conformance',
    operator: '>=',
    value: 0,
    name: [],
    displayVal: 0
  });
  const [conformanceValueError, setConformanceValueError] = useState(false);
  useEffect(() => {
    fetchValidModels();
    if (props.filterObj && props.filterObj.modelId) {
      setSelectedModel(props.filterObj);
    }
    if (props.filterObj && props.filterObj.modelId) {
      props.setIsValid(true);
    } else {
      props.setIsValid(false);
    }
  }, []);

  useEffect(() => {
    props.handleFilterChange(selectedModel);
  }, [selectedModel]);

  useEffect(() => {
    updateName();
  }, [selectedModel.modelId, selectedModel.value, selectedModel.operator]);

  const fetchValidModels = async () => {
    const requestBody = {
      search: null,
      filterPageNo: '1',
      filterPageSize: '100',
      filterSortOrder: 'ASC'
    };
    try {
      setLoading(true);
      const response = await ApiService.getConformanceModels(
        requestBody,
        props.workgroupId,
        props.projectId
      );
      if (response) {
        setConformanceModels(response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t('FILTER.FAILED_TO_FETCH_CONFORMANCE_MODELS_ERROR'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    }
  };
  const updateName = () => {
    const name = [];
    if (selectedModel) {
      name.push(
        'FILTER.CONFORMANCE',
        selectedModel.modelName,
        selectedModel.operator,
        selectedModel.value * 100 + '%'
      );
      setSelectedModel((prevSelectedModel) => ({
        ...prevSelectedModel,
        name: name
      }));
    }
  };

  const handleFilterChange = (value) => {
    setSelectedModel((prevSelectedModel) => ({
      ...prevSelectedModel,
      modelId: value.id,
      modelName: value.name
    }));
    props.handleFilterChange(selectedModel);
    if (value) {
      props.setIsValid(true);
    } else {
      props.setIsValid(false);
    }
  };

  const handleOperatorChange = (selectedOperator) => {
    setSelectedModel((prevDurationFilterData) => ({
      ...prevDurationFilterData,
      operator: selectedOperator
    }));
  };

  const handleConformanceValueChange = (val) => {
    if (!val || val > 100 || val < 0) {
      setConformanceValueError(true);
      props.setIsValid(false);
    } else {
      setConformanceValueError(false);
      props.setIsValid(true);
    }
    setSelectedModel((prevDurationFilterData) => ({
      ...prevDurationFilterData,
      value: val / 100,
      displayVal: val
    }));
  };
  return (
    <Grid container>
      <Grid item lg={6} xl={6} className='paddingRight20'>
        <FormControl fullWidth size='small'>
          <label id='filter_conformance_label' className='filter-label'>
            {t('FILTER.CONFORMANCE_TO_MODEL')}
          </label>
          <Select
            labelId='filter_conformance_label'
            id='filter_conformance_label'
            key='filter_conformance'
            data-testid='filter_conformance_select'
            style={{ backgroundColor: '#F9F9Fc' }}
            placeholder={t('FILTER.CONFORMANCE_TO_MODEL')}
            value={selectedModel.modelId}
            onChange={(event) => {
              handleFilterChange(event.target.value);
            }}
            renderValue={(value) => {
              let item = null;
              if (conformanceModels.length > 0) {
                item = conformanceModels.find((v) => v.id === value);
                return item ? item.name : '';
              }
            }}
          >
            <ThemeMode></ThemeMode>
            {conformanceModels &&
              conformanceModels.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option.name}
                </MenuItem>
              ))}
          </Select>
          {loading === true && <LoadingSpinner></LoadingSpinner>}
        </FormControl>
      </Grid>
      <Grid item lg={3} xl={3} className='paddingRight20'>
        <div className='paddingLeft'>
          <Typography
            sx={{
              color: '#4D5464',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '20px',
              letterSpacing: '0.07px',
              marginBottom: '5px'
            }}
          >
            {t('FILTER.OPERATOR')}
          </Typography>
          <Select
            value={selectedModel.operator}
            onChange={(e) => {
              handleOperatorChange(e.target.value);
            }}
            sx={{ height: '40px' }}
            fullWidth
            data-testid='conformance-operator-select'
            labelId='conformance-operator_label'
            id='conformance-operator_label'
            key='conformance-operator'
            role='listbox'
          >
            <ThemeMode></ThemeMode>
            {OPERATORS.map((operator) => (
              <MenuItem key={operator.key} value={operator.key}>
                {operator.value}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Grid>
      <Grid item lg={3} xl={3}>
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 500,
            color: '#4D5464',
            lineHeight: '20px',
            letterSpacing: '0.07px',
            marginBottom: '5px'
          }}
        >
          {t('FILTER.CONFORMANCE_LABEL')}
        </Typography>
        <TextField
          id='numeric-value'
          error={conformanceValueError}
          helperText={conformanceValueError ? '' : null}
          value={selectedModel.displayVal}
          onChange={(e) => {
            handleConformanceValueChange(e.target.value);
          }}
          type='number'
          className='numeric-textfield'
          size='small'
          placeholder={t('FILTER.CONFORMANCE_LABEL')}
          data-testid='conformance-value'
          InputProps={{
            endAdornment: <InputAdornment>%</InputAdornment>
          }}
        />
      </Grid>
    </Grid>
  );
};
ConformanceFilter.propTypes = {
  handleFilterChange: PropTypes.func,
  workgroupId: PropTypes.string,
  projectId: PropTypes.string,
  filterObj: PropTypes.object,
  setIsValid: PropTypes.func
};
export default ConformanceFilter;
