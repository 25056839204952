import { createSlice } from '@reduxjs/toolkit';

const initialMetricAndStatisticsState = {
  metricAndStatistics: {}
};

const metricAndStatisticsSlice = createSlice({
  name: 'metricAndStatistics',
  initialState: initialMetricAndStatisticsState,
  reducers: {
    setMetricAndStatistics: (state, action) => {
      state.metricAndStatistics = action.payload;
    }
  }
});

export const { setMetricAndStatistics } = metricAndStatisticsSlice.actions;
export default metricAndStatisticsSlice.reducer;
