import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  MenuItem,
  Chip,
  Box,
  OutlinedInput,
  ListItemText,
  Checkbox
} from '@mui/material';
import Button from '@mui/material/Button';
import '../../styles/AppsLayout.scss';
import configVariables from '../../utils/ConfigurationVariables';
import ThemeMode from '../themeMode/themeMode';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import DashboardTemplate from './DashboardTemplate.js';
import { ToasterError } from '../../utils/toaster.js';
import ApiService from '../../services/ApiService.js';
import LoadingSpinner from '../loader/LoadingSpinner.js';

const AddNewApp = (props) => {
  const { t } = useTranslation();
  const [appNameError, setAppNameError] = useState(false);
  const [appNameDuplicateError, setAppNameDuplicateError] = useState(false);
  const [dataSourceError, setDataSourceError] = useState(false);
  const [selectedDataSourceName, setSelectedDataSourceName] = useState([]);
  const [dashboardTemplates, setDashboardTemplates] = useState([]);
  const [loading, setLoading] = useState(false);

  const [app, setApp] = useState({
    name: '',
    description: '',
    workgroupId: '',
    workgroupName: '',
    connection: {
      projectIds: []
    },
    isPublic: 'true',
    dashboardTemplateId: ''
  });

  const selectedDataSource = () => {
    const selectedDataToDisplay = [];
    props?.appToEdit?.connection?.projectIds?.forEach((projectId) => {
      props?.projects?.forEach((projects) => {
        if (projects.projectId === projectId) {
          selectedDataToDisplay.push(projects);
        }
      });
    });
    return selectedDataToDisplay;
  };

  const constructDashboardTemplateData = (templates) => {
    const updatedTemplates = [];
    const blankTemplate = {
      id: '',
      name: 'Blank Template',
      description: 'Dashboard will be created with no pre-configured widgets',
      widgetNames: []
    };
    updatedTemplates.push(blankTemplate);
    templates?.forEach((template) => {
      updatedTemplates.push(template);
    });
    return updatedTemplates;
  };

  const getDashboardTemplates = async () => {
    try {
      setLoading(true);
      const response = await ApiService.fetchDashboardTemplates();
      const updatedDashboardTemplate = constructDashboardTemplateData(response);
      setDashboardTemplates(updatedDashboardTemplate);
    } catch (error) {
      ToasterError(t('DASHBOARD_TEMPLATE.TEMPLATE_FETCH_FAILED_INFO'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.open) {
      getDashboardTemplates();
      if (props.appToEdit) {
        setApp((prevApp) => ({
          ...prevApp,
          ...props.appToEdit,
          connection: {
            projectIds: props?.appToEdit?.connection?.projectIds || []
          }
        }));
        setAppDialogTitle(t('APP.EDIT_APP'));
        setSelectedDataSourceName(selectedDataSource());
      } else {
        setApp({
          name: '',
          description: '',
          workgroupId: '',
          workgroupName: '',
          connection: {
            projectIds: []
          },
          isPublic: 'true'
        });
        setAppDialogTitle(t('ADD_NEW_APP'));
      }

      setAppNameError(false);
      setAppNameDuplicateError(false);
      setDataSourceError(false);
    }
  }, [props.open, props.appToEdit]);

  const [appDialogTitle, setAppDialogTitle] = useState('');

  const addUpdateApp = () => {
    if (
      app?.name &&
      app?.connection?.projectIds?.length > 0 &&
      props?.appToEdit &&
      !appNameDuplicateError
    ) {
      props.updateApp(app);
      props.handleClose();
    } else if (
      app?.name &&
      app?.connection?.projectIds?.length > 0 &&
      !props?.appToEdit &&
      !appNameDuplicateError
    ) {
      props.handleSave(app);
      props.handleClose();
    } else {
      app?.name ? setAppNameError(false) : setAppNameError(true);
      app?.connection?.projectIds.length !== 0
        ? setDataSourceError(false)
        : setDataSourceError(true);
    }
  };
  const handleAppChange = (key, value) => {
    if (key === 'name') {
      if (value) {
        let valueArr = [];
        valueArr = props.totalApps?.includes(value);
        if (valueArr) {
          setAppNameDuplicateError(true);
        } else {
          setAppNameDuplicateError(false);
        }
      }
    }
    setApp((prevApp) => ({
      ...prevApp,
      [key]: value
    }));
  };

  const selectDataSource = (selectedProjectIds) => {
    setApp((prevApp) => ({
      ...prevApp,
      connection: { ...prevApp.connection, projectIds: selectedProjectIds }
    }));
  };

  // To set max Height for dropdown section for multiple data source
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
        width: 250
      }
    }
  };

  const getLabel = (projectId) => {
    if (props.projects.length > 0) {
      const dataSource = props.projects.find((item) => item.projectId === projectId);
      return dataSource.name;
    }
  };

  const removeSelectedDataSource = (id) => {
    const index = app.connection.projectIds.findIndex((item) => item === id);
    app.connection.projectIds.splice(index, 1);
    selectDataSource(app.connection.projectIds);
  };

  const setDashboardTemplateId = (key, value) => {
    setApp((prevApp) => ({
      ...prevApp,
      [key]: value
    }));
  };

  return (
    <div id='add-new-app'>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby='add-widget-dialog-title'
        open={props.open}
        role='dialog'
        PaperProps={{
          sx: {
            height: '520px',
            maxHeight: '800px',
            maxWidth: '1000px',
            width: '950px'
          }
        }}
      >
        <ThemeMode></ThemeMode>
        <DialogTitle id='dialog-title'>
          <DashboardTypography id='new-app-title' typographyClass={'title-18-700'}>
            {appDialogTitle}
          </DashboardTypography>
        </DialogTitle>
        <Grid container spacing={2} className='app-config-container'>
          <Grid xs={6} item className='app-config-section'>
            <DialogContent className='dialog-mainSection' id='dialog-content'>
              <Grid container spacing={2} className='dialogBody-grid' rowSpacing={0.5}>
                <Grid item xs={12} className='appgrid-item'>
                  <InputLabel required className='apps-label'>
                    <DashboardTypography id='apps-label' typographyClass={'title-14-400'}>
                      {t('APP_NAME')}
                    </DashboardTypography>
                  </InputLabel>
                  <TextField
                    variant='outlined'
                    required
                    fullWidth
                    key='App Name'
                    error={appNameError}
                    id='chart-label'
                    placeholder={t('APP_NAME')}
                    size='small'
                    className='app-input'
                    value={app.name}
                    onChange={(event) => {
                      var regex = /^$|^\S+.*/;
                      const labelCheck = regex.test(event.target.value);
                      if (labelCheck) {
                        handleAppChange(
                          'name',
                          event.target.value.slice(0, configVariables.app.maxAppNameAllowedChar)
                        );
                        event ? setAppNameError(false) : setAppNameError(true);
                      }
                    }}
                  />
                  <FormHelperText>
                    <DashboardTypography id='app-name-helper-text' typographyClass={'title-12-400'}>
                      {appNameError
                        ? t('APP_NAME_ERROR_MESSAGE')
                        : t('MAX_CHARACTERS_MESSAGE', {
                            characterLength: app?.name?.length,
                            maxAllowdedCharLength: configVariables?.app?.maxAppNameAllowedChar
                          })}
                    </DashboardTypography>
                  </FormHelperText>
                  {appNameDuplicateError === true ? (
                    <div className='appName-errorSection'>App Name is Duplicate</div>
                  ) : null}
                </Grid>
                <Grid item xs={12} className='grid-section appgrid-item'>
                  <InputLabel className='apps-label'>
                    <DashboardTypography id='apps-description' typographyClass={'title-14-400'}>
                      {t('DESCRIPTION')}
                    </DashboardTypography>
                  </InputLabel>
                  <TextField
                    fullWidth
                    key='Description'
                    id='app-description'
                    placeholder={t('DESCRIPTION')}
                    size='large'
                    className='description-input'
                    value={app.description}
                    onChange={(event) => {
                      var regex = /^$|^\S+.*/;
                      const labelCheck = regex.test(event.target.value);
                      if (labelCheck) {
                        handleAppChange('description', event.target.value.slice(0, 255));
                      }
                    }}
                  />
                  <FormHelperText>
                    <DashboardTypography
                      id='description-helper-text'
                      typographyClass={'title-12-400'}
                    >
                      {' '}
                      {t('MAX_CHARACTERS_MESSAGE', {
                        characterLength: app?.description?.length,
                        maxAllowdedCharLength: configVariables?.app?.maxDescriptionAllowedChar
                      })}
                    </DashboardTypography>
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} className='grid-section appgrid-item'>
                  <FormControl fullWidth size='small' error={dataSourceError}>
                    {!props.appToEdit ? (
                      <label id='data-source-label' className='data-source-label'>
                        <DashboardTypography
                          id='data-source-label-heading'
                          typographyClass={'title-14-400'}
                        >
                          {' '}
                          {t('SELECT_DATA_SOURCE', {
                            maxAllowdedDataSources: configVariables?.app?.maxAllowdedDataSources
                          })}{' '}
                        </DashboardTypography>
                        <span className='red-asterisk'>*</span>
                      </label>
                    ) : (
                      <label id='selected-data-source-label' className='data-source-label'>
                        <DashboardTypography
                          id='selected-data-source-label-heading'
                          typographyClass={'title-14-400'}
                        >
                          {' '}
                          {t('SELECTED_DATA_SOURCE')}
                        </DashboardTypography>
                      </label>
                    )}
                    {!props.appToEdit ? (
                      <Select
                        labelId='data-source-label'
                        id='select-data-source-label'
                        key='data-source'
                        data-testid='data-source-select'
                        style={{
                          width: '100%',
                          border: '1px solid #D8D8D8'
                        }}
                        multiple
                        MenuProps={MenuProps}
                        value={
                          props.projects && props.projects.length > 0
                            ? app.connection.projectIds
                            : []
                        }
                        onChange={(event) => {
                          selectDataSource(event.target.value);
                        }}
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                            border: 0
                          },
                          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: 0
                          }
                        }}
                        input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected?.map((value) => (
                              <Chip
                                key={value}
                                label={getLabel(value)}
                                variant='light'
                                data-testid='source-chip'
                                size='small'
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                                onDelete={() => {
                                  removeSelectedDataSource(value);
                                }}
                                sx={{ color: '#041AB9', backgroundColor: '#CDD1F1' }}
                                id='chip'
                              />
                            ))}
                          </Box>
                        )}
                      >
                        <ThemeMode></ThemeMode>
                        {props.projects && props.projects.length > 0 ? (
                          props.projects.map((option, index) => (
                            <MenuItem
                              key={index}
                              value={option.projectId}
                              disabled={
                                app.connection.projectIds.length ===
                                  configVariables?.app?.maxAllowdedDataSources &&
                                app.connection.projectIds.indexOf(option.projectId) <= -1
                              }
                            >
                              <Checkbox
                                checked={app.connection.projectIds.indexOf(option.projectId) > -1}
                              />
                              <ListItemText>{option.name}</ListItemText>
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value='no_data' disabled>
                            {t('APP.EMPTY_DATA_SOURCE_MESSAGE')}
                          </MenuItem>
                        )}
                      </Select>
                    ) : (
                      <div
                        className='data-source-chip'
                        data-testid='data-source-chip'
                        id='data-source-chip'
                      >
                        {selectedDataSourceName.map((value) => (
                          <Chip
                            key={value.projectId}
                            label={value.name}
                            variant='light'
                            size='small'
                            id='selected-datasource-chip'
                            sx={{ color: '#041AB9', backgroundColor: '#CDD1F1' }}
                          />
                        ))}
                      </div>
                    )}

                    {dataSourceError && (
                      <FormHelperText>{t('DATA_SOURCE_ERROR_MESSAGE')}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item xs={6} className='dashboard-template-grid'>
            <DashboardTemplate
              setDashboardTemplateId={setDashboardTemplateId}
              dashboardTemplates={dashboardTemplates}
            />
          </Grid>
        </Grid>
        <DialogActions id='dialog-action'>
          <Grid container item xs={12} className={props?.appToEdit ? 'grid-edit-btn' : 'grid-btn'}>
            <Grid item xs={6} className='cancel-btn-section'>
              <Button
                data-testid='cancel-app'
                onClick={props.handleClose}
                className='cancel-btn genericApps-btn'
              >
                {t('CANCEL')}
              </Button>
            </Grid>
            <Grid item xs={6} className='save-btn-section'>
              <Button
                autoFocus
                onClick={addUpdateApp}
                data-testid='save-app-button'
                inputprops={{ 'data-testid': 'save-app-button' }}
                className={
                  app.name === '' ||
                  app.connection.projectIds.length === 0 ||
                  appNameDuplicateError === true
                    ? 'disabledSave-btn genericApps-btn'
                    : 'save-btn genericApps-btn'
                }
              >
                {props.appToEdit ? t('UPDATE') : t('CreateApp')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {loading === true && <LoadingSpinner></LoadingSpinner>}
    </div>
  );
};
AddNewApp.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  projects: PropTypes.array,
  handleSave: PropTypes.func,
  totalApps: PropTypes.array,
  appToEdit: PropTypes.object,
  updateApp: PropTypes.func
};
export default AddNewApp;
