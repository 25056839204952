import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, MenuItem, Select } from '@mui/material';
import ThemeMode from '../../themeMode/themeMode';

const ZoomLevelDropDown = (props) => {
  return (
    <FormControl>
      <Select
        data-testid='zoom-btn'
        id="zoom-value"
        value={props.svgZoomValue}
        onChange={props.changeZoomValue}
      >
        <ThemeMode></ThemeMode>
        <MenuItem value={0.1}>10%</MenuItem>
        <MenuItem value={0.25}>25%</MenuItem>
        <MenuItem value={0.5}>50%</MenuItem>
        <MenuItem value={0.75}>75%</MenuItem>
        <MenuItem value={1}>100%</MenuItem>
        <MenuItem value={1.25}>125%</MenuItem>
        <MenuItem value={1.50}>150%</MenuItem>
        <MenuItem value={1.75}>175%</MenuItem>
        <MenuItem value={2}>200%</MenuItem>
        <MenuItem value={2.25}>225%</MenuItem>
        <MenuItem value={2.50}>250%</MenuItem>
      </Select>
    </FormControl>
  );
};

ZoomLevelDropDown.propTypes = {
  changeZoomValue: PropTypes.func,
  svgZoomValue: PropTypes.number

};

export default ZoomLevelDropDown;
