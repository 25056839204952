import { createSlice } from '@reduxjs/toolkit';

const initialSelectedExistingWidgetState = {
  selectedExistingWidget: {
    dashboardId: '',
    chartId: ''
  }
};

const existingWidgetSlice = createSlice({
  name: 'existingWidget',
  initialState: initialSelectedExistingWidgetState,
  reducers: {
    setSelectedExistingWidget: (state, action) => {
      state.selectedExistingWidget.dashboardId = action.payload.dashboardId;
      state.selectedExistingWidget.chartId = action.payload.chartId;
    }
  }
});

export const { setSelectedExistingWidget } = existingWidgetSlice.actions;

export default existingWidgetSlice.reducer;
