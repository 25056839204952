import { toast } from 'react-toastify';

export const ToasterSuccess = (message, position = 'top-right') => {
    toast.success(message, {
        position: position,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
    });
};

export const ToasterError = (message, position = 'top-right') => {
    toast.error(message, {
        position: position,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
    });
};