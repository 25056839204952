import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ProcessModelView from '../charts/ProcessModelView/ProcessModelView';
import '../../styles/LoadingSpinner.scss';
import LoadingSpinner from '../loader/LoadingSpinner';
import {
  Paper,
  Typography,
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Badge
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../../styles/DashboardLayout.css';
import ApiService from '../../services/ApiService';
import { toast } from 'react-toastify';
import '../../styles/ProcessModelPreviewPanel.scss';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ProcessModelSettingsPanel from './ProcessModelSettingsPanel';
import { METRICS } from '../../utils/Constants';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import SwipeDownAltIcon from '@mui/icons-material/SwipeDownAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SwipeRightAltIcon from '@mui/icons-material/SwipeRightAlt';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedVariantIndex } from '../../Redux/slices/variantSelectionSlice';
import ProcessModelVariantsSelectionPanel from './ProcessModelVariantsSelectionPanel';
import {
  setIsResetZoomTriggerOnFullScreen,
  setZoomValue,
  setZoomValueFromDropDown
} from '../../Redux/slices/processModelCofigSlice';
import ZoomLevelDropDown from './ZoomLevelDropdown/ZoomLevelDropdown';

const ProcessModelPreviewPanel = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { chartOptions, workgroupId, setChartOptions } = props;

  const isSettingsPanelOpen = useSelector((state) => state.processModelConfig.isSettingsPanelOpen);
  const metricAndStatistics = useSelector((state) => state.metricAndStatistics.metricAndStatistics);
  const svgZoomValue = useSelector((state) => state.processModelConfig.svgZoomValue);
  const isDashboardEditMode = useSelector((state) => state.dashboard.isDashboardEditMode);

  const [loading, setLoading] = useState(false);
  const [displayChartOptions, setDisplayChartOptions] = useState({ ...props.chartOptions });
  const [selectedIcon, setSelectedIcon] = useState('portrait');
  const [settingsPanel, setSettingsPanel] = useState(false);
  const [isFromDefaultConfig, setIsFromDefaultConfig] = useState(true);
  const [variantSelectionPanel, setVariantSelectionPanel] = useState(false);
  const [variantsStatistic, setVariantsStatistic] = useState([]);
  const { totalSelectedVariants } = useSelector((state) => state.variantSelection);

  const svgContainer =
    isDashboardEditMode && isSettingsPanelOpen
      ? 'svg-container-view-edit-mode'
      : 'svg-container-view';
  useEffect(() => {
    if (chartOptions?.sliderCombination) {
      fetchProcessModel(chartOptions);
    }
  }, [chartOptions.sliderCombination]);

  useEffect(() => {
    setDisplayChartOptions(props.chartOptions);
    setSelectedIcon(props.chartOptions?.orientation);
    setIsFromDefaultConfig(true);
    if (
      chartOptions.metric &&
      chartOptions.statistic &&
      metricAndStatistics['statistics']?.length
    ) {
      fetchProcessModel(chartOptions);
    }
  }, [
    chartOptions.metric,
    chartOptions.selectedDefaultStatistics,
    chartOptions.ruleId,
    chartOptions.modelId,
    chartOptions.isOkConformance,
    chartOptions.showModel,
    chartOptions.orientation,
    metricAndStatistics.statistics
  ]);

  useEffect(() => {
    if (
      !isFromDefaultConfig &&
      displayChartOptions.metric &&
      displayChartOptions.statistic &&
      metricAndStatistics['statistics']?.length
    ) {
      fetchProcessModel(displayChartOptions);
    }
    setIsFromDefaultConfig(false);
  }, [
    displayChartOptions.metric,
    displayChartOptions.statistic,
    displayChartOptions.ruleId,
    displayChartOptions.modelId,
    displayChartOptions.isOkConformance,
    displayChartOptions.showModel,
    displayChartOptions.orientation,
    metricAndStatistics.statistics
  ]);

  const changeZoomValue = (event) => {
    dispatch(setZoomValueFromDropDown(event.target.value));
    dispatch(setZoomValue(event.target.value));
  };

  const updateOrientation = (orientation) => {
    if (orientation !== null) {
      setSelectedIcon(orientation);

      setChartOptions((prevChartOptions) => ({
        ...prevChartOptions,
        orientation: orientation
      }));

      setDisplayChartOptions((prevChartOptions) => ({
        ...prevChartOptions,
        orientation: orientation
      }));
    }
  };

  const fetchProcessModel = async (chartOption) => {
    try {
      setLoading(true);
      const response = await ApiService.generateProcessModelView(
        chartOption,
        chartOption.projectIds[0]
      );
      setChartOptions((prevChartOptions) => ({
        ...prevChartOptions,
        chartData: response.svg,
        legend: response.legend
      }));
      setVariantsStatistic(response.variantsStatistic);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t('PROCESS_MODEL.FAILED_FETCH_PROCESS_MODEL'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    }
  };

  const applyVariant = (firstVariantIndex, lastVariantIndex) => {
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      sliderCombination: {
        firstVariantIndex: firstVariantIndex,
        lastVariantIndex: lastVariantIndex
      }
    }));
  };

  const openVariantsSelectionPopup = () => {
    setVariantSelectionPanel(true);
  };

  const closeVariantsSelectionPopup = () => {
    setVariantSelectionPanel(false);
  };

  const openSettingsPopup = () => {
    setSettingsPanel(true);
  };

  const closeSettingsPopup = () => {
    setSettingsPanel(false);
  };

  const exitFullScreen = () => {
    dispatch(setZoomValueFromDropDown(0));
    dispatch(setZoomValue(0.1));
    dispatch(
      setSelectedVariantIndex({ firstSelectedVariantIndex: 0, lastSelectedVariantIndex: 0 })
    );
    props.onExitFullscreen();
  };

  const displayMetricsAndStatisticsChange = (updatedDisplayMetrics) => {
    const selectedMetrics = updatedDisplayMetrics['selectedMetrics'];
    const selectedStatistics = updatedDisplayMetrics['selectedStatistics'];
    const selectedRule = updatedDisplayMetrics['selectedBusinessRule'];
    const selectedModel = updatedDisplayMetrics['selectedConformanceModel'];
    setIsFromDefaultConfig(false);
    if (METRICS.includes(selectedMetrics.id)) {
      setDisplayChartOptions((prevChartOptions) => ({
        ...prevChartOptions,
        metric: selectedMetrics.id,
        otherMetricId: '',
        statistic: selectedStatistics.name,
        displayStatistic: selectedStatistics,
        displayBusinessRule: selectedRule,
        ruleId: selectedRule ? selectedRule?.id : null,
        displayConformanceModel: selectedModel,
        modelId: selectedModel ? selectedModel?.id : null,
        isOkConformance: selectedStatistics?.id?.includes('non-') ? false : true,
        showModel: updatedDisplayMetrics?.showConformanceModel
      }));
    } else {
      setDisplayChartOptions((prevChartOptions) => ({
        ...prevChartOptions,
        metric: 'metric',
        otherMetricId: selectedMetrics.id,
        statistic: selectedStatistics.name,
        displayStatistic: selectedStatistics
      }));
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        sx={{ margin: '20px', width: '95.3%' }}
      >
        <Grid item>
          <Grid container spacing={1} alignItems='center'>
            <Grid item>
              <ZoomLevelDropDown
                svgZoomValue={svgZoomValue}
                changeZoomValue={changeZoomValue}
              ></ZoomLevelDropDown>
            </Grid>

            <Grid>
              <Tooltip title={t('PROCESS_MODEL.RESET_ZOOM')} arrow>
                <Button
                  variant='outlined'
                  data-testid='reset-zoom-btn'
                  sx={{
                    marginLeft: '5%',
                    height: '40px',
                    color: '#3648C7',
                    border: '1px solid #3648C7',
                    marginTop: '7px'
                  }}
                  onClick={() => dispatch(setIsResetZoomTriggerOnFullScreen(true))}
                >
                  <RestartAltIcon />
                </Button>
              </Tooltip>
            </Grid>

            {!isSettingsPanelOpen && (
              <Grid item>
                <Tooltip title={t('PROCESS_MODEL.EXIT_FULLSCREEN')} arrow>
                  <Button
                    variant='outlined'
                    data-testid='exit-fullscreen-btn'
                    sx={{
                      height: '39px',
                      color: '#3648C7',
                      border: '1px solid #3648C7',
                      marginTop: '1px'
                    }}
                    onClick={exitFullScreen}
                  >
                    <FullscreenExitIcon />
                  </Button>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isSettingsPanelOpen && (
          <ToggleButtonGroup
            value={selectedIcon}
            color='primary'
            exclusive
            onChange={(event, newIcon) => updateOrientation(newIcon)}
            data-testid='orientation-toggle-button-group'
          >
            <Tooltip title={t('PROCESS_MODEL.PORTRAIT')}>
              <ToggleButton
                value='portrait'
                aria-label='portrait'
                className={
                  selectedIcon === 'portrait'
                    ? 'portrait-orientation-selected'
                    : 'portrait-orientation-not-selected'
                }
              >
                <SwipeDownAltIcon />
              </ToggleButton>
            </Tooltip>

            <Tooltip title={t('PROCESS_MODEL.LANDSCAPE')}>
              <ToggleButton
                value='landscape'
                aria-label='landscape'
                className={
                  selectedIcon === 'landscape'
                    ? 'portrait-orientation-selected'
                    : 'portrait-orientation-not-selected'
                }
              >
                <SwipeRightAltIcon />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        )}
      </Grid>
      <Grid container justifyContent='space-between' sx={{ width: '98%' }}>
        <Grid item>
          <Typography variant='h6' className='process-model-chart-title'>
            {chartOptions && chartOptions.title ? chartOptions.title : null}
          </Typography>
        </Grid>
        {!isSettingsPanelOpen && (
          <Grid item className='settings-container'>
            <Tooltip title={t('SETTINGS')} arrow>
              <Button
                onClick={openSettingsPopup}
                className='process-chart-btn'
                data-testid='setting-btn'
              >
                <SettingsOutlinedIcon className='icon-color' />
              </Button>
            </Tooltip>
            <Tooltip title={t('PROCESS_MODEL.VARIANT_SELECTION')} arrow>
              <Button
                className='process-chart-btn'
                data-testid='variant-bar-chart-selection-btn'
                onClick={openVariantsSelectionPopup}
              >
                <Badge badgeContent={totalSelectedVariants} color='primary'>
                  <BarChartIcon className='icon-color' />
                </Badge>
              </Button>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      {chartOptions.chartData && chartOptions.chartData.length > 0 && (
        <div className={svgContainer}>
          <ProcessModelView
            chartData={chartOptions.chartData}
            isPreview={true}
            chartOption={chartOptions}
          />
        </div>
      )}
      {loading === true && <LoadingSpinner></LoadingSpinner>}

      <ProcessModelSettingsPanel
        open={settingsPanel}
        panelClose={closeSettingsPopup}
        applyDisplayMetricsAndStatisticsConfig={displayMetricsAndStatisticsChange}
        selectedDisplayMetrics={displayChartOptions.metric}
        selectedDisplayMetricsId={displayChartOptions.otherMetricId}
        selectedDisplayStatistics={
          displayChartOptions.displayStatistic
            ? displayChartOptions.displayStatistic
            : displayChartOptions.statistic
        }
        selectedDisplayBusinessRule={
          displayChartOptions.displayBusinessRule
            ? displayChartOptions.displayBusinessRule
            : displayChartOptions.selectedDefaultBusinessRule
        }
        selectedDisplayConformanceModel={
          displayChartOptions.displayConformanceModel
            ? displayChartOptions.displayConformanceModel
            : displayChartOptions.selectedDefaultConformanceModel
        }
        selectedDisplayShowModel={displayChartOptions.showModel}
        selectedIsOkConformance={displayChartOptions.isOkConformance}
      ></ProcessModelSettingsPanel>

      {/* Variant selection modal from bar chart */}
      {variantSelectionPanel && (
        <ProcessModelVariantsSelectionPanel
          open={variantSelectionPanel}
          panelClose={closeVariantsSelectionPopup}
          variantsStatistic={variantsStatistic}
          onApply={applyVariant}
          workgroupId={workgroupId}
          chartOptions={chartOptions}
        />
      )}

      {loading === true && <LoadingSpinner></LoadingSpinner>}
    </React.Fragment>
  );
};

ProcessModelPreviewPanel.propTypes = {
  chartOptions: PropTypes.object,
  workgroupId: PropTypes.string,
  setChartOptions: PropTypes.func,
  onExitFullscreen: PropTypes.func
};

export default ProcessModelPreviewPanel;
