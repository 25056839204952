import React from 'react';
import PropTypes from 'prop-types';
import ChartRenderer from '../ChartRenderer.js';
import BubbleChartConfiguration from '../../../services/BubbleChartConfiguration.js';
import { useSelector } from 'react-redux';

const BubbleChart = (props) => {
  const isDashboardEditMode = useSelector((state) => state.dashboard.isDashboardEditMode);
  const option = BubbleChartConfiguration.getBubbleChartOptions(
    props.options,
    props.chartData,
    props.themeColors,
    isDashboardEditMode,
    props.isWidgetConfigureMode,
    props.layoutType
  );
  return <ChartRenderer width={props.width} height={props.height} option={option}></ChartRenderer>;
};

BubbleChart.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  options: PropTypes.object,
  chartData: PropTypes.array,
  themeColors: PropTypes.array,
  isWidgetConfigureMode: PropTypes.bool,
  layoutType: PropTypes.string
};

export default BubbleChart;
