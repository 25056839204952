import axios from 'axios';
import { refreshKeycloakToken } from './KeycloakWrapper';

const AuthInterceptor = (authContext) => {
  // request interceptor
  axios.interceptors.request.use(
    (config) => {
      if (authContext && config) {
        config.headers['Authorization'] = `Bearer ${authContext.keycloak.token}`;
        config.headers['Content-Type'] = 'application/json';
        return config;
      } else {
        return config;
      }
    },
    (error) => {
      // Handle request error
      return Promise.reject(error);
    }
  );

  // response interceptor
  axios.interceptors.response.use(
    async (response) => {
      // Modify the response data here (e.g., transform data, handle errors)
      return response;
    },
    async (error) => {
      // Handle response error
      const originalRequest = error.config;
      if (authContext && error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        await refreshKeycloakToken(authContext.keycloak);
        return axios.request(originalRequest);
      }
      return Promise.reject(error);
    }
  );
};

// Call the AuthInterceptor to apply the interceptor to all Axios requests
(function () {
  AuthInterceptor();
})();

export default AuthInterceptor;
