import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import AddchartIcon from '@mui/icons-material/Addchart';
import { useTranslation } from 'react-i18next';
import '../../../styles/WidgetComponents.scss';
import PropTypes from 'prop-types';
import { LowPriorityOutlined } from '@mui/icons-material';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import AddIconComponent from '../../shared/AddIcon/AddIconComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setAddCustomTextWiget } from '../../../Redux/slices/selectedWidgetDataSlice';
import ExistingCharts from './ExistingCharts';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ImageIcon from '@mui/icons-material/Image';
import ThemeMode from '../../themeMode/themeMode';
import DashboardTypography from '../../shared/DashboardTypography/DashboardTypography';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const WidgetComponents = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isExistingChartsSectionOpen, setIsExistingChartsSectionOpen] = useState(false);
  const [isRepositoriesAvailable, setIsRepositoriesAvailable] = useState(false);
  const repositories = useSelector((state) => state.workgroupRepositories.repositories);

  useEffect(() => {
    setIsRepositoriesAvailable(repositories?.length > 0 ? true : false);
  }, [repositories]);

  const addNewTextWidget = () => {
    dispatch(setAddCustomTextWiget(true));
  };

  const openExistingChartsSection = () => {
    setIsExistingChartsSectionOpen(true);
  };

  const closeExistingChartsSection = () => {
    setIsExistingChartsSectionOpen(false);
  };

  return (
    <div
      className='right-section-container'
      data-testid='right-section'
      id='right-section-actions-container'
    >
      <ThemeMode></ThemeMode>
      <div
        className={
          isExistingChartsSectionOpen
            ? 'components-tab-existing-chart-container'
            : 'components-tab-container'
        }
      >
        {isExistingChartsSectionOpen && (
          <span className='back-arrow'>
            <Tooltip title={t('BACK')}>
              <ArrowBackIosIcon onClick={() => closeExistingChartsSection()} />
            </Tooltip>
          </span>
        )}
        <DashboardTypography id={'component-existing-chart-title'} typographyClass={'title-16-700'}>
          {isExistingChartsSectionOpen ? t('APP.EXISTING_CHARTS') : t('COMPONENTS')}
        </DashboardTypography>
        {!isExistingChartsSectionOpen && (
          <Button
            name={'Existing charts'}
            onClick={() => openExistingChartsSection()}
            sx={{
              borderBottom: 'none'
            }}
            className='existing-charts-button'
          >
            {t('APP.EXISTING_CHARTS')}
          </Button>
        )}
      </div>

      {/* Add Chart button */}
      {!isExistingChartsSectionOpen && (
        <div
          data-testid='add-charts-button'
          className='widget-component-button'
          onClick={props.handleAddChart}
          id='add-charts-button'
        >
          <AddchartIcon id='add-chart-button-icon' sx={{ color: '#041AB9' }} />
          <div className='name-icon'>
            <DashboardTypography
              id={'add-chart-button-title'}
              typographyClass={'title-14-600 widget-component-generic-button'}
            >
              {t('CHARTS')}
            </DashboardTypography>
            <AddIconComponent eventHandler={props.handleAddChart}></AddIconComponent>
          </div>
        </div>
      )}
      {/* Add process model view button */}
      {!isExistingChartsSectionOpen && (
        <div
          data-testid='process-model-view-widget-add-button'
          className='widget-component-button cursor-pointer'
          onClick={props.addProcessModelView}
          id='process-model-view-widget-add-button'
        >
          <LowPriorityOutlined id='add-chart-button-icon' />
          <div className='name-icon'>
            <DashboardTypography
              id={'add-chart-button-title'}
              typographyClass={'title-14-600 widget-component-generic-button'}
            >
              {t('PROCESS_MODEL.PROCESS_MODEL_VIEW')}
            </DashboardTypography>
            <AddIconComponent></AddIconComponent>
          </div>
        </div>
      )}
      {/* Add report button */}
      {!isExistingChartsSectionOpen && (
        <Tooltip title={!isRepositoriesAvailable ? t('APP.REPORTS_DISABLED') : ''}>
          <div
            data-testid='report-add-button'
            className={`widget-component-button ${
              !isRepositoriesAvailable ? 'report-add-button-disabled' : 'cursor-pointer'
            }`}
            onClick={isRepositoriesAvailable ? props.addReportWidget : null}
            id='report-widget-add-button'
          >
            <InsertDriveFileIcon id='add-chart-button-icon' />
            <div className='name-icon'>
              <DashboardTypography
                id={'add-chart-button-title'}
                typographyClass={'title-14-600 widget-component-generic-button'}
              >
                {t('REPORT.REPORT')}
              </DashboardTypography>
              <AddIconComponent></AddIconComponent>
            </div>
          </div>
        </Tooltip>
      )}
      {/* Add custom text button */}
      {!isExistingChartsSectionOpen && (
        <div
          data-testid='pcustom-text-add-button'
          className='widget-component-button cursor-pointer'
          onClick={addNewTextWidget}
          id='custom-text-add-button'
        >
          <TextFieldsIcon id='add-chart-button-icon' />
          <div className='name-icon'>
            <DashboardTypography
              id={'add-chart-button-title'}
              typographyClass={'title-14-600 widget-component-generic-button'}
            >
              {t('TEXT')}
            </DashboardTypography>
            <AddIconComponent></AddIconComponent>
          </div>
        </div>
      )}

      {/* Add image widget button */}
      {!isExistingChartsSectionOpen && (
        <div
          data-testid='image-chart-widget'
          className='widget-component-button cursor-pointer'
          onClick={props.addImage}
          id='image-chart-add-button'
        >
          <ImageIcon id='add-chart-button-icon' />
          <div className='name-icon'>
            <DashboardTypography
              id={'add-chart-button-title'}
              typographyClass={'title-14-600 widget-component-generic-button'}
            >
              {t('IMAGE')}
            </DashboardTypography>
            <AddIconComponent></AddIconComponent>
          </div>
        </div>
      )}

      {isExistingChartsSectionOpen ? (
        <ExistingCharts
          appId={props.appId}
          closeExistingChartsSection={closeExistingChartsSection}
        />
      ) : null}
    </div>
  );
};
WidgetComponents.propTypes = {
  appId: PropTypes.string,
  handleAddChart: PropTypes.func,
  addProcessModelView: PropTypes.func,
  addReportWidget: PropTypes.func,
  addImage: PropTypes.func
};
export default WidgetComponents;
