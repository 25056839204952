import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './DashboardTypography.scss';
import { useSelector } from 'react-redux';
import { themeModes } from '../../../utils/Constants';
import { darkThemeModeTextColors, lightThemeModeTextColors } from './idTextColorMapping';

const DashboardTypography = (props) => {
    const { children, typographyClass } = props;
    const [typographyClassWithTheme, setTypographyClassWithTheme] = useState('');
    const themeMode = useSelector(state => state.themeMode?.themeMode);

    useEffect(() => {
        setTypographyClassWithTheme(typographyClass);
    }, []);

    return (
        <React.Fragment>
            {themeMode === themeModes?.LIGHT_THEME && <span data-testid={props.testId} title={props.title} style={{ color: lightThemeModeTextColors[props.id] }} className={typographyClassWithTheme}> {children}</span>}
            {themeMode === themeModes?.DARK_THEME && <span data-testid={props.testId} title={props.title} style={{ color: darkThemeModeTextColors[props.id] }} className={typographyClassWithTheme}> {children}</span>}
        </React.Fragment>

    );
};

DashboardTypography.propTypes = {
    children: PropTypes.string,
    typographyClass: PropTypes.string,
    color: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
    testId: PropTypes.string
};
export default DashboardTypography;
