import { createSlice } from '@reduxjs/toolkit';
import {
  MODIFIED_DATE,
  RECENT,
  OLDEST,
  DESCENDING,
  ASCENDING,
  A_Z,
  Z_A,
  NAME
} from '../../utils/Constants';

const sortingSlice = createSlice({
  name: 'sorting',
  initialState: {
    sortType: RECENT,
    sortBy: MODIFIED_DATE,
    sortOrder: DESCENDING
  },

  reducers: {
    setSortByModifiedDate: (state, action) => {
      state.sortBy = MODIFIED_DATE;
      if (action.payload === RECENT) {
        state.sortOrder = DESCENDING;
        state.sortType = RECENT;
      } else if (action.payload === OLDEST) {
        state.sortOrder = ASCENDING;
        state.sortType = OLDEST;
      }
    },

    setSortByName: (state, action) => {
      state.sortBy = NAME;
      if (action.payload === A_Z) {
        state.sortOrder = ASCENDING;
        state.sortType = A_Z;
      } else if (action.payload === Z_A) {
        state.sortOrder = DESCENDING;
        state.sortType = Z_A;
      }
    }
  }
});

export const { setSortByModifiedDate, setSortByName } = sortingSlice.actions;
export default sortingSlice.reducer;
