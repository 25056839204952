import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import '../../../styles/ChartsLayout.scss';
import ProcessModelLegend from '../../processModelConfiguration/ProcessModelLegend/ProcessModelLegend';
import * as d3 from 'd3';
import { getUuid } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setIsResetZoomTriggerOnFullScreen, setIsResetZoomTriggerOnWidget, setZoomLevelForSVGWidgetsDropdown, setZoomValue } from '../../../Redux/slices/processModelCofigSlice';
import { getNearestValueFromSet } from '../../../utils/GetNearestValueFromSet';

const ProcessModelView = (props) => {
  const dispatch = useDispatch();
  const svgRef = useRef(null);
  const svgStr = props.chartData;
  const id = getUuid();
  const { svgZoomValueFromDropDown, isSettingsPanelOpen, isResetZoomTriggerOnFullScreen, resetWidgetSvg, zoomLevelForSVGWidgets } = useSelector((state) => state.processModelConfig);
  const isDashboardEditMode = useSelector((state) => state.dashboard.isDashboardEditMode);

  let svgContainerRef;
  let svgElement;
  let gElement;
  let svgContainer;
  let chartContainer;
  let zoom;

  useEffect(() => {
    if (props.isPreviewOnDashboard) {
      constructGraphOnDashboard(false);
    }
  }, [svgStr]);

  useEffect(() => {
    if (props.isPreview) {
      constructGraphOnFullScreen();
    }
  }, [svgStr]);

  useEffect(() => {
    if (resetWidgetSvg.isResetZoomTriggerOnWidget && props.isPreviewOnDashboard && props.chartOption.chartId === resetWidgetSvg.chartId) {
      constructGraphOnDashboard(false);
      dispatch(setIsResetZoomTriggerOnWidget({ isResetZoomTriggerOnWidget: false, chartId: '' }));
    }
  }, [resetWidgetSvg.isResetZoomTriggerOnWidget]);

  useEffect(() => {
    if (isResetZoomTriggerOnFullScreen && props.isPreview) {
      constructGraphOnFullScreen();
      dispatch(setIsResetZoomTriggerOnFullScreen(false));
    }
  }, [isResetZoomTriggerOnFullScreen]);

  useEffect(() => {
    if (props.isPreview && svgZoomValueFromDropDown !== 0) {
      constructGraphOnFullScreenFromCustomValue();
    }
  }, [svgZoomValueFromDropDown]);

  useEffect(() => {
    const index = zoomLevelForSVGWidgets?.findIndex((zoomLevelForSVGWidget) => zoomLevelForSVGWidget.chartId === props.chartOption.chartId);
    if (index > -1 && props.isPreviewOnDashboard && zoomLevelForSVGWidgets[index].zoomLevel !== 0 && zoomLevelForSVGWidgets[index].chartId === props.chartOption.chartId) {
      constructGraphOnDashboard(true, index);
    }
  }, [zoomLevelForSVGWidgets, zoomLevelForSVGWidgets.zoomLevel]);

  const getScaleValues = (bBox, containerHeight, containerWidth) => {
    const originalHeight = bBox.height;
    const originalWidth = bBox.width;
    if (props.chartOption.orientation === 'portrait') {
      const scale = (containerHeight / originalHeight);
      const scaleHeight = originalHeight * scale;
      const scaleWidth = originalWidth * scale;
      return [scaleWidth, scaleHeight, scale];
    } else if (props.chartOption.orientation === 'landscape') {
      let scale = (containerWidth / originalWidth);
      let scaleHeight = originalHeight * scale;
      if (scaleHeight > containerHeight) {
        scale = (containerHeight / originalHeight);
        scaleHeight = originalHeight * scale;
      }
      const scaleWidth = originalWidth * scale;
      return [scaleWidth, scaleHeight, scale];
    }
  };

  const getScaleHeightAndWidth = (bBox, scale) => {
    const originalHeight = bBox.height;
    const originalWidth = bBox.width;
    const scaleHeight = originalHeight * scale;
    const scaleWidth = originalWidth * scale;
    return [scaleWidth, scaleHeight];
  };

  const zoomConfig = () => {
    svgContainerRef = d3.select(svgRef.current);
    svgElement = svgContainerRef.html(svgStr).select('svg');
    gElement = svgContainerRef.select('g');
    svgContainer = document.getElementById(id);

    svgElement.selectAll('text').style('font-family', 'Inter');

    svgElement.attr('viewBox', null);
    let initialHeight;
    let isInitialHeight = true;

    zoom = d3.zoom().scaleExtent([0.1, 2.50]).on('zoom', function (event) {
      const [scaledWidth, scaledHeight] = getScaleHeightAndWidth(gElement?.node()?.getBBox(), event?.transform?.k);
      if (isInitialHeight) {
        initialHeight = scaledHeight;
        isInitialHeight = false;
      }

      event.transform.x = (event?.transform?.x < svgContainer?.offsetWidth - 50) ? event?.transform?.x : (svgContainer?.offsetWidth - 50); //RIght
      event.transform.x = (event?.transform?.x > (-scaledWidth + 50)) ? event?.transform?.x : (-scaledWidth + 50);  //Left

      event.transform.y = (event?.transform?.y > (60 * event?.transform?.k)) ? event?.transform?.y : (60 * event?.transform?.k); //Top
      if (props.chartOption.orientation === 'portrait') {
        event.transform.y = (event?.transform?.y < (initialHeight + scaledHeight) - (60 * event?.transform?.k) ? event?.transform?.y : (initialHeight + scaledHeight) - (60 * event?.transform?.k)); //Bottom
      } else if (props.chartOption.orientation === 'landscape' && props.isPreview) {
        event.transform.y = (event?.transform?.y < ((svgContainer?.offsetHeight - 200) + scaledHeight) - (80 * event?.transform?.k)) ? event?.transform?.y : ((svgContainer?.offsetHeight - 200) + scaledHeight) - (80 * event?.transform?.k);
      } else if (props.chartOption.orientation === 'landscape' && props.isPreviewOnDashboard) {
        event.transform.y = (event?.transform?.y < ((svgContainer?.offsetHeight - 50) + scaledHeight) - (80 * event?.transform?.k)) ? event?.transform?.y : ((svgContainer?.offsetHeight - 50) + scaledHeight) - (80 * event?.transform?.k);
      }

      gElement.attr('transform', event.transform);
      dispatch(setZoomValue(getNearestValueFromSet(event?.transform?.k)));
      if (props.isPreviewOnDashboard) {
        dispatch(setZoomLevelForSVGWidgetsDropdown({ zoomLevel: event?.transform?.k, chartId: props.chartOption.chartId }));
      }

    });

    if (document.getElementById('chart-container')) {
      chartContainer = svgContainer.parentElement.parentElement;
    }

    svgContainerRef?.call(zoom);
  };


  const constructGraphOnDashboard = (isConstructGraphOnDashboardFromDropdownZoom, index) => {
    if (svgStr && svgRef.current) {
      zoomConfig();
      let initialTRansform;
      const offsetWidth = svgContainer?.offsetWidth - 10;
      svgElement.attr('width', offsetWidth).attr('height', chartContainer?.offsetHeight);
      const [scaleWidth, scaleHeight, scale] = getScaleValues(gElement?.node()?.getBBox(), (chartContainer?.offsetHeight - 60), offsetWidth);

      const scaleToApply = isConstructGraphOnDashboardFromDropdownZoom ? zoomLevelForSVGWidgets[index].zoomLevel : scale;

      if (props.chartOption.orientation === 'portrait') {
        initialTRansform = d3.zoomIdentity.translate((svgContainer?.offsetWidth / 2 - (scaleWidth / 2)), scaleHeight).scale(scaleToApply);
      } else if (props.chartOption.orientation === 'landscape') {
        const padding = 40; //this is extra space of svg going outside of botder at bottom.
        const yPosition = (chartContainer?.offsetHeight - ((chartContainer?.offsetHeight - scaleHeight) / 2)) - padding;
        initialTRansform = d3.zoomIdentity.translate(0, yPosition).scale(scaleToApply);
      }
      svgContainerRef.call(zoom.transform, initialTRansform);
    }
  };

  const constructGraphOnFullScreen = () => {
    if (svgStr && svgRef.current) {
      const svgContainerFromPreivew = document.getElementsByClassName(isDashboardEditMode && isSettingsPanelOpen ? 'svg-container-view-edit-mode' : 'svg-container-view')[0];
      zoomConfig();

      let initialTRansform;

      const offsetWidth = svgContainerFromPreivew?.offsetWidth - 10;
      svgElement.attr('width', (svgContainerFromPreivew?.offsetWidth - 10)).attr('height', svgContainerFromPreivew?.offsetHeight);

      const [scaleWidth, scaleHeight, scale] = getScaleValues(gElement?.node()?.getBBox(), (svgContainerFromPreivew?.offsetHeight - 200), offsetWidth);

      if (props.chartOption.orientation === 'portrait') {
        initialTRansform = d3.zoomIdentity.translate((svgContainerFromPreivew?.offsetWidth / 2) - (scaleWidth / 2), scaleHeight).scale(scale);
      } else if (props.chartOption.orientation === 'landscape') {
        const padding = 100; //this is extra space of svg going outside of botder at bottom.
        const yPosition = (svgContainerFromPreivew?.offsetHeight - ((svgContainerFromPreivew?.offsetHeight - scaleHeight) / 2)) - padding;
        initialTRansform = d3.zoomIdentity.translate(0, yPosition).scale(scale);
      }

      svgContainerRef.call(zoom.transform, initialTRansform);
    }
  };

  const constructGraphOnFullScreenFromCustomValue = () => {
    if (svgStr && svgRef.current) {
      zoomConfig();

      let initialTRansform;
      const svgContainerFromPreivew = document.getElementsByClassName(isDashboardEditMode && isSettingsPanelOpen ? 'svg-container-view-edit-mode' : 'svg-container-view')[0];
      svgElement.attr('width', (svgContainerFromPreivew?.offsetWidth - 10)).attr('height', svgContainerFromPreivew?.offsetHeight);

      const [scaleWidth, scaleHeight] = getScaleHeightAndWidth(gElement?.node()?.getBBox(), svgZoomValueFromDropDown);

      if (props.chartOption.orientation === 'portrait') {
        initialTRansform = d3.zoomIdentity.translate((svgContainerFromPreivew?.offsetWidth / 2) - (scaleWidth / 2), scaleHeight).scale(svgZoomValueFromDropDown);
      } else if (props.chartOption.orientation === 'landscape') {
        const padding = 100; //this is extra space of svg going outside of botder at bottom.
        const yPosition = (svgContainerFromPreivew?.offsetHeight - ((svgContainerFromPreivew?.offsetHeight - scaleHeight) / 2)) - padding;
        initialTRansform = d3.zoomIdentity.translate(0, yPosition).scale(svgZoomValueFromDropDown);
      }

      svgContainerRef.call(zoom.transform, initialTRansform);
    }
  };

  return (
    <React.Fragment>
      <div id='process-model-full-screen'>

        <div ref={svgRef} id={id} data-testid='svg-img'></div>
        {props.chartOption?.legend?.percentiles?.length > 0 && (
          <ProcessModelLegend chartOption={props.chartOption}></ProcessModelLegend>
        )}
      </div>

    </React.Fragment>
  );
};

ProcessModelView.propTypes = {
  chartData: PropTypes.string,
  chartContainerId: PropTypes.string,
  chartOption: PropTypes.object,
  zoomValue: PropTypes.number,
  isPreviewOnDashboard: PropTypes.bool,
  isPreview: PropTypes.bool,
};

export default React.memo(ProcessModelView);
