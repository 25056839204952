import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/ChartsLayout.scss';
import '../../../styles/MetricChart.scss';
import { formatDuration } from '../../../utils/utils';
import DashboardTypography from '../../shared/DashboardTypography/DashboardTypography';

function MetricChart(props) {
  const formatValue = (value) => {
    switch (props.options.metricFormat) {
      case 'number':
        return (value.toFixed(2) * 1).toLocaleString();
      case 'percent':
        return value.toFixed(2) * 1 + '%';
      case 'time':
        return formatDuration(value);
      default:
        return value.toFixed(2) * 1;
    }
  };
  return (
    <div
      data-testid='metric-chart-container'
      className={props.layoutType === 'AddWidget' ? 'metric-widget-edit-mode' : 'metric-view-mode'}
    >
      <div
        className={
          props.layoutType === 'AddWidget' ? 'metric-widget-data' : 'metric-view-mode-data'
        }
      >
        {props.chartData.map((chartValue, index) => (
          <div data-testid='metric-chart-value' key={index}>
            <DashboardTypography id={'metric-chart-value'} typographyClass={'title-34-700'}>{formatValue(chartValue)}</DashboardTypography>
          </div>
        ))}
      </div>
      <div
        data-testid='metric-chart-description'
        className={
          props.layoutType === 'AddWidget'
            ? 'metric-widget-description'
            : 'metric-view-mode-description'
        }
      >
        <DashboardTypography id='metric-chart-description' typographyClass={props.layoutType === 'AddWidget'
          ? 'title-24-400'
          : 'title-14-500'}>
          {props.options.description}
        </DashboardTypography>
      </div>
    </div>
  );
}

MetricChart.propTypes = {
  options: PropTypes.object,
  chartData: PropTypes.array,
  themeColors: PropTypes.array,
  layoutType: PropTypes.string
};

export default MetricChart;
