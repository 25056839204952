import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { Tooltip } from '@mui/material';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import { Clear } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import AddNewApp from '../apps/AddNewApp';
import SortIcon from '@mui/icons-material/Sort';
import AppListSort from '../sort/AppListSort';
import { CONTAINED, OUTLINED, GRID, LIST } from '../../utils/Constants';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import './AppsHeader.scss';
import ThemeMode from '../themeMode/themeMode';
export default function AppsHeader(props) {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const [openPopup, setOpenPopup] = useState(false);

  const [sortMenuState, setSortMenuState] = useState(null);
  const open = Boolean(sortMenuState);

  useEffect(() => {
    setOpenPopup(props.openAddPopup);
  }, [props.openAddPopup]);

  const searchFilterFunction = (text) => {
    if (text.target.value) {
      setSearch(text.target.value);
      props.searchApps(text.target.value);
    } else {
      clearSearch();
    }
  };

  const clearSearch = () => {
    setSearch('');
    props.searchApps('');
  };

  const openAddNewPopup = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setOpenPopup(false);
    props.closeAddPopup(false);
  };

  const openConfirmationPopupForPreconfiguredWidgets = (app) => {
    props.handleSave({
      ...app
    });
  };

  const openSortMenu = (event) => {
    setSortMenuState(event.currentTarget);
  };

  const onSortMenuClose = () => {
    setSortMenuState(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box id='apps-header-section'>
        <Grid container className='appsHeader-container'>
          <Grid item mt={0.4} xs={6} className='app-header-name'>
            <DashboardTypography
              testId='header-app-name'
              typographyClass={'title-24-500'}
              id={'apps-header'}
            >
              {t('APPS')}
            </DashboardTypography>
            <DashboardTypography
              typographyClass={'title-16-500 text-overflow-25'}
              testId='header-workgroup-name'
              id={'header-workgroup-name'}
            >
              <span title={props.workgroupName}>
                &#40;{props.workgroupName ? props.workgroupName : ''}&#41;
              </span>
            </DashboardTypography>
          </Grid>
          <Grid item xs={6} className='userBtn-section'>
            <Stack direction='row' spacing={1}>
              <Button
                variant='contained'
                className='apps-NewAppBtn genericApps-btn'
                onClick={openAddNewPopup}
              >
                <AddIcon />
                {t('NEW_APP')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: { xs: 'flex', md: 'flex' },
            minHeight: '50px'
          }}
        >
          <Grid container spacing={2} className='appsHeader-tabsContainer'>
            <Grid item xs={3} className='genericApps-gridItem'>
              <DashboardTypography
                typographyClass={'title-14-500 header-all-apps-title'}
                id={'header-all-apps-title'}
              >
                {' '}
                {t('APP.ALL_APPS') +
                  '(' +
                  (props.totalNumberOfApps ? props.totalNumberOfApps : 0) +
                  ')'}
              </DashboardTypography>
            </Grid>
            <Grid item xs={9} style={{ textAlign: 'end', paddingRight: '23px' }}>
              <div className='appsHeader-searchSection'>
                <TextField
                  id='search'
                  value={search}
                  onChange={searchFilterFunction}
                  placeholder={t('searchAppsPlaceholder')}
                  data-testid='search-apps-text'
                  className='appsHeader-search'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Search id='search-icon' />
                      </InputAdornment>
                    ),
                    endAdornment: search && (
                      <InputAdornment
                        position='end'
                        onClick={clearSearch}
                        style={{ cursor: 'pointer' }}
                      >
                        <ThemeMode></ThemeMode>
                        <Clear id='clear-search' />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div style={{ display: 'inline-block', marginLeft: '0px' }}>
                <Button
                  id='export-button'
                  variant='outlined'
                  className='dashboard-sort-btn generic-btn'
                  onClick={openSortMenu}
                  data-testid='sort-btn'
                  startIcon={<SortIcon />}
                >
                  {t('APP.SORT')}
                </Button>
                <AppListSort
                  anchorEl={sortMenuState}
                  open={open}
                  onSortMenuClose={onSortMenuClose}
                />

                <Button
                  variant={props.selectedAppViewType === GRID ? OUTLINED : CONTAINED}
                  size='small'
                  sx={{
                    backgroundColor: props.selectedAppViewType === GRID ? '#EFEFFD' : '#FFFFFF',
                    border: 1,
                    borderRadius: 1,
                    borderStyle: 'solid',
                    borderColor: props.selectedAppViewType === GRID ? '#9BA3E3' : '#E0E2E7',
                    color: props.selectedAppViewType === GRID ? '#3648C7' : '#667085',
                    boxShadow: 'unset',
                    minWidth: 'unset',
                    height: '40px',
                    width: '40px',
                    position: 'relative',
                    top: '-6px',
                    ':hover': {
                      bgcolor: '#EFEFFD',
                      boxShadow: 'unset'
                    }
                  }}
                  onClick={() => props.selectAppViewType(GRID)}
                  data-testid='apps-grid-view'
                >
                  <Tooltip title={t('GRID_VIEW')} arrow>
                    <GridViewIcon
                      sx={{
                        width: 20,
                        height: 20
                      }}
                    />
                  </Tooltip>
                </Button>
              </div>
              <div style={{ display: 'inline-block', marginLeft: '0px' }}>
                <Button
                  variant={props.selectedAppViewType === LIST ? OUTLINED : CONTAINED}
                  size='small'
                  sx={{
                    backgroundColor: props.selectedAppViewType === LIST ? '#EFEFFD' : '#FFFFFF',
                    border: 1,
                    borderRadius: 1,
                    borderStyle: 'solid',
                    borderColor: props.selectedAppViewType === LIST ? '#9BA3E3' : '#E0E2E7',
                    color: props.selectedAppViewType === LIST ? '#3648C7' : '#667085',
                    boxShadow: 'unset',
                    minWidth: 'unset',
                    marginLeft: '5px',
                    position: 'relative',
                    top: '-6px',
                    height: '40px',
                    width: '40px',
                    ':hover': {
                      bgcolor: '#EFEFFD',
                      boxShadow: 'unset'
                    }
                  }}
                  onClick={() => props.selectAppViewType(LIST)}
                  data-testid='apps-list-view'
                >
                  <Tooltip title={t('LIST_VIEW')} arrow>
                    <ViewListOutlinedIcon sx={{ width: 24, height: 24 }} />
                  </Tooltip>
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <AddNewApp
        open={openPopup}
        handleClose={handleClose}
        handleSave={openConfirmationPopupForPreconfiguredWidgets}
        projects={props.projects}
        totalApps={props.totalApps}
      ></AddNewApp>
    </ThemeProvider>
  );
}

AppsHeader.propTypes = {
  searchApps: PropTypes.func,
  totalNumberOfApps: PropTypes.number,
  selectAppViewType: PropTypes.func,
  selectedAppViewType: PropTypes.string,
  projects: PropTypes.array,
  handleSave: PropTypes.func,
  openAddPopup: PropTypes.bool,
  totalApps: PropTypes.array,
  workgroupName: PropTypes.string,
  closeAddPopup: PropTypes.func
};

const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
      fontSize: 11
    }
  }
});
