import { LICENSE_TYPES } from './Constants';

const dataToDisplayKeyMap = {
  LICENSE_EFFECTIVE: 'effectiveLicenseCounts',
  APP_INTERACTIONS_COUNT_BY_USERNAME: 'appInteractionsCountByUsername',
  USER_LOGINS_COUNT_BY_USERNAME: 'userLoginsCountByUsername',
  OBJECT_ACCESS_COUNT: 'objectAccessCount',
  TABS_VISIT_COUNT: 'tabsVisitCount'
};

export const getReportData = (dataToDisplay, reportDetails) => {
  const key = dataToDisplayKeyMap[dataToDisplay] || '';
  if (key === '') {
    return reportDetails;
  }

  return Object.entries(reportDetails[key])
    .map(([key, value]) => ({
      label: dataToDisplay === 'LICENSE_EFFECTIVE' ? LICENSE_TYPES[key] : key,
      data: value
    }))
    .sort((a, b) => b.data - a.data);
};

export const getReportTableViewData = (dataToDisplay, reportDetails) => {
  if (dataToDisplay === 'LICENSE_EFFECTIVE') {
    return reportDetails['licenseDetail'].map((user) => {
      return {
        ...user,
        userLicense: user.userLicense === 'CollaboratorUser' ? 'Collaborator' : user.userLicense
      };
    });
  }
  return [];
};

export const getAxisTitle = (dataToDisplay) => {
  const titles = {
    LICENSE_EFFECTIVE: {
      xAxisTitle: 'User license type',
      yAxisTitle: 'Count of user license'
    },
    APP_INTERACTIONS_COUNT_BY_USERNAME: {
      xAxisTitle: 'Username',
      yAxisTitle: 'App interactions'
    },
    USER_LOGINS_COUNT_BY_USERNAME: {
      xAxisTitle: 'Username',
      yAxisTitle: 'Count of user logins'
    },
    OBJECT_ACCESS_COUNT: {
      xAxisTitle: 'Object name',
      yAxisTitle: 'Object access count'
    }
  };

  return titles[dataToDisplay] || { xAxisTitle: '', yAxisTitle: '' };
};
