import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/ChartsLayout.scss';

function TextChart(props) {
  const description = { __html: props.options.description };
  return (
    <div
      className={
        props.layoutType === 'AddWidget'
          ? 'textChart-addEditDescription'
          : 'textChart-leftSectionDescription'
      }
    >
      <p
        className={
          props.layoutType === 'AddWidget' ? 'textChart-description2' : 'textChart-description'
        }
        dangerouslySetInnerHTML={description} >
      </p>
    </div>
  );
}

TextChart.propTypes = {
  options: PropTypes.object,
  chartData: PropTypes.array,
  themeColors: PropTypes.array,
  layoutType: PropTypes.string
};

export default TextChart;
