import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import { Clear } from '@mui/icons-material';
import '../../styles/WorkgroupsContainer.scss';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import ThemeMode from '../themeMode/themeMode';

const WorkgroupsHeader = (props) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const searchFilterFunction = (text) => {
    if (text.target.value) {
      setSearch(text.target.value);
      props.searchWorkgroup(text.target.value);
    } else {
      clearSearch();
    }
  };

  const clearSearch = () => {
    setSearch('');
    props.searchWorkgroup('');
  };
  return (
    <ThemeProvider theme={theme}>
      <Box className='workgroup-Header' id='workgroup-header'>
        <div className='widgetContainerHeading'>
          <div className='all-workgroups'>
            <DashboardTypography id={'all-workgroup-text'} typographyClass={'title-18-500'}>{t('ALL_WORKGROUPS')}</DashboardTypography>
          </div>
          <div className='search-section'>
            <TextField
              id='search'
              value={search}
              onChange={searchFilterFunction}
              placeholder={t('SEARCH_WORKGROUP_PLACEHOLDER')}
              data-testid='search-workgroup-text'
              className='textField-search'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' >
                    <Search id='search-icon' />
                  </InputAdornment>
                ),
                endAdornment: search && (
                  <InputAdornment
                    position='end'
                    onClick={clearSearch}
                    style={{ cursor: 'pointer' }}
                  >
                    <ThemeMode></ThemeMode>
                    <Clear id='clear-search' />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </Box>
    </ThemeProvider>
  );
};

WorkgroupsHeader.propTypes = {
  searchWorkgroup: PropTypes.func
};
const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
      fontSize: 11
    }
  }
});
export default WorkgroupsHeader;
