import React from 'react';
import PropTypes from 'prop-types';
import ChartRenderer from '../ChartRenderer.js';
import StackBarChartConfiguration from '../../../services/StackBarChartConfiguration.js';

const StackBarChart = (props) => {
    const option = StackBarChartConfiguration.getStackBatChartOptions(props.chartData, props.themeColors);
    return <ChartRenderer width={props.width} height={props.height} option={option} />;
};

StackBarChart.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    chartData: PropTypes.array,
    themeColors: PropTypes.array,
};

export default StackBarChart;
