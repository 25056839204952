import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import dashboardStyles from '../../styles/dashboardStyles';
import { useTranslation } from 'react-i18next';
import '../../styles/DashboardLayout.scss';
import DashboardButton from '../shared/Buttons/DashboardButton';
import ThemeMode from '../themeMode/themeMode';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';

const ConfirmationDialog = (props) => {
  const { t } = useTranslation();

  return (
    <div id='delete-dashboard'>
    <Dialog
      className='delete-confirmation'
      onClose={props.onClose}
      aria-labelledby='customized-dialog-title'
      open={open}
      maxWidth='md'
    >
      <ThemeMode></ThemeMode>
      <DialogTitle
        sx={{ m: 0, p: 2, paddingLeft: '25px' }}
        id='customized-dialog-title'
        data-testid='customized-dialog-title'
        style={dashboardStyles.modalThemeTitle}
      >
            <DashboardTypography typographyClass='title-18-700' id='dashboard-dialog-title'>
            {t(props.title)}
            </DashboardTypography>  
            <ThemeMode></ThemeMode>
        <IconButton
          aria-label='close'
          onClick={props.onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          id='close-icon'
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
       id='delete-dashboard-content-container'>
        <DialogContentText
        data-testid='confirmation-msg'
        >
          <DashboardTypography  id='delete-confirmation-message' typographyClass={'title-16-400'}>{t(props.confirmationMsg)}</DashboardTypography>
        </DialogContentText>
        {props.note && (
          <div className='delete-note' id='delete-note' data-testid='confirmation-note'>
          <DashboardTypography  id='delete-confirmation-note' typographyClass={'title-14-600'}> {t(props.note)}</DashboardTypography>
        </div>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          paddingBottom: '20px',
          display: 'flex',
          justifyContent: props.isConfirmationForPreconfiguredWidgets
            ? 'flex-end'
            : 'space-between',
          gap: props.isConfirmationForPreconfiguredWidgets ? '10px' : '0',
        }}
        id='delete-dashboard-action-container'
      >
        <DashboardButton
          color='secondary'
          dataTestid='cancel-btn'
          eventHandler={props.onClose}
          variant='outlined'
        >
          {props.isConfirmationForPreconfiguredWidgets ? t('NO') : t('CANCEL')}
        </DashboardButton>
        <DashboardButton color='primary' dataTestid='ok-btn' eventHandler={props.onConfirm}>
          {props.isConfirmationForPreconfiguredWidgets ? t('YES') : t('OK')}
        </DashboardButton>
      </DialogActions>
    </Dialog>
    </div>
  );
};

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  confirmationMsg: PropTypes.string,
  note: PropTypes.string,
  isConfirmationForPreconfiguredWidgets: PropTypes.bool
};

export default ConfirmationDialog;
