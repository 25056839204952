import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useNavigate, generatePath } from 'react-router-dom';
import { CorporateFareOutlined } from '@mui/icons-material';
import '../../styles/WorkgroupsContainer.scss';
import { Grid } from '@mui/material';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import ThemeMode from '../themeMode/themeMode';

const WorkgroupsGridView = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [workgroupData, setWorkgroupData] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await chunk(props.allWorkgroups, 4);
      setWorkgroupData([...data]);
    })();
  }, [props.allWorkgroups]);

  const chunk = async (arr, size) =>
    Array.from({ length: Math.ceil(arr?.length / size) }, (v, i) =>
      arr?.slice(i * size, i * size + size)
    );

  const navigateToApps = (selectedWorkgroup) => {
    const routePattern = '/Workgroups/:id/Apps';
    const params = {
      id: selectedWorkgroup.id
    };

    const generatedUrl = generatePath(routePattern, params);
    const urlWithQueryParams = `${generatedUrl}?${new URLSearchParams({
      workgroupName: encodeURIComponent(selectedWorkgroup.name)
    })}`;
    navigate(urlWithQueryParams);
  };

  const renderEmptyWorkgroup = () => {
    return !props.loading && workgroupData.length === 0 ? (
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'center',
          alignSelf: 'center',
          marginTop: '10%',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography
          textAlign='center'
          sx={{ width: '100%', color: '#5D5D5D', fontSize: 17, fontWeight: 700 }}
        >
          {t('YOU_DO_NOT_HAVE_ANY_WORKGROUPS')}
        </Typography>
      </Box>
    ) : null;
  };

  return (
    <>
      {workgroupData.length > 0 ? (
        <div className='workgroup-GridContainer' id='workgroup-grid-container'>
          {workgroupData.map((row, i) => (
            <Grid
              container
              spacing={2}
              key={i}
              style={{ width: '100%' }}
              data-testid={`workgroup-item-${i}`}
            >
              {row.map((cell, j) => (
                <Grid
                  key={j}
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={3}
                  xl={3}
                  className='genericWorkgroup-gridItem'
                >
                  <div key={j} className='workgroup-cards' onClick={() => navigateToApps(cell)} id='workgroup-cards'>
                    <ThemeMode />
                    <div className='workgroup-cardsHeader'>
                      <div className='workgroup-cardIconSection'>
                        <CorporateFareOutlined className='card-icon' />
                      </div>
                      <div className='workgroup-title'>
                        <DashboardTypography testId='workgroup-name' title={cell.name} id={'work-group-name-title'} typographyClass={'title-18-700 text-overflow-15rem'}>{cell.name}</DashboardTypography>
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          ))}
        </div>
      ) : (
        renderEmptyWorkgroup()
      )}
    </>
  );
};

WorkgroupsGridView.propTypes = {
  allWorkgroups: PropTypes.array,
  loading: PropTypes.bool
};
export default WorkgroupsGridView;
