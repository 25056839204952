import { Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../styles/DashboardLayout.css';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useStyles } from '../../styles/periodFilterStyles';
import TaskFilter from './TaskFilter';
import LinkFilter from './LinkFilter';
import ReworkAndOccurrencesPickers from './ReworkAndOccurrencesPickers';

const OccurrencesFilter = (props) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState();
  const [occurrencesError, setOccurrencesError] = useState(false);
  const classes = useStyles();
  const [selectedTask, setSelectedTask] = useState({
    taskIds: [],
    filterType: 'task',
    name: []
  });

  const [selectedLinks, setSelectedLinks] = useState({
    linkIds: [],
    filterType: 'link',
    name: []
  });

  const [occurrencesFilter, setOccurrencesFilter] = useState({
    filterType: 'occurrencesInCase',
    scope: 'TASKS',
    name: [],
    operator: '>=',
    value: 1
  });

  useEffect(() => {
    setSelectedTab(0);
    if (props?.filterObj && props?.filterObj?.scope) {
      if (props?.filterObj?.scope === 'TASKS') {
        setSelectedTab(0);
        setSelectedTask((prevSelectedTask) => ({
          ...prevSelectedTask,
          taskIds: props.filterObj.taskIds
        }));
      }
      if (props?.filterObj?.scope === 'LINKS') {
        setSelectedTab(2);
        setSelectedLinks((prevSelectedLinks) => ({
          ...prevSelectedLinks,
          linkIds: props.filterObj.linkIds
        }));
      }
      setOccurrencesFilter(props.filterObj);
    }
  }, []);

  useEffect(() => {
    props.handleFilterChange(occurrencesFilter);
  }, [occurrencesFilter]);

  const getScope = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return 'TASKS';
      case 2:
        return 'LINKS';
    }
  };

  const checkIsValid = (itemIds, newIsValid) => {
    if (newIsValid && itemIds?.length > 0) {
      props.setIsValid(true);
    } else {
      props.setIsValid(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    const selectedScope = getScope(newValue);
    setOccurrencesFilter((prevOccurrencesFilterData) => ({
      ...prevOccurrencesFilterData,
      scope: selectedScope
    }));
    setSelectedTab(newValue);
  };

  const handleTaskFilterChange = (value) => {
    setOccurrencesFilter((prevOccurrencesFilterData) => ({
      ...prevOccurrencesFilterData,
      taskIds: value ? value.taskIds : []
    }));
    props.handleFilterChange(occurrencesFilter);
    checkIsValid(value.taskIds, occurrencesFilter.value >= 1);
  };

  const handleLinkFilterChange = (value) => {
    setOccurrencesFilter((prevOccurrencesFilterData) => ({
      ...prevOccurrencesFilterData,
      linkIds: value ? value.linkIds : []
    }));
    props.handleFilterChange(occurrencesFilter);
    checkIsValid(value.linkIds, occurrencesFilter.value >= 1);
  };

  const handleOccurrencesChange = (event) => {
    setOccurrencesFilter((prevOccurrencesFilterData) => ({
      ...prevOccurrencesFilterData,
      value: event.target.value
    }));
    if (event.target.value !== '' && Number(event.target.value) >= 1) {
      setOccurrencesError(false);
      checkIsValid(
        occurrencesFilter.scope === 'TASKS' ? occurrencesFilter.taskIds : occurrencesFilter.linkIds,
        true
      );
    } else {
      setOccurrencesError(true);
      props.setIsValid(false);
    }
  };

  const handleOperatorChange = (selectedOperator) => {
    setOccurrencesFilter((prevOccurrencesFilterData) => ({
      ...prevOccurrencesFilterData,
      operator: selectedOperator
    }));
  };

  return (
    <>
      <div className={classes.tabsContainer} id='filters-main-container'>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          sx={{
            borderRadius: '6px',
            padding: '4px',
            margin: '2%'
          }}
          id='filters-container-tabs'
        >
          <Tab
            label='Tasks'
            sx={{
              borderRadius: '6px',
              paddingLeft: '12px',
              paddingRight: '12px',
              paddingTop: '6px',
              marginRight: '3px',
              textTransform: 'unset',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 500,
              margin: '5px'
            }}
            className={selectedTab === 0 ? classes.selectedTab : ''}
            id='filters-container-tab'
          />
          <Tab
            label='Cases'
            disabled='true'
            sx={{
              borderRadius: '6px',
              paddingLeft: '12px',
              paddingRight: '12px',
              paddingTop: '6px',
              marginRight: '3px',
              textTransform: 'unset',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 500,
              margin: '5px'
            }}
            className={selectedTab === 1 ? classes.selectedTab : ''}
            id='filters-container-tab'
          />
          <Tab
            label='Links'
            sx={{
              borderRadius: '6px',
              paddingLeft: '12px',
              paddingRight: '12px',
              paddingTop: '6px',
              marginRight: '3px',
              textTransform: 'unset',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 500,
              margin: '5px'
            }}
            className={selectedTab === 2 ? classes.selectedTab : ''}
            id='filters-container-tab'
          />
        </Tabs>
        <div>
          {selectedTab === 0 && (
            <div className={classes.tabContent}>
              <TaskFilter
                workgroupId={props.workgroupId}
                projectId={props.projectId}
                filterObj={selectedTask}
                handleFilterChange={handleTaskFilterChange}
                setIsValid={props.setIsValid}
              />
              <ReworkAndOccurrencesPickers
                reworkOccurrencesError={occurrencesError}
                reworkOccurrencesFilter={occurrencesFilter}
                reworkOccurrenceNumberChange={handleOccurrencesChange}
                handleOperatorChange={handleOperatorChange}
                numericFieldTitle={t('FILTER.OCCURRENCES')}
                minNumber={'1'}
                minNumberErrorMessage={t('FILTER.OCCURRENCES_ERROR_MESSAGE')}
                placeholder={t('FILTER.PLEASE_ENTER_OCCURRENCES')}
              ></ReworkAndOccurrencesPickers>
            </div>
          )}
          {selectedTab === 1 && <div className={classes.tabContent}></div>}
          {selectedTab === 2 && (
            <div className={classes.tabContent}>
              <LinkFilter
                workgroupId={props.workgroupId}
                projectId={props.projectId}
                handleFilterChange={handleLinkFilterChange}
                filterObj={selectedLinks}
                setIsValid={props.setIsValid}
                data-testid='filter_link_select'
              />
              <ReworkAndOccurrencesPickers
                reworkOccurrencesError={occurrencesError}
                reworkOccurrencesFilter={occurrencesFilter}
                reworkOccurrenceNumberChange={handleOccurrencesChange}
                handleOperatorChange={handleOperatorChange}
                numericFieldTitle={t('FILTER.OCCURRENCES')}
                minNumber={'1'}
                minNumberErrorMessage={t('FILTER.OCCURRENCES_ERROR_MESSAGE')}
                placeholder={t('FILTER.PLEASE_ENTER_OCCURRENCES')}
              ></ReworkAndOccurrencesPickers>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

OccurrencesFilter.propTypes = {
  workgroupId: PropTypes.string,
  projectId: PropTypes.string,
  filterObj: PropTypes.object,
  handleFilterChange: PropTypes.func,
  setIsValid: PropTypes.func
};
export default OccurrencesFilter;
