import { createSlice } from '@reduxjs/toolkit';

const initialfilterLisrPopoverState = {
  filterLisrPopoverRef: '',
  appliedGlobalFilterList: [],
  appliedWigetFilterList: []
};

const filterLisrPopoverSlice = createSlice({
  name: 'filterLisrPopover',
  initialState: initialfilterLisrPopoverState,
  reducers: {
    setFilterListPopoverRef: (state, action) => {
      state.filterLisrPopoverRef = action.payload;
    },

    setAppliedGlobalFilter: (state, action) => {
      state.appliedGlobalFilterList = action.payload;
    },

    setAppliedWidgetFilter: (state, action) => {
      state.appliedWigetFilterList = action.payload;
    },
  }
});

export const {
  setFilterListPopoverRef,
  setAppliedGlobalFilter,
  setAppliedWidgetFilter
} = filterLisrPopoverSlice.actions;
export default filterLisrPopoverSlice.reducer;
