import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Tooltip, useMediaQuery } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useNavigate, generatePath } from 'react-router-dom';
import AddNewApp from './AddNewApp';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { formatDate } from '../../utils/utils';
import '../../styles/AppsListView.scss';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog/DeleteConfirmationDialog';
import DashboardButton from '../shared/Buttons/DashboardButton';
import ThemeMode from '../themeMode/themeMode';

const AppsListView = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dashboardAllData, setAllDashboardData] = useState(props.allApps ? props.allApps : []);
  const isMobileScreen = useMediaQuery((Theme) => Theme.breakpoints.only('sm'));

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [appToDelete, setAppToDelete] = useState(null);
  const [addNewAppOpen, setAddNewAppOpen] = useState(false);
  const [appToEdit, setAppToEdit] = useState(null);

  useEffect(() => {
    setAllDashboardData(props.allApps ? [...props.allApps] : []);
  }, [props.allApps]);

  const editApp = (appId) => {
    const appEdit = props.allApps.find((app) => app.id === appId);
    setAppToEdit(appEdit);
    setAddNewAppOpen(true);
  };

  const handleDeleteApp = (appId) => {
    setAppToDelete(appId);
    setConfirmationOpen(true);
  };

  const deleteApp = () => {
    props.deleteApp(appToDelete);
    setAppToDelete(null);
    setConfirmationOpen(false);
  };
  const createApp = (app) => {
    props.handleSave(app);
  };

  const updateApp = (app) => {
    props.updateApp(app);
  };

  const closeAppNewModal = () => {
    setAddNewAppOpen(false);
    setAppToEdit(null);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const navigateToDashboard = (selectedAppId) => {
    const routePattern = '/Workgroups/:workgroupId/Apps/:appId';
    const params = {
      workgroupId: props.workgroupId,
      appId: selectedAppId
    };

    const generatedUrl = generatePath(routePattern, params);
    const urlWithQueryParams = `${generatedUrl}?${new URLSearchParams({
      workgroupName: encodeURIComponent(props.workgroupName)
    })}`;
    navigate(urlWithQueryParams, {
      state: {
        workgroupName: props.workgroupName
      }
    });
  };

  return (
    <>
      {dashboardAllData?.length > 0 && (
        <div className='app-list-view' id='app-list-view'>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center'
            }}
          >
            {dashboardAllData.map((app, i) => (
              <Box
                key={i}
                data-testid='Tiles'
                className='list-card'
                id='apps-card'
              >
                <ThemeMode></ThemeMode>
                <div className='list-container' >
                  <div
                    style={{
                      width: isMobileScreen ? '80px' : '225px'
                    }}
                  >
                    <Typography
                      textAlign='start'
                      data-testid='app-name'
                      className={`${app.name.length > 5 ? 'truncate' : null}`}
                      sx={{
                        marginLeft: 2.5,
                        width: isMobileScreen ? 'unset' : 350,
                        color: '#6876D5',
                        fontSize: isMobileScreen ? 12 : 16,
                        fontWeight: isMobileScreen ? 400 : 600,
                        fontFamily: 'Inter'
                      }}
                    >
                      <span title={app.name} className='app-name'>
                        {app.name}
                      </span>
                    </Typography>
                  </div>
                  <div style={{ width: isMobileScreen ? '80px' : '300px' }}>
                    <Typography
                      textAlign='start'
                      sx={{
                        fontSize: isMobileScreen ? 10 : 12,
                        marginLeft: isMobileScreen ? 'unset' : 13,
                        color: '#667085',
                        fontWeight: 400,
                        fontFamily: 'Inter'
                      }}
                    >
                      {app?.modifiedAt ? (
                        <span>
                          {t('APP.DATE_MODIFIED')} {formatDate(app.modifiedAt)}
                        </span>
                      ) : (
                        t('APP.DATE_EDITED')
                      )}
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: isMobileScreen ? '79px' : '155px',
                      marginRight: isMobileScreen ? '20px' : ''
                    }}
                  >
                    <div className='favourite-button'>
                      <DashboardButton color='primary'
                        dataTestid='apps-edit-menu'
                        eventHandler={() => navigateToDashboard(app.id)}
                      >
                        {t('OPEN')}
                      </DashboardButton>

                      <Button
                        className='apps-edit-btn'
                        data-testid='edit-app-button'
                        onClick={() => editApp(app.id)}
                        sx={{ marginLeft: '7px' }}
                      >
                        <Tooltip title={t('EDIT')} arrow>
                          <EditOutlinedIcon sx={{ color: '#667085' }} />
                        </Tooltip>
                      </Button>
                      {addNewAppOpen && (
                        <AddNewApp
                          open={addNewAppOpen}
                          handleClose={closeAppNewModal}
                          handleSave={createApp}
                          projects={props.projects}
                          totalApps={props.totalApps}
                          appToEdit={appToEdit}
                          updateApp={updateApp}
                        />
                      )}
                      <Button
                        sx={{ minWidth: 0, padding: 'unset', marginLeft: '10px' }}
                        data-testid='delete-app-btn'
                        onClick={() => handleDeleteApp(app.id)}
                      >
                        <Tooltip title={t('DELETE')} arrow>
                          <DeleteOutlinedIcon sx={{ color: '#667085' }} />
                        </Tooltip>
                      </Button>
                      {confirmationOpen && (
                        <DeleteConfirmationDialog
                          open={confirmationOpen}
                          onClose={handleConfirmationClose}
                          onDeleteConfirm={deleteApp}
                          dialogTitle={t('APP.DELETE_APP')}
                          deleteConfirmationMessage={t('APP.DELETE_APP_CONFIRMATION')}
                          deleteConfirmationNote={t('APP.DELETE_APP_NOTE')}
                        ></DeleteConfirmationDialog>
                      )}
                    </div>
                  </div>
                </div>
              </Box>
            ))}
          </Box>
        </div>
      )}
    </>
  );
};

AppsListView.propTypes = {
  deleteApp: PropTypes.func,
  allApps: PropTypes.array,
  workgroupName: PropTypes.string,
  workgroupId: PropTypes.string,
  totalApps: PropTypes.array,
  projects: PropTypes.array,
  handleSave: PropTypes.func,
  updateApp: PropTypes.func
};

export default AppsListView;
