import axios from 'axios';
import { ENDPOINTURL } from '../utils/ApiConstants';
import { appInteractionsCountByUsername, userListReport } from '../utils/MockData';

const ReportService = {
  generateReport: async (requestBody) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      if (requestBody.reportType === 'APP_INTERACTIONS_COUNT_BY_USERNAME') {
        return appInteractionsCountByUsername;
      } else {
        return userListReport;
      }
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
          ENDPOINTURL.API_HUBCAP +
          ENDPOINTURL.API_DESIGN_REPORT_DATA,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};

export default ReportService;
