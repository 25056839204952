export const setGlobalFiltersInSessionStorage = (key, appliedGlobalFilters) => {
  window.sessionStorage.setItem(key, JSON.stringify(appliedGlobalFilters));
};

export const getGlobalFiltersFromSessionStorage = (globalFilterKeyValue) => {
  return JSON.parse(window.sessionStorage.getItem(globalFilterKeyValue));
};

export const removeGlobalFiltersFromSessionStorage = (globalFilterKeyValue) => {
  window.sessionStorage.removeItem(globalFilterKeyValue);
};
