import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts';
import { Paper } from '@mui/material';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedVariantIndex } from '../../Redux/slices/variantSelectionSlice';
import DashboardButton from '../shared/Buttons/DashboardButton';
import '../../styles/ProcessModelVariantsSelectionPanel.scss';
import ApiService from '../../services/ApiService';
import ThemeMode from '../themeMode/themeMode';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';

const ProcessModelVariantsSelectionPanel = (props) => {
  const { sliderCombination } = useSelector((state) => state.variantSelection);
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const { t } = useTranslation();

  const [selectedStatistics, setSelectedStatistics] = useState({});
  const [totalSelectedCasesPercentage, setTotalSelectedCasesPercentage] = useState(0);
  const [selectedVariantRange, setSelectedVariantRange] = useState({
    firstVariantIndex: 0,
    lastVariantIndex: 0
  });

  let chart;
  let statistics;

  useEffect(() => {
    fetchStatistics();
  }, []);

  useEffect(() => {
    /**
     * if global state has no selected variant index then set 0 to last variant for selection
     * Else use gobal state values for selection
     */

    if (sliderCombination.firstVariantIndex === 0 && sliderCombination.lastVariantIndex === 0) {
      setSelectedVariantRange({
        firstVariantIndex: 0,
        lastVariantIndex: props.variantsStatistic.length - 1
      });
    } else {
      setSelectedVariantRange({
        firstVariantIndex: sliderCombination.firstVariantIndex,
        lastVariantIndex: sliderCombination.lastVariantIndex
      });
    }
  }, [sliderCombination]);

  useEffect(() => {
    /**
     * Render bar chart and set brush on it using selected varinat index
     */
    renderChart();
    setupBrushRange(selectedVariantRange.firstVariantIndex, selectedVariantRange.lastVariantIndex);
  }, [selectedStatistics]);

  const fetchStatistics = async () => {
    const requestBody = {
      search: null,
      filterPageNo: '1',
      filterPageSize: '100',
      filterSortOrder: 'ASC'
    };
    try {
      const response = await ApiService.getSvgStatisticData(
        requestBody,
        props.workgroupId,
        props.chartOptions.projectIds[0]
      );
      setSelectedStatistics(response);
    } catch (error) {
      toast.error(t('FILTER.FAILED_TO_FETCH_STATISTICS'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    }
  };

  const setupBrushRange = (firstSelectedVariantIndex, lastSelectedVariantIndex) => {
    setTimeout(() => {
      chart.dispatchAction({
        type: 'brush',
        areas: [
          {
            brushType: 'lineY',
            xAxisIndex: 0,
            coordRange: [firstSelectedVariantIndex, lastSelectedVariantIndex]
          }
        ]
      });
    }, 800);
    selectedPercentage(firstSelectedVariantIndex, lastSelectedVariantIndex);
  };

  const calculateSelectedVariantCount = () => {
    return selectedVariantRange.lastVariantIndex - selectedVariantRange.firstVariantIndex + 1;
  };

  const renderChart = () => {
    chart = echarts.init(chartRef.current);
    const variantsStatistic = [...props.variantsStatistic].reverse();
    statistics = variantsStatistic;
    const xAxisData = variantsStatistic.map((variant) => variant.variantName);

    const seriesData = variantsStatistic?.map((variant) => ({
      value: parseFloat(((variant.occurrences * 100) / selectedStatistics.totalCases).toFixed(2)),
      name: 'Variant ' + variant.variantName
    }));
    const option = {
      grid: {
        top: 10
      },
      xAxis: {
        type: 'value',
        data: xAxisData,
        interval: 10,
        name: 'Frequency',
        nameLocation: 'middle',
        nameGap: 20,
        nameTextStyle: {
          fontWeight: 'bold'
        }
      },
      yAxis: {
        type: 'category',
        name: 'Variants',
        nameLocation: 'middle',
        nameGap: 20,
        axisTick: null,
        axisLabel: null,
        axisLine: {
          show: false
        },
        nameTextStyle: {
          fontWeight: 'bold'
        }
      },
      tooltip: {
        appendToBody: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function (params) {
          const percent = params[0]?.value ? params[0]?.value : '...';
          return `${params[0]?.name}  -  ${percent}%`;
        }
      },
      series: [
        {
          data: seriesData,
          type: 'bar',
          barCategoryGap: '40%'
        }
      ],
      brush: {
        brushType: 'lineY',
        xAxisIndex: 0,
        throttleType: 'debounce',
        brushMode: 'single',
        brushLink: 'all',
        outOfBrush: {
          colorAlpha: 0.2
        },
        brushStyle: {
          color: '#0400B91A',
          borderColor: '#00000026',
          borderWidth: 2
        }
      }
    };
    chart.setOption(option);

    chart.on('brushEnd', function (params) {
      const [firstSelectedVariantIndex, lastSelectedVariantIndex] = params.areas[0].coordRange;
      selectedPercentage(firstSelectedVariantIndex, lastSelectedVariantIndex);
      setSelectedVariantRange({
        firstVariantIndex: firstSelectedVariantIndex,
        lastVariantIndex: lastSelectedVariantIndex
      });
    });
  };

  const selectedPercentage = (firstSelectedVariantIndex, lastSelectedVariantIndex) => {
    let occurance = 0;
    for (let i = firstSelectedVariantIndex; i <= lastSelectedVariantIndex; i++) {
      occurance = occurance + statistics[i]?.occurrences;
    }
    const selectedPercent = parseFloat(
      ((occurance / selectedStatistics?.totalCases) * 100).toFixed(2)
    );
    setTotalSelectedCasesPercentage(isNaN(selectedPercent) ? '...' : selectedPercent);
  };

  const ApplyVariants = () => {
    dispatch(
      setSelectedVariantIndex({
        firstSelectedVariantIndex: selectedVariantRange.firstVariantIndex,
        lastSelectedVariantIndex: selectedVariantRange.lastVariantIndex
      })
    );
    props.onApply(selectedVariantRange.firstVariantIndex, selectedVariantRange.lastVariantIndex);
    props.panelClose();
  };

  return (
    <Paper
      sx={{
        position: 'absolute',
        top: '60px',
        right: '0',
        padding: '15px',
        height: '85vh',
        zIndex: 1
      }}
      className='process-model-variants-selection-panel'
      id='variants-selection-panel-container'
    >
      <ThemeMode></ThemeMode>
      <div className='header-container' id='variants-selection-panel-header'>
        <div>
          <DashboardTypography typographyClass='title-16-700' id='variants-selection-panel-title'>
            {t('VARIANTS')}
          </DashboardTypography>
        </div>

        <div className='actions-container'>
          <DashboardButton dataTestid='apply-selected-variant' eventHandler={ApplyVariants}>
            {t('PROCESS_MODEL.APPLY')}
          </DashboardButton>

          <DashboardButton
            color='secondary'
            dataTestid='close-model'
            eventHandler={props.panelClose}
          >
            {t('PROCESS_MODEL.CLOSE')}
          </DashboardButton>
        </div>
      </div>
      <div className='selected-percent'>
        <DashboardTypography
          typographyClass='title-16-500'
          id='variants-selection-panel-selected-cases'
        >
          {t('PROCESS_MODEL.TOTAL_SELECTED_CASES_MESSAGE', {
            selectedBarsCount: calculateSelectedVariantCount(),
            totalSelectedCasesPercentage: totalSelectedCasesPercentage
          })}
        </DashboardTypography>
      </div>
      <div ref={chartRef} className='chart-container'></div>
    </Paper>
  );
};

ProcessModelVariantsSelectionPanel.propTypes = {
  variantsStatistic: PropTypes.array.isRequired,
  panelClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  chartOptions: PropTypes.object,
  workgroupId: PropTypes.string
};

export default ProcessModelVariantsSelectionPanel;
