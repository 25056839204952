import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import placeholderImage from '../../assets/thumbnails/placeholderImage.svg';
import PropTypes from 'prop-types';
import { useNavigate, generatePath } from 'react-router-dom';
import { Grid, Tooltip } from '@mui/material';
import '../../styles/DashboardLayout.css';
import '../../styles/AppsLayout.scss';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useStyles } from '../../styles/appsViewStyles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddNewApp from './AddNewApp';
import { formatDate } from '../../utils/utils';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog/DeleteConfirmationDialog';
import DashboardButton from '../shared/Buttons/DashboardButton';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import ThemeMode from '../themeMode/themeMode';

const AppsGridView = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [dashboardAllData, setAllDashboardData] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [appToDelete, setAppToDelete] = useState(null);
  const [addNewAppOpen, setAddNewAppOpen] = useState(false);
  const [appToEdit, setAppToEdit] = useState(null);

  const classes = useStyles();
  useEffect(() => {
    (async () => {
      const data = await chunk(props.allApps, 4);
      setAllDashboardData([...data]);
    })();
  }, [props.allApps]);

  const editApp = (appId) => {
    const appEdit = props.allApps.find((app) => app.id === appId);
    setAppToEdit(appEdit);
    setAddNewAppOpen(true);
  };

  const createApp = (app) => {
    props.handleSave(app);
  };

  const updateApp = (app) => {
    props.updateApp(app);
  };

  const closeAppNewModal = () => {
    setAddNewAppOpen(false);
    setAppToEdit(null);
  };

  const handleDeleteApp = (appId) => {
    setAppToDelete(appId);
    setConfirmationOpen(true);
  };

  const deleteApp = () => {
    props.deleteApp(appToDelete);
    setAppToDelete(null);
    setConfirmationOpen(false);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const chunk = async (arr, size) =>
    Array.from({ length: Math.ceil(arr?.length / size) }, (v, i) =>
      arr?.slice(i * size, i * size + size)
    );

  const navigateToDashboard = (selectedAppId) => {
    const routePattern = '/Workgroups/:workgroupId/Apps/:appId';
    const params = {
      workgroupId: props.workgroupId,
      appId: selectedAppId
    };

    const generatedUrl = generatePath(routePattern, params);
    const urlWithQueryParams = `${generatedUrl}?${new URLSearchParams({
      workgroupName: encodeURIComponent(props.workgroupName)
    })}`;
    navigate(urlWithQueryParams);
  };

  return (
    <div id='app-grid-view'>
      <Grid container spacing={2}>
        {dashboardAllData.length > 0 && (
          <>
            {dashboardAllData.map((row, i) => (
              <Grid
                container
                item
                key={i}
                spacing={3}
                sx={{ marginLeft: '10px', marginRight: '10px' }}
              >
                {row.map((cell, j) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    xl={3}
                    key={j}
                    className='genericApps-gridItem genericApps-gridRightItem'
                  >
                    <div key={j} className={classes.gridContainer} id='apps-card'>
                      <ThemeMode></ThemeMode>
                      <div className={classes.gridImageContainer}>
                        <img src={cell?.thumbnail ? 'data:image/png;base64,' + cell?.thumbnail : placeholderImage} className={classes.placeholderImage} />
                      </div>
                      <span className={classes.appDetailsSection}>
                        <div className={classes.appHeaderSection}>
                          <DashboardTypography testId={'app-name'} typographyClass={'title-20-700 text-overflow-95'} title={cell.name} id={'app-name'}>{cell.name}</DashboardTypography>
                        </div>
                        <div className={classes.DescriptionSection}>
                          <DashboardTypography typographyClass={'title-14-400 text-overflow-95'} title={cell?.description} id={'app-description'}>{cell?.description}</DashboardTypography>
                        </div>
                      </span>
                      <hr className={classes.divider} />
                      <div className={classes.Editedate}>
                        <div className='editedText-section'>
                          {
                            cell?.modifiedAt ? (
                              <DashboardTypography id={'app-date-modified'} typographyClass={'title-12-400'}> {t('APP.DATE_MODIFIED')} {formatDate(cell.modifiedAt)}</DashboardTypography>
                            ) : (
                              <DashboardTypography id={'app-date-edited'} typographyClass={'title-12-400'}> {t('APP.DATE_EDITED')}</DashboardTypography>
                            )
                          }
                        </div>
                        <div className='actionBtn-section'>
                          <DashboardButton color='primary'
                            dataTestid='apps-edit-menu'
                            eventHandler={() => navigateToDashboard(cell.id)}
                          >
                            {t('OPEN')}
                          </DashboardButton>
                          <Button
                            className='apps-edit-btn'
                            data-testid='edit-app-button'
                            onClick={() => editApp(cell.id)}
                            role='edit-app-button'
                          >
                            <Tooltip title={t('EDIT')} arrow>
                              <EditOutlinedIcon sx={{ color: '#667085' }} />
                            </Tooltip>
                          </Button>
                          {addNewAppOpen && (
                            <AddNewApp
                              open={addNewAppOpen}
                              handleClose={closeAppNewModal}
                              handleSave={createApp}
                              projects={props.projects}
                              totalApps={props.totalApps}
                              appToEdit={appToEdit}
                              updateApp={updateApp}
                            />
                          )}
                          <Button
                            sx={{ minWidth: 0, padding: 'unset' }}
                            data-testid='delete-app-btn'
                            onClick={() => handleDeleteApp(cell.id)}
                          >
                            <Tooltip title={t('DELETE')} arrow>
                              <DeleteOutlinedIcon sx={{ color: '#667085' }} />
                            </Tooltip>
                          </Button>
                        </div>
                        {confirmationOpen && (
                          <DeleteConfirmationDialog
                            open={confirmationOpen}
                            onClose={handleConfirmationClose}
                            onDeleteConfirm={deleteApp}
                            dialogTitle={t('APP.DELETE_APP')}
                            deleteConfirmationMessage={t('APP.DELETE_APP_CONFIRMATION')}
                            deleteConfirmationNote={t('APP.DELETE_APP_NOTE')}
                          ></DeleteConfirmationDialog>
                        )}
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </div>
  );
};

AppsGridView.propTypes = {
  deleteApp: PropTypes.func,
  allApps: PropTypes.array,
  workgroupName: PropTypes.string,
  workgroupId: PropTypes.string,
  totalApps: PropTypes.array,
  projects: PropTypes.array,
  handleSave: PropTypes.func,
  updateApp: PropTypes.func
};

export default AppsGridView;
