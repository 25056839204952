import React from 'react';
import PropTypes from 'prop-types';
import { PAGE_SIZES } from '../../../utils/Constants';
import TablePagination from '@mui/material/TablePagination';
import './PaginationComponent.scss';
import { useTranslation } from 'react-i18next';

const PaginationComponent = (props) => {
  const { t } = useTranslation();
  const handleChangePage = (event, newPage) => {
    props.handlePageChange(event, newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    props.handlePageSizeChange(newPageSize);
  };

  return (
    <div data-testid='pagination-component' id='pagination-component' className='pagination-component'>
    <TablePagination
      component="div"
      count={props.data?.totalElements || 0}
      page={props.currentPageNumber}
      onPageChange={handleChangePage}
      rowsPerPage={props.pageSize}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={PAGE_SIZES}
      labelDisplayedRows={({ from, to, count }) =>
        t('PAGINATION_SHOWING', { from, to, count })
      }
      labelRowsPerPage={t('ITEMS_PER_PAGE')}
      data-testid="page-size-dropdown"
    />
    </div>
  );
};

PaginationComponent.propTypes = {
  data: PropTypes.object,
  pageSize: PropTypes.number,
  handlePageSizeChange: PropTypes.func,
  currentPageNumber: PropTypes.number,
  handlePageChange: PropTypes.func,
};

export default PaginationComponent;