import React, { useState, useEffect, useContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Theme from '../Theme';
import Header from '../header/Header';
import ApiService from '../../services/ApiService';
import { toast } from 'react-toastify';
import WorkgroupsHeader from './WorkgroupsHeader';
import WorkgroupsGridView from './WorkgroupsGridView';
import LoadingSpinner from '../loader/LoadingSpinner';
import '../../styles/LoadingSpinner.scss';
import '../../styles/WorkgroupsContainer.scss';
import { t } from 'i18next';
import { AuthContext } from '../../utils/KeycloakWrapper';
import AuthInterceptor from '../../utils/AuthInterceptor';
import PaginationComponent from '../shared/Pagination/PaginationComponent';
import ThemeMode from '../themeMode/themeMode';

const WorkgroupsContainer = () => {
  const [allWorkGroups, setAllWorkGroups] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [pageSize, setPageSize] = useState(12);
  const [loading, setLoading] = useState(false);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    AuthInterceptor(authContext);
    const fetchData = async () => {
      setCurrentPageNumber(0);
      await fetchAllWorkGroups(0, '');
    };
    if (authContext && Object.keys(authContext).length !== 0) {
      fetchData();
    }
  }, [authContext]);

  const searchWorkgroup = async (searchText) => {
    setSearchQuery(searchText);
    setCurrentPageNumber(0);
    await fetchAllWorkGroups(0, searchText);
  };

  const fetchAllWorkGroups = async (pageNumber, searchText, newPageSize) => {
    try {
      setLoading(true);
      const response = await ApiService.getAllWorkgroups(
        pageNumber,
        newPageSize ? newPageSize : pageSize,
        searchText
      );
      setAllWorkGroups(response);
      setLoading(false);
    } catch (error) {
      if (!error.toString().includes('401')) {
        setLoading(false);
        toast.error(t('WORKGROUPS.FAILED_TO_FETCH_WORKGROUPS'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: 'light'
        });
      }
    }
  };

  const handlePageChange = async (event, pageNumber) => {
    const selectedPageNumber = pageNumber - 1;
    setCurrentPageNumber(selectedPageNumber);
    await fetchAllWorkGroups(selectedPageNumber, searchQuery);
  };

  const getStartingDataNumber = () => {
    return currentPageNumber * pageSize + 1;
  };

  const getEndingDataNumber = () => {
    if (currentPageNumber + 1 === allWorkGroups?.totalPages) {
      return allWorkGroups?.totalElements;
    }
    return (currentPageNumber + 1) * pageSize;
  };

  const handlePageSizeChange = async (selectedPageSize) => {
    setPageSize(selectedPageSize);
    setCurrentPageNumber(0);
    await fetchAllWorkGroups(0, searchQuery, selectedPageSize);
  };

  return (
    <ThemeProvider theme={Theme}>
      <ThemeMode />
      <Header />
      <WorkgroupsHeader searchWorkgroup={searchWorkgroup} />
      <div className={loading === true ? 'mainSection-blur' : ''}>
        <div className='workgroup-container' id='workgroup-container'>
          <WorkgroupsGridView
            loading={loading}
            allWorkgroups={allWorkGroups?.content}
            data-testid='WorkgroupsGridViewContainer'
          />
        </div>
      </div>
      <div
        data-testid='paginationView'
        id='workgroup-pagination'
        className={
          loading === true ? 'mainSection-blur workgroup-pagination' : 'workgroup-pagination'
        }
      >
        <PaginationComponent
          data={allWorkGroups}
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          getStartingDataNumber={getStartingDataNumber}
          getEndingDataNumber={getEndingDataNumber}
          currentPageNumber={currentPageNumber}
          handlePageChange={handlePageChange}
        ></PaginationComponent>
      </div>
      {loading === true && <LoadingSpinner></LoadingSpinner>}
    </ThemeProvider>
  );
};

export default WorkgroupsContainer;
