import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

const SelectAllOption = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <MenuItem key={'SelectAll'}>
        <Checkbox
          data-testid='select-all-checkbox'
          onClick={(event) => {
            props.handleSelectAll(event);
          }}
          checked={props.checkAllSelected()}
        />
        <ListItemText>{t('FILTER.SELECT_ALL')}</ListItemText>
      </MenuItem>
    </div>
  );
};
SelectAllOption.propTypes = {
  handleSelectAll: PropTypes.func,
  checkAllSelected: PropTypes.func
};
export default SelectAllOption;
