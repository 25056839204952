import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import '../../../../styles/LoadingSpinner.scss';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ImageWidgetPreviewPanel from './ImageWidgetPreviewPanel/ImageWidgetPreviewPanel.js';
import ImageWidgetConfigPanel from './ImageWidgetConfigPanel/ImageWidgetConfigPanel.js';
import './configureImageWidget.scss';
import WidgetConfigHeaderComponent from '../../../shared/WidgetConfigHeaderComponent/WidgetConfigHeaderComponent.js';
import getChartOptionObject from '../../../../utils/getChartOptionObject.js';

const ConfigureImageWidget = (props) => {
  const { t } = useTranslation();
  const [open] = React.useState(props.openImageDialogContainer);
  const [chartLabelError, setChartLabelError] = useState(false);
  const [imageUploadedError, setImageUploadedError] = useState(false);
  const [chartOptions, setChartOptions] = useState({
    ...getChartOptionObject('image', 'image', '')
  });

  useEffect(() => {
    setChartOptions({ ...props.chartOptions });
  }, []);

  const closeConfigPopup = () => {
    props.onClose();
  };

  const saveImageWidgetConfiguration = () => {
    if (chartOptions.title === '') {
      setChartLabelError(true);
    } else if (chartOptions.imageData === '') {
      setImageUploadedError(true);
    } else {
      props.saveImageWidgetConfig(chartOptions, chartOptions?.imageData);
      props.onClose();
    }
  };

  return (
    <div>
      <Dialog
        onClose={closeConfigPopup}
        aria-labelledby='add-widget-dialog-title'
        open={open}
        fullScreen
        role='dialog'
      >
        <Grid container spacing={2} className='image-container-grid'>
          <WidgetConfigHeaderComponent
            headerTitle={t('IMAGE_WIDGET.ADD_IMAGE')}
            onClose={closeConfigPopup}
            saveChartConfig={saveImageWidgetConfiguration}
          ></WidgetConfigHeaderComponent>
          <Grid
            item
            xs={8}
            sx={{
              boxShadow: 'unset'
            }}
            id='image-container-grid'
          >
            <ImageWidgetPreviewPanel chartOptions={chartOptions}></ImageWidgetPreviewPanel>
          </Grid>
          <Grid item xs={4} className='image-config-panel' id='image-widegt-setting-panel'>
            <ImageWidgetConfigPanel
              chartOptions={chartOptions}
              setChartOptions={setChartOptions}
              chartLabelError={chartLabelError}
              setChartLabelError={setChartLabelError}
              imageUploadedError={imageUploadedError}
              setImageUploadedError={setImageUploadedError}
            ></ImageWidgetConfigPanel>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

ConfigureImageWidget.propTypes = {
  openImageDialogContainer: PropTypes.bool,
  onClose: PropTypes.func,
  chartOptions: PropTypes.object,
  saveImageWidgetConfig: PropTypes.func
};

export default ConfigureImageWidget;
