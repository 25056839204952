import { createSlice } from '@reduxjs/toolkit';

const variantListState = {
  variants: [],
  tasks: [],
  links: []
};

const variantSlice = createSlice({
  name: 'variants',
  initialState: variantListState,
  reducers: {
    setVariantList: (state, action) => {
      state.variants = action.payload;
    },

    setTaskList: (state, action) => {
      state.tasks = action.payload;
    },

    setLinkList: (state, action) => {
      state.links = action.payload;
    },
  }
});

export const { setVariantList, setTaskList, setLinkList } = variantSlice.actions;
export default variantSlice.reducer;