import { formatYAxisLabel } from '../utils/chartUtils';

const ChartService = {
  getChartOption: (optionObj, data, themeColors, isDashboardEditMode, isWidgetConfigureMode) => {
    const seriesConfig = {
      type: optionObj.shape,
      data: data.map((obj, index) => {
        return {
          name: obj.label,
          value: obj.data,
          itemStyle: {
            color:
              optionObj.shape === 'bar' ? themeColors[index % themeColors.length] : themeColors[1]
          }
        };
      }),
      itemStyle: {
        color: themeColors[1]
      }
    };
    let xAxisData = null;

    if (optionObj.shape === 'bar') {
      xAxisData = data.map((obj) => obj.label);
    } else if (optionObj.shape === 'line') {
      xAxisData = data.map((obj) => formatDate(obj.label));
    }

    if (xAxisData !== null) {
      xAxisData = xAxisData.map((value) => {
        const maxLabelLength = 15;
        if (value.length > maxLabelLength) {
          return value.substring(0, maxLabelLength) + '...';
        }
        return value;
      });
    }

    if (optionObj.shape === 'line' || optionObj.shape === 'bar') {
      return {
        grid: {
          left: '8%',
          right: '5%',
          bottom: !isDashboardEditMode || isWidgetConfigureMode ? 100 : 70,
          top: '15%',
          containLabel: true
        },
        xAxis: {
          type: optionObj.xAxisType,
          data: xAxisData,
          name: optionObj.xAxisTitle?.substring(0, 24),
          nameLocation: 'middle',
          nameGap: 20,
          nameTextStyle: {
            fontWeight: 'bold'
          },
          axisLabel: {
            show: true,
            width: 30,
            fontSize: 8,
            fontWeight: 'bold'
          }
        },

        tooltip: {
          show: true,
          trigger: 'axis',
          axisPointer: {
            show: false
          },
          extraCssText: 'z-index: 9999; position: absolute;top: 0; left: 0;',
          formatter: function (params) {
            const formattedValue = formatYAxisLabel(params[0].value, optionObj);
            const xAxisValueLabel = params[0]?.axisValueLabel ? params[0].axisValueLabel : '';
            const yAxisTitle = optionObj.yAxisTitle.substring(0, 24);
            const tooltipContent = params
              .map((param) => {
                let displayedValue;

                if (optionObj.dataToDisplay === 'cost') {
                  const numericValue = parseFloat(formattedValue);

                  displayedValue = ((numericValue * 100) / 100)
                    .toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2
                    })
                    .replace(/\.00$/, '');
                } else {
                  displayedValue = formattedValue;
                }
                const unit = optionObj.dataToDisplay === 'cost' ? ` ${optionObj.unit}` : '';
                const colorCircle = `<span style="display:inline-block; width: 10px; height: 10px; margin-right: 5px; background-color: ${param.color}; border-radius: 50%;"></span>`;
                if (displayedValue === '0') {
                  return `${colorCircle} ${yAxisTitle}(${xAxisValueLabel}):  (No data available)`;
                } else {
                  return `${colorCircle} ${yAxisTitle}(${xAxisValueLabel}):  ${displayedValue} ${unit}`;
                }
              })
              .join('<br/>');

            return tooltipContent;
          }
        },

        yAxis: {
          type: optionObj.yAxisType,
          axisPointer: {
            type: 'shadow',

            label: {
              formatter: function (params) {
                const customFormattedValue = formatYAxisLabel(params.value, optionObj);

                if (optionObj.dataToDisplay === 'caseDuration') {
                  return customFormattedValue;
                } else {
                  const percentageValue = (params.value * 100).toFixed(2).replace(/\.00$/, '');
                  return `${percentageValue}%`;
                }
              }
            }
          },
          axisLabel: {
            formatter: function (value) {
              return formatYAxisLabel(value, optionObj);
            },
            fontSize: 8,
            fontWeight: 'bold'
          },
          name: optionObj.yAxisTitle?.substring(0, 24),
          nameLocation: 'end',
          nameGap: 25,
          nameTextStyle: {
            fontWeight: 'bold',
            align: 'left'
          }
        },
        dataZoom: [...addDataZoomToChartsConfig(isDashboardEditMode, isWidgetConfigureMode)],
        series: [seriesConfig]
      };
    } else if (optionObj.shape === 'pie') {
      const pieChartData = data.map((obj) => ({
        value: obj.data,
        name: obj.label
      }));
      return {
        tooltip: {
          trigger: 'item',
          ...(optionObj.type === 'designReport' && {
            formatter: function (params) {
              const total = data.reduce((sum, obj) => sum + obj.data, 0);
              const percentage = ((params.value / total) * 100).toFixed(2);
              return `Tab type: ${params.name}<br/>Number of visits: ${params.value} (${percentage}%)`;
            }
          })
        },
        legend: {
          orient: 'vertical',
          right: 'right'
        },
        series: [
          {
            type: optionObj.shape,
            data: pieChartData,
            color: themeColors,
            radius: [0, '50%'],
            label: {
              normal: {
                ...(optionObj.type === 'designReport' && {
                  formatter: '{b}: {d}%',
                  position: 'outside'
                })
              }
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
    } else {
      return null;
    }
  }
};

function addDataZoomToChartsConfig(isDashboardEditMode, isWidgetConfigureMode) {
  if (!isDashboardEditMode || isWidgetConfigureMode) {
    return [
      {
        type: 'inside',
        xAxisIndex: 0,
        minSpan: 5,
        height: 20,
        bottom: 50
      },
      {
        type: 'slider',
        xAxisIndex: 0,
        minSpan: 5,
        height: 20,
        bottom: 60
      }
    ];
  } else {
    return [];
  }
}

export function formatDate(timestamp) {
  const date = new Date(timestamp);

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };
  return date.toLocaleDateString(undefined, options);
}

export default ChartService;
