import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../header/Header';
import '../../styles/page404.css';
import { Typography } from '@mui/material';
import pageNotFound from '../../assets/thumbnails/pageNotFound.svg';

const Page404 = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className='page404'>
        <div>
          <img src={pageNotFound} />
          <h1>{t('PAGE_NOT_FOUND')}</h1>
          <p>{t('PAGE_NOT_EXISTS')}</p>
          <a href='/' style={{ textDecoration: 'none' }}>
            <Typography
              data-testid='homepage-link'
              textAlign='center'
              sx={{
                color: '#041AB9',
                fontSize: 15,
                fontWeight: 700,
                transform: 'none',
                textTransform: 'capitalize'
              }}
            >
              {t('GO_TO_HOMEPAGE')}
            </Typography>
          </a>
        </div>
      </div>
    </>
  );
};

export default Page404;
