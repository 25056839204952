import { createSlice } from '@reduxjs/toolkit';

const initialDataSourcesState = {
  dataSources: [],
};

const dataSourcesSlice = createSlice({
  name: 'dataSources',
  initialState: initialDataSourcesState,
  reducers: {
    setDataSources: (state, action) => {
      state.dataSources = action.payload;
    },
  }
});

export const {
  setDataSources
} = dataSourcesSlice.actions;
export default dataSourcesSlice.reducer;
