import { MenuItem, Select, Typography, TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/DashboardLayout.css';
import { OPERATORS } from '../../utils/Constants';
import { useStyles } from '../../styles/periodFilterStyles';
import { useTranslation } from 'react-i18next';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import ThemeMode from '../themeMode/themeMode';

const ReworkAndOccurrencesPickers = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <div className={classes.customPeriod}>
        <div className={classes.width48}>
          <DashboardTypography id='filter-dialog-title' typographyClass={'title-14-500'}>
              {t('FILTER.OPERATOR')}
            </DashboardTypography>
          <Select
            value={props.reworkOccurrencesFilter.operator}
            onChange={(e) => {
              props.handleOperatorChange(e.target.value);
            }}
            fullWidth
            data-testid='occurrences-operator-select'
            labelId='occurrences-operator_label'
            id='occurrences-operator-label'
            key='occurrences-operator'
            role='listbox'
            sx={{ height: '40px',marginTop:'10px' }}
          >
            <ThemeMode></ThemeMode>
            {OPERATORS.map((operator) => (
              <MenuItem key={operator.key} value={operator.key}>
                {operator.value}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.occurrencesField}>
          <Typography
            data-testid='numeric-field-title'
            sx={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 500,
              color: '#4D5464',
              lineHeight: '20px',
              letterSpacing: '0.07px',
              marginBottom: '5px'
            }}
          >
            {props.numericFieldTitle}
          </Typography>
          <TextField
            id='occurrences-value'
            error={props.reworkOccurrencesError}
            helperText={props.reworkOccurrencesError ? props.minNumberErrorMessage : null}
            value={props.reworkOccurrencesFilter.value}
            onChange={props.reworkOccurrenceNumberChange}
            type='number'
            size='small'
            placeholder={props.placeholder}
            data-testid='value-to-compare'
            inputProps={{ min: props.minNumber }}
          />
        </div>
      </div>
    </>
  );
};

ReworkAndOccurrencesPickers.propTypes = {
  reworkOccurrencesError: PropTypes.bool,
  reworkOccurrencesFilter: PropTypes.object,
  reworkOccurrenceNumberChange: PropTypes.func,
  handleOperatorChange: PropTypes.func,
  numericFieldTitle: PropTypes.string,
  minNumber: PropTypes.string,
  minNumberErrorMessage: PropTypes.string,
  placeholder: PropTypes.string
};

export default ReworkAndOccurrencesPickers;