import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './NoWidgetToDisplay.scss';
import emptyScreenImage from '../../../../../assets/thumbnails/noWidgetsIllustration.svg';
import { setDashboardEditMode } from '../../../../../Redux/slices/dashboardSlice';
import DashboardTypography from '../../../../shared/DashboardTypography/DashboardTypography';

const NoWidgetToDisplay = () => {
  const isDashboardEditMode = useSelector(state => state.dashboard.isDashboardEditMode);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className='card' id='empty-screen-image-container'>
      <span className='empty-screen-image-container'>
        <img src={emptyScreenImage} alt={emptyScreenImage} className='empty-screen-image' />
        <DashboardTypography id='empty-screen-msg' data-testid='empty-screen-msg' typographyClass={'title-16-600'}> {t('EMPTY_SCREEN')}</DashboardTypography>
        {!isDashboardEditMode ? (
          <Button
            data-testid='edit-app-button'
            sx={{
              textTransform: 'unset',
              color: '#5D5AE7',
              fontFamily: 'Inter',
              fontSize: 14,
              fontWeight: 500
            }}
            onClick={() => dispatch(setDashboardEditMode(true))}
          >
            {t('EDIT_APP')}
          </Button>
        ) : (
          <DashboardTypography  data-testid='add-widget-components' id='add-components-to-screen' typographyClass={'title-14-500'}>{t('ADD_COMPONENTS_TO_SCREEN')}</DashboardTypography>
        )}
      </span>
    </div>
  );
};

export default NoWidgetToDisplay;