import React, { useContext, useEffect } from 'react';
import './App.css';
import Dashboard from './components/dashboard/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WorkgroupsContainer from './components/Workgroups/WorkgroupsContainer';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import Apps from './components/screens/AppsContainer';
import './utils/i18n';
import Page404 from './components/page404/Page404';
import AuthInterceptor from './utils/AuthInterceptor';
import { AuthContext } from './utils/KeycloakWrapper';
import ProfilePageDialog from './components/Profile/ProfilePageDialog';
import ThemeMode from './components/themeMode/themeMode';

const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to='/Workgroups' replace />
  },
  {
    path: '/Workgroups',
    element: <WorkgroupsContainer />
  },
  {
    path: '/Workgroups/:workgroupId/Apps',
    element: <Apps />
  },
  {
    path: '/Workgroups/:workgroupId/Apps/:appId',
    element: <Dashboard />
  },
  {
    path: '*',
    element: <Page404 />
  },
  {
    path: '/userinfo',
    element: <ProfilePageDialog />
  }
]);

function App() {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    AuthInterceptor(authContext);
  }, [authContext]);

  return (
    <div className='App'>
      <RouterProvider router={appRouter}></RouterProvider>
      <ToastContainer />
      <ThemeMode />
    </div>
  );
}

export default App;
