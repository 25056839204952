import React from 'react';
import PropTypes from 'prop-types';
import ChartRenderer from '../ChartRenderer.js';
import ChartService from '../../../services/chartService';
import { useSelector } from 'react-redux';

const LineChart = (props) => {
  const isDashboardEditMode = useSelector((state) => state.dashboard.isDashboardEditMode);
  const option = ChartService.getChartOption(props.options, props.chartData, props.themeColors, isDashboardEditMode, props.isWidgetConfigureMode);
  return <ChartRenderer width={props.width} height={props.height} option={option}></ChartRenderer>;
};

LineChart.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  options: PropTypes.object,
  chartData: PropTypes.array,
  themeColors: PropTypes.array,
  isWidgetConfigureMode: PropTypes.bool
};

export default LineChart;
