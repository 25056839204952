import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Select, FormControl, FormHelperText, MenuItem } from '@mui/material';
import '../../styles/AddNewApp.css';
import { capitalize } from '../../utils/utils.js';
import '../../styles/FiltersLayout.scss';
import ThemeMode from '../themeMode/themeMode.js';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography.js';

const FilterTypes = (props) => {
  const { t } = useTranslation();
  const [filterTypeError, setFilterTypeError] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState();

  useEffect(() => {
    if (props.filterTypes) {
      if (
        props.selectedFilter &&
        props.selectedFilter.filterType === 'dimension' &&
        props.selectedFilter?.dimensionName
      ) {
        props.selectedFilter['customFieldName'] = props.selectedFilter.dimensionName;
        props.selectedFilter['customFieldId'] = props.selectedFilter.dimensionId;
      }
      if (
        props.selectedFilter &&
        props.selectedFilter.filterType === 'metric' &&
        props.selectedFilter?.metricName
      ) {
        props.selectedFilter['customFieldName'] = props.selectedFilter.metricName;
      }
      setSelectedFilterTypeHandle(props.filterTypes, props.selectedFilter);
    }
  }, [props.selectedFilter, props.filterTypes]);

  const setSelectedFilterTypeHandle = (filterTypeList, newSelectedFilter) => {
    if (newSelectedFilter?.filterType) {
      if (
        newSelectedFilter.filterType !== 'dimension' &&
        newSelectedFilter.filterType !== 'metric'
      ) {
        setSelectedFilterType(
          filterTypeList.find((filter) => filter.filterType === newSelectedFilter?.filterType)
        );
      } else {
        setSelectedFilterType(
          filterTypeList.find(
            (filter) => filter?.customFieldName === newSelectedFilter?.customFieldName
          )
        );
      }
    }
  };

  const handleFilterTypeChange = (value) => {
    value ? setFilterTypeError(false) : setFilterTypeError(true);
    setSelectedFilterTypeHandle(props.filterTypes, value);
    props.handleFilterTypeChange(value);
  };

  return (
    <div>
      <FormControl fullWidth size='small' error={filterTypeError}>
        <label id='filter_type_label'>
          <DashboardTypography id='filter-type-label' typographyClass={'title-14-500'}>
            {t('SELECT_FILTER_TYPE')}
          </DashboardTypography>
        </label>
        <Select
          labelId='filter_type_label'
          id='filter_type-label'
          key='filter_type'
          data-testid='filter_type_select'
          style={{ backgroundColor: '#F9F9Fc', marginTop: '10px' }}
          placeholder={t('SELECT')}
          value={selectedFilterType || ''}
          onChange={(event) => {
            handleFilterTypeChange(event.target.value);
          }}
        >
          <ThemeMode></ThemeMode>
          {props.filterTypes &&
            props.filterTypes
              .filter((option) => option.filterType !== 'globalSelection')
              .map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option.filterType !== 'dimension' && option.filterType !== 'metric'
                    ? t('FILTER.' + option.filterType.toUpperCase())
                    : capitalize(option.customFieldName)}
                </MenuItem>
              ))}
        </Select>
        {filterTypeError && <FormHelperText>{t('FILTER_TYPE_ERROR_MESSAGE')}</FormHelperText>}
      </FormControl>
    </div>
  );
};
FilterTypes.propTypes = {
  handleFilterTypeChange: PropTypes.func,
  projectId: PropTypes.string,
  selectedFilter: PropTypes.object,
  filterTypes: PropTypes.object
};
export default FilterTypes;
